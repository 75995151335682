import { Route } from '@angular/router';
import { RoutesHelper } from 'src/app/helpers/routes.helper';
import { RawDataComponent } from './raw-data.component';
import { MiningPlanListComponent } from '../../parts/mining-plan/mining-plan-list/mining-plan-list.component';
import { EnrichmentStandardListComponent } from '../../parts/enrichment-standard/enrichment-standard-list/enrichment-standard-list.component';
import { ShippingPlanListComponent } from '../../parts/shipping-plan/shipping-plan-list/shipping-plan-list.component';
import { WarehouseBalanceListComponent } from '../../parts/warehouse-balance/warehouse-balance-list/warehouse-balance-list.component';
import { FundListComponent } from '../../parts/fund/fund-list/fund-list.component';
import { WagonFleetListComponent } from '../../parts/wagon-fleet/wagon-fleet-list/wagon-fleet-list.component';

export const dataRoutes: Route[] = [
  {
    path: RoutesHelper.EMPTY_PATH,
    component: RawDataComponent,
    children: [
      {
        path: RoutesHelper.EMPTY_PATH,
        pathMatch: 'full',
        redirectTo: RoutesHelper.DATA_SHIPPING_PLAN_PATH,
      },
      {
        path: RoutesHelper.DATA_ENRICHMENT_STANDARD,
        loadComponent: () => EnrichmentStandardListComponent,
        data: {
          title: 'НОРМЫ ТК',
          typeDataInstances: 'enrichmentStandard',
          path: RoutesHelper.DATA_ENRICHMENT_STANDARD,
        },
      },
      {
        path: RoutesHelper.DATA_ENRICHMENT_STANDARD_URL,
        loadComponent: () => EnrichmentStandardListComponent,
        data: {
          title: 'НОРМЫ ТК',
          typeDataInstances: 'enrichmentStandard',
          path: RoutesHelper.DATA_ENRICHMENT_STANDARD,
        },
      },
      {
        path: RoutesHelper.DATA_MINING_PLAN,
        loadComponent: () => MiningPlanListComponent,
        data: {
          title: 'ПЛАН ДОБЫЧИ',
          typeDataInstances: 'miningPlan',
          path: RoutesHelper.DATA_MINING_PLAN,
        },
      },
      {
        path: RoutesHelper.DATA_MINING_PLAN_URL,
        loadComponent: () => MiningPlanListComponent,
        data: {
          title: 'ПЛАН ДОБЫЧИ',
          typeDataInstances: 'miningPlan',
          path: RoutesHelper.DATA_MINING_PLAN,
        },
      },
      {
        path: RoutesHelper.DATA_SHIPPING_PLAN_PATH,
        loadComponent: () => ShippingPlanListComponent,
        data: {
          title: 'СБЫТОВАЯ ПОТРЕБНОСТЬ',
          typeDataInstances: 'shippingPlan',
          path: RoutesHelper.DATA_SHIPPING_PLAN_PATH,
        },
      },
      {
        path: RoutesHelper.DATA_SHIPPING_PLAN_URL,
        loadComponent: () => ShippingPlanListComponent,
        data: {
          title: 'СБЫТОВАЯ ПОТРЕБНОСТЬ',
          typeDataInstances: 'shippingPlan',
          path: RoutesHelper.DATA_SHIPPING_PLAN_PATH,
        },
      },
      {
        path: RoutesHelper.DATA_WAREHOUSE_BALANCE,
        loadComponent: () => WarehouseBalanceListComponent,
        data: {
          title: 'СКЛАДСКИЕ ОСТАТКИ',
          typeDataInstances: 'warehouseBalance',
          path: RoutesHelper.DATA_WAREHOUSE_BALANCE,
        },
      },
      {
        path: RoutesHelper.DATA_WAREHOUSE_BALANCE_URL,
        loadComponent: () => WarehouseBalanceListComponent,
        data: {
          title: 'СКЛАДСКИЕ ОСТАТКИ',
          typeDataInstances: 'warehouseBalance',
          path: RoutesHelper.DATA_WAREHOUSE_BALANCE,
        },
      },
      {
        path: RoutesHelper.DATA_FUND,
        loadComponent: () => FundListComponent,
        data: {
          title: 'ДОСТУПНОСТЬ РЕСУРСОВ',
          typeDataInstances: 'resourceAvailability',
          path: RoutesHelper.DATA_FUND,
        },
      },
      {
        path: RoutesHelper.DATA_FUND_URL,
        loadComponent: () => FundListComponent,
        data: {
          title: 'ДОСТУПНОСТЬ РЕСУРСОВ',
          typeDataInstances: 'resourceAvailability',
          path: RoutesHelper.DATA_FUND,
        },
      },
      {
        path: RoutesHelper.DATA_WAGON_FLEET,
        loadComponent: () => WagonFleetListComponent,
        data: {
          title: 'Парк вагонов',
          typeDataInstances: 'wagonFleet',
          path: RoutesHelper.DATA_WAGON_FLEET,
        },
      },
      {
        path: RoutesHelper.DATA_WAGON_FLEET_URL,
        loadComponent: () => WagonFleetListComponent,
        data: {
          title: 'Парк вагонов',
          typeDataInstances: 'wagonFleet',
          path: RoutesHelper.DATA_WAGON_FLEET,
        },
      },
    ],
  },
];
