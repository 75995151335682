import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface ITabState {
  type: string;
  path: string;
  id: string;
}

interface ILastJournalSource {
  typeJournal: string;
  id: string;
  path: string;
  dataInstanceId: string;
}

@Injectable({
  providedIn: 'root',
})
export class TabStateService {
  private readonly currentTabUrlSource = new BehaviorSubject<ITabState>({
    type: '',
    path: '',
    id: '',
  });

  private readonly lastTabUrlSource = new BehaviorSubject<ITabState>({
    type: '',
    path: '',
    id: '',
  });

  private readonly lastJournalOPSource = new BehaviorSubject<ILastJournalSource>({
    typeJournal: '',
    id: '',
    path: '',
    dataInstanceId: '',
  });

  private readonly lastJournalKPSource = new BehaviorSubject<ILastJournalSource>({
    typeJournal: '',
    id: '',
    path: '',
    dataInstanceId: '',
  });

  getLastTabUrl(): ITabState {
    return this.lastTabUrlSource.getValue();
  }

  private setLastTabUrl(url: ITabState): void {
    this.lastTabUrlSource.next(url);
  }

  setCurrentTabUrl(url: ITabState): void {
    if (this.currentTabUrlSource.getValue().type !== url.type)
      this.setLastTabUrl(this.currentTabUrlSource.getValue());

    this.currentTabUrlSource.next(url);
  }

  getCurrentTabUrl(): ITabState {
    return this.currentTabUrlSource.getValue();
  }

  getLastJournalOP(): ILastJournalSource {
    return this.lastJournalOPSource.getValue();
  }

  getLastJournalKP(): ILastJournalSource {
    return this.lastJournalKPSource.getValue();
  }

  setLastJournalOP(value: ILastJournalSource): void {
    this.lastJournalOPSource.next(value);
  }

  setLastJournalKP(value: ILastJournalSource): void {
    this.lastJournalKPSource.next(value);
  }
}
