import { ChangeDetectorRef, ComponentRef, EventEmitter, Injectable, Type } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, forkJoin, of, take } from 'rxjs';
import { EnrichmentStandardApiService } from 'src/app/api/enrichment-standard.api.service';
import { IEnrichmentStandard } from 'src/app/data-models/enrichment-standard/enrichment-standard.interface';
import { ModalEnrichmentStandardListConfirmComponent } from '../../components/parts/enrichment-standard/modal-enrichment-standard-list-confirm/modal-enrichment-standard-list-confirm.component';
import { IEditEnrichmentStandard } from 'src/app/data-models/enrichment-standard/edit-enrichment-standard.interface';
import { cloneDeep } from 'lodash-es';
import { IQueryRequest } from 'src/app/data-models/query-request/query-request.interface';
import { DictionariesEnum } from 'src/app/core/enums/dictionaries.enum';
import { IDictionaryModel } from 'src/app/data-models/dictionary-model/dictionary-model.interface';
import { DictionariesService } from 'src/app/core/services/dictionaries.service';

@Injectable({
  providedIn: 'root',
})
export class ModalEnrichmentStandardArrayService {
  close!: EventEmitter<void>;
  update!: EventEmitter<void>;
  isCopy!: boolean;
  isChange!: boolean;
  open!: (
    comp: Type<ModalEnrichmentStandardListConfirmComponent>,
  ) => ComponentRef<ModalEnrichmentStandardListConfirmComponent>;
  formData!: FormGroup;
  data!: IEnrichmentStandard[];
  isTk1!: boolean;

  fieldsToSkip = ['id', 'dataInstance'];

  paramsDictionaries = [
    'rdinaryCoalMark',
    'ordinaryCoalMiningArea',
    'ordinaryCoalMiningLayer',
    'ordinaryCoalMiningProfile',
    'tk1ConcentrateMark',
    'tk1DropoutMark',
    'tk1SludgeMark',
    'tk2ConcentrateMark',
    'tk2IndustrialProductMark',
  ];
  currentParams: string[] = [];

  constructor(
    private enrichmentStandardApiService: EnrichmentStandardApiService,
    private cdr: ChangeDetectorRef,
    private dictionariesService: DictionariesService,
  ) {}

  chechValidForm(): boolean {
    if (this.data.length > 1) return true;

    this.formData.markAllAsTouched();

    if (this.formData.invalid) {
      Object.keys(this.formData.controls).forEach((controlName) => {
        this.formData.get(controlName)?.updateValueAndValidity();
      });
      return false;
    }

    return true;
  }

  createOptionsDictionaries(formData: FormGroup): Observable<IDictionaryModel[]> {
    const data = { ...formData.value };
    this.currentParams = this.paramsDictionaries.filter(
      (item) => data[item] && typeof data[item] === 'string',
    );

    const requestDictionaries = this.currentParams.map((item) => {
      const currentDictionarie = item.includes('Mark')
        ? DictionariesEnum.Mark
        : item.includes('Area')
          ? DictionariesEnum.Area
          : item.includes('Layer')
            ? DictionariesEnum.Layer
            : DictionariesEnum.Profile;
      return this.dictionariesService.createOption(currentDictionarie, data[item]);
    });

    return requestDictionaries.length > 0 ? forkJoin(requestDictionaries) : of([]);
  }

  transformData(data: IEnrichmentStandard[], form: IEnrichmentStandard): IEnrichmentStandard[] {
    return data.map((item) => {
      Object.keys(form).forEach((key) => {
        if (this.fieldsToSkip.includes(key)) return;
        if (form[key] && item[key] !== form[key]) item[key] = form[key];
      });
      return item;
    });
  }

  nextStepToSaveArray(
    request: Observable<IEnrichmentStandard[]>[],
    data: IEnrichmentStandard[],
  ): void {
    forkJoin(request)
      .pipe(take(1))
      .subscribe((res) => {
        const amountResult = res.reduce((acc, item) => acc + item.length, 0);

        if (amountResult > 0) {
          this.cdr.markForCheck();
          this.openModal();
          const newData = data.filter((_, index) => !res[index].length);
          this.isCopy ? this.createArray(newData) : this.changeArray(newData);
        } else {
          this.isCopy ? this.createArray(data) : this.changeArray(data);
        }
      });
  }

  saveArray() {
    if (!this.chechValidForm()) return;

    this.createOptionsDictionaries(this.formData)
      .pipe(take(1))
      .subscribe((arrayResponse) => {
        arrayResponse.forEach((response, index) => {
          this.formData.get(this.currentParams[index])?.setValue(response);
        });

        const form: IEnrichmentStandard = { ...this.formData.value };
        const dataFormat = cloneDeep(this.data);

        const data = this.data.length ? this.transformData(dataFormat, form) : [form];

        const request = data.map((item) =>
          this.enrichmentStandardApiService.getList(1, this.createQueryArray(item)),
        );

        this.nextStepToSaveArray(request, data);
      });
  }

  createArray(value: IEditEnrichmentStandard[]) {
    const request = value.map((item) => {
      const body = { ...item };
      delete body['id'];
      return this.enrichmentStandardApiService.create(body);
    });
    forkJoin(request)
      .pipe(take(1))
      .subscribe(() => this.close.emit());
  }

  changeArray(value: IEditEnrichmentStandard[]): void {
    const request = value
      .filter((item) => item.id)
      .map((item) => this.enrichmentStandardApiService.update(item));

    forkJoin(request)
      .pipe(take(1))
      .subscribe(() => this.close.emit());
  }

  removeArray() {
    const request = this.data.map((item) => {
      return this.enrichmentStandardApiService.delete(item.id as number);
    });
    forkJoin(request)
      .pipe(take(1))
      .subscribe(() => {
        this.close.emit();
      });
  }

  openModal(): void {
    this.open(ModalEnrichmentStandardListConfirmComponent);
  }

  createQueryArray(item: IEnrichmentStandard): IQueryRequest[] {
    const arrQuery: IQueryRequest[] = [
      {
        name: 'dataInstance.id',
        value: item.dataInstance.id || this.getGroupId('dataInstance'),
      },
      {
        name: 'ordinaryCoalMark.id',
        value: item.ordinaryCoalMark?.id || this.getGroupId('ordinaryCoalMark'),
      },
      {
        name: 'ordinaryCoalMiningArea.id',
        value: item.ordinaryCoalMiningArea?.id || this.getGroupId('ordinaryCoalMiningArea'),
      },
      {
        name: 'ordinaryCoalMiningLayer.id',
        value: item.ordinaryCoalMiningLayer?.id || this.getGroupId('ordinaryCoalMiningLayer'),
      },
      {
        name: 'ordinaryCoalMiningProfile.id',
        value: item.ordinaryCoalMiningProfile?.id || this.getGroupId('ordinaryCoalMiningProfile'),
      },
      {
        name: 'ordinaryCoalAd',
        value: item.ordinaryCoalAd || this.getControlValue('ordinaryCoalAd'),
      },
      {
        name: 'ordinaryCoalVd',
        value: item.ordinaryCoalVd || this.getControlValue('ordinaryCoalVd'),
      },
      {
        name: 'ordinaryCoalY',
        value: item.ordinaryCoalY || this.getControlValue('ordinaryCoalY'),
      },
    ];
    return arrQuery;
  }

  getControlValue(value: string): string {
    return this.formData.get(value)?.value || '';
  }

  getGroupId(value: string): string {
    return this.formData.get(value)?.get('id')?.value || '';
  }
}
