import { Injectable } from '@angular/core';
import { EntityApi } from '../core/abstract/entity.api';
import { ApiProvider } from '../core/providers/api.provider';
import { IEditJournal, IJournal } from '../data-models/enrichment-standard/journal.interface';

@Injectable({
  providedIn: 'root',
})
export class JournalApiService extends EntityApi<IJournal, IEditJournal> {
  protected readonly api = 'result/calculationLog';

  constructor(apiProvider: ApiProvider) {
    super(apiProvider);
  }
}
