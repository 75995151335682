import { EntityApi } from '../core/abstract/entity.api';
import { Injectable } from '@angular/core';
import { ApiProvider } from '../core/providers/api.provider';
import { NsiDictionaryModel } from '../data-models/dictionary/dictionary.model';
import { EditDictionaryModel } from '../data-models/dictionary/edit-dictionary.model';
import { Observable } from 'rxjs';
import { ApiRequestModel } from '../core/models/api-request.model';

@Injectable({
  providedIn: 'root'
})
export class DictionaryApiService extends EntityApi<NsiDictionaryModel, EditDictionaryModel> {
  protected readonly api = 'sourceData/dictionary';

  constructor(apiProvider: ApiProvider) {
    super(apiProvider);
  }

  copy = (fromId: number): Observable<void> => {
    const apiModel = new ApiRequestModel(this.api, { fromId })
      .addToPath('copy');

    return this.apiProvider.post(apiModel);
  }
}
