<div class="wagon-fleet-under-loading-root">
  <nguk-section>
    <div class="sticky-container">
      <div class="container-buttons" *ngIf="!isShow">
        <nguk-button type="primary" class="create-button" (click)="save()">
          <mat-icon [svgIcon]="svgIconsEnum.Save"></mat-icon>
        </nguk-button>
        <nguk-button
          type="primary"
          class="create-button"
          (click)="addTact()"
          *ngIf="data.length < 11"
        >
          <mat-icon [svgIcon]="svgIconsEnum.Plus"></mat-icon>
        </nguk-button>
      </div>

      <div class="heder-table">
        <div class="container-th-second-level">
          <nguk-text weight="600" styleName="text-lg" class="th-table-second"
            >Под погрузкой</nguk-text
          >
          <div
            class="tact-container"
            *ngFor="let item of data; let i = index"
            (mouseover)="visibleActionsTact = i"
            (focus)="(null)"
            (mouseleave)="visibleActionsTact = undefined"
          >
            <nguk-text weight="600" styleName="text-xs" class="th-tact">Такт {{ i + 1 }}</nguk-text>
            <div class="container-actions" *ngIf="visibleActionsTact === i && !isShow">
              <nguk-button
                class="copy-button"
                type="secondary"
                matTooltip="Копировать такт"
                (click)="addColumn(i)"
              >
                <mat-icon [svgIcon]="svgIconsEnum.Duplicate"></mat-icon>
              </nguk-button>

              <nguk-button
                class="delete-button"
                type="secondary"
                matTooltip="Удалить такт"
                (click)="removeColumn(i)"
              >
                <mat-icon [svgIcon]="svgIconsEnum.Delete"></mat-icon>
              </nguk-button>
            </div>
          </div>
        </div>
      </div>

      <div class="wagon-fleet-under-loading">
        <div class="left-header">
          <div class="first-column">
            <nguk-text weight="600" styleName="text-xs">ТК1</nguk-text>
          </div>
          <div class="second-column four-cells">
            <nguk-text weight="500" styleName="text-xs">15,16<br />(конц)</nguk-text>
          </div>
          <div class="second-column">
            <nguk-text weight="500" styleName="text-xs">12,13<br />(отсев)</nguk-text>
          </div>

          <div class="first-column eight-cells">
            <nguk-text weight="600" styleName="text-xs">СГП</nguk-text>
          </div>
          <div class="second-column four-cells">
            <nguk-text weight="500" styleName="text-xs">8,9</nguk-text>
          </div>
          <div class="second-column four-cells">
            <nguk-text weight="500" styleName="text-xs">11</nguk-text>
          </div>
          <div class="first-column four-cells">
            <nguk-text weight="600" styleName="text-xs">Кокс.<br />линия</nguk-text>
          </div>
          <div class="second-column four-cells">
            <nguk-text weight="500" styleName="text-xs">10</nguk-text>
          </div>
        </div>

        <div
          class="column-tact"
          *ngFor="let tact of data; let index = index"
          (mouseover)="visibleActionsTact = index"
          (focus)="(null)"
          (mouseleave)="visibleActionsTact = undefined"
          [class.bg-light-blue]="!(index % 2)"
          [class.bg-blue]="index % 2"
        >
          <div class="column-tact__item -tk1">
            <div class="header">
              <wagon-fleet-dropdown
                [isShow]="isShow"
                [options]="modeListTk1"
                [header]="'Режим производства'"
                [control]="getFormArray(index, 'productionModeTK1')"
                [showText]="true"
                [isMainCell]="true"
                [isRadio]="true"
                [isShow]="isShow"
              ></wagon-fleet-dropdown>
            </div>

            <ng-container *ngFor="let item of amountRow; let i = index">
              <div>
                <wagon-fleet-dropdown
                  [isShow]="isShow"
                  [options]="customersList"
                  [header]="'Заказчик'"
                  [control]="getFormArray(index, 'customerTK1516Row' + (i + 1))"
                  [showText]="true"
                ></wagon-fleet-dropdown>
              </div>
              <div>
                <wagon-fleet-dropdown
                  [isShow]="isShow"
                  [options]="wagonList"
                  [header]="'Вагон'"
                  [control]="getFormArray(index, 'wagonTypeTK1516Row' + (i + 1))"
                  [showText]="true"
                ></wagon-fleet-dropdown>
              </div>
              <div
                [attr.role]="'none'"
                [class.input-field]="currentInput === index + 'wagonAmountTK1516Row' + (i + 1)"
                (click)="currentInput = index + 'wagonAmountTK1516Row' + (i + 1)"
              >
                <nguk-input-ctrl
                  *ngIf="
                    currentInput === index + 'wagonAmountTK1516Row' + (i + 1) && !isShow;
                    else text
                  "
                  [type]="'number'"
                  [control]="getControl(index, 'wagonAmountTK1516Row' + (i + 1))"
                />

                <ng-template #text>
                  <nguk-text
                    weight="400"
                    styleName="text-xs"
                    [class.opacity]="!getControl(index, 'wagonAmountTK1516Row' + (i + 1)).value"
                    >{{
                      getControl(index, 'wagonAmountTK1516Row' + (i + 1)).value ?? 'Ваг., шт'
                    }}</nguk-text
                  >
                </ng-template>
              </div>
            </ng-container>

            <ng-container *ngFor="let item of amountRow; let i = index">
              <div>
                <wagon-fleet-dropdown
                  [isShow]="isShow"
                  [options]="customersList"
                  [header]="'Заказчик'"
                  [control]="getFormArray(index, 'customerTK1213Row' + (i + 1))"
                  [showText]="true"
                ></wagon-fleet-dropdown>
              </div>
              <div>
                <wagon-fleet-dropdown
                  [isShow]="isShow"
                  [options]="wagonList"
                  [header]="'Вагон'"
                  [control]="getFormArray(index, 'wagonTypeTK1213Row' + (i + 1))"
                  [showText]="true"
                ></wagon-fleet-dropdown>
              </div>
              <div
                [attr.role]="'none'"
                [class.input-field]="currentInput === index + 'wagonAmountTK1213Row' + (i + 1)"
                (click)="currentInput = index + 'wagonAmountTK1213Row' + (i + 1)"
              >
                <nguk-input-ctrl
                  *ngIf="
                    currentInput === index + 'wagonAmountTK1213Row' + (i + 1) && !isShow;
                    else text
                  "
                  [type]="'number'"
                  [control]="getControl(index, 'wagonAmountTK1213Row' + (i + 1))"
                />

                <ng-template #text>
                  <nguk-text
                    weight="400"
                    styleName="text-xs"
                    [class.opacity]="!getControl(index, 'wagonAmountTK1213Row' + (i + 1)).value"
                    >{{
                      getControl(index, 'wagonAmountTK1213Row' + (i + 1)).value ?? 'Ваг., шт'
                    }}</nguk-text
                  >
                </ng-template>
              </div>
            </ng-container>
          </div>

          <div class="column-tact__item -sgp">
            <div class="header">
              <wagon-fleet-dropdown
                [isShow]="isShow"
                [options]="marksList"
                [header]="'Марка'"
                [control]="getFormArray(index, 'markSGP9')"
                [showText]="true"
                [isMainCell]="true"
                [isRadio]="true"
              ></wagon-fleet-dropdown>
            </div>

            <ng-container *ngFor="let item of amountRow; let i = index">
              <div>
                <wagon-fleet-dropdown
                  [isShow]="isShow"
                  [options]="customersList"
                  [header]="'Заказчик'"
                  [control]="getFormArray(index, 'customerSGP9Row' + (i + 1))"
                  [showText]="true"
                ></wagon-fleet-dropdown>
              </div>
              <div>
                <wagon-fleet-dropdown
                  [isShow]="isShow"
                  [options]="wagonList"
                  [header]="'Вагон'"
                  [control]="getFormArray(index, 'wagonTypeSGP9Row' + (i + 1))"
                  [showText]="true"
                ></wagon-fleet-dropdown>
              </div>
              <div
                [attr.role]="'none'"
                [class.input-field]="currentInput === index + 'wagonAmountSGP9Row' + (i + 1)"
                (click)="currentInput = index + 'wagonAmountSGP9Row' + (i + 1)"
              >
                <nguk-input-ctrl
                  *ngIf="
                    currentInput === index + 'wagonAmountSGP9Row' + (i + 1) && !isShow;
                    else text
                  "
                  [type]="'number'"
                  [control]="getControl(index, 'wagonAmountSGP9Row' + (i + 1))"
                />

                <ng-template #text>
                  <nguk-text
                    weight="400"
                    styleName="text-xs"
                    [class.opacity]="!getControl(index, 'wagonAmountSGP9Row' + (i + 1)).value"
                    >{{
                      getControl(index, 'wagonAmountSGP9Row' + (i + 1)).value ?? 'Ваг., шт'
                    }}</nguk-text
                  >
                </ng-template>
              </div>
            </ng-container>

            <div class="header">
              <wagon-fleet-dropdown
                [isShow]="isShow"
                [options]="marksList"
                [header]="'Марка'"
                [control]="getFormArray(index, 'markSGP11')"
                [showText]="true"
                [isMainCell]="true"
                [isRadio]="true"
              ></wagon-fleet-dropdown>
            </div>

            <ng-container *ngFor="let item of amountRow; let i = index">
              <div>
                <wagon-fleet-dropdown
                  [isShow]="isShow"
                  [options]="customersList"
                  [header]="'Заказчик'"
                  [control]="getFormArray(index, 'customerSGP11Row' + (i + 1))"
                  [showText]="true"
                ></wagon-fleet-dropdown>
              </div>
              <div>
                <wagon-fleet-dropdown
                  [isShow]="isShow"
                  [options]="wagonList"
                  [header]="'Вагон'"
                  [control]="getFormArray(index, 'wagonTypeSGP11Row' + (i + 1))"
                  [showText]="true"
                ></wagon-fleet-dropdown>
              </div>
              <div
                [attr.role]="'none'"
                [class.input-field]="currentInput === index + 'wagonAmountSGP11Row' + (i + 1)"
                (click)="currentInput = index + 'wagonAmountSGP11Row' + (i + 1)"
              >
                <nguk-input-ctrl
                  *ngIf="
                    currentInput === index + 'wagonAmountSGP11Row' + (i + 1) && !isShow;
                    else text
                  "
                  [type]="'number'"
                  [control]="getControl(index, 'wagonAmountSGP11Row' + (i + 1))"
                />

                <ng-template #text>
                  <nguk-text
                    weight="400"
                    styleName="text-xs"
                    [class.opacity]="!getControl(index, 'wagonAmountSGP11Row' + (i + 1)).value"
                    >{{
                      getControl(index, 'wagonAmountSGP11Row' + (i + 1)).value ?? 'Ваг., шт'
                    }}</nguk-text
                  >
                </ng-template>
              </div>
            </ng-container>
          </div>

          <div class="column-tact__item -koks">
            <div class="header">
              <wagon-fleet-dropdown
                [isShow]="isShow"
                [options]="marksList"
                [header]="'Марка'"
                [control]="getFormArray(index, 'markKL10')"
                [showText]="true"
                [isMainCell]="true"
                [isRadio]="true"
              ></wagon-fleet-dropdown>
            </div>

            <ng-container *ngFor="let item of amountRow; let i = index">
              <div>
                <wagon-fleet-dropdown
                  [isShow]="isShow"
                  [options]="customersList"
                  [header]="'Заказчик'"
                  [control]="getFormArray(index, 'customerKL10Row' + (i + 1))"
                  [showText]="true"
                ></wagon-fleet-dropdown>
              </div>
              <div>
                <wagon-fleet-dropdown
                  [isShow]="isShow"
                  [options]="wagonList"
                  [header]="'Вагон'"
                  [control]="getFormArray(index, 'wagonTypeKL10Row' + (i + 1))"
                  [showText]="true"
                ></wagon-fleet-dropdown>
              </div>
              <div
                [attr.role]="'none'"
                [class.input-field]="currentInput === index + 'wagonAmountKL10Row' + (i + 1)"
                (click)="currentInput = index + 'wagonAmountKL10Row' + (i + 1)"
              >
                <nguk-input-ctrl
                  *ngIf="
                    currentInput === index + 'wagonAmountKL10Row' + (i + 1) && !isShow;
                    else text
                  "
                  [type]="'number'"
                  [control]="getControl(index, 'wagonAmountKL10Row' + (i + 1))"
                />

                <ng-template #text>
                  <nguk-text
                    weight="400"
                    styleName="text-xs"
                    [class.opacity]="!getControl(index, 'wagonAmountKL10Row' + (i + 1)).value"
                    >{{
                      getControl(index, 'wagonAmountKL10Row' + (i + 1)).value ?? 'Ваг., шт'
                    }}</nguk-text
                  >
                </ng-template>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </nguk-section>
</div>
