import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextComponent } from '../text/text.component';

@Component({
  selector: 'nguk-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TextComponent],
})
export class ButtonComponent {
  @Input({ required: true }) type!: 'primary' | 'secondary';
  @Input() disabled = false;

  @HostBinding('class.nguk-button') private baseClass = true;
}
