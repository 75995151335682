import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { SvgIconsEnum } from 'src/app/core/enums/svg-icons.enum';
import { ModalActionsComponent } from 'src/app/modules/ui/components/modal-actions/modal-actions.component';
import * as moment from 'moment';
import { ResourceAvailabilityConditionApiService } from 'src/app/api/resource-availability-condition.api.service';
import { finalize, forkJoin, take } from 'rxjs';
import { IEditResourceAvailabilityConditionTransform } from '../../../../../../../data-models/resource-availability-condition/edit-resource-availability-condition.interface';
import { IDataInstance } from 'src/app/data-models/enrichment-standard/data-instance.interface';
import { clone } from 'lodash-es';
import { SpinnerService } from '../../../../../../ui/services/spinner.service';

@Component({
  selector: 'modal-cell-actions',
  templateUrl: './modal-cell-actions.component.html',
  styleUrls: ['./modal-cell-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ModalActionsComponent],
})
export class ModalCellActionsComponent {
  @Input() formData!: FormGroup;
  @Input() data!: IEditResourceAvailabilityConditionTransform[][];
  @Input() removeIds!: string[];
  @Input() dataInstance!: IDataInstance;

  @Output() readonly close = new EventEmitter<void>();
  @Output() readonly updateData = new EventEmitter<void>();

  readonly svgIconsEnum = SvgIconsEnum;

  constructor(
    private ss: SpinnerService,
    private cdr: ChangeDetectorRef,
    private resourceAvailabilityConditionApiService: ResourceAvailabilityConditionApiService,
  ) {}

  save(): void {
    this.data.length === 1 || this.data.length === 0 ? this.saveOneCell() : this.saveMultiCell();
  }

  saveOneCell(): void {
    this.ss.startSpinner();
    this.cdr.detectChanges();
    const arrayValues: IEditResourceAvailabilityConditionTransform[] = this.formData
      .get('values')
      ?.value.map((item: IEditResourceAvailabilityConditionTransform) => ({
        ...item,
        duration: +item.duration,
        dateStarted: moment(item.dateStarted).utcOffset(0, true).format(),
        dateFinished: moment(item.dateFinished).utcOffset(0, true).format(),
      }));

    const requestArray = arrayValues.map((item) => {
      if (item.id) {
        return this.resourceAvailabilityConditionApiService.update(item);
      } else {
        const value = { ...item };
        delete value.id;
        return this.resourceAvailabilityConditionApiService.create(value);
      }
    });

    const removeRequests = this.removeIds.map((item) => {
      return this.resourceAvailabilityConditionApiService.delete(item);
    });

    forkJoin([...requestArray, ...removeRequests])
      .pipe(take(1), finalize(this.ss.stopSpinner))
      .subscribe(() => this.close.emit());
  }

  saveMultiCell(): void {
    this.ss.startSpinner();
    this.cdr.detectChanges();
    const values = this.createArrayRequestMultiCell();

    const requests = values.map((value) =>
      this.resourceAvailabilityConditionApiService.create(value),
    );

    forkJoin(requests)
      .pipe(take(1), finalize(this.ss.stopSpinner))
      .subscribe(() => this.close.emit());
  }

  createArrayRequestMultiCell(): IEditResourceAvailabilityConditionTransform[] {
    const arrayValues: IEditResourceAvailabilityConditionTransform[] =
      this.formData.get('values')?.value;

    const dataTransform = this.data.reduce(
      (acc: IEditResourceAvailabilityConditionTransform[][], item) => {
        const transformedItem = arrayValues.map((value) => {
          const transformedValue = clone(value);
          delete transformedValue.id;
          transformedValue.resourceAvailability = { id: item[0].resourceAvailability.id };

          transformedValue.duration = +transformedValue.duration;

          transformedValue.dateStarted = moment(this.dataInstance.dateStarted)
            .add(+item[0].day! - 1, 'days')
            .utcOffset(0)
            .format();

          transformedValue.dateFinished = moment(this.dataInstance.dateStarted)
            .add(+item[0].day!, 'days')
            .utcOffset(0)
            .format();

          return transformedValue;
        });
        acc.push(transformedItem);
        return acc;
      },
      [],
    );

    return dataTransform.flat();
  }

  remove() {
    this.ss.startSpinner();
    this.cdr.detectChanges();
    const filterArray = this.data.flat().filter((item) => {
      const start = moment(this.dataInstance.dateStarted)
        .add(+item.day! - 1, 'days')
        .utcOffset(0)
        .startOf('day');
      const current = moment(item.dateStarted).utcOffset(0).startOf('day');
      const diff = start.diff(current, 'days');
      return item.id && diff < 1;
    });

    const requests = filterArray.map((item) =>
      this.resourceAvailabilityConditionApiService.delete(item.id!),
    );

    forkJoin(requests)
      .pipe(take(1), finalize(this.ss.stopSpinner))
      .subscribe(() => this.close.emit());
  }
}
