import { Route } from '@angular/router';
import { RoutesHelper } from '../../helpers/routes.helper';
import { CalculationsPageComponent } from './components/pages/calculations-page/calculations-page.component';

export const routes: Route[] = [
  {
    path: RoutesHelper.EMPTY_PATH,
    component: CalculationsPageComponent,
  },
];
