import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ButtonComponent } from 'src/app/modules/ui/components/button/button.component';
import { SvgIconsEnum } from 'src/app/core/enums/svg-icons.enum';
import { JournalApiService } from 'src/app/api/journal.api.service';
import { FormGroup } from '@angular/forms';
import { IEditJournal } from 'src/app/data-models/enrichment-standard/journal.interface';
import { SpinnerService } from '../../../../../ui/services/spinner.service';
import { finalize, take } from 'rxjs';
import { OperationalPlanJournalApiService } from 'src/app/api/operational-plan-journal.api.service';
import { ModalJournalListShortService } from 'src/app/modules/calendar-plan/services/modal-journal-list-short.service';

@Component({
  selector: 'calendar-plan-modal-journal-list-short-actions',
  templateUrl: './modal-journal-list-short-actions.component.html',
  styleUrls: ['./modal-journal-list-short-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ButtonComponent, MatIconModule, MatTooltipModule],
})
export class ModalJournalListShortActionsComponent {
  @Input() formData!: FormGroup;
  @Input() isCopy = false;
  @Input() isOperationalPlan = false;

  @Output() closeModal = new EventEmitter<void>();

  readonly svgIconsEnum = SvgIconsEnum;

  constructor(
    private ss: SpinnerService,
    private journalApiService: JournalApiService,
    private operationalPlanJournalApiService: OperationalPlanJournalApiService,
    private modalJournalListShortService: ModalJournalListShortService,
  ) {}

  save(): void {
    this.isCopy ? this.create() : this.change();
  }

  create(): void {
    this.ss.startSpinner();

    const journal: IEditJournal = this.modalJournalListShortService.removeRepeatsDataInstances({
      ...this.formData.getRawValue(),
    });

    this.modalJournalListShortService.initDates(journal, this.isOperationalPlan);

    delete journal.dateCreated;
    delete journal.id;
    delete journal.graph;

    journal.productionPlans = [];
    journal.taskLogs = [];

    this.isOperationalPlan
      ? this.operationalPlanJournalApiService
          .create(journal)
          .pipe(take(1), finalize(this.ss.stopSpinner))
          .subscribe(() => this.closeModal.emit())
      : this.journalApiService
          .create(journal)
          .pipe(take(1), finalize(this.ss.stopSpinner))
          .subscribe(() => this.closeModal.emit());
  }

  change(): void {
    this.ss.startSpinner();

    this.modalJournalListShortService
      .changeJournal(this.formData, this.isOperationalPlan)
      .pipe(take(1), finalize(this.ss.stopSpinner))
      .subscribe(() => this.closeModal.emit());
  }
}
