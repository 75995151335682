import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize, mergeMap, take, takeWhile, tap } from 'rxjs';
import { ProductionPlanApiService } from 'src/app/api/production-plan.api.service';
import { SvgIconsEnum } from 'src/app/core/enums/svg-icons.enum';
import { IDataColumnTable } from 'src/app/data-models/data-column-table/data-column-table.interface';
import { IProductionPlan } from 'src/app/data-models/production-plan/production-plan.interface';
import { ActionModal } from 'src/app/modules/ui/base/action-modal';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from 'src/app/modules/ui/components/button/button.component';
import { SectionComponent } from 'src/app/modules/ui/components/section/section.component';
import { TextComponent } from 'src/app/modules/ui/components/text/text.component';
import { ModalResultSupplyComponent } from '../modal-result-supply/modal-result-supply.component';
import { ResultSupplyItemApiService } from 'src/app/api/result-supply-item.api.service';
import {
  IResultSupplyItem,
  IResultSupplyItemTransform,
} from 'src/app/data-models/result-supply-item/result-supply-item.interface';
import * as moment from 'moment';
import { JournalApiService } from 'src/app/api/journal.api.service';
import { IJournal } from 'src/app/data-models/enrichment-standard/journal.interface';
import { SpinnerService } from 'src/app/modules/ui/services/spinner.service';
import { ResultSupplyBorderHiddenPipe } from '../../../pipes/result-supply/result-supply-border-hidden.pipe';
import { ResultSupplyHorizontalLinePipe } from '../../../pipes/result-supply/result-supply-horizontal-line.pipe';
import { ResultSupplyValueCellPipe } from '../../../pipes/result-supply/result-supply-value-cell.pipe';
import { ThSortFilterComponent } from 'src/app/modules/ui/components/th-sort-filter/th-sort-filter.component';
import { FilterSortByColumnService } from 'src/app/modules/ui/services/filter-sort-by-column.service';
import { WeekendDayPipe } from '../../../../../core/pipes/weekend-day.pipe';
import { OperationalPlanColorShiftPipe } from '../../../pipes/operational-plan-color-shift.pipe';
import { OperationalPlanJournalApiService } from 'src/app/api/operational-plan-journal.api.service';
import { RedirectDataService } from 'src/app/modules/data/services/redirect-data.service';

@Component({
  selector: 'calendar-plan-result-supply',
  templateUrl: './result-supply.component.html',
  styleUrls: ['./result-supply.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    TextComponent,
    MatSortModule,
    SectionComponent,
    MatIconModule,
    ButtonComponent,
    ResultSupplyHorizontalLinePipe,
    ResultSupplyBorderHiddenPipe,
    ResultSupplyValueCellPipe,
    ThSortFilterComponent,
    WeekendDayPipe,
    OperationalPlanColorShiftPipe,
  ],
})
export class ResultSupplyComponent extends ActionModal implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('modal', { read: ViewContainerRef }) modal!: ViewContainerRef;

  @ViewChild('table', { read: ElementRef })
  table!: ElementRef<HTMLTableElement>;

  @Output() getCurrentTable = new EventEmitter();

  @Input() id!: string | undefined;

  isShow = false;
  isAlive = true;
  isAliveFiltering = false;
  isOperationalPlan = false;

  data!: IResultSupplyItemTransform[];
  rawData!: IResultSupplyItemTransform[];

  mapKeyId = new Map<string, IResultSupplyItem[]>();

  productionPlan!: IProductionPlan;
  journal!: IJournal;

  days!: number;

  readonly svgIconsEnum = SvgIconsEnum;

  enrichmentComplexMarkMap = new Map<string, string[]>();
  totalValueMap = new Map<string, { day: number; amount: string }[]>();

  amountTotalMap = new Map<string, string>();

  columnsDays!: IDataColumnTable[];
  columnsDaysTotal!: IDataColumnTable[];
  columnsDaysTotalEnrichmentComplex!: IDataColumnTable[];

  columns: IDataColumnTable[] = [
    { name: 'index', display: '#' },
    { name: 'mark', display: 'Марка' },
  ];

  displayedColumns!: string[];
  displayedColumnsTotal!: string[];
  displayedColumnsTotalEnrichmentComplex!: string[];

  listDatesForOP: string[] = [];
  displayedRowDatesForOP: string[] = [];

  constructor(
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private productionPlanApiService: ProductionPlanApiService,
    private resultSupplyItemApiService: ResultSupplyItemApiService,
    private journalApiService: JournalApiService,
    private operationalPlanJournalApiService: OperationalPlanJournalApiService,
    private ss: SpinnerService,
    private filterSortByColumnService: FilterSortByColumnService,
    private redirectDataService: RedirectDataService,
  ) {
    super();
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    this.isAliveFiltering = false;
  }

  ngOnInit(): void {
    this.isOperationalPlan =
      !!this.route.parent?.snapshot.data['isOperationalPlan'] ||
      !!this.route.snapshot.queryParams['type'];

    this.route.params.pipe(takeWhile(() => this.isAlive)).subscribe((params) => {
      const idJournal =
        this.route.parent?.snapshot.params['calendarPlanId'] ||
        this.route.snapshot.params['calendarPlanId'];

      if (!idJournal && !this.id) return;

      const id = params['resultSupplyId'] || this.id;
      id ? this.getProductionPlan(id, idJournal) : this.initRedirect(idJournal);
    });

    if (this.id) this.isShow = true;
  }

  ngAfterViewInit() {
    this.getCurrentTable.emit(this.table.nativeElement);
  }

  subscribeSortingFiltering(): void {
    this.route.queryParams.pipe(takeWhile(() => this.isAlive)).subscribe((query) => {
      this.data = this.filterSortByColumnService.sort(query, 'enrichmentComplex', this.rawData);
      this.data = this.filterSortByColumnService.filter(query, this.data);

      this.createTotalValues();

      this.cdr.detectChanges();
    });
  }

  initRedirect(idJournal: string): void {
    this.ss.startSpinner();
    this.productionPlanApiService
      .getList(1)
      .pipe(take(1))
      .subscribe((res: IProductionPlan[]) => {
        const find = res.find(
          (item) => +item.calculationLog?.id === +idJournal && item.type === 'resultSupply',
        );
        if (find) this.router.navigate([find.id], { relativeTo: this.route });
      });
  }

  getHours(value: number): number {
    return value % 24 || 24;
  }

  getProductionPlan(id: string, idJournal: string): void {
    this.redirectDataService.setLastJournal(this.route, id, idJournal);

    this.ss.startSpinner();
    this.productionPlanApiService
      .getOne(id)
      .pipe(
        take(1),
        mergeMap((productionPlan: IProductionPlan) => {
          this.productionPlan = productionPlan;
          return this.isOperationalPlan
            ? this.operationalPlanJournalApiService.getOne(idJournal)
            : this.journalApiService.getOne(idJournal);
        }),
        tap((journal) => {
          this.journal = journal;
          this.setAmountsDays();
        }),
      )
      .subscribe(() => this.initData(id));
  }

  setAmountsDays() {
    if (this.isOperationalPlan) {
      this.days = moment(this.journal.datePlanFinished).diff(
        moment(this.journal.datePlanStarted),
        'hours',
      );

      const amountDates = Math.ceil(this.days / 24);
      this.listDatesForOP = [];
      for (let index = 1; index <= amountDates; index++) {
        this.listDatesForOP.push(`dateStarted${index}`);
      }

      this.displayedRowDatesForOP = ['empty', ...this.listDatesForOP];
    } else {
      this.days = moment(this.journal.datePlanFinished).diff(
        moment(this.journal.datePlanStarted),
        'days',
      );
    }
  }

  getAmountColsForDate(index: number): number {
    const maxLastIndex = index * 24;
    if (maxLastIndex <= this.days) return 24;

    return maxLastIndex - this.days;
  }

  getDate(index: number): string {
    const firstDate = moment(this.journal.datePlanStarted).utcOffset(0, true);
    return firstDate.add(index, 'days').format('DD.MM.YY');
  }

  initData(id: string): void {
    this.ss.startSpinner();
    this.initColumnsDays();
    this.initDisplayedColumns();
    this.getDataList(id);
  }

  initDisplayedColumns(): void {
    this.displayedColumns = [...this.columns, ...this.columnsDays].map((col) => col.name);

    this.displayedColumns.push('total-row');

    this.displayedColumnsTotal = [
      'index-total',
      ...this.columnsDaysTotal.map((col) => col.name),
      'amount-total-row',
    ];

    this.displayedColumnsTotalEnrichmentComplex = [
      'index-enrichmentComplex',
      ...this.columnsDaysTotalEnrichmentComplex.map((col) => col.name),
      'total-enrichmentComplex-row',
    ];
  }

  initColumnsDays(): void {
    this.columnsDays = Array.from(Array(this.days), (_, index) => ({
      name: `c${index + 1}`,
      display: `${index + 1}`,
    }));

    this.columnsDaysTotal = Array.from(Array(this.days), (_, index) => ({
      name: `tt${index + 1}`,
      display: `${index + 1}`,
    }));

    this.columnsDaysTotalEnrichmentComplex = Array.from(Array(this.days), (_, index) => ({
      name: `te${index + 1}`,
      display: `${index + 1}`,
    }));
  }

  getDataList(id: string): void {
    this.resultSupplyItemApiService
      .getList(1, [
        { name: 'productionPlan.id', value: id },
        { name: 'itemsPerPage', value: 500 * 1000 },
      ])
      .pipe(take(1))

      .subscribe(
        (data: IResultSupplyItem[]) => {
          this.mapKeyId.clear();
          this.initTransformData(data);

          if (!this.isAliveFiltering) {
            this.subscribeSortingFiltering();
            this.isAliveFiltering = true;
          }

          this.ss.stopSpinner();
        },
        () => this.ss.stopSpinner(),
      );
  }

  initTransformData(data: IResultSupplyItem[]): void {
    const dataFormat = data.reduce((result: IResultSupplyItemTransform[], item) => {
      const finishValue =
        moment(item.dateSupply).diff(
          moment(this.journal.datePlanFinished),
          this.isOperationalPlan ? 'hours' : 'days',
        ) + 1;

      if (finishValue > 0) {
        return result;
      }

      const key = `${item.enrichmentComplex ?? ''}_${item.mark?.title ?? ''}`;

      const currentDay: number =
        moment(item.dateSupply).diff(
          moment(this.journal.datePlanStarted),
          this.isOperationalPlan ? 'hours' : 'days',
        ) + 1;

      const currentKey = key + currentDay;
      const currentValue = this.mapKeyId.get(currentKey);

      if (currentValue) {
        currentValue.push(item);
        this.mapKeyId.set(currentKey, currentValue);
      } else {
        this.mapKeyId.set(currentKey, [item]);
      }

      const existingItem: IResultSupplyItemTransform | undefined = result.find(
        (group) => group.key === key,
      );

      const day = {
        ...item,
        day: currentDay,
      };

      if (existingItem) {
        existingItem.values?.push(day);
        existingItem.total += +item.volume;
      } else {
        const value = {
          key: key,
          total: +item.volume,
          enrichmentComplex: item.enrichmentComplex,
          mark: item.mark,
          volume: '',
        };

        const itemTransform: IResultSupplyItemTransform = { ...value, key, values: [day] };

        result.push(itemTransform);
      }

      return result;
    }, []);

    const dataSort = this.sortingByEnrichmentComplex(dataFormat).map((item) => {
      item.total = Math.round((item.total / 1000) * 100) / 100;
      return item;
    });

    this.data = dataSort;
    this.rawData = [...this.data];

    this.createTotalValues();

    this.cdr.detectChanges();
  }

  initEnrichmentComplexMarkMap(): void {
    this.enrichmentComplexMarkMap = new Map<string, string[]>();

    this.data.forEach((item) => {
      const currentEnrichmentComplex = this.enrichmentComplexMarkMap.get(item.enrichmentComplex);

      if (currentEnrichmentComplex) {
        if (!currentEnrichmentComplex.includes(item.mark.title)) {
          currentEnrichmentComplex.push(item.mark.title);
          this.enrichmentComplexMarkMap.set(item.enrichmentComplex, currentEnrichmentComplex);
        }
      } else {
        this.enrichmentComplexMarkMap.set(item.enrichmentComplex, [item.mark.title]);
      }
    });
  }

  createTotalValues(): void {
    this.amountTotalMap.clear();
    this.initEnrichmentComplexMarkMap();
    this.totalValueMap = new Map<string, { day: number; amount: string }[]>();

    const arr: { enrichmentComplex: string; values: { day: number; amount: string }[] }[] = [];

    this.enrichmentComplexMarkMap.forEach((value, enrichmentComplex) => {
      const currentArr: { day: number; amount: string }[] = [];

      for (let day = 1; day <= this.days; day++) {
        const total = value.reduce((acc, mark) => {
          const item = this.mapKeyId.get(`${enrichmentComplex}_${mark}${day}`);
          if (item) {
            const filtered = item.reduce((acc, i) => {
              return i.volume ? (acc += +i.volume) : acc;
            }, 0);

            return (acc += +filtered);
          } else return acc;
        }, 0);
        currentArr.push({
          day: day,
          amount: (Math.round((total / 1000) * 100) / 100).toString(),
        });
      }
      arr.push({ enrichmentComplex: enrichmentComplex, values: currentArr });
    });

    if (arr.length) {
      const res = arr[0].values.reduce(
        (acc, _, index) => {
          let start = 0;
          arr.forEach((i) => {
            start += +i.values[index].amount;
          });
          acc.push({ day: index + 1, amount: (Math.round(start * 100) / 100).toString() });
          return acc;
        },
        [] as { day: number; amount: string }[],
      );

      arr.push({ enrichmentComplex: 'total', values: res });
    }

    arr.forEach((item) => {
      this.totalValueMap.set(item.enrichmentComplex, item.values);
      const amount = item.values.reduce((acc, el) => (acc += +el.amount), 0);
      this.amountTotalMap.set(item.enrichmentComplex, (Math.round(amount * 100) / 100).toString());
    });
  }

  getTotalValue(value: string, i: number): string {
    const current = this.totalValueMap.get(value);
    return current ? (current[i].amount === '0' ? '' : current[i].amount) : '';
  }

  getAmountTotalValue(value: string): string {
    const current = this.amountTotalMap.get(value);
    return current ? (current === '0' ? '' : current) : '';
  }

  addUpString(str1: string, str2: string): string {
    const num = Number(str1) + Number(str2);
    return num % 1 === 0 ? num.toFixed(0) : num.toFixed(2);
  }

  sortingByEnrichmentComplex(result: IResultSupplyItemTransform[]): IResultSupplyItemTransform[] {
    return result.sort((a, b) => {
      const nameA = a.enrichmentComplex.toUpperCase();
      const nameB = b.enrichmentComplex.toUpperCase();
      return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
    });
  }

  openModal(row: IResultSupplyItemTransform, item: IDataColumnTable, isTotal = false): void {
    let cell: IResultSupplyItem[] | undefined;
    if (isTotal) {
      const arrMark = this.enrichmentComplexMarkMap.get(row.enrichmentComplex);
      cell = arrMark?.reduce((acc, mark) => {
        const key = `${row.enrichmentComplex}_${mark}`;
        const value = this.mapKeyId.get(key + +item.display) ?? null;
        if (value) acc.push(...value);
        return acc;
      }, [] as IResultSupplyItem[]);
    } else {
      cell = this.mapKeyId.get(row.key + +item.display);
    }

    if (!cell) return;
    this.modalContainer = this.modal;
    const modalComponent = this.open(ModalResultSupplyComponent);
    modalComponent.instance.data = cell;
    modalComponent.instance.day = +item.display;
  }
}
