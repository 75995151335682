import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IDataInstance } from 'src/app/data-models/enrichment-standard/data-instance.interface';
import { MatTableModule } from '@angular/material/table';
import { TextComponent } from 'src/app/modules/ui/components/text/text.component';
import { MAT_RADIO_DEFAULT_OPTIONS, MatRadioModule } from '@angular/material/radio';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IEditJournal } from 'src/app/data-models/enrichment-standard/journal.interface';
import * as moment from 'moment';

@Component({
  selector: 'calendar-plan-modal-choose-data-instances-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './modal-choose-data-instances-list.component.html',
  styleUrls: ['./modal-choose-data-instances-list.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    TextComponent,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'primary' },
    },
  ],
})
export class ModalChooseDataInstancesListComponent implements OnInit {
  @Input() journal!: IEditJournal;
  @Input() typeDataInstances!: string;
  @Input() dataInstances!: IDataInstance[];

  currentData!: IDataInstance[];
  currentDataInstance = new FormControl();

  readonly moment = moment;

  displayedColumns = ['index', 'dateCreated', 'min', 'max', 'note'];

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.getDataList();
    this.currentDataInstance.valueChanges.subscribe((data: IDataInstance) => {
      const current = this.journal.dataInstances.find(
        (item) => item.type === this.typeDataInstances,
      );
      if (!current) return;
      current.id = data.id as string;
    });
  }

  getDataList(): void {
    if (this.journal) {
      const value = this.journal.dataInstances.find((item) => item.type === this.typeDataInstances);
      const currentIndex = this.dataInstances.findIndex((item) => item.id === value?.id);
      this.currentDataInstance.setValue(this.dataInstances[currentIndex ?? 0]);
    } else {
      this.currentDataInstance.setValue(this.dataInstances[0]);
    }
    this.cdr.detectChanges();
  }
}
