import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { ModalBase } from 'src/app/modules/ui/base/modal.base';
import { ModalComponent } from 'src/app/modules/ui/components/modal/modal.component';
import { TextComponent } from 'src/app/modules/ui/components/text/text.component';
import { IWarehouseDynamicsItem } from 'src/app/data-models/warehouse-dynamics-item/warehouse-dynamics.interface';
import { IDataColumnTable } from 'src/app/data-models/data-column-table/data-column-table.interface';

@Component({
  selector: 'calendar-plan-modal-warehouse-dynamics',
  templateUrl: './modal-warehouse-dynamics.component.html',
  styleUrls: ['./modal-warehouse-dynamics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ModalComponent, TextComponent, MatTableModule, TextComponent],
})
export class ModalWarehouseDynamicsComponent extends ModalBase implements OnInit, AfterViewInit {
  prevCell!: IWarehouseDynamicsItem[];
  currentCell!: IWarehouseDynamicsItem[];

  data!: IWarehouseDynamicsItem[];

  isOperationalPlan = false;
  isWagonDynamics = false;
  day!: number;

  readonly math = Math;

  columns: IDataColumnTable[] = [];

  tableInfo: { label: string; value: string }[] = [];

  displayedColumns: string[] = [];

  constructor(cdRef: ChangeDetectorRef) {
    super(cdRef);
  }

  ngOnInit(): void {
    this.initColumns();
    this.initData();
  }

  ngAfterViewInit(): void {
    this.initModal();
  }

  initColumns(): void {
    this.columns = [
      { name: 'index', display: '#' },
      { name: 'lineType', display: 'Направление' },
      { name: 'correspondent', display: 'Корреспондент' },
      { name: 'materialType', display: 'Тип материала' },
      { name: 'mark', display: 'Марка' },
    ];

    if (!this.isWagonDynamics) {
      this.columns.push(
        { name: 'ad', display: 'Ad' },
        { name: 'vd', display: 'Vd' },
        { name: 'y', display: 'Y' },
        { name: 'csr', display: 'CSR' },
        { name: 'hgi', display: 'HGI' },
      );
    }

    this.columns.push({ name: 'volume', display: 'Объем' });

    this.displayedColumns = this.columns.map((item) => item.name);
  }

  initData(): void {
    const prevData = this.prevCell
      ? this.prevCell.filter((item) => item.lineType === 'Ост' || item.lineType === 'Нач')
      : [];

    const prevAmount = prevData.reduce((acc, item) => acc + +item.volume, 0);

    const prevBalance = (
      Math.round((prevAmount / (this.isWagonDynamics ? 1 : 1000)) * 100) / 100
    ).toString();

    const admissionAmount = this.currentCell
      .filter((item) => item.lineType === 'Пост')
      .reduce((acc, item) => acc + +item.volume, 0);

    const admission = (
      Math.round((admissionAmount / (this.isWagonDynamics ? 1 : 1000)) * 100) / 100
    ).toString();

    const writeOffAmount = this.currentCell
      .filter((item) => item.lineType === 'Спис')
      .reduce((acc, item) => acc + +item.volume, 0);

    const writeOff = (
      Math.round((writeOffAmount / (this.isWagonDynamics ? 1 : 1000)) * 100) / 100
    ).toString();

    const balanceAmount = this.currentCell
      .filter((item) => item.lineType === 'Ост')
      .reduce((acc, item) => acc + +item.volume, 0);

    const balance = (
      Math.round((balanceAmount / (this.isWagonDynamics ? 1 : 1000)) * 100) / 100
    ).toString();

    this.tableInfo = [
      { label: 'На начало', value: prevBalance },
      { label: 'Поступление', value: admission },
      { label: 'Списание', value: writeOff },
      { label: 'На конец', value: balance },
    ];
  }
}
