import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextComponent } from 'src/app/modules/ui/components/text/text.component';
import { ButtonComponent } from 'src/app/modules/ui/components/button/button.component';
import { RoutesHelper } from 'src/app/helpers/routes.helper';
import { ActivatedRoute, Router } from '@angular/router';
import * as XLSX from 'xlsx';

@Component({
  selector: 'print-actions',
  standalone: true,
  imports: [CommonModule, TextComponent, ButtonComponent],
  templateUrl: './print-actions.component.html',
  styleUrls: ['./print-actions.component.scss'],
})
export class PrintActionsComponent {
  @Input() listTables!: { table: HTMLTableElement | XLSX.WorkSheet; label: string }[];

  @Output() save = new EventEmitter<void>();
  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  printPage(): void {
    this.save.emit();
  }

  comeBack(): void {
    const isOp = !!this.route.snapshot.queryParams['type'];
    this.router.navigate([
      isOp ? RoutesHelper.DATA_OPERATIONAL_PLAN : RoutesHelper.DATA_CALENDAR_PLAN,
    ]);
  }

  exportToExcel() {
    const timeSpan = new Date().toISOString();
    const prefix = 'ExportResult';
    const fileName = `${prefix}-${timeSpan}`;
    const wb = XLSX.utils.book_new();

    this.listTables.forEach((item) => {
      if (item.table instanceof HTMLTableElement) {
        const ws = XLSX.utils.table_to_sheet(item.table, <XLSX.Table2SheetOpts>{
          cellStyles: true,
        });
        XLSX.utils.book_append_sheet(wb, ws, item.label);
      } else {
        XLSX.utils.book_append_sheet(wb, item.table, item.label);
      }
    });

    XLSX.writeFile(wb, `${fileName}.xlsx`);
  }
}
