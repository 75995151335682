<nguk-modal [visible]="isVisible" [close]="closeModal.bind(this, false)">
  <div class="nguk-popup-root">
    <div class="popup-icon" *ngIf="icon">
      <mat-icon [svgIcon]="icon"></mat-icon>
    </div>

    <div class="popup-info" [class.left-position]="listTitle && listTitle.length">
      <nguk-text weight="600" styleName="text-lg" *ngIf="title">
        {{ title }}
      </nguk-text>
      <nguk-text weight="400" styleName="text-base" *ngIf="text">
        {{ text }}
      </nguk-text>
      <ng-container *ngIf="listTitle?.length">
        <nguk-text weight="400" styleName="text-base" *ngFor="let item of listTitle">
          {{ item }}
        </nguk-text>
      </ng-container>
    </div>
  </div>
</nguk-modal>
