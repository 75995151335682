import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TextComponent } from '../../../ui/components/text/text.component';

@Component({
  selector: 'nguk-control-error',
  template: `
    <div class="nguk-control-error-root" [class.-display-error]="showError">
      <nguk-text weight="400" styleName="text-xs">
        <ng-content></ng-content>
      </nguk-text>
    </div>
  `,
  styleUrls: ['./control-error.component.scss'],
  standalone: true,
  imports: [TextComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlErrorComponent {
  @Input() showError!: boolean;
}
