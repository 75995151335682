import { Injectable } from '@angular/core';
import { EntityApi } from '../core/abstract/entity.api';
import { ApiProvider } from '../core/providers/api.provider';
import { IMiningPlanVolume } from '../data-models/mining-plan-volume/mining-plan-volume.interface';
import { IEditMiningPlanVolume } from '../data-models/mining-plan-volume/edit-mining-plan-volume.interface';

@Injectable({
  providedIn: 'root',
})
export class MiningPlanVolumeApiService extends EntityApi<
  IMiningPlanVolume,
  IEditMiningPlanVolume
> {
  protected readonly api = 'sourceData/miningPlanVolume';

  constructor(apiProvider: ApiProvider) {
    super(apiProvider);
  }
}
