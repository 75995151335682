import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextComponent } from 'src/app/modules/ui/components/text/text.component';
import { ButtonComponent } from 'src/app/modules/ui/components/button/button.component';
import { Router } from '@angular/router';
import { SvgIconsEnum } from 'src/app/core/enums/svg-icons.enum';
import { IPrint } from 'src/app/data-models/print/print.interface';
import { RoutesHelper } from 'src/app/helpers/routes.helper';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormGroup } from '@angular/forms';
import { IEditJournal, IJournal } from 'src/app/data-models/enrichment-standard/journal.interface';

@Component({
  selector: 'calendar-plan-modal-journal-list-short-print-header',
  templateUrl: './modal-journal-list-short-print-header.component.html',
  styleUrls: ['./modal-journal-list-short-print-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TextComponent, ButtonComponent, MatIconModule, MatTooltipModule],
})
export class ModalJournalListShortPrintHeaderComponent {
  readonly svgIconsEnum = SvgIconsEnum;

  @Input() form!: FormGroup;
  @Input() journal!: IJournal | IEditJournal;
  @Input() isOperationalPlan = false;

  constructor(private router: Router) {}

  goToPrint(): void {
    const obj = this.form.value;
    const print = Object.keys(obj)
      .reduce((acc, key) => {
        if (Array.isArray(obj[key])) acc.push(...obj[key]);
        return acc;
      }, [] as IPrint[])
      .filter((item) => item.isActive && item.id);

    if (print.length) {
      const queryParams = print.reduce(
        (acc: { [key: string]: string }, item) => {
          acc[item.type] = item.id;
          return acc;
        },
        {} as { [key: string]: string },
      );

      if (this.isOperationalPlan) queryParams['type'] = 'op';

      this.router.navigate([RoutesHelper.PRINT_PATH, this.journal.id], {
        queryParams,
      });
    }
  }
}
