<div class="modal-parameters-calculate">
  <div class="modal-parameters-calculate-item">
    <nguk-text weight="400" styleName="text-sm" color="grey" class="bigger-width"
      >Начало планового периода</nguk-text
    >
    <div class="container-select datepicker-select" [class.-isOP]="isOperationalPlan">
      <nguk-datepicker-ctrl
        [control]="getControl('datePlanStarted')"
        [showTime]="isOperationalPlan"
      />
    </div>
  </div>

  <div class="modal-parameters-calculate-item">
    <nguk-text weight="400" styleName="text-sm" color="grey">{{
      isOperationalPlan ? 'Количество смен расчета' : 'Количество дней расчета'
    }}</nguk-text>
    <div class="container-input">
      <nguk-input-ctrl
        [type]="'number'"
        [min]="0"
        [max]="isOperationalPlan ? 6 : null"
        [control]="getControl('amountDays')"
      />
    </div>
  </div>

  <div class="modal-parameters-calculate-item">
    <nguk-text weight="400" styleName="text-sm" color="grey" class="bigger-width"
      >Время расчета в секундах</nguk-text
    >
    <div class="container-select">
      <nguk-input-ctrl [type]="'number'" [min]="0" [control]="getControl('maxDuration')" />
    </div>
  </div>
</div>
