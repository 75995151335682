import { Pipe, PipeTransform } from '@angular/core';
import {
  IWarehouseBalance,
  IWarehouseBalanceItem,
} from 'src/app/data-models/enrichment-standard/warehouse-balance.interface';

@Pipe({
  name: 'warehouseBalanceBorderHidden',
  standalone: true,
})
export class WarehouseBalanceBorderHiddenPipe implements PipeTransform {
  transform(index: number, data: IWarehouseBalance[] | IWarehouseBalanceItem[]): boolean {
    return data[index + 1] && data[index].warehouse.id !== data[index + 1].warehouse.id;
  }
}
