import { Pipe, PipeTransform } from '@angular/core';
import {
  IWarehouseDynamicsItem,
  IWarehouseDynamicsItemTransform,
} from 'src/app/data-models/warehouse-dynamics-item/warehouse-dynamics.interface';

@Pipe({
  name: 'warehouseDynamicsValueCellVoid',
  standalone: true,
})
export class WarehouseDynamicsValueCellVoidPipe implements PipeTransform {
  transform(
    row: IWarehouseDynamicsItemTransform,
    day: number,
    mapKeyId: Map<string, IWarehouseDynamicsItem[]>,
    isWagonDynamics: boolean,
  ): boolean {
    const isPathWagon = row.warehouse.title.toLocaleLowerCase().includes('путь') && isWagonDynamics;

    if (day === 0 || isPathWagon) return false;

    const value =
      mapKeyId
        .get(row.key + day)
        ?.reduce(
          (acc, item) =>
            (item.lineType === (isPathWagon ? 'Пост' : 'Ост') ? +item.volume : 0) + acc,
          0,
        ) ?? 0;

    const hasMoving = !!(mapKeyId.get(row.key + day) ?? []).filter(
      (item) => item.lineType !== 'Ост',
    ).length;

    const balance = Math.round((value / (isWagonDynamics ? 1 : 1000)) * 100) / 100;

    const prevValue =
      mapKeyId
        .get(row.key + (day - 1))
        ?.reduce(
          (acc, item) =>
            (item.lineType === (isPathWagon ? 'Пост' : 'Ост') ? +item.volume : 0) + acc,
          0,
        ) ?? 0;

    const prevBalance = Math.round((prevValue / (isWagonDynamics ? 1 : 1000)) * 100) / 100;

    return balance === 0 && prevBalance === 0 && hasMoving
      ? true
      : balance === 0 && prevBalance !== 0
        ? true
        : false;
  }
}
