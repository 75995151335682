import { Pipe, PipeTransform } from '@angular/core';
import { IMiningPlanTransform } from 'src/app/data-models/mining-plan/mining-plan.interface';

@Pipe({
  name: 'miningPlanCheckHorizontalArea',
  standalone: true,
})
export class MiningPlanCheckHorizontalAreaPipe implements PipeTransform {
  transform(index: number, data: IMiningPlanTransform[]): boolean {
    if (index === 0) return false;
    return data[index].area.title === data[index - 1].area.title;
  }
}
