import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { MatRadioModule } from '@angular/material/radio';
import { ControlDirective } from '../../directives/control.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@Component({
  selector: 'nguk-slide-toggle',
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatRadioModule, FormsModule, ReactiveFormsModule, MatSlideToggleModule],
})
export class SlideToggleComponent extends ControlDirective {
  @HostBinding('class.nguk-slide-toggle') private radioBaseClass = true;
}
