import { Injectable } from '@angular/core';
import { IEditJournal } from 'src/app/data-models/enrichment-standard/journal.interface';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { JournalApiService } from 'src/app/api/journal.api.service';
import { OperationalPlanJournalApiService } from 'src/app/api/operational-plan-journal.api.service';

@Injectable({
  providedIn: 'root',
})
export class ModalJournalListShortService {
  constructor(
    private journalApiService: JournalApiService,
    private operationalPlanJournalApiService: OperationalPlanJournalApiService,
  ) {}

  changeJournal(formData: FormGroup, isOperationalPlan: boolean): Observable<IEditJournal> {
    const journal: IEditJournal = this.removeRepeatsDataInstances({ ...formData.value });

    this.initDates(journal, isOperationalPlan);
    return isOperationalPlan
      ? this.operationalPlanJournalApiService.update(journal)
      : this.journalApiService.update(journal);
  }

  removeRepeatsDataInstances(journal: IEditJournal): IEditJournal {
    const dataInstancesMap = new Map<string, boolean>();
    const dataInstances = journal.dataInstances.reduce(
      (acc, item) => {
        const isHas = dataInstancesMap.has(item.type);
        if (isHas) return acc;
        acc.push(item);
        dataInstancesMap.set(item.type, true);
        return acc;
      },
      [] as { id: string; type: string }[],
    );
    return { ...journal, dataInstances };
  }

  initDates(journal: IEditJournal, isOperationalPlan: boolean): void {
    if (isOperationalPlan) {
      const datePlanStarted = moment(journal.datePlanStarted).utcOffset(0, true);
      journal.datePlanStarted = datePlanStarted.format();
      journal.datePlanFinished = datePlanStarted
        .add(12 * (journal.amountDays || 0), 'hours')
        .format();
    } else {
      const datePlanStarted = moment(journal.datePlanStarted).utcOffset(0, true).startOf('day');
      journal.datePlanStarted = datePlanStarted.format();
      journal.datePlanFinished = datePlanStarted.add(journal.amountDays, 'days').format();
    }
  }
}
