<div class="nguk-checkbox-ctrl-root" [class.-horizontal]="direction === 'horizontal'">
  <mat-checkbox
    color="primary"
    [formControl]="control"
    [indeterminate]="indeterminate"
    (change)="changeState()"
  >
    <ng-content select="[label]" />
  </mat-checkbox>
</div>
