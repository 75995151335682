<div class="container-buttons">
  <nguk-button
    *ngIf="listMode !== editListModeEnum.View"
    type="primary"
    class="save-button"
    (click)="saveChanges.emit()"
  >
    <mat-icon [svgIcon]="svgIconsEnum.Save" />
  </nguk-button>

  <nguk-button
    *ngIf="!isBlockedToEdit"
    type="secondary"
    class="options-button"
    [matMenuTriggerFor]="listMenuModes"
    [class.-active]="listMenuModesTrigger.menuOpen"
    #listMenuModesTrigger="matMenuTrigger"
  >
    <mat-icon [svgIcon]="svgIconsEnum.MoreHorizontal" />
  </nguk-button>
</div>

<mat-menu class="nsi-edit-panel" #listMenuModes="matMenu">
  <button
    mat-menu-item
    [class.-active]="listMode === editListModeEnum.View"
    (click)="changeListMode.emit(editListModeEnum.View)"
  >
    <mat-icon [svgIcon]="svgIconsEnum.Eye" />
    <nguk-text styleName="text-xs">Просмотр</nguk-text>
  </button>

  <button
    mat-menu-item
    [class.-active]="listMode === editListModeEnum.EditStructure"
    (click)="changeListMode.emit(editListModeEnum.EditStructure)"
  >
    <mat-icon [svgIcon]="svgIconsEnum.TableProperties" />
    <nguk-text styleName="text-xs">Редактирование структуры</nguk-text>
  </button>

  <button
    mat-menu-item
    [class.-active]="listMode === editListModeEnum.EditData"
    (click)="changeListMode.emit(editListModeEnum.EditData)"
  >
    <mat-icon [svgIcon]="svgIconsEnum.Binary" />
    <nguk-text styleName="text-xs">Редактирование данных</nguk-text>
  </button>
</mat-menu>
