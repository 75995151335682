<div class="modal-row-data">
  <nguk-text weight="700" styleName="text-sm">Исходные данные</nguk-text>

  <div class="row-data-container">
    <div class="row-data-item">
      <div class="row-data-item__type">
        <nguk-text weight="400" styleName="text-xs" color="grey">Исходные данные</nguk-text>
      </div>
      <div class="row-data-item__date">
        <nguk-text weight="400" styleName="text-xs" color="grey">Дата формирования</nguk-text>
      </div>
      <div class="row-data-item__note">
        <nguk-text weight="400" styleName="text-xs" color="grey">Примечание</nguk-text>
      </div>
      <div class="row-data-item__min">
        <nguk-text weight="400" styleName="text-xs" color="grey">Min</nguk-text>
      </div>
      <div class="row-data-item__max">
        <nguk-text weight="400" styleName="text-xs" color="grey">Max</nguk-text>
      </div>
    </div>

    <div class="row-data-item" *ngFor="let item of currentDataInstance">
      <div class="row-data-item__type">
        <nguk-text
          weight="400"
          styleName="text-xs"
          color="grey"
          [class.text-button]="!isHasCalculated"
          (click)="isHasCalculated ? null : chooseDataInstances.emit(item.type)"
          >{{ findTypesName(item.type) }}</nguk-text
        >
      </div>
      <div class="row-data-item__date">
        <nguk-text weight="400" styleName="text-xs">{{
          moment(item.dateCreated).format('DD.MM.YY')
        }}</nguk-text>
      </div>
      <div class="row-data-item__note">
        <nguk-text weight="400" styleName="text-xs">{{ item.note }}</nguk-text>
      </div>
      <div class="row-data-item__min">
        <nguk-text
          weight="400"
          styleName="text-xs"
          [class.min-max-date]="item.dateStarted | minMaxDate: currentDataInstance : true"
          >{{ item.dateStarted ? moment(item.dateStarted).format('DD.MM.YY') : '-' }}</nguk-text
        >
      </div>
      <div class="row-data-item__max">
        <nguk-text
          weight="400"
          styleName="text-xs"
          [class.min-max-date]="item.dateFinished | minMaxDate: currentDataInstance : false"
          >{{
            item.dateFinished
              ? moment(item.dateFinished).add(item.days, 'days').format('DD.MM.YY')
              : '-'
          }}</nguk-text
        >
      </div>
    </div>
  </div>
</div>
