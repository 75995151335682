import {
  AbstractControl,
  FormArray,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import * as moment from 'moment';
import { IDataInstance } from 'src/app/data-models/enrichment-standard/data-instance.interface';

export function ValidateDatePlanning(
  formData: FormGroup,
  mapDataInstance: Map<string, IDataInstance>,
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const shippingPlanId = (
      (formData.get('dataInstances') as FormArray).controls as FormGroup[]
    ).find((item) => item?.get('type')?.value === 'shippingPlan');

    const id = shippingPlanId?.value.id ?? '';
    if (!id) return null;

    const currentShippingPlan = mapDataInstance.get(id as string);

    if (!currentShippingPlan || !control.value) return null;

    const currentDate = moment(control.value);
    const startDate = moment(currentShippingPlan.dateStarted);
    const finishDate = moment(currentShippingPlan.dateFinished);

    if (currentDate.diff(startDate, 'days') >= 0 && finishDate.diff(currentDate, 'days') >= 0)
      return null;
    else
      return {
        datePlanning: true,
      };
  };
}
