<div class="modal-confirm-root">
  <nguk-modal [visible]="isVisible" [close]="closeModal.bind(this, false)">
    <div class="modal-confirm">
      <div class="modal-confirm-header">
        <nguk-text weight="700" styleName="text-xl">Невозможно сохранить</nguk-text>
      </div>
      <nguk-text weight="400" styleName="text-sm"
        >В данной ячейка уже есть данные. Попробуйте изменить параметры.
      </nguk-text>

      <div class="actions">
        <nguk-button type="primary" (click)="closeModal(false)"
          ><nguk-text weight="400" styleName="text-sm">Закрыть</nguk-text>
        </nguk-button>
      </div>
    </div>
  </nguk-modal>
</div>
