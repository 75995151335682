import { Injectable } from '@angular/core';
import { map, Observable, of, take } from 'rxjs';
import { TaskLogApiService } from 'src/app/api/task-log.api.service';
import { IDataInstance } from 'src/app/data-models/enrichment-standard/data-instance.interface';
import { ITaskLog } from 'src/app/data-models/task-log/task-log.interface';

@Injectable({
  providedIn: 'root',
})
export class CheckFinishCaluclationService {
  constructor(private taskLogApiService: TaskLogApiService) {}

  getIsShow(dataInstance: IDataInstance): Observable<boolean> {
    if (!dataInstance.operationalPlan.length && !dataInstance.calculationLog.length)
      return of(false);

    const hasOP = !!dataInstance.operationalPlan.length;
    const hasKP = !!dataInstance.calculationLog.length;
    const lastJournalOP = hasOP
      ? dataInstance.operationalPlan[dataInstance.operationalPlan.length - 1].id
      : null;
    const lastJournal = hasKP
      ? dataInstance.calculationLog[dataInstance.calculationLog.length - 1].id
      : null;

    return this.getCaluclations().pipe(
      take(1),
      map((caluclations) => {
        const caluclationsKPMap = new Map<string, ITaskLog>();
        const caluclationsOPMap = new Map<string, ITaskLog>();

        caluclations.forEach((item) => {
          const idOP = item.operationalPlan?.id;
          const idKP = item.calculationLog?.id;

          if (idOP) {
            caluclationsOPMap.set(idOP, item);
          } else if (idKP) {
            caluclationsKPMap.set(idKP, item);
          }
        });

        if (lastJournal || lastJournalOP) {
          const calculationLog = lastJournal
            ? caluclationsKPMap.get(lastJournal)
            : lastJournalOP
              ? caluclationsOPMap.get(lastJournalOP)
              : undefined;
          if (!calculationLog) return false;
          return calculationLog.status !== 'Ошибка расчета';
        }

        return false;
      }),
    );
  }

  getCaluclations(): Observable<ITaskLog[]> {
    return this.taskLogApiService.getList(1, [{ name: 'itemsPerPage', value: 500 * 1000 }]);
  }
}
