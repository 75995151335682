import { Pipe, PipeTransform } from '@angular/core';
import { IDataInstance } from 'src/app/data-models/enrichment-standard/data-instance.interface';
import { IJournal } from 'src/app/data-models/enrichment-standard/journal.interface';
import { ITaskLog } from 'src/app/data-models/task-log/task-log.interface';

@Pipe({
  name: 'checkCalculationComplete',
  standalone: true,
})
export class CheckCalculationCompletePipe implements PipeTransform {
  transform(
    dataInstance: IDataInstance,
    taskLogMapKP?: Map<string, ITaskLog>,
    taskLogMapOP?: Map<string, ITaskLog>,
  ): boolean {
    const hasOP = !!dataInstance.operationalPlan.length;
    const hasKP = !!dataInstance.calculationLog.length;

    const lastJournalOP = hasOP
      ? dataInstance.operationalPlan[dataInstance.operationalPlan.length - 1].id
      : null;
    const lastJournal = hasKP
      ? dataInstance.calculationLog[dataInstance.calculationLog.length - 1].id
      : null;

    if (lastJournal || lastJournalOP) {
      const calculationLog =
        lastJournal && taskLogMapKP
          ? taskLogMapKP.get(lastJournal)
          : lastJournalOP && taskLogMapOP
            ? taskLogMapOP.get(lastJournalOP)
            : undefined;
      if (!calculationLog) return false;
      return calculationLog.status !== 'Ошибка расчета';
    }

    return false;
  }
}
