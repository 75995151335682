import { Pipe, PipeTransform } from '@angular/core';
import { IResultSupplyItemTransform } from 'src/app/data-models/result-supply-item/result-supply-item.interface';

@Pipe({
  name: 'resultSupplyHorizontalLine',
  standalone: true,
})
export class ResultSupplyHorizontalLinePipe implements PipeTransform {
  transform(index: number, data: IResultSupplyItemTransform[]): boolean {
    if (index === 0) return false;
    return data[index].enrichmentComplex === data[index - 1].enrichmentComplex;
  }
}
