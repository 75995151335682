import { ActivatedRoute } from '@angular/router';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextComponent } from 'src/app/modules/ui/components/text/text.component';
import { JournalApiService } from 'src/app/api/journal.api.service';
import { IJournal } from 'src/app/data-models/enrichment-standard/journal.interface';
import { finalize, forkJoin, take } from 'rxjs';
import { DataInstanceApiService } from 'src/app/api/data-instance.api.service';
import { IDataInstance } from 'src/app/data-models/enrichment-standard/data-instance.interface';
import { SpinnerService } from '../../../ui/services/spinner.service';
import { OperationalPlanJournalApiService } from 'src/app/api/operational-plan-journal.api.service';

@Component({
  selector: 'print-info',
  templateUrl: './print-info.component.html',
  styleUrls: ['./print-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TextComponent],
})
export class PrintInfoComponent implements OnInit {
  journal!: IJournal;

  mapDataInstance = new Map<string, IDataInstance>();
  mapJournalDataInstance = new Map<string, IDataInstance>();

  isOperationalPlan = false;

  constructor(
    private ss: SpinnerService,
    private activatedRoute: ActivatedRoute,
    private journalApiService: JournalApiService,
    private operationalPlanJournalApiService: OperationalPlanJournalApiService,
    private cdr: ChangeDetectorRef,
    private dataInstanceApiService: DataInstanceApiService,
  ) {}

  ngOnInit(): void {
    this.isOperationalPlan = !!this.activatedRoute.snapshot.queryParams['type'];

    const id = this.activatedRoute.snapshot.params['calendarPlanId'];
    if (!id) return;

    this.ss.startSpinner();
    forkJoin([
      this.isOperationalPlan
        ? this.operationalPlanJournalApiService.getOne(id)
        : this.journalApiService.getOne(id),
      this.dataInstanceApiService.getList(1, [{ name: 'itemsPerPage', value: 500 * 1000 }]),
    ])
      .pipe(take(1), finalize(this.ss.stopSpinner))
      .subscribe(([journal, dataInstance]) => {
        this.journal = journal;

        dataInstance.forEach((item) => this.mapDataInstance.set(item.id!, item));

        journal.dataInstances.forEach((item) =>
          this.mapJournalDataInstance.set(
            item.type,
            this.mapDataInstance.get(item.id) as IDataInstance,
          ),
        );

        this.cdr.detectChanges();
      });
  }
}
