import { Pipe, PipeTransform } from '@angular/core';
import { IInternalLogisticsTransform } from 'src/app/data-models/internal-logistics/internal-logistics.interface';

@Pipe({
  name: 'internalLogisticsHorizontalLine',
  standalone: true,
})
export class InternalLogisticsHorizontalLinePipe implements PipeTransform {
  transform(index: number, data: IInternalLogisticsTransform[]): boolean {
    if (index === 0) return false;
    return data[index].direction === data[index - 1].direction;
  }
}
