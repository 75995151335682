<div class="wagon-fleet-dropdown-root">
  <div
    class="title"
    [attr.role]="'none'"
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
    (click)="togglePopup($event)"
  >
    <nguk-text
      weight="400"
      styleName="text-xs"
      [class.opacity]="!checkValueCurrentDropdown && showText"
      [class.mainCell]="!checkValueCurrentDropdown && isMainCell"
      >{{ currentDropdown }}
    </nguk-text>
  </div>

  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="isOpen"
    [cdkConnectedOverlayHasBackdrop]="true"
    (backdropClick)="closeOverlay()"
    cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  >
    <div class="wagon-fleet-dropdown-root-overlay-popup">
      <nguk-text weight="600" styleName="text-lg">{{ header }}</nguk-text>
      <div class="list">
        <ng-container *ngIf="!isRadio; else radio">
          <div class="list-item" *ngFor="let item of arrayOptions; let i = index">
            <nguk-checkbox-ctrl
              (handleCheckbox)="handleCheckbox()"
              [control]="getCheckboxControl(i)"
            >
              <ng-container label>
                <nguk-text color="grey" weight="400" styleName="text-xs" class="label">{{
                  item.title
                }}</nguk-text>
              </ng-container>
            </nguk-checkbox-ctrl>
          </div>
        </ng-container>

        <ng-template #radio>
          <mat-radio-group [formControl]="formDataRadio">
            <div class="list-item" *ngFor="let item of arrayOptions; let i = index">
              <mat-radio-button [value]="item" (change)="changeCurrentValue($event)">
                <nguk-text color="grey" weight="400" styleName="text-xs" class="label">{{
                  item.title
                }}</nguk-text>
              </mat-radio-button>
            </div>
          </mat-radio-group>
        </ng-template>
      </div>

      <div class="container-btn" *ngIf="isRadio">
        <nguk-button [type]="'primary'" (click)="resetForm()">Сбросить</nguk-button>
      </div>
    </div>
  </ng-template>
</div>
