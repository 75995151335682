export enum ShippingPlanModalListColumnsEnum {
  Index = 'index',
  RequiredWagonMark = 'requiredWagonMark',
  FactoryProductsMixture = 'factoryProductsMixture',
  ContractualAd = 'contractualAd',
  ContractualVd = 'contractualVd',
  ContractualY = 'contractualY',
  ContractualCsr = 'contractualCsr',
  ContractualHgi = 'contractualHgi',
  PlannedAd = 'plannedAd',
  PlannedVd = 'plannedVd',
  PlannedY = 'plannedY',
  PlannedCsr = 'plannedCsr',
  PlannedHgi = 'plannedHgi',
  Actions = 'actions',
}
