<div class="nguk-sort-filter-root">
  <div class="sort action">
    <nguk-button class="action-button" type="primary" (click)="changeStatusSorting()">
      <mat-icon
        [svgIcon]="
          currentColumn === column && typeSorting === 'desc'
            ? svgIconsEnum.SortingDesc
            : currentColumn === column && typeSorting === 'asc'
              ? svgIconsEnum.SortingAsc
              : svgIconsEnum.Sorting
        "
      />
    </nguk-button>
  </div>

  <div class="filter action">
    <nguk-button
      class="action-button"
      type="primary"
      cdkOverlayOrigin
      #trigger="cdkOverlayOrigin"
      (click)="openModalFiltering()"
    >
      <mat-icon [svgIcon]="isHasFilter ? svgIconsEnum.FilteringFull : svgIconsEnum.Filtering" />
    </nguk-button>
  </div>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpenFiltering"
>
  <nguk-overlay-filtering
    [filterData]="filterData"
    [column]="column"
    (toClose)="close()"
  ></nguk-overlay-filtering>
</ng-template>
