import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalBase } from 'src/app/modules/ui/base/modal.base';
import { ModalComponent } from 'src/app/modules/ui/components/modal/modal.component';
import { IWarehouseBalanceItem } from 'src/app/data-models/enrichment-standard/warehouse-balance.interface';
import { FormControl, FormGroup } from '@angular/forms';
import { TextComponent } from 'src/app/modules/ui/components/text/text.component';
import { ModalWarehouseBalanceItemsActionsComponent } from '../modal-warehouse-balance-items-actions/modal-warehouse-balance-items-actions.component';
import { ModalWarehouseBalanceFormComponent } from '../modal-warehouse-balance-form/modal-warehouse-balance-form.component';
import { IDataColumnTable } from 'src/app/data-models/data-column-table/data-column-table.interface';
import { IDataInstance } from 'src/app/data-models/enrichment-standard/data-instance.interface';
import { ToggleComponent } from 'src/app/modules/forms/components/toggle/toggle.component';

@Component({
  selector: 'modal-warehouse-balance-items',
  templateUrl: './modal-warehouse-balance-items.component.html',
  styleUrls: ['./modal-warehouse-balance-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ModalComponent,
    TextComponent,
    ModalWarehouseBalanceFormComponent,
    ModalWarehouseBalanceItemsActionsComponent,
    ToggleComponent,
  ],
})
export class ModalWarehouseBalanceItemsComponent extends ModalBase implements AfterViewInit {
  data!: IWarehouseBalanceItem[];
  dataInstance!: IDataInstance;
  warehouseBalanceId!: string;
  isShow = false;

  toggleList = ['Редактирование', 'Копирование'];
  toggleControl = new FormControl<string | null>(null);

  columns: IDataColumnTable[] = [
    { name: 'ad', display: 'Ad' },
    { name: 'vd', display: 'Vd' },
    { name: 'y', display: 'Y' },
    { name: 'csr', display: 'CSR' },
    { name: 'hgi', display: 'HGI' },
    { name: 'volume', display: 'Объем' },
  ];

  updateData!: () => void;

  formData!: FormGroup;

  constructor(cdRef: ChangeDetectorRef) {
    super(cdRef);
  }

  getForm(form: FormGroup): void {
    this.formData = form;
  }

  ngAfterViewInit(): void {
    this.initModal();
    this.toggleControl.setValue(!this.data.length ? this.toggleList[1] : this.toggleList[0]);
  }
}
