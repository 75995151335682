import { DestroyRef, Directive, HostBinding, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { SvgIconsEnum } from '../../../core/enums/svg-icons.enum';
import { ValidationErrorService } from '../services/validation-error.service';

@Directive({
  selector: '[ngukControl]',
  standalone: true,
})
export class ControlDirective<T = unknown> {
  @Input() control = new FormControl<null | T>(null);
  @Input() placeholder = '';
  @Input() direction: 'vertical' | 'horizontal' = 'horizontal';
  @HostBinding('class.nguk-control') private readonly baseClass = true;

  readonly svgIconsEnum = SvgIconsEnum;

  get isRequired(): boolean {
    return this.control.hasValidator(Validators.required);
  }

  get errorState(): boolean {
    return this.control.invalid && (this.control.dirty || this.control.touched);
  }

  get error(): string | null {
    return this.validationErrorService.getValidationErrorMessage(this.control);
  }

  constructor(
    protected dr: DestroyRef,
    private validationErrorService: ValidationErrorService,
  ) {}
}
