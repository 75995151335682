import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input, OnChanges, SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TablesListColumnsEnum } from '../../../enums/tables-list-columns.enum';
import { SvgIconsEnum } from '../../../../../core/enums/svg-icons.enum';
import { NsiTableTypeEnum } from '../../../enums/nsi-table-type.enum';
import {
  CdkDrag,
  CdkDragDrop,
  CdkDropList,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { MatTable, MatTableModule } from '@angular/material/table';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ButtonComponent } from 'src/app/modules/ui/components/button/button.component';
import { TextComponent } from 'src/app/modules/ui/components/text/text.component';
import { NsiDictionaryModel } from '../../../../../data-models/dictionary/dictionary.model';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Utils } from '../../../../../helpers/utils';

@Component({
  selector: 'nsi-edit-nsi-tables-list',
  templateUrl: './edit-nsi-tables-list.component.html',
  styleUrls: ['./edit-nsi-tables-list.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    ButtonComponent,
    TextComponent,
    MatTableModule,
    MatIconModule,
    MatMenuModule,
    CdkDropList,
    FormsModule,
    CdkDrag,
    MatTooltipModule,
  ],
})
export class EditNsiTablesListComponent implements OnChanges {
  @Input() dictionaries!: NsiDictionaryModel[];
  @Input() isCalculationComplete!: boolean;
  @ViewChild(MatTable) table!: MatTable<any>;

  readonly svgIconsEnum = SvgIconsEnum;
  readonly nsiTableTypeEnum = NsiTableTypeEnum;
  readonly tablesListColumnsEnum = TablesListColumnsEnum;
  readonly displayedColumns = [
    TablesListColumnsEnum.Order,
    TablesListColumnsEnum.Name,
    TablesListColumnsEnum.Format,
    TablesListColumnsEnum.Columns,
    TablesListColumnsEnum.Rows,
    TablesListColumnsEnum.Actions,
  ];

  dictionariesData: NsiDictionaryModel[] = [];

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    const { dictionaries } = changes;
    if (dictionaries?.currentValue && dictionaries?.currentValue !== dictionaries?.previousValue) {
      this.dictionariesData = [...this.dictionaries];
    }
  }

  getRowTypeText(format: NsiTableTypeEnum): string {
    return format === NsiTableTypeEnum.List ? 'Список' : 'Матрица';
  }

  copyRow(rowIndex: any): void {
    const rowToCopy = this.dictionariesData[rowIndex];
    const copiedRow = {
      dataInstance: rowToCopy.dataInstance,
      ordinal: this.dictionaries.length
        ? Utils.getMaxValueByKey(this.dictionaries, 'ordinal') + 1
        : Utils.getMaxValueByKey(this.dictionariesData, 'ordinal') + 1,
      title: '-',
      format: rowToCopy.format,
      dictionaryColumns: [...rowToCopy.dictionaryColumns].filter(Boolean),
      dictionaryRows: [...rowToCopy.dictionaryRows].filter(Boolean),
      copiedFromId: rowToCopy.id || (rowToCopy as NsiDictionaryModel & { copiedFromId: number }).copiedFromId,
    } as any;

    this.dictionariesData = [
      ...this.dictionariesData.slice(0, rowIndex + 1),
      copiedRow,
      ...this.dictionariesData.slice(rowIndex + 1),
    ];
    this.cdr.markForCheck();
  }

  deleteRow(rowIndex: number): void {
    this.dictionariesData = this.dictionariesData.filter((_, index: number) => index !== rowIndex);
    this.cdr.markForCheck();
  }

  addRow(): void {
    this.dictionariesData = [
      ...this.dictionariesData,
      {
        dataInstance: this.dictionariesData[0]?.dataInstance ?? null,
        ordinal: this.dictionariesData.length ? Utils.getMaxValueByKey(this.dictionariesData, 'ordinal') + 1 : 1,
        title: '-',
        format: NsiTableTypeEnum.List,
      } as any,
    ];
  }

  changeListMode(row: NsiDictionaryModel, listType: NsiTableTypeEnum): void {
    row.format = listType;
  }

  onRowsReorder(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.dictionariesData, event.previousIndex, event.currentIndex);
    this.table.renderRows();
    this.dictionariesData.forEach((dictionary: NsiDictionaryModel, index: number) => {
      dictionary.ordinal = index + 1;
    });
    this.cdr.detectChanges();
  }
}
