import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProviderActiveIdService {
  public isOpenedIdSource = new BehaviorSubject<number>(0);
  readonly isOpenedId$ = this.isOpenedIdSource.asObservable();

  setValue(id: number): void {
    this.isOpenedIdSource.next(id);
  }
}
