<div class="journal-submenu-item-root" [class.-opened]="isOpened">
  <div class="wrapper">
    <div class="submenu-item-header">
      <mat-icon
        *ngIf="type === 'dictionary' || (menuItems.length && type !== 'dictionary')"
        [svgIcon]="svgIconsEnum.ArrowUpDown"
      />
      <ng-container *ngIf="type !== 'graphs'; else graphs">
        <nguk-text
          weight="400"
          styleName="text-xs"
          [attr.role]="'none'"
          (click)="handleStatusOpened()"
          >{{ title }}</nguk-text
        >
      </ng-container>

      <ng-template #graphs>
        <nguk-text
          *ngIf="dataJournal?.graph?.list?.length"
          weight="400"
          styleName="text-xs"
          class="graphs"
          [class.active-current]="isActive()"
          [routerLink]="['graphs']"
          [routerLinkActive]="'-active'"
          (click)="changeItemGraphs()"
        >
          {{ title }}
        </nguk-text>
      </ng-template>
    </div>

    <div class="journal-submenu-item-list">
      <ng-container *ngIf="type === 'dictionary'; else otherSubmenu">
        <ng-container *ngFor="let item of listDictionary; let index = index">
          <nguk-text
            weight="400"
            styleName="text-xs"
            class="submenu-list-item"
            [class.active-current]="isActive()"
            [routerLink]="getRouterLinkNSI(item)"
            [routerLinkActive]="'-active'"
            (click)="changeItemDictionary(item)"
          >
            {{ item.title }}
          </nguk-text>
        </ng-container>
      </ng-container>

      <ng-template #otherSubmenu>
        <ng-container *ngFor="let menuItem of menuItems">
          <nguk-text
            *ngIf="
              getItemMenu(menuItem.type) &&
              (isOperationalPlan ? menuItem.isOperationalPlan : menuItem.isCalendarPlan)
            "
            weight="400"
            styleName="text-xs"
            class="submenu-list-item"
            [class.active-current]="isActive()"
            (click)="changeItem(menuItem)"
            [routerLink]="getRouterLink(menuItem)"
            [routerLinkActive]="'-active'"
          >
            {{ menuItem.text }}
          </nguk-text>
        </ng-container>
      </ng-template>
    </div>
  </div>
</div>
