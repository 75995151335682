<nguk-modal [visible]="isVisible" [close]="closeModal.bind(this, false)">
  <div class="modal">
    <div class="modal-header">
      <div class="modal-header-info">
        <nguk-text weight="600" styleName="text-xl">
          {{ modalTitle }}
        </nguk-text>

        <nguk-toggle
          *ngIf="isVisibleToggle() && !isShow"
          [toggleList]="toggleList"
          [control]="toggleControl"
        />
      </div>

      <nguk-modal-actions
        *ngIf="!isShow"
        [showDelete]="toggleControl.value !== 'Копирование'"
        (save)="handleSave()"
        (delete)="handleDelete()"
      />
    </div>

    <shipping-plan-modal-form
      *ngIf="editShippingPlanForm"
      [isShow]="isShow"
      [dataInstance]="dataInstance"
      [form]="editShippingPlanForm"
    />

    <shipping-plan-modal-list
      [isShow]="isShow"
      [tableRows]="tableRows"
      (addRow)="handleAddRow()"
      (copyRow)="handleCopyRow($event)"
      (deleteRow)="handleDeleteRow($event)"
    />
  </div>
</nguk-modal>
