<form [formGroup]="formData" class="form">
  <div class="container-item">
    <div class="form-item">
      <nguk-text color="grey" weight="500" styleName="text-sm">Задача</nguk-text>
      <div class="form-item__input">
        <!-- <nguk-input-ctrl [control]="getControl('task')"></nguk-input-ctrl> -->
        <nguk-select [control]="getControl('task')" [options]="selectTask" />
      </div>
    </div>

    <div class="form-item">
      <nguk-text color="grey" weight="500" styleName="text-sm">Имя</nguk-text>
      <div class="form-item__input">
        <nguk-input-ctrl [control]="getControl('ruleName')"></nguk-input-ctrl>
      </div>
    </div>

    <div class="form-item">
      <nguk-text color="grey" weight="500" styleName="text-sm">Тип</nguk-text>
      <div class="form-item__input">
        <!-- <nguk-input-ctrl [control]="getControl('ruleType')"></nguk-input-ctrl> -->
        <nguk-select [control]="getControl('ruleType')" [options]="selectType" />
      </div>
    </div>

    <div class="form-item" [attr.role]="'none'">
      <nguk-text color="grey" weight="500" styleName="text-sm">Код ресурса</nguk-text>
      <div class="form-item__input code-resource">
        <nguk-input-ctrl [control]="getControl('resourceCode')"></nguk-input-ctrl>
        <mat-icon
          [svgIcon]="svgIconsEnum.ArrowUpDown"
          (click)="openModalSelectResource.emit(formData.value)"
        ></mat-icon>
      </div>
    </div>

    <div class="form-item">
      <nguk-text color="grey" weight="500" styleName="text-sm">Норма</nguk-text>
      <div class="form-item__input">
        <nguk-input-ctrl [control]="getControl('generalNorm')"></nguk-input-ctrl>
      </div>
    </div>

    <div class="form-item">
      <nguk-text color="grey" weight="500" styleName="text-sm">Активный</nguk-text>
      <div class="form-item__input">
        <nguk-slide-toggle [control]="getControl('activated')"></nguk-slide-toggle>
      </div>
    </div>
  </div>
</form>
