import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { SvgIconsEnum } from '../enums/svg-icons.enum';

@Injectable({
  providedIn: 'root',
})
export class IconsService {
  static ASSETS_IMAGES_PATH = '../../../../../assets/svg';
  static svgIcons = [
    SvgIconsEnum.LogoNguk,
    SvgIconsEnum.LogoKonsom,
    SvgIconsEnum.LogoInfo,
    SvgIconsEnum.Filter,
    SvgIconsEnum.User,
    SvgIconsEnum.Close,
    SvgIconsEnum.ArrowUpDown,
    SvgIconsEnum.Plus,
    SvgIconsEnum.Save,
    SvgIconsEnum.Duplicate,
    SvgIconsEnum.Delete,
    SvgIconsEnum.Clock,
    SvgIconsEnum.CalendarDays,
    SvgIconsEnum.Edit,
    SvgIconsEnum.Calculate,
    SvgIconsEnum.Tick,
    SvgIconsEnum.DoubleTick,
    SvgIconsEnum.CheckBold,
    SvgIconsEnum.LockClose,
    SvgIconsEnum.LockOpen,
    SvgIconsEnum.Trash,
    SvgIconsEnum.CancelCalculationDone,
    SvgIconsEnum.ErrorCalculationDone,
    SvgIconsEnum.TimePairClock,
    SvgIconsEnum.Refresh,
    SvgIconsEnum.MoreHorizontal,
    SvgIconsEnum.Binary,
    SvgIconsEnum.Eye,
    SvgIconsEnum.EyeHide,
    SvgIconsEnum.TableProperties,
    SvgIconsEnum.Print,
    SvgIconsEnum.NewMining,
    SvgIconsEnum.UnfoldVertical,
    SvgIconsEnum.ChevronDown,
    SvgIconsEnum.Import,
    SvgIconsEnum.Excel,
    SvgIconsEnum.RelatedSystems,
    SvgIconsEnum.List,
    SvgIconsEnum.Grid2x2,
    SvgIconsEnum.Sorting,
    SvgIconsEnum.SortingDesc,
    SvgIconsEnum.SortingAsc,
    SvgIconsEnum.Filtering,
    SvgIconsEnum.FilteringFull,
    SvgIconsEnum.Search,
    SvgIconsEnum.Remove,
    SvgIconsEnum.Download,
    SvgIconsEnum.FileUploadedSuccessful,
    SvgIconsEnum.FileUploadedWrongly,
    SvgIconsEnum.ExitIcon,
    SvgIconsEnum.CloudDownload,
    SvgIconsEnum.CloudUpload,
    SvgIconsEnum.Attention,
  ];

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {}

  registerSvgIcons(): void {
    IconsService.svgIcons.forEach(this.addSvgIcon);
  }

  private addSvgIcon = (imageName: string): void => {
    this.matIconRegistry.addSvgIcon(
      imageName,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `${IconsService.ASSETS_IMAGES_PATH}/${imageName}.svg`,
      ),
    );
  };
}
