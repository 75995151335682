<form class="form form-list" [formGroup]="formData">
  <mat-table [dataSource]="dataSource" formArrayName="values">
    <ng-container [matColumnDef]="'prev-value'">
      <mat-header-cell *matHeaderCellDef [attr.colspan]="listColumns.length">
        <nguk-text weight="400" styleName="text-xl" class="prev-value">...</nguk-text>
      </mat-header-cell>
    </ng-container>

    <ng-container [matColumnDef]="'dateStarted'">
      <mat-header-cell *matHeaderCellDef>
        <nguk-text color="grey" weight="400" styleName="text-xs">Начало</nguk-text>
      </mat-header-cell>

      <mat-cell
        *matCellDef="let row; let index = index"
        [formGroupName]="index"
        (click)="chooseActiveRoute(row, index)"
        [class.parent-row]="checkParentValue(row)"
      >
        <ng-container *ngIf="editRowIndex === index && !isShow && row.dateStarted; else textView">
          <nguk-datepicker-ctrl
            [control]="getControlArray(index, 'dateStarted')"
            [showTime]="true"
            [appendTo]="'body'"
          ></nguk-datepicker-ctrl>
        </ng-container>

        <ng-template #textView>
          <div
            class="date-container"
            [attr.role]="'none'"
            *ngIf="row.dateStarted; else emptyDateStarted"
          >
            <nguk-text weight="400" styleName="text-xs" class="date-container__date"
              >{{ getControlArray(index, 'dateStarted').value | date: 'dd.MM' }}
            </nguk-text>
            <mat-icon [svgIcon]="svgIconsEnum.Clock" />
            <nguk-text weight="400" styleName="text-xs" class="date-container__time">
              {{ getControlArray(index, 'dateStarted').value | date: 'H:mm' }}
            </nguk-text>
          </div>
          <ng-template #emptyDateStarted>
            <nguk-text weight="400" styleName="text-xs" class="date-container__date">...</nguk-text>
          </ng-template>
        </ng-template>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="'dateFinished'">
      <mat-header-cell *matHeaderCellDef>
        <nguk-text color="grey" weight="400" styleName="text-xs">Окончание</nguk-text>
      </mat-header-cell>

      <mat-cell
        *matCellDef="let row; let index = index"
        [formGroupName]="index"
        (click)="chooseActiveRoute(row, index)"
        [class.parent-row]="checkParentValue(row)"
      >
        <ng-container *ngIf="editRowIndex === index && !isShow && row.dateFinished; else textView">
          <nguk-datepicker-ctrl
            [control]="getControlArray(index, 'dateFinished')"
            [showTime]="true"
            [appendTo]="'body'"
          ></nguk-datepicker-ctrl>
        </ng-container>

        <ng-template #textView>
          <div
            class="date-container"
            [attr.role]="'none'"
            *ngIf="row.dateFinished; else emptyDateFinished"
          >
            <nguk-text weight="400" styleName="text-xs" class="journal-item-date__date"
              >{{ getControlArray(index, 'dateFinished').value | date: 'dd.MM' }}
            </nguk-text>
            <mat-icon [svgIcon]="svgIconsEnum.Clock" />
            <nguk-text weight="400" styleName="text-xs" class="journal-item-date__time">
              {{ getControlArray(index, 'dateFinished').value | date: 'H:mm' }}
            </nguk-text>
          </div>

          <ng-template #emptyDateFinished>
            <nguk-text weight="400" styleName="text-xs" class="date-container__date">...</nguk-text>
          </ng-template>
        </ng-template>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="'duration'">
      <mat-header-cell *matHeaderCellDef>
        <nguk-text color="grey" weight="400" styleName="text-xs">Длительность</nguk-text>
      </mat-header-cell>

      <mat-cell
        *matCellDef="let row; let index = index"
        (click)="chooseActiveRoute(row, index)"
        [class.parent-row]="checkParentValue(row)"
      >
        <ng-container *ngIf="editRowIndex === index && !isShow; else textView">
          <nguk-input-ctrl [type]="'number'" [control]="getControlArray(index, 'duration')" />
        </ng-container>

        <ng-template #textView>
          <nguk-text weight="400" styleName="text-xs" class="text-view">
            {{ getControlArray(index, 'duration').value }}
          </nguk-text>
        </ng-template>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="'value'">
      <mat-header-cell *matHeaderCellDef>
        <nguk-text color="grey" weight="400" styleName="text-xs">Значение</nguk-text>
      </mat-header-cell>

      <mat-cell
        *matCellDef="let row; let index = index"
        (click)="chooseActiveRoute(row, index)"
        [class.parent-row]="checkParentValue(row)"
      >
        <ng-container *ngIf="editRowIndex === index && !isShow; else textView">
          <nguk-input-ctrl [control]="getControlArray(index, 'value')" />
        </ng-container>

        <ng-template #textView>
          <nguk-text weight="400" styleName="text-xs" class="text-view">
            {{ getControlArray(index, 'value').value }}
          </nguk-text>
        </ng-template>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="'unit'">
      <mat-header-cell *matHeaderCellDef>
        <nguk-text color="grey" weight="400" styleName="text-xs">Ед. измерения</nguk-text>
      </mat-header-cell>

      <mat-cell
        *matCellDef="let row; let index = index"
        (click)="chooseActiveRoute(row, index)"
        [class.parent-row]="checkParentValue(row)"
      >
        <ng-container *ngIf="editRowIndex === index && !isShow; else textView">
          <nguk-input-ctrl [control]="getControlArray(index, 'unit')" />
        </ng-container>

        <ng-template #textView>
          <nguk-text weight="400" styleName="text-xs" class="text-view">
            {{ getControlArray(index, 'unit').value }}
          </nguk-text>
        </ng-template>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="'actions'">
      <mat-header-cell *matHeaderCellDef>
        <nguk-text color="grey" weight="400" styleName="text-xs">Действия</nguk-text>
      </mat-header-cell>

      <mat-cell *matCellDef="let row; let index = index" [class.parent-row]="checkParentValue(row)">
        <div class="actions-row" *ngIf="!isShow">
          <nguk-button
            class="copy-button"
            [class.-hidden]="checkParentValue(row)"
            type="secondary"
            matTooltip="Копировать строку"
            (click)="addFormGroupToFormArray(formDataArray.value[index])"
          >
            <mat-icon [svgIcon]="svgIconsEnum.Duplicate"></mat-icon>
          </nguk-button>

          <nguk-button
            class="delete-button"
            [class.-hidden]="checkParentValue(row)"
            type="secondary"
            matTooltip="Удалить строку"
            (click)="removeItem(index)"
          >
            <mat-icon [svgIcon]="svgIconsEnum.Delete"></mat-icon>
          </nguk-button>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="listColumns; sticky: true"></mat-header-row>
    <ng-container *ngIf="checkPrevValue()">
      <mat-header-row *matHeaderRowDef="['prev-value']"></mat-header-row>
    </ng-container>
    <mat-row *matRowDef="let row; columns: listColumns"></mat-row>
  </mat-table>

  <div class="add-row" *ngIf="!isShow" [attr.role]="'none'" (click)="addNewRow()">
    <nguk-button type="secondary">
      <ng-container>
        <mat-icon [svgIcon]="svgIconsEnum.Plus"></mat-icon>
        <nguk-text weight="400" styleName="text-sm">Добавить строку</nguk-text>
      </ng-container>
    </nguk-button>
  </div>
</form>
