<div class="container-buttons">
  <nguk-button (click)="comeBack()" type="secondary"
    ><nguk-text styleName="text-sm">Назад</nguk-text>
  </nguk-button>
  <nguk-button (click)="printPage()" type="primary">
    <nguk-text styleName="text-sm">Распечатать документ</nguk-text>
  </nguk-button>

  <nguk-button (click)="exportToExcel()" type="secondary"
    ><nguk-text styleName="text-sm">Скачать Excel</nguk-text>
  </nguk-button>
</div>
