import { Injectable } from '@angular/core';
import { EntityApi } from '../core/abstract/entity.api';
import { ApiProvider } from '../core/providers/api.provider';
import { IDataInstanceCopy } from '../data-models/enrichment-standard/data-instance-copy.interface';

@Injectable({
  providedIn: 'root',
})
export class DataInstanceCopyApiService extends EntityApi<IDataInstanceCopy, IDataInstanceCopy> {
  protected readonly api = 'sourceData/dataInstance/copy';

  constructor(apiProvider: ApiProvider) {
    super(apiProvider);
  }
}
