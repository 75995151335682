<div class="fund-list-root">
  <nguk-section>
    <div class="sticky-container" tabindex="0">
      <div class="container-buttons" *ngIf="!isShow">
        <nguk-button
          type="primary"
          class="create-button"
          (click)="addColumn()"
          matTooltip="Добавить колонку"
        >
          <mat-icon [svgIcon]="svgIconsEnum.Plus"></mat-icon>
        </nguk-button>
      </div>

      <div class="container-buttons -main-row" *ngIf="!isShow">
        <nguk-button
          type="primary"
          *ngIf="dataMap.size > 1"
          class="edit-button"
          (click)="openModalCell()"
        >
          <mat-icon [svgIcon]="svgIconsEnum.Edit"></mat-icon>
        </nguk-button>

        <nguk-button
          type="primary"
          class="create-button"
          (click)="openModal()"
          matTooltip="Добавить строку"
        >
          <mat-icon [svgIcon]="svgIconsEnum.Plus"></mat-icon>
        </nguk-button>
      </div>
      <table mat-table [dataSource]="data" matSort multiTemplateDataRows #table>
        <ng-container [matColumnDef]="'empty'">
          <th
            mat-header-cell
            *matHeaderCellDef
            [attr.colspan]="columns.length"
            class="horizontal-alignment"
          ></th>
        </ng-container>

        <ng-container [matColumnDef]="'dateStarted'">
          <th
            mat-header-cell
            *matHeaderCellDef
            [attr.colspan]="columnsDays.length"
            class="horizontal-alignment"
          >
            <nguk-text weight="400" styleName="text-xs"
              >График с {{ dataInstance && dataInstance.dateStarted | date: 'dd.MM.yyyy' }}
            </nguk-text>
          </th>
        </ng-container>

        <ng-container
          *ngFor="let item of columns; let i = index"
          [matColumnDef]="item.name"
          [sticky]="true"
        >
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            <nguk-th-sort-filter
              [columnName]="item.name"
              [columnDisplay]="item.display"
              [rawData]="rawData"
            ></nguk-th-sort-filter>
          </th>
          <td
            mat-cell
            *matCellDef="let row; let ind = dataIndex"
            [class.not-activated-row]="!row['activated']"
            (dblclick)="openModal(row, ind)"
          >
            <nguk-text weight="400" styleName="text-xs">
              {{ item.name === 'index' ? ind + 1 : row[item.name] || '' }}
            </nguk-text>
          </td>
        </ng-container>

        <ng-container *ngFor="let item of columnsDays; let i = index" [matColumnDef]="item.name">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="day-header-cell"
            [class.day-weekend]="+item.display | ngukWeekendDayPipe: dataInstance.dateStarted + ''"
          >
            <nguk-text weight="400" styleName="text-xs" class="opacity-text"
              >{{ item.display }}
            </nguk-text>
          </th>

          <td
            mat-cell
            *matCellDef="let row; let ind = dataIndex"
            class="day-mat-cell"
            [class.day-weekend]="+item.display | ngukWeekendDayPipe: dataInstance.dateStarted + ''"
            [class.first-date-period]="checkValueDay(row, item)"
            [class.choose-cell]="checkChooseDay(row, item)"
            (click)="chooseDay(row, item)"
            (dblclick)="openModalCell(row, item, ind)"
          >
            <nguk-text weight="400" styleName="text-xs">
              {{ getValueCurrentDay(row, item) }}
            </nguk-text>
          </td>
        </ng-container>

        <ng-container [matColumnDef]="'index-total'" [sticky]="true">
          <th mat-header-cell *matHeaderCellDef [attr.colspan]="6"></th>
        </ng-container>

        <ng-container [matColumnDef]="'total-row'">
          <th mat-header-cell *matHeaderCellDef>
            <nguk-text weight="700" styleName="text-xs"> Итого </nguk-text>
          </th>

          <td mat-cell *matCellDef="let row; let i = index" class="total-row">
            <nguk-text weight="700" styleName="text-xs">{{
              row.totalRow ? row.totalRow : ''
            }}</nguk-text>
          </td>
        </ng-container>

        <ng-container [matColumnDef]="'total-row-amount'">
          <th mat-header-cell *matHeaderCellDef class="total-row">
            <nguk-text weight="700" styleName="text-xs">{{
              totalValueMap.get('total') ?? ''
            }}</nguk-text>
          </th>
        </ng-container>

        <ng-container *ngFor="let day of columnsDaysTotal; let i = index" [matColumnDef]="day.name">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="day-header-cell"
            [class.day-weekend]="+day.display | ngukWeekendDayPipe: dataInstance.dateStarted + ''"
          >
            <nguk-text weight="700" styleName="text-xs">{{
              totalValueMap.get(day.display) ?? ''
            }}</nguk-text>
          </th>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="['empty', 'dateStarted']; sticky: true"></tr>
        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
          class="sticky-row"
        ></tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumnsTotal; sticky: true"></tr>

        <tr
          mat-row
          *matRowDef="let row; let ind = dataIndex; columns: displayedColumns"
          (mouseover)="currentActionsRows = ind"
          (focus)="(null)"
          (mouseleave)="currentActionsRows = undefined"
        ></tr>
      </table>
    </div>
  </nguk-section>
</div>

<ng-template #modal></ng-template>
