import { isDevMode } from '@angular/core';

export const environment = {
  apiURL: process.env['BACKEND_URL'] as string,
  // apiURL: 'http://dev-nguk-plan.inka.konsom.ru/api',
  KC_ADDR: process.env['KC_ADDR'],
  KC_REALM: process.env['KC_REALM'],
  KC_CLIENT_ID: process.env['KC_CLIENT_ID'],

  type: 'dev',
};
