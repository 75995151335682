import { IDataColumnTable } from 'src/app/data-models/data-column-table/data-column-table.interface';

export const firstHeaderRow: IDataColumnTable[] = [
  { name: 'toggle', display: '', colspan: '2' },
  { name: 'coal', display: 'Рядовой уголь', colspan: '6' },
  { name: 'concentrate', display: 'Концентрат', colspan: '3' },
  { name: 'tk1dropout', display: 'Отсев', colspan: '3' },
  { name: 'tk1sludge', display: 'Шлам + ПП', colspan: '3' },
  { name: 'tk2sludge', display: 'ПП', colspan: '3' },
  // { name: 'tk1-create-button', display: '', colspan: '1' },
  // { name: 'tk2-create-button', display: '', colspan: '1' },
];

export const columnsData: IDataColumnTable[] = [
  { name: 'index', display: '#' },
  { name: 'ordinaryCoalMark', display: 'Марка' },
  { name: 'ordinaryCoalMiningArea', display: 'Уч.' },
  { name: 'ordinaryCoalMiningLayer', display: 'Пласт' },
  { name: 'ordinaryCoalMiningProfile', display: 'Проф.' },
  { name: 'ordinaryCoalAd', display: 'Ad' },
  { name: 'ordinaryCoalVd', display: 'Vd' },
  { name: 'ordinaryCoalY', display: 'Y' },
  { name: 'tk1ConcentrateMark', display: 'Марка' },
  { name: 'tk1ConcentrateAd', display: 'Ad' },
  { name: 'tk1ConcentrateKx', display: 'Kx' },
  { name: 'tk1DropoutMark', display: 'Марка' },
  { name: 'tk1DropoutAd', display: 'Ad' },
  { name: 'tk1DropoutKx', display: 'Kx' },
  { name: 'tk1SludgeMark', display: 'Марка' },
  { name: 'tk1SludgeAd', display: 'Ad' },
  { name: 'tk1SludgeKx', display: 'Kx' },
  { name: 'tk2ConcentrateMark', display: 'Марка' },
  { name: 'tk2ConcentrateAd', display: 'Ad' },
  { name: 'tk2ConcentrateKx', display: 'Kx' },
  { name: 'tk2IndustrialProductMark', display: 'Марка' },
  { name: 'tk2IndustrialProductAd', display: 'Ad' },
  { name: 'tk2IndustrialProductKx', display: 'Kx' },
];
