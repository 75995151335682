<div
  class="nguk-selection-switch-ctrl-root"
  [class.-horizontal]="direction === 'horizontal'"
  [class.-disabled]="control.disabled"
>
  <ng-content select="[label]" />

  <div class="selection-switch-ctrl">
    <div class="selection-switch-options">
      <div
        *ngFor="let option of options"
        class="option"
        [class.-selected]="option.value === control.value"
        (click)="control.setValue(option.value)"
      >
        <nguk-text class="label-text" weight="400" styleName="text-xs">{{ option.label }}</nguk-text>
        <nguk-text
          class="additional-label-text"
          weight="400"
          styleName="text-xs"
        >
          {{ option.additionalLabel }}
        </nguk-text>
      </div>
    </div>
    <nguk-control-error [showError]="errorState">{{ error }}</nguk-control-error>
  </div>
</div>
