import {
  ChangeDetectionStrategy,
  Component,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from 'src/app/modules/ui/components/button/button.component';
import { SectionComponent } from 'src/app/modules/ui/components/section/section.component';
import { TextComponent } from 'src/app/modules/ui/components/text/text.component';
import { RouterModule } from '@angular/router';
import { PageComponent } from 'src/app/modules/ui/components/page/page.component';
import { NsiDataInstancesComponent } from '../../parts/nsi-data-instances/nsi-data-instances.component';
import { Page } from '../../../../ui/base/page';

@Component({
  selector: 'nsi-page',
  templateUrl: './nsi-page.component.html',
  styleUrls: ['./nsi-page.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    SectionComponent,
    ButtonComponent,
    TextComponent,
    MatIconModule,
    RouterModule,
    PageComponent,
    NsiDataInstancesComponent,
  ],
})
export class NsiPageComponent extends Page {
}
