import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlDirective } from '../../directives/control.directive';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'nguk-checkbox-ctrl',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatCheckboxModule, ReactiveFormsModule],
})
export class CheckboxComponent extends ControlDirective {
  @Input() indeterminate = false;
  @Output() handleCheckbox = new EventEmitter<void>();
  @HostBinding('class.nguk-checkbox-ctrl') readonly checkboxBaseClass = true;

  changeState(): void {
    this.handleCheckbox.emit();
  }
}
