import { Injectable } from '@angular/core';
import { forkJoin, mergeMap, Observable, of } from 'rxjs';
import { IShippingPlan } from '../../../data-models/shipping-plan/shipping-plan.interface';
import { ShippingPlanApiService } from '../../../api/shipping-plan.api.service';
import { IShippingPlanLoadingRule } from '../../../data-models/shipping-plan-loading-rule/shipping-plan-loading-rule.interface';
import { ShippingPlanLoadingRuleApiService } from '../../../api/shipping-plan-loading-rule.api.service';
import { omit, uniq } from 'lodash-es';
import { EditShippingPlanLoadingRuleForm } from '../forms/edit-shipping-plan-loading-rule.form';
import { EditShippingPlanForm } from '../forms/edit-shipping-plan.form';
import { IQueryRequest } from '../../../data-models/query-request/query-request.interface';
import { TShippingPlanListRow } from '../types/shipping-plan-list-row.type';
import { TShippingPlanDay } from '../types/shipping-plan-day.type';
import { IDataInstance } from '../../../data-models/enrichment-standard/data-instance.interface';
import * as moment from 'moment/moment';

@Injectable({
  providedIn: 'root',
})
export class ShippingPlanService {
  constructor(
    private shippingPlanApiService: ShippingPlanApiService,
    private shippingPlanLoadingRuleApiService: ShippingPlanLoadingRuleApiService,
  ) {}

  getShippingPlan(shippingPlanId: number | string): Observable<IShippingPlan> {
    return this.shippingPlanApiService.getOne(shippingPlanId);
  }

  getShippingPlanList(dataInstanceId: string): Observable<IShippingPlan[]> {
    const shippingPlanListQueryParams: IQueryRequest[] = [
      { name: 'dataInstance.id', value: dataInstanceId },
      { name: 'itemsPerPage', value: 50 * 10 },
    ];

    return this.shippingPlanApiService.getList(1, shippingPlanListQueryParams);
  }

  getShippingPlanLoadingRules(
    shippingPlanId: number | string,
  ): Observable<IShippingPlanLoadingRule[]> {
    return this.shippingPlanLoadingRuleApiService.getList(1, [
      { name: 'shippingPlan.id', value: shippingPlanId },
    ]);
  }

  getExpectedShipmentDate(
    dataInstance: IDataInstance,
    expectedShipmentDate: string | number,
  ): string {
    if (typeof expectedShipmentDate === 'string') {
      return expectedShipmentDate;
    }

    return moment.utc(dataInstance.dateStarted).add(expectedShipmentDate, 'days').format();
  }

  copy(
    shippingPlanForm: EditShippingPlanForm,
    loadingRules: EditShippingPlanLoadingRuleForm[],
  ): Observable<unknown> {
    const model = shippingPlanForm.getFormData();

    return this.shippingPlanApiService.create(omit(model, 'id')).pipe(
      mergeMap((shippingPlan: IShippingPlan) => {
        const loadingRulesRequests = loadingRules?.map(
          (shippingPlanLoadingRule: EditShippingPlanLoadingRuleForm) => {
            const loadingRuleModelToCopy = {
              ...shippingPlanLoadingRule.getFormData(),
              shippingPlan: { id: String(shippingPlan.id) },
            };

            return this.shippingPlanLoadingRuleApiService.create(
              omit(loadingRuleModelToCopy, 'id'),
            );
          },
        );

        return forkJoin(!loadingRulesRequests.length ? of(null) : loadingRulesRequests);
      }),
    );
  }

  mapShippingPlansToListRepresentation(
    dataInstance: IDataInstance,
    shippingPlans: IShippingPlan[],
    days: number,
  ): TShippingPlanListRow[] {
    const customerToTrainEquipmentMap = this.getCustomerToTrainEquipmentsMap(shippingPlans);
    const trainStationToCustomersMap = this.getTrainStationToCustomersMap(shippingPlans);
    const duplicates = this.getDaysDuplicates(shippingPlans);

    const tableRows = this.getTableRows(
      trainStationToCustomersMap,
      customerToTrainEquipmentMap,
      duplicates,
    );
    this.setDaysToTableRows(dataInstance, shippingPlans, tableRows, duplicates, days);

    return tableRows;
  }

  private getDaysDuplicates(shippingPlans: IShippingPlan[]): IShippingPlan[] {
    const duplicates: IShippingPlan[] = [];
    shippingPlans.forEach((shippingPlan: IShippingPlan) => {
      shippingPlans.forEach((_: IShippingPlan) => {
        if (shippingPlan.id === _.id) {
          return;
        }

        if (
          shippingPlan.expectedShipmentDate === _.expectedShipmentDate &&
          shippingPlan.customer.id === _.customer.id &&
          shippingPlan.trainWagonsEquipment === _.trainWagonsEquipment &&
          shippingPlan.station.id === _.station.id &&
          !duplicates.find((el: IShippingPlan) => el.id === _.id) &&
          !duplicates.find((el: IShippingPlan) => el.id === shippingPlan.id)
        ) {
          _.isDuplicate = true;
          duplicates.push(_);
        }
      });
    });

    return duplicates;
  }

  private getTrainStationToCustomersMap(shippingPlans: IShippingPlan[]): Map<string, string[]> {
    const map = new Map<string, string[]>();
    shippingPlans.forEach((shippingPlan: IShippingPlan) => {
      const existingCustomers = map.get(shippingPlan.station?.title?.trim());
      if (!existingCustomers) {
        map.set(shippingPlan.station?.title?.trim(), [shippingPlan.customer?.title]);
        return;
      }

      map.set(
        shippingPlan.station?.title?.trim(),
        uniq([...existingCustomers, shippingPlan.customer?.title]),
      );
    });

    return map;
  }

  private getCustomerToTrainEquipmentsMap(shippingPlans: IShippingPlan[]): Map<string, string[]> {
    const map = new Map<string, string[]>();
    shippingPlans.forEach((shippingPlan: IShippingPlan) => {
      const existingTrainEquipments = map.get(shippingPlan.customer?.title);
      if (!existingTrainEquipments) {
        map.set(shippingPlan.customer?.title, [shippingPlan.trainWagonsEquipment]);
        return;
      }

      map.set(
        shippingPlan.customer?.title,
        uniq([...existingTrainEquipments, shippingPlan.trainWagonsEquipment]),
      );
    });

    return map;
  }

  private getTableRows(
    trainStationToCustomersMap: Map<string, string[]>,
    customerToTrainEquipmentMap: Map<string, string[]>,
    duplicates: IShippingPlan[],
  ): TShippingPlanListRow[] {
    const tableRows: TShippingPlanListRow[] = [];
    let index = 0;
    trainStationToCustomersMap.forEach((customers: string[], trainStation: string) => {
      customers.forEach((customer: string) => {
        const customerTrainEquipments = customerToTrainEquipmentMap.get(customer) as string[];
        customerTrainEquipments.forEach((trainWagonsEquipment: string) => {
          const isDuplicateExists = duplicates.find(
            (duplicate: IShippingPlan) =>
              duplicate.customer.title === customer &&
              duplicate.trainWagonsEquipment === trainWagonsEquipment,
          );
          tableRows.push({
            totalRow: 0,
            index: ++index,
            customer,
            trainWagonsEquipment,
            station: trainStation?.trim(),
            days: {} as { [day: string]: TShippingPlanDay },
          });

          if (isDuplicateExists) {
            tableRows.push({
              totalRow: 0,
              index: ++index,
              customer,
              trainWagonsEquipment,
              station: trainStation?.trim(),
              days: {} as { [day: string]: TShippingPlanDay },
              isDuplicatesRow: true,
            });
          }
        });
      });
    });

    return tableRows;
  }

  private setDaysToTableRows(
    dataInstance: IDataInstance,
    shippingPlans: IShippingPlan[],
    tableRows: (TShippingPlanListRow | { trainStation: string })[],
    duplicates: IShippingPlan[],
    days: number,
  ): void {
    shippingPlans.forEach((shippingPlan: IShippingPlan) => {
      const isDuplicate = duplicates.some(
        (duplicate: IShippingPlan) => duplicate.id === shippingPlan.id,
      );
      const tableRow = tableRows.find((row: TShippingPlanListRow | { trainStation: string }) => {
        return (
          (row as TShippingPlanListRow).customer === shippingPlan.customer?.title &&
          (row as TShippingPlanListRow).trainWagonsEquipment ===
            shippingPlan.trainWagonsEquipment &&
          (row as TShippingPlanListRow).station?.trim() === shippingPlan.station?.title?.trim() &&
          (isDuplicate ? (row as TShippingPlanListRow).isDuplicatesRow : true)
        );
      });

      if (!(tableRow as TShippingPlanListRow)!.customer) {
        return;
      }

      const dayNumber =
        moment(shippingPlan.expectedShipmentDate).diff(dataInstance.dateStarted, 'days') + 1;
      (tableRow as TShippingPlanListRow)!.days[dayNumber] = {
        shippingPlanId: shippingPlan.id,
        trainVolume: shippingPlan.trainVolume,
        customer: shippingPlan.customer,
        trainWagonsEquipment: shippingPlan.trainWagonsEquipment,
        station: shippingPlan.station,
        color: shippingPlan.color,
        expectedShipmentDate: shippingPlan.expectedShipmentDate,
        position: shippingPlan.position,
        isDuplicate,
      };

      if (dayNumber > days) return;

      (tableRow as TShippingPlanListRow).totalRow += +shippingPlan.trainVolume || 0;
    });

    tableRows.forEach((tableRow) => {
      if (!(tableRow as TShippingPlanListRow)!.customer) {
        return;
      }

      (tableRow as TShippingPlanListRow).totalRow =
        Math.round((tableRow as TShippingPlanListRow).totalRow * 100) / 100;
    });
  }

  createTotalValue(
    data: TShippingPlanListRow[],
    totalValueMap: Map<string, string>,
    days: number,
  ): void {
    let total = 0;
    for (let day = 1; day <= days; day++) {
      const amount = data.reduce((acc, row) => {
        if (!(row as TShippingPlanListRow)!.customer) {
          return acc;
        }

        const currentRow = row as TShippingPlanListRow;

        return currentRow.days[day] ? (acc += +currentRow.days[day].trainVolume) : acc;
      }, 0);

      total += amount;

      const roundedAmount = Math.round(amount * 100) / 100;

      totalValueMap.set(day.toString(), roundedAmount ? roundedAmount.toString() : '');
    }
    const roundedTotal = Math.round(total * 100) / 100;
    totalValueMap.set('total', roundedTotal ? roundedTotal.toString() : '');
  }
}
