<div class="warehouse-balance-list-root">
  <nguk-section>
    <div class="sticky-container" [class.-print]="id">
      <div class="container-buttons" *ngIf="!isShow">
        <nguk-button
          type="primary"
          *ngIf="mapRows.size > 1"
          class="edit-button"
          (click)="openModal()"
        >
          <mat-icon [svgIcon]="svgIconsEnum.Edit"></mat-icon>
        </nguk-button>
        <nguk-button type="primary" class="create-button" (click)="openModal()">
          <mat-icon [svgIcon]="svgIconsEnum.Plus"></mat-icon>
        </nguk-button>
      </div>

      <div class="toggle-view">
        <nguk-toggle [toggleList]="toggleList" [control]="toggleControl"></nguk-toggle>
      </div>

      <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="table-data" #table>
        <ng-container *ngFor="let item of columns; let i = index" [matColumnDef]="item.name">
          <th mat-header-cell *matHeaderCellDef>
            <ng-container *ngIf="item.name === 'button-toggle'; else othersColumnsHeader">
              <nguk-button
                *ngIf="!isFlatTable"
                type="secondary"
                class="button-toggle"
                [class.-opened]="data && expandedItemsMap.size === data.length"
                (click)="handleAllRows()"
              >
                <mat-icon [svgIcon]="svgIconsEnum.ArrowUpDown"></mat-icon>
              </nguk-button>
            </ng-container>
            <ng-template #othersColumnsHeader>
              <nguk-th-sort-filter
                [columnName]="item.name"
                [columnDisplay]="item.display"
                [rawData]="rawData"
              ></nguk-th-sort-filter>
            </ng-template>
          </th>

          <td
            mat-cell
            *matCellDef="let row; let ind = dataIndex"
            [class.border-none]="ind | warehouseBalanceBorderHidden: data"
            [class.mat-row-last]="ind === data.length - 1"
          >
            <ng-container *ngIf="item.name === 'button-toggle'; else othersColumns">
              <nguk-button
                *ngIf="!isFlatTable"
                type="secondary"
                class="button-toggle"
                [class.-opened]="!!expandedItemsMap.get(row.id)"
              >
                <mat-icon [svgIcon]="svgIconsEnum.ArrowUpDown"></mat-icon>
              </nguk-button>
            </ng-container>

            <ng-template #othersColumns>
              <nguk-text weight="400" styleName="text-xs">
                {{
                  item.name === 'index'
                    ? ind + 1
                    : item.name === 'dateAvailability'
                      ? (row[item.name] | date: 'dd.MM.yyyy')
                      : row[item.name]?.title || row[item.name]
                }}
              </nguk-text>
            </ng-template>
          </td>
        </ng-container>

        <ng-container matColumnDef="horizontal-area">
          <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
            <div class="horizontal-area">
              <nguk-text weight="700" styleName="text-xs">{{ element.warehouse.title }}</nguk-text>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <td
            mat-cell
            *matCellDef="let element"
            [attr.colspan]="columns.length"
            class="internal-mat-cell"
          >
            <div
              class="internal-table-animation"
              [class.-active]="!!expandedItemsMap.get(element.id)"
            >
              <table mat-table [dataSource]="element.values">
                <ng-container
                  *ngFor="let item of columns; let i = index"
                  [matColumnDef]="item.name"
                >
                  <td mat-cell *matCellDef="let row" (dblclick)="openModal(row, element.id)">
                    <nguk-text weight="400" styleName="text-xs">
                      {{
                        item.name === 'dateAvailability'
                          ? (row[item.name] | date: 'dd.MM.yyyy')
                          : row[item.name]?.title || row[item.name]
                      }}
                    </nguk-text>
                  </td>
                </ng-container>

                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumns; let i = index"
                  (click)="toggleCurrentRow(row)"
                  [class.internal-table-opened]="expandedElement"
                  [ngClass]="{
                    'internal-table-opened': row !== mapRows.get(row.id),
                    'selected-row': row === mapRows.get(row.id)
                  }"
                ></tr>
              </table>
            </div>
          </td>
        </ng-container>
        <ng-container>
          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
            class="first-header"
          ></tr>
        </ng-container>

        <tr
          mat-row
          *matRowDef="let row; columns: ['horizontal-area']; let i = index; let ind = dataIndex"
          [class.horizontal-area-hidden]="ind | warehouseBalanceCheckHorizontalLine: data"
        ></tr>

        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns; let i = index"
          [class.internal-table-opened]="!!expandedItemsMap.get(row.id)"
          (click)="toggleTable(row)"
          (dblclick)="isFlatTable ? openModal(row) : null"
          [class.selected-row]="isFlatTable && row === mapRows.get(row.id)"
        ></tr>

        <tr
          mat-row
          *matRowDef="let row; columns: ['expandedDetail']"
          class="internal-table"
          [class.hidden-row]="isFlatTable"
        ></tr>
      </table>
    </div>
  </nguk-section>
</div>

<ng-template #modal></ng-template>
