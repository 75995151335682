import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TextComponent } from 'src/app/modules/ui/components/text/text.component';
import { IMiningPlan } from 'src/app/data-models/mining-plan/mining-plan.interface';
import { IDataInstance } from 'src/app/data-models/enrichment-standard/data-instance.interface';
import { DatepickerComponent } from 'src/app/modules/forms/components/datepicker/datepicker.component';
import { InputComponent } from 'src/app/modules/forms/components/input/input.component';
import * as moment from 'moment';
import { AutocompleteComponent } from 'src/app/modules/forms/components/autocomplete/autocomplete.component';
import { DictionaryPipe } from '../../../../../../core/pipes/dictionary.pipe';
import { DictionariesEnum } from 'src/app/core/enums/dictionaries.enum';
import { ModalMiningPlanFormTableComponent } from '../modal-mining-plan-form-table/modal-mining-plan-form-table.component';
import { IMiningPlanVolume } from 'src/app/data-models/mining-plan-volume/mining-plan-volume.interface';

@Component({
  selector: 'modal-mining-plan-form',
  templateUrl: './modal-mining-plan-form.component.html',
  styleUrls: ['./modal-mining-plan-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TextComponent,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    InputComponent,
    DatepickerComponent,
    AutocompleteComponent,
    DictionaryPipe,
    ModalMiningPlanFormTableComponent,
  ],
})
export class ModalMiningPlanFormComponent implements OnInit {
  formData!: FormGroup;

  @Output() form = new EventEmitter<FormGroup>();
  @Input() data!: IMiningPlan[];
  @Input() dataInstance!: IDataInstance;
  @Input() isShow!: boolean;

  @Output() setArrayListMiningPlanVolume = new EventEmitter<IMiningPlanVolume[][]>();
  @Output() setRemoveListIds = new EventEmitter<number[]>();

  dataFormat!: IMiningPlan[];

  readonly dictionariesEnum = DictionariesEnum;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.initFormGroup();
  }

  initFormGroup(): void {
    const data = this.data;
    const size = this.data.length;

    this.formData = this.fb.group({
      dataInstance: this.fb.group({
        id: [
          size && data.every((el) => el.dataInstance.id === data[0].dataInstance.id)
            ? data[0].dataInstance.id
            : this.dataInstance.id,
        ],
      }),
      id: [size && data.every((el) => el.id === data[0].id) ? data[0].id : null],
      mark: [
        size && data.every((el) => el.mark.id === data[0].mark.id) ? data[0].mark : null,
        [Validators.required],
      ],
      area: [
        size && data.every((el) => el.area.id === data[0].area.id) ? data[0].area : null,
        [Validators.required],
      ],
      layer: [
        size && data.every((el) => el.layer.id === data[0].layer.id) ? data[0].layer : null,
        [Validators.required],
      ],
      profile: [
        size && data.every((el) => el.profile && el.profile.id === data[0].profile.id)
          ? data[0].profile
          : null,

        [Validators.required],
      ],
      volume: [
        size && data.every((el) => el.volume === data[0].volume) ? data[0].volume : null,
        [Validators.required],
      ],
      ad: [
        size && data.every((el) => el.ad === data[0].ad) ? data[0].ad : null,
        [Validators.required],
      ],
      vd: [
        size && data.every((el) => el.vd === data[0].vd) ? data[0].vd : null,
        [Validators.required],
      ],
      y: [size && data.every((el) => el.y === data[0].y) ? data[0].y : null],
      csr: [size && data.every((el) => el.csr === data[0].csr) ? data[0].csr : null],
      hgi: [size && data.every((el) => el.hgi === data[0].hgi) ? data[0].hgi : null],
      date: [
        size && data.every((el) => el.dateMining === data[0].dateMining)
          ? this.convertDateToUTC(new Date(data[0].dateMining))
          : null,
        [Validators.required],
      ],
      dateMining: [
        size && data.every((el) => el.dateMining === data[0].dateMining)
          ? data[0].dateMining
          : null,
      ],
      table: this.fb.array([]),
    });

    this.formData.get('date')?.valueChanges.subscribe((date: string) => {
      if (!date) {
        this.getControl('dateMining').setValue(null);
      } else {
        const dateWithZeroOffset = moment(date).startOf('day').utcOffset(0, true);
        this.getControl('dateMining').setValue(dateWithZeroOffset.format());
      }
    });

    if (!this.isShow) {
      this.formData
        .get('table')
        ?.valueChanges.subscribe(
          (list: { dateStarted: string; dateFinished: string; volume: string }[]) => {
            const volume = this.formData.get('volume') as FormControl;

            list.length ? volume.disable() : volume.enable();
            volume.updateValueAndValidity();

            if (this.data.length > 1) return;

            volume.setValue(
              list.length ? list.reduce((acc, item) => acc + +item.volume, 0).toString() : null,
            );
          },
        );
    }

    if (this.isShow) {
      this.formData.disable();
      this.formData.updateValueAndValidity();
    }

    this.form.emit(this.formData);
  }

  getControl(name: string): FormControl {
    return this.formData.controls[name] as FormControl;
  }

  convertDateToUTC(date: Date): Date {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0));
  }
}
