import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from 'src/app/modules/ui/components/modal/modal.component';
import { TextComponent } from 'src/app/modules/ui/components/text/text.component';
import { FormControl, FormGroup } from '@angular/forms';
import { ModalBase } from 'src/app/modules/ui/base/modal.base';
import { ModalDataInstancesListShortActionsComponent } from '../modal-data-instances-list-short-actions/modal-data-instances-list-short-actions.component';
import { ModalDataInstancesListShortFormComponent } from '../modal-data-instances-list-short-form/modal-data-instances-list-short-form.component';
import { IDataInstance } from 'src/app/data-models/enrichment-standard/data-instance.interface';
import { ToggleComponent } from 'src/app/modules/forms/components/toggle/toggle.component';

@Component({
  selector: 'modal-data-instances-list-short',
  templateUrl: './modal-data-instances-list-short.component.html',
  styleUrls: ['./modal-data-instances-list-short.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ModalComponent,
    TextComponent,
    ModalDataInstancesListShortActionsComponent,
    ModalDataInstancesListShortFormComponent,
    ToggleComponent,
  ],
})
export class ModalDataInstancesListShortComponent extends ModalBase implements AfterViewInit {
  data!: IDataInstance;
  date!: Date;

  isCalculationComplete = false;

  toggleList = ['Редактирование', 'Копирование'];
  toggleControl = new FormControl<string | null>(this.toggleList[0]);

  typeDataInstances!: string;

  updateData!: () => void;

  formData!: FormGroup;

  constructor(cdRef: ChangeDetectorRef) {
    super(cdRef);
  }

  getForm(form: FormGroup) {
    this.formData = form;
  }

  ngAfterViewInit(): void {
    this.initModal();
    this.date = this.data ? this.data.dateCreated : new Date();

    if (this.isCalculationComplete) {
      this.toggleList = ['Копирование'];
      this.toggleControl.setValue(this.toggleList[0]);
      this.cdRef.detectChanges();
    }
  }
}
