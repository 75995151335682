<nguk-modal [visible]="isVisible" [close]="closeModal.bind(this, false)">
  <div class="modal">
    <div class="modal-header">
      <nguk-text weight="600" styleName="text-xl">{{ modalTitle }}</nguk-text>

      <div class="info-blocks">
        <div *ngIf="cell.deviationOfDate" class="info">
          <nguk-text
            weight="400"
            color="grey"
            styleName="text-xs"
          >
            Отклонение по дате отгрузки:
          </nguk-text>
          <nguk-text weight="400" styleName="text-xs" class="info-value">{{ cell.deviationOfDate ? 'Да' : 'Нет' }}</nguk-text>
        </div>

        <div *ngIf="cell.deviationOfVolume" class="info">
          <nguk-text
            weight="400"
            color="grey"
            styleName="text-xs"
          >
            Отклонение по объему отгрузки:
          </nguk-text>
          <nguk-text weight="400" styleName="text-xs" class="info-value">{{ cell.deviationOfVolume ? 'Да' : 'Нет' }}</nguk-text>
        </div>

        <div *ngIf="cell.deviationOfMixture" class="info">
          <nguk-text
            weight="400"
            color="grey"
            styleName="text-xs"
          >
            Отклонение по составу смеси:
          </nguk-text>
          <nguk-text weight="400" styleName="text-xs" class="info-value">{{ cell.deviationOfMixture ? 'Да' : 'Нет' }}</nguk-text>
        </div>
      </div>
    </div>

    <div class="tables">
      <div class="commodity-shipment-modal-table-container">
        <div class="table-container-header">
          <nguk-text weight="700" styleName="text-xs">Прогнозируемое качество</nguk-text>
          <nguk-text
            weight="700"
            styleName="text-xs"
            color="blue"
            class="table-header-count"
          >
            {{ (shipmentQualitySpecifications || []).length }}
          </nguk-text>
        </div>
        <div class="container-table" tabindex="0">
          <table mat-table [dataSource]="shipmentQualitySpecifications">
            <ng-container *ngFor="let item of qualitySpecificationColumns; let i = index" [matColumnDef]="item.name">
              <th mat-header-cell *matHeaderCellDef>
                <nguk-text weight="400" styleName="text-xs" color="grey">{{ item.display }}</nguk-text>
              </th>

              <td mat-cell *matCellDef="let row">
                <nguk-text weight="400" styleName="text-xs">{{ row[item.name] }}</nguk-text>
              </td>
            </ng-container>

            <ng-container *ngFor="let item of ordinalColumns; let i = index" [matColumnDef]="item.name">
              <th mat-header-cell *matHeaderCellDef>
                <nguk-text weight="400" styleName="text-xs" color="grey">{{ item.display }}</nguk-text>
              </th>

              <td
                mat-cell
                [class.-yellow]="row.items?.[item.name]?.deviationColor === colorAccentEnum.Yellow"
                [class.-red]="row.items?.[item.name]?.deviationColor === colorAccentEnum.Red"
                *matCellDef="let row"
              >
                <nguk-text weight="400" styleName="text-xs">{{ row.items?.[item.name]?.formula }}</nguk-text>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedQualitySpecificationColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedQualitySpecificationColumns"></tr>
          </table>
        </div>
      </div>

      <div class="commodity-shipment-modal-table-container used-materials">
        <div class="table-container-header">
          <nguk-text weight="700" styleName="text-xs">Используемый материал</nguk-text>
          <nguk-text
            weight="700"
            styleName="text-xs"
            color="blue"
            class="table-header-count"
          >
            {{ (shipmentMaterials || []).length }}
          </nguk-text>
        </div>
        <div class="container-table" tabindex="0">
          <table mat-table [dataSource]="shipmentMaterials">
            <ng-container *ngFor="let item of materialColumns; let i = index" [matColumnDef]="item.name">
              <th mat-header-cell *matHeaderCellDef>
                <nguk-text weight="400" styleName="text-xs" color="grey">{{ item.display }}</nguk-text>
              </th>

              <td mat-cell *matCellDef="let row">
                <nguk-text weight="400" styleName="text-xs">{{ getShipmentMaterialCellValue(row, item.name) }}</nguk-text>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedMaterialColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedMaterialColumns"></tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</nguk-modal>
