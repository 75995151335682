import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActionModal } from 'src/app/modules/ui/base/action-modal';
import { MatTableModule } from '@angular/material/table';
import { SvgIconsEnum } from 'src/app/core/enums/svg-icons.enum';
import { IDataColumnTable } from 'src/app/data-models/data-column-table/data-column-table.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize, mergeMap, take, takeWhile } from 'rxjs';
import { TextComponent } from 'src/app/modules/ui/components/text/text.component';
import { SectionComponent } from 'src/app/modules/ui/components/section/section.component';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from 'src/app/modules/ui/components/button/button.component';
import { ProductionPlanApiService } from 'src/app/api/production-plan.api.service';
import { IProductionPlan } from 'src/app/data-models/production-plan/production-plan.interface';
import * as moment from 'moment';
import { IJournal } from 'src/app/data-models/enrichment-standard/journal.interface';
import { SpinnerService } from 'src/app/modules/ui/services/spinner.service';
import { FilterSortByColumnService } from 'src/app/modules/ui/services/filter-sort-by-column.service';
import { ThSortFilterComponent } from 'src/app/modules/ui/components/th-sort-filter/th-sort-filter.component';
import { OperationalPlanJournalApiService } from 'src/app/api/operational-plan-journal.api.service';
import { RedirectDataService } from 'src/app/modules/data/services/redirect-data.service';
import { OperationalCommodityShipmentApiService } from 'src/app/api/operational-commodity-shipment.api.service';
import {
  IOperationalCommodityShipment,
  IOperationalCommodityShipmentGeneral,
  IOperationalCommodityShipmentTransform,
} from 'src/app/data-models/operational-commodity-shipment/operational-commodity-shipment.interface';
import { DatePairComponent } from '../../../../../ui/components/date-pair/date-pair.component';
import { IOperationalCommodityShipmentSpecificationItemTransform } from 'src/app/data-models/operational-commodity-shipment-specification-item/operational-commodity-shipment-specification-item.interface';
import { omit } from 'lodash-es';

@Component({
  selector: 'calendar-plan-operational-commodity-shipment-list',
  templateUrl: './operational-commodity-shipment-list.component.html',
  styleUrls: ['./operational-commodity-shipment-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    TextComponent,
    SectionComponent,
    MatIconModule,
    ButtonComponent,
    ThSortFilterComponent,
    DatePairComponent,
  ],
})
export class OperationalCommodityShipmentListComponent
  extends ActionModal
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('table', { read: ElementRef })
  table!: ElementRef<HTMLTableElement>;
  @Output() getCurrentTable = new EventEmitter();

  @ViewChild('modal', { read: ViewContainerRef }) modal!: ViewContainerRef;

  @Input() id!: string | undefined;

  isShow = false;
  isAliveFiltering = false;
  isAlive = true;

  amountColumns = 0;

  data: IOperationalCommodityShipmentTransform[] = [];
  rawData: IOperationalCommodityShipmentTransform[] = [];

  productionPlan!: IProductionPlan;
  journal!: IJournal;

  readonly svgIconsEnum = SvgIconsEnum;
  readonly math = Math;

  columnsFirstHeader: IDataColumnTable[] = [
    { name: 'empty-first', display: '', colspan: '3' },
    { name: 'equipment', display: 'Комплектация', colspan: '2' },
    { name: 'volume', display: 'Объем', colspan: '2' },
    { name: 'date', display: 'Дата', colspan: '2' },
    { name: 'specification', display: 'Спецификация', colspan: '1' },
  ];

  columns: IDataColumnTable[] = [
    { name: 'index', display: '#' },
    { name: 'orderType', display: 'Тип заказа' },
    { name: 'orderId', display: 'Заказ' },
    { name: 'targetKit', display: 'Целевая' },
    { name: 'appointedKit', display: 'Назначенная' },
    { name: 'targetVolume', display: 'Целевой' },
    { name: 'appointedVolume', display: 'Назначенный' },
    { name: 'dateTarget', display: 'Целевая' },
    { name: 'dateAppointed', display: 'Назначенная' },
  ];
  columnsSpecification: IDataColumnTable[] = [];

  displayedColumns = this.columns.map((col) => col.name);
  displayedColumnsFirstHeader = this.columnsFirstHeader.map((col) => col.name);
  displayedColumnsSpecification: string[] = [];

  constructor(
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private productionPlanApiService: ProductionPlanApiService,
    private operationalPlanJournalApiService: OperationalPlanJournalApiService,
    private ss: SpinnerService,
    private filterSortByColumnService: FilterSortByColumnService,
    private redirectDataService: RedirectDataService,
    private operationalCommodityShipmentApiService: OperationalCommodityShipmentApiService,
  ) {
    super();
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  ngOnInit(): void {
    this.route.params.pipe(takeWhile(() => this.isAlive)).subscribe((params) => {
      const id = params['productionPlanId'] || this.id;

      const idJournal =
        this.route.parent?.snapshot.params['calendarPlanId'] ||
        this.route.snapshot.params['calendarPlanId'];

      if (!idJournal) return;

      id ? this.getProductionPlan(id, idJournal) : this.initRedirect(idJournal);
    });

    if (this.id) this.isShow = true;
  }

  ngAfterViewInit() {
    this.getCurrentTable.emit(this.table.nativeElement);
  }

  getHours(value: number): number {
    return value % 24 || 24;
  }

  initRedirect(idJournal: string): void {
    this.ss.startSpinner();
    this.productionPlanApiService
      .getList(1)
      .pipe(take(1))
      .subscribe((res: IProductionPlan[]) => {
        const find = res.find(
          (item) => +item.calculationLog?.id === +idJournal && item.type === 'productionPlanId',
        );
        if (find) this.router.navigate([find.id], { relativeTo: this.route });
      });
  }

  getProductionPlan(id: string, idJournal: string): void {
    this.redirectDataService.setLastJournal(this.route, id, idJournal);

    this.ss.startSpinner();
    this.productionPlanApiService
      .getOne(id)
      .pipe(
        take(1),
        mergeMap((productionPlan: IProductionPlan) => {
          this.productionPlan = productionPlan;
          return this.operationalPlanJournalApiService.getOne(idJournal);
        }),
      )
      .subscribe((journal) => {
        this.journal = journal;
        this.cdr.detectChanges();

        this.initData(id);
      });
  }

  getDate(index: number): string {
    const firstDate = moment(this.journal.datePlanStarted).utcOffset(0, true);
    return firstDate.add(index, 'days').format('DD.MM.YY');
  }

  initData(id: string): void {
    this.ss.startSpinner();
    this.getDataList(id);
  }

  getDataList(id: string): void {
    this.operationalCommodityShipmentApiService
      .getList(1, [
        { name: 'productionPlan.id', value: id },
        { name: 'itemsPerPage', value: 5000 * 10000 },
      ])
      .pipe(take(1), finalize(this.ss.stopSpinner))
      .subscribe((data) => {
        this.initTransformData(data);

        if (!this.isAliveFiltering) {
          this.subscribeSortingFiltering();
          this.isAliveFiltering = true;
        }
      });
  }

  initTransformData(data: IOperationalCommodityShipment[]): void {
    const dataTransformed: IOperationalCommodityShipmentTransform[] = data.map((element) => {
      element.appointedVolume = element.appointedVolume ? element.appointedVolume.toString() : '0';
      element.targetVolume = element.targetVolume ? element.targetVolume.toString() : '0';

      const specification = element.operationalCommodityShipmentSpecifications[0];

      const indicators = specification.operationalCommodityShipmentSpecificationItems.reduce(
        (acc, item) => {
          const formula = `${item.designation}[${item.interval}]=${item.fact}`;
          acc[item.ordinal.toString()] = { ...item, formula };

          return acc;
        },
        {} as { [key: string]: IOperationalCommodityShipmentSpecificationItemTransform },
      );

      const amountIndicators = specification.operationalCommodityShipmentSpecificationItems.length;

      if (this.amountColumns < amountIndicators) this.amountColumns = amountIndicators;

      const dataWithoutFields = omit(
        element,
        'operationalCommodityShipmentSpecifications',
      ) as IOperationalCommodityShipmentGeneral;

      return {
        ...dataWithoutFields,
        specification: {
          mixture: specification.mixture,
          indicators: indicators,
        },
      };
    });

    this.initSpecificationColumns();

    console.log(dataTransformed);

    this.data = dataTransformed;
    this.rawData = [...dataTransformed];

    this.cdr.detectChanges();
  }

  initSpecificationColumns(): void {
    this.columnsSpecification = Array.from(Array(this.amountColumns), (_, index) => ({
      name: `${index + 1}`,
      display: `${index + 1}`,
    }));

    this.displayedColumns = [...this.columns, ...this.columnsSpecification].map((col) => col.name);
  }

  subscribeSortingFiltering(): void {
    this.route.queryParams.pipe(takeWhile(() => this.isAlive)).subscribe((query) => {
      this.data = this.filterSortByColumnService.sort(query, '', this.rawData);
      this.data = this.filterSortByColumnService.filter(query, this.data);

      this.cdr.detectChanges();
    });
  }
}
