import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, FormControl } from '@angular/forms';
import { SvgIconsEnum } from 'src/app/core/enums/svg-icons.enum';
import {
  IResourceAvailability,
  IResourceAvailabilityTransform,
} from 'src/app/data-models/resource-availability/resource-availability.interface';
import { ModalActionsComponent } from 'src/app/modules/ui/components/modal-actions/modal-actions.component';
import { ResourceAvailabilityApiService } from 'src/app/api/resource-availability.api.service';
import { finalize, forkJoin, mergeMap, of, take, tap } from 'rxjs';
import { ResourceAvailabilityConditionApiService } from 'src/app/api/resource-availability-condition.api.service';
import { IEditResourceAvailabilityCondition } from '../../../../../../../data-models/resource-availability-condition/edit-resource-availability-condition.interface';
import { SpinnerService } from '../../../../../../ui/services/spinner.service';

@Component({
  selector: 'modal-general-actions',
  templateUrl: './modal-general-actions.component.html',
  styleUrls: ['./modal-general-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ModalActionsComponent],
})
export class ModalGeneralActionsComponent implements OnInit {
  @Input() formData!: FormGroup;
  @Input() data!: IResourceAvailabilityTransform;

  @Input() toggleControl!: FormControl<string | null>;

  @Output() readonly close = new EventEmitter<void>();
  @Output() readonly updateData = new EventEmitter<void>();

  fieldsToSkip = ['id', 'dataInstance'];

  isCopy = false;

  readonly svgIconsEnum = SvgIconsEnum;

  constructor(
    private ss: SpinnerService,
    private cdr: ChangeDetectorRef,
    private resourceAvailabilityApiService: ResourceAvailabilityApiService,
    private resourceAvailabilityConditionApiService: ResourceAvailabilityConditionApiService,
  ) {}

  checkIsChange(): boolean {
    if (this.isCopy) return false;

    return this.data && this.data.id ? true : false;
  }

  ngOnInit(): void {
    this.toggleControl.valueChanges.subscribe((value) => {
      if (!value) return;
      this.isCopy = value.toLowerCase() === 'редактирование' ? false : true;

      this.cdr.detectChanges();
    });
  }

  save(): void {
    this.isCopy || !this.data?.id ? this.create() : this.change();
  }

  create(): void {
    this.ss.startSpinner();
    const value = { ...this.formData.value };
    delete value.id;

    this.isCopy
      ? this.copyChildDate(value, this.formData.value.id)
      : this.resourceAvailabilityApiService
          .create(value)
          .pipe(take(1), finalize(this.ss.stopSpinner))
          .subscribe(() => this.close.emit());
  }

  copyChildDate(value: IResourceAvailability, id: string): void {
    this.ss.startSpinner();

    forkJoin([
      this.resourceAvailabilityApiService.create(value),
      this.resourceAvailabilityConditionApiService.getList(1, [
        { name: 'itemsPerPage', value: 20000 },
        { name: 'resourceAvailability.id', value: id.toString() },
      ]),
    ])
      .pipe(
        mergeMap(([created, dataChild]) => {
          const requestArray = dataChild.map((item: IEditResourceAvailabilityCondition) => {
            const value = { ...item };
            delete value.id;
            value.resourceAvailability.id = created.id;
            return this.resourceAvailabilityConditionApiService.create(value);
          });
          return requestArray.length ? forkJoin(requestArray) : of([]);
        }),
        take(1),
        finalize(this.ss.stopSpinner),
      )
      .subscribe(() => {
        this.updateData.emit();
      });
  }

  change(): void {
    this.ss.startSpinner();
    this.resourceAvailabilityApiService
      .update(this.formData.value)
      .pipe(take(1), finalize(this.ss.stopSpinner))
      .subscribe(() => this.close.emit());
  }

  remove() {
    this.ss.startSpinner();
    this.resourceAvailabilityApiService
      .delete(this.formData.value.id)
      .pipe(take(1), finalize(this.ss.stopSpinner))
      .subscribe(() => this.close.emit());
  }
}
