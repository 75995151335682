import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { IDataColumnTable } from 'src/app/data-models/data-column-table/data-column-table.interface';
import { IEditResourceAvailabilityConditionTransform } from 'src/app/data-models/resource-availability-condition/edit-resource-availability-condition.interface';
import { IResourceAvailabilityCondition } from 'src/app/data-models/resource-availability-condition/resource-availability-condition.interface';
import {
  IResourceAvailability,
  IResourceAvailabilityTransform,
} from 'src/app/data-models/resource-availability/resource-availability.interface';

@Injectable({
  providedIn: 'root',
})
export class DataFundService {
  initTransformData(
    data: IResourceAvailability[],
    dataCondition: IResourceAvailabilityCondition[],
    columnsDays: IDataColumnTable[],
    dateStarted: Date,
    getValueDay: Map<string, string>,
    hasValueDay: Map<string, boolean>,
  ): IResourceAvailabilityTransform[] {
    const dataFormat: IResourceAvailabilityTransform[] = data.map((item) => {
      const filtersData = dataCondition.filter(
        (value) => +value.resourceAvailability.id === +item.id,
      );
      return { ...item, totalRow: 0, values: filtersData };
    });

    columnsDays.forEach((item) => {
      dataFormat.forEach((row) => {
        this.checkValueDayinArray(row, item, dateStarted, hasValueDay);
        this.setValueDay(row, item, dateStarted, getValueDay);
      });
    });
    return dataFormat;
  }

  checkValueDayinArray(
    row: IResourceAvailabilityTransform,
    item: IDataColumnTable,
    dateStarted: Date,
    hasValueDay: Map<string, boolean>,
  ): boolean {
    const values = row.values.find((value) => {
      if (!value.id) return;

      const start = moment(value.dateStarted).utcOffset(0).startOf('day');
      const current = moment(dateStarted)
        .add(+item.display - 1, 'days')
        .utcOffset(0)
        .startOf('day');

      return current.diff(start, 'days') === 0;
    });

    const id = row.id + '-' + item.display;
    hasValueDay.set(id, !!values);
    return !!values;
  }

  setValueDay(
    row: IResourceAvailabilityTransform,
    item: IDataColumnTable,
    dateStarted: Date,
    getValueDay: Map<string, string>,
  ): void {
    const currentValue = this.getCurrentValue(row, item, dateStarted);
    const id = row.id + '-' + item.display;

    if (currentValue.length === 1 && !currentValue[0].id) {
      getValueDay.set(id, '');
      return;
    }

    const newValue = currentValue.map((value, index) => {
      const diffHours = moment(value.dateFinished).diff(value.dateStarted, 'hours');

      if (diffHours > 0 && diffHours <= 24) {
        return { ...value, value: Math.floor(+value.value).toString() };
      }

      const diffHoursTotal = moment(value.dateFinished).diff(value.dateStarted, 'hours');
      const currentDate = moment(moment(dateStarted)).add(+item.display - 1, 'days');
      const diffCurrentHoursStart = moment(currentDate).diff(value.dateStarted, 'hours');
      const diffCurrentHours = moment(value.dateFinished).diff(currentDate, 'hours');

      const valueDay =
        diffCurrentHours > 24
          ? diffCurrentHoursStart < 0
            ? 24 + diffCurrentHoursStart
            : 24
          : diffCurrentHours;

      return { ...value, value: Math.floor((valueDay / diffHoursTotal) * +value.value).toString() };
    });

    const currentElement = newValue.reduce((prev, current) => {
      return +prev.value < +current.value ? prev : current;
    });

    getValueDay.set(id, currentElement.value);
  }

  getCurrentValue(
    row: IResourceAvailabilityTransform,
    item: IDataColumnTable,
    dateStarted: Date,
  ): IEditResourceAvailabilityConditionTransform[] {
    const filterArray = row.values.filter((value) => {
      const start = moment(value.dateStarted).utcOffset(0).startOf('day');
      const finish = moment(value.dateFinished).utcOffset(0).startOf('day');

      const current = moment(dateStarted)
        .add(+item.display - 1, 'days')
        .utcOffset(0)
        .startOf('day');

      const finichUTCHour = moment(value.dateFinished).utc().hours();

      return current.isBetween(start, finish, undefined, finichUTCHour ? '[]' : '[)');
    });

    return filterArray.length
      ? this.transformFilterArray(filterArray, +item.display)
      : this.createEmtyCell(row, +item.display, dateStarted);
  }

  transformFilterArray(filterArray: IEditResourceAvailabilityConditionTransform[], day: number) {
    return filterArray.map((item) => {
      return { ...item, day: day };
    });
  }

  createEmtyCell(
    row: IResourceAvailabilityTransform,
    day: number,
    dateStarted: Date,
  ): IEditResourceAvailabilityConditionTransform[] {
    return [
      {
        resourceAvailability: {
          id: row.id,
        },
        dateStarted: moment(dateStarted)
          .add(+day - 1, 'days')
          .startOf('day')
          .utcOffset(0, true)
          .format(),
        dateFinished: moment(dateStarted)
          .add(+day, 'days')
          .startOf('day')
          .utcOffset(0, true)
          .format(),
        duration: 24,
        value: '',
        unit: '%',
        day: day,
      },
    ];
  }

  createTotalValue(
    data: IResourceAvailabilityTransform[],
    totalValueMap: Map<string, string>,
    getValueDay: Map<string, string>,
    days: number,
  ): IResourceAvailabilityTransform[] {
    let totalAmount = 0;
    const transformedData = data
      .map((row) => {
        row.totalRow = row.values.reduce((acc, item) => acc + (item.value ? +item.value : 0), 0);
        totalAmount += row.totalRow;
        return row;
      })
      .map((item) => {
        item.totalRow = Math.round(item.totalRow * 100) / 100;
        return item;
      });

    totalAmount = Math.round(totalAmount * 100) / 100;
    totalValueMap.set('total', totalAmount ? totalAmount.toString() : '');

    for (let day = 1; day <= days; day++) {
      const amount = data.reduce((acc, row) => {
        const id = row.id + '-' + day;
        const value = getValueDay.get(id) || '';
        return acc + (value ? +value : 0);
      }, 0);

      totalAmount = Math.round(amount * 100) / 100;
      totalValueMap.set(day.toString(), totalAmount ? totalAmount.toString() : '');
    }

    return transformedData;
  }
}
