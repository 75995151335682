import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { EnrichmentStandardApiService } from '../../../api/enrichment-standard.api.service';
import { MiningPlanApiService } from '../../../api/mining-plan.api.service';
import { ResourceAvailabilityApiService } from '../../../api/resource-availability.api.service';
import { ShippingPlanApiService } from '../../../api/shipping-plan.api.service';
import { WarehouseBalanceApiService } from '../../../api/warehouse-balance.api.service';
import { IFileUploadResponse } from '../../../data-models/file-upload-response.interface';

@Injectable({
  providedIn: 'root',
})
export class DataInstancesProviderService {
  private currentDataInstanceSource = new BehaviorSubject<string>('');
  currentDataInstance$ = this.currentDataInstanceSource.asObservable();

  private currentPageSource = new BehaviorSubject({
    title: '',
    typeDataInstances: '',
    path: '',
  });

  currentPage$ = this.currentPageSource.asObservable();

  constructor(
    private enrichmentStandardApiService: EnrichmentStandardApiService,
    private miningPlanApiService: MiningPlanApiService,
    private resourceAvailabilityApiService: ResourceAvailabilityApiService,
    private shippingPlanApiService: ShippingPlanApiService,
    private warehouseBalanceApiService: WarehouseBalanceApiService,
  ) {}

  setNewDataInstance(value: string): void {
    this.currentDataInstanceSource.next(value);
  }

  getPathCurrentPage(): string {
    return this.currentPageSource.getValue().path;
  }

  setNewCurrentPage(title: string, typeDataInstances: string, path: string): void {
    this.currentPageSource.next({ title, typeDataInstances, path });
  }

  uploadDataFile = (
    dataInstanceId: string,
    dataType: any,
    files: File[],
  ): Observable<IFileUploadResponse> => {
    let request!: (formData: any, dataInstanceId: string) => Observable<any>;
    const formData = new FormData();
    formData.append('file', files[0]);

    switch (dataType) {
      case 'shippingPlan': {
        request = this.shippingPlanApiService.uploadFile;
        break;
      }
      case 'warehouseBalance': {
        request = this.warehouseBalanceApiService.uploadFile;
        break;
      }
      case 'miningPlan': {
        request = this.miningPlanApiService.uploadFile;
        break;
      }
      case 'enrichmentStandard': {
        request = this.enrichmentStandardApiService.uploadFile;
        break;
      }
      case 'resourceAvailability': {
        request = this.resourceAvailabilityApiService.uploadFile;
        break;
      }
      default: {
        console.error(`There is no handler for data type: ${dataType}`);
      }
    }

    return request(formData, dataInstanceId);
  };
}
