<div class="modal-journal-list-short-print-list-root">
  <ng-container *ngFor="let item of isOperationalPlan ? arrayCheckboxOP : arrayCheckbox">
    <div class="list-item" [class.-dataInstance]="item.array === 'dataInstance'">
      <nguk-text weight="600" styleName="text-sm">{{ item.title }}</nguk-text>
      <div class="list-checkbox" [class.-wrap-list]="isOperationalPlan">
        <div class="item-checkbox" *ngFor="let menu of item.checkbox; let index = index">
          <nguk-checkbox-ctrl [control]="getControl(item.array, 'isActive', index)">
            <ng-container label>
              <nguk-text color="grey" weight="400" styleName="text-xs">{{ menu.title }}</nguk-text>
            </ng-container>
          </nguk-checkbox-ctrl>
        </div>
      </div>
    </div>
  </ng-container>
</div>
