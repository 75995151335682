import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, OnInit } from '@angular/core';
import { NotificationsService } from '../../../../core/services/notifications.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NotificationModel } from '../../../../core/models/notification.model';
import { delay, filter, of } from 'rxjs';
import { NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TextComponent } from '../text/text.component';
import { SvgIconsEnum } from '../../../../core/enums/svg-icons.enum';
import { NotificationTypeEnum } from '../../../../core/enums/notification-type.enum';

@Component({
  selector: 'nguk-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    MatIconModule,
    TextComponent
  ]
})
export class NotificationComponent implements OnInit {
  notification!: NotificationModel | null;
  isVisible = false;

  readonly svgIconsEnum = SvgIconsEnum;

  get notificationTypeIcon(): SvgIconsEnum {
    if (this.notification?.type === NotificationTypeEnum.Info) {
      return SvgIconsEnum.CancelCalculationDone;
    }

    return SvgIconsEnum.ErrorCalculationDone;
  }

  constructor(
    private destroyRef: DestroyRef,
    private cdr: ChangeDetectorRef,
    private notificationsService: NotificationsService,
  ) {}

  ngOnInit(): void {
    this.notificationsService.notifications$
      .pipe(filter(Boolean), takeUntilDestroyed(this.destroyRef))
      .subscribe(this.handleNewNotification);
  }

  closeNotification(): void {
    this.isVisible = false;
    this.cdr.markForCheck();
  }

  private handleNewNotification = (notification: NotificationModel | null): void => {
    this.notification = notification;
    this.isVisible = true;
    this.cdr.markForCheck();
    of(null).pipe(
      delay(10 * 1000),
    ).subscribe(() => {
      this.isVisible = false;
      this.cdr.detectChanges();
    });
  }
}
