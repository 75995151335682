export enum SvgIconsEnum {
  LogoNguk = 'logo-nguk',
  LogoKonsom = 'logo-konsom',
  LogoInfo = 'logo-info',
  ArrowUpDown = 'arrow-up-down',
  Filter = 'filter',
  User = 'user',
  Close = 'close',
  Plus = 'plus',
  Save = 'save',
  Duplicate = 'duplicate',
  Delete = 'delete',
  Clock = 'clock',
  CalendarDays = 'calendar-days',
  Edit = 'edit',
  Calculate = 'calculate',
  Tick = 'tick',
  DoubleTick = 'double-tick',
  CheckBold = 'check-bold',
  LockClose = 'lock-close',
  LockOpen = 'lock-open',
  Trash = 'trash',
  CancelCalculationDone = 'cancel-calculation-done',
  ErrorCalculationDone = 'error-calculation-service',
  TimePairClock = 'time-pair-clock',
  Refresh = 'refresh',
  MoreHorizontal = 'more-horizontal',
  Binary = 'binary',
  Eye = 'eye',
  EyeHide = 'eye-hide',
  TableProperties = 'table-properties',
  Print = 'print',
  NewMining = 'new-mining',
  UnfoldVertical = 'unfold-vertical',
  ChevronDown = 'chevron-down',
  Import = 'import',
  Excel = 'excel',
  RelatedSystems = 'related-systems',
  List = 'list',
  Grid2x2 = 'grid-2x2',
  Sorting = 'sorting',
  SortingDesc = 'sorting-desc',
  SortingAsc = 'sorting-asc',
  Filtering = 'filtering',
  FilteringFull = 'filtering-full',
  Search = 'search',
  Remove = 'remove',
  Download = 'download',
  FileUploadedSuccessful = 'file-uploaded-successful',
  FileUploadedWrongly = 'file-uploaded-wrongly',
  ExitIcon = 'exit-icon',
  CloudDownload = 'cloud-download',
  CloudUpload = 'cloud-upload',
  Attention = 'attention',
}
