import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ViewChild,
} from '@angular/core';
import { ModalBase } from '../../../../ui/base/modal.base';
import { FormControl, FormsModule } from '@angular/forms';
import { NewNsiForm } from '../../../forms/new-nsi.form';
import { NsiService } from '../../../services/nsi.service';
import { EditNsiTablesListComponent } from '../../parts/edit-nsi-tables-list/edit-nsi-tables-list.component';
import { CdkDropList, CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ModalDataInstancesListShortActionsComponent } from 'src/app/modules/data/components/parts/modal-data-instances-list-short/modal-data-instances-list-short-actions/modal-data-instances-list-short-actions.component';
import { ModalDataInstancesListShortFormComponent } from 'src/app/modules/data/components/parts/modal-data-instances-list-short/modal-data-instances-list-short-form/modal-data-instances-list-short-form.component';
import { SelectComponent } from 'src/app/modules/forms/components/select/select.component';
import { TextareaComponent } from 'src/app/modules/forms/components/textarea/textarea.component';
import { ToggleComponent } from 'src/app/modules/forms/components/toggle/toggle.component';
import { EditableTextDirective } from 'src/app/modules/forms/directives/editable-text.directive';
import { ButtonComponent } from 'src/app/modules/ui/components/button/button.component';
import { ModalActionsComponent } from 'src/app/modules/ui/components/modal-actions/modal-actions.component';
import { ModalComponent } from 'src/app/modules/ui/components/modal/modal.component';
import { SectionComponent } from 'src/app/modules/ui/components/section/section.component';
import { SidebarListItemComponent } from 'src/app/modules/ui/components/sidebar-list/sidebar-list-item.component';
import { TextComponent } from 'src/app/modules/ui/components/text/text.component';
import { NsiRowActionsComponent } from '../../parts/nsi-row-actions/nsi-row-actions.component';
import { NsiDictionaryModel } from '../../../../../data-models/dictionary/dictionary.model';
import { forkJoin, mergeMap, Observable, take } from 'rxjs';
import { IDataInstance } from '../../../../../data-models/enrichment-standard/data-instance.interface';
import { Utils } from '../../../../../helpers/utils';

@Component({
  selector: 'nsi-edit-modal',
  templateUrl: './edit-nsi-modal.component.html',
  styleUrls: ['./edit-nsi-modal.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    SectionComponent,
    ButtonComponent,
    TextComponent,
    MatTableModule,
    MatIconModule,
    MatMenuModule,
    MatMenuModule,
    EditableTextDirective,
    NsiRowActionsComponent,
    SidebarListItemComponent,
    CdkOverlayOrigin,
    PortalModule,
    CdkDropList,
    CdkDrag,
    CdkDragHandle,
    ModalComponent,
    ModalDataInstancesListShortActionsComponent,
    ModalDataInstancesListShortFormComponent,
    ToggleComponent,
    TextareaComponent,
    SelectComponent,
    MatProgressSpinnerModule,
    ModalActionsComponent,
    MatTooltipModule,
    FormsModule,
    EditNsiTablesListComponent,
  ],
})
export class EditNsiModalComponent extends ModalBase implements AfterViewInit {
  @ViewChild(EditNsiTablesListComponent) editNSITables!: EditNsiTablesListComponent;

  toggleList = ['Редактирование', 'Копирование'];
  toggleControl = new FormControl<string | null>(this.toggleList[0]);
  isCalculationComplete!: boolean;
  editNsiForm!: NewNsiForm;
  dataInstance!: IDataInstance;
  dictionaries: NsiDictionaryModel[] = [];
  detailedDictionaries: NsiDictionaryModel[] = [];

  constructor(
    cdr: ChangeDetectorRef,
    private normativeInfoService: NsiService,
  ) {
    super(cdr);
  }

  ngAfterViewInit(): void {
    this.getDetailedDictionaries();
    this.editNsiForm = NewNsiForm.createForm(this.dataInstance);
    this.initModal();
    if (this.isCalculationComplete) {
      this.toggleList = ['Копирование'];
      this.toggleControl.setValue(this.toggleList[0]);
      this.editNsiForm.disable({});
    }
  }

  handleSave(): void {
    if (!this.dataInstance) {
      this.normativeInfoService.createDataInstance(this.editNsiForm.note.value)
        .pipe(
          mergeMap((dataInstance: IDataInstance) =>
            this.normativeInfoService.saveDictionariesChanges(dataInstance, this.detailedDictionaries, this.editNSITables.dictionariesData)
          ),
          take(1)
        )
        .subscribe(() => this.closeModal(true));
      return;
    }

    if (this.toggleControl.value === 'Редактирование') {
      this.normativeInfoService.updateDataInstance(this.dataInstance, this.editNsiForm)
        .pipe(take(1))
        .subscribe(() => {
          this.normativeInfoService.saveDictionariesChanges(this.dataInstance, this.detailedDictionaries, this.editNSITables.dictionariesData)
            .pipe(take(1))
            .subscribe(() => this.closeModal(true));
        });
    } else {
      this.normativeInfoService.saveDictionariesChanges(this.dataInstance, this.detailedDictionaries, this.editNSITables.dictionariesData)
        .pipe(take(1))
        .subscribe(() => {
          this.normativeInfoService.copyDataInstance(this.dataInstance.id!)
            .pipe(take(1))
            .subscribe(() => this.closeModal(true));
        });
    }
  }

  private getDetailedDictionaries = (): void => {
    const requests: Observable<NsiDictionaryModel>[] = [];
    this.dictionaries
      .forEach((dictionary: NsiDictionaryModel) => {
        const request = this.normativeInfoService.getDictionary(dictionary.id);
        requests.push(request);
      });

    forkJoin(requests)
      .pipe(take(1))
      .subscribe((dictionaries: NsiDictionaryModel[]) => {
        this.detailedDictionaries = Utils.sortBy(dictionaries, 'ordinal');
        this.cdRef.markForCheck();
      });
  }
}
