import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SvgIconsEnum } from '../../../../../core/enums/svg-icons.enum';
import { EditListModeEnum } from '../../../enums/edit-list-mode.enum';
import { ButtonComponent } from '../../../../ui/components/button/button.component';
import { NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TextComponent } from '../../../../ui/components/text/text.component';

@Component({
  selector: 'nsi-container-buttons',
  templateUrl: './nsi-container-buttons.component.html',
  styleUrls: ['./nsi-container-buttons.component.scss'],
  standalone: true,
  imports: [
    ButtonComponent,
    NgIf,
    MatIconModule,
    MatMenuModule,
    TextComponent
  ]
})
export class NsiContainerButtonsComponent {
  @Input() listMode!: EditListModeEnum;
  @Input() isBlockedToEdit!: boolean;
  @Output() readonly saveChanges = new EventEmitter<void>();
  @Output() readonly changeListMode = new EventEmitter<EditListModeEnum>();

  readonly svgIconsEnum = SvgIconsEnum;
  readonly editListModeEnum = EditListModeEnum;
}
