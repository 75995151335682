import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { IDataInstance } from 'src/app/data-models/enrichment-standard/data-instance.interface';
import { IResourceAvailabilityTransform } from 'src/app/data-models/resource-availability/resource-availability.interface';
import { TextComponent } from 'src/app/modules/ui/components/text/text.component';
import { InputComponent } from 'src/app/modules/forms/components/input/input.component';
import { SelectComponent } from 'src/app/modules/forms/components/select/select.component';
import { DatepickerComponent } from 'src/app/modules/forms/components/datepicker/datepicker.component';
import { SvgIconsEnum } from 'src/app/core/enums/svg-icons.enum';
import { MatIconModule } from '@angular/material/icon';
import { CheckboxComponent } from 'src/app/modules/forms/components/checkbox/checkbox.component';
import { SlideToggleComponent } from '../../../../../../forms/components/slide-toggle/slide-toggle.component';

@Component({
  selector: 'modal-general-form',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TextComponent,
    InputComponent,
    SelectComponent,
    DatepickerComponent,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    SelectComponent,
    SlideToggleComponent,
  ],
  templateUrl: './modal-general-form.component.html',
  styleUrls: ['./modal-general-form.component.scss'],
})
export class ModalGeneralFormComponent implements OnInit {
  formData!: FormGroup;

  @Output() form = new EventEmitter<FormGroup>();
  @Output() openModalSelectResource = new EventEmitter<IResourceAvailabilityTransform>();

  @Input() data!: IResourceAvailabilityTransform;
  @Input() dataInstance!: IDataInstance;
  @Input() isShow!: boolean;

  readonly svgIconsEnum = SvgIconsEnum;

  selectTask = [
    { value: 'КП', label: 'КП' },
    { value: 'ОП', label: 'ОП' },
  ];

  selectType = [
    { value: 'Поток', label: 'Поток' },
    { value: 'Емкость', label: 'Емкость' },
    { value: 'Выпуск', label: 'Выпуск' },
    { value: 'Режим', label: 'Режим' },
  ];

  constructor(
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.initFormGroup();
  }

  initFormGroup(): void {
    const currentData = this.data;

    this.formData = this.fb.group({
      dataInstance: this.fb.group({
        id: [this.dataInstance.id],
      }),
      id: [currentData && currentData.id ? currentData.id : ''],
      activated: [currentData && currentData.activated ? true : false],
      generalNorm: [currentData && currentData.generalNorm ? currentData.generalNorm : ''],
      resourceCode: [currentData && currentData.resourceCode ? currentData.resourceCode : null],
      ruleName: [currentData && currentData.ruleName ? currentData.ruleName : ''],
      ruleType: [
        currentData && currentData.ruleName ? currentData.ruleType : this.selectType[0].value,
      ],
      task: [currentData && currentData.task ? currentData.task : this.selectTask[0].value],
    });

    if (this.isShow) this.formData.disable();

    this.form.emit(this.formData);
  }

  getControl(name: string): FormControl {
    return this.formData.controls[name] as FormControl;
  }
}
