import { Injectable } from '@angular/core';
import { CommodityShipmentApiService } from '../../../api/commodity-shipment.api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { ICommodityShipment } from '../../../data-models/commodity-shipment/commodity-shipment.interface';
import { ProductionPlanApiService } from '../../../api/production-plan.api.service';
import { IProductionPlan } from '../../../data-models/production-plan/production-plan.interface';
import { CommodityShipmentMaterialApiService } from '../../../api/commodity-shipment-material.api.service';
import { CommodityShipmentQualitySpecificationApiService } from '../../../api/commodity-shipment-quality-specification.api.service';
import { CommodityShipmentQualitySpecificationItemApiService } from '../../../api/commodity-shipment-quality-specification-item.api.service';
import { ICommodityShipmentMaterial } from '../../../data-models/commodity-shipment-material/commodity-shipment-material.interface';
import { ICommodityShipmentQualitySpecification } from '../../../data-models/commodity-shipment-quality-specification/commodity-shipment-quality-specification.interface';
import { ICommodityShipmentQualitySpecificationItem } from '../../../data-models/commodity-shipment-quality-specification-item/commodity-shipment-quality-specification-item.interface';
import { uniq } from 'lodash-es';
import { TShippingPlanListRow } from '../../data/types/shipping-plan-list-row.type';
import { TShippingPlanDay } from '../../data/types/shipping-plan-day.type';
import { JournalApiService } from 'src/app/api/journal.api.service';
import { IJournal } from 'src/app/data-models/enrichment-standard/journal.interface';
import * as moment from 'moment';
import { OperationalPlanJournalApiService } from 'src/app/api/operational-plan-journal.api.service';

@Injectable()
export class CommodityShipmentService {
  private readonly trainWagonsEquipmentsSource = new BehaviorSubject<string[]>([]);
  readonly trainWagonsEquipments$ = this.trainWagonsEquipmentsSource.asObservable();

  set trainWagonsEquipments(trainWagonsEquipment: string[]) {
    this.trainWagonsEquipmentsSource.next(trainWagonsEquipment);
  }

  constructor(
    private commodityShipmentApiService: CommodityShipmentApiService,
    private commodityShipmentMaterialApiService: CommodityShipmentMaterialApiService,
    private commodityShipmentQualitySpecificationApiService: CommodityShipmentQualitySpecificationApiService,
    private commodityShipmentQualitySpecificationItemApiService: CommodityShipmentQualitySpecificationItemApiService,
    private productionPlanApiService: ProductionPlanApiService,
    private journalApiService: JournalApiService,
    private operationalPlanJournalApiService: OperationalPlanJournalApiService,
  ) {}

  getProductionPlan(productionPlanId: string): Observable<IProductionPlan> {
    return this.productionPlanApiService.getOne(productionPlanId);
  }

  getJournal(journalId: string, isOperationalPlan: boolean): Observable<IJournal> {
    return isOperationalPlan
      ? this.operationalPlanJournalApiService.getOne(journalId)
      : this.journalApiService.getOne(journalId);
  }

  getListItems(productionPlanId: string): Observable<ICommodityShipment[]> {
    const query = [
      { name: 'productionPlan.id', value: productionPlanId },
      { name: 'itemsPerPage', value: 50 * 100 },
    ];

    return this.commodityShipmentApiService.getList(1, query);
  }

  getCommodityShipmentMaterial(
    commodityShipmentId: string | number,
  ): Observable<ICommodityShipmentMaterial[]> {
    const query = [{ name: 'commodityShipment.id', value: commodityShipmentId }];

    return this.commodityShipmentMaterialApiService.getList(1, query);
  }

  getCommodityShipmentQualitySpecification(
    commodityShipmentId: string | number,
  ): Observable<ICommodityShipmentQualitySpecification[]> {
    const query = [{ name: 'commodityShipment.id', value: commodityShipmentId }];

    return this.commodityShipmentQualitySpecificationApiService.getList(1, query);
  }

  getListCommodityShipmentQualitySpecification(): Observable<
    ICommodityShipmentQualitySpecification[]
  > {
    const query = [{ name: 'itemsPerPage', value: 5000 * 1000 }];

    return this.commodityShipmentQualitySpecificationApiService.getList(1, query);
  }

  getCommodityShipmentQualitySpecificationItems(
    commodityShipmentQualitySpecificationId: string | number,
  ): Observable<ICommodityShipmentQualitySpecificationItem[]> {
    const query = [
      {
        name: 'commodityShipmentQualitySpecification.id',
        value: commodityShipmentQualitySpecificationId,
      },
    ];

    return this.commodityShipmentQualitySpecificationItemApiService.getList(1, query);
  }

  getListCommodityShipmentQualitySpecificationItems(): Observable<
    ICommodityShipmentQualitySpecificationItem[]
  > {
    const query = [{ name: 'itemsPerPage', value: 5000 * 1000 }];
    return this.commodityShipmentQualitySpecificationItemApiService.getList(1, query);
  }

  mapShippingPlansToListRepresentation(
    journal: IJournal,
    commodityShipments: ICommodityShipment[],
    isOperationalPlan: boolean,
    days: number,
  ): TShippingPlanListRow[] {
    const customerToTrainEquipmentMap = this.getCustomerToTrainEquipmentsMap(commodityShipments);
    const trainStationToCustomersMap = this.getTrainStationToCustomersMap(commodityShipments);
    const tableRows = this.getTableRows(trainStationToCustomersMap, customerToTrainEquipmentMap);

    this.trainWagonsEquipments = [
      ...uniq(
        commodityShipments.map(
          (commodityShipment: ICommodityShipment) => commodityShipment.trainWagonsEquipment,
        ),
      ),
    ];
    this.setDaysToTableRows(journal, commodityShipments, tableRows, isOperationalPlan, days);

    return tableRows;
  }

  private getCustomerToTrainEquipmentsMap(
    commodityShipments: ICommodityShipment[],
  ): Map<string, string[]> {
    const map = new Map<string, string[]>();
    commodityShipments.forEach((commodityShipment: ICommodityShipment) => {
      const existingTrainEquipments = map.get(commodityShipment.customer?.title);
      if (!existingTrainEquipments) {
        map.set(commodityShipment.customer?.title, [commodityShipment.trainWagonsEquipment]);
        return;
      }

      map.set(
        commodityShipment.customer?.title,
        uniq([...existingTrainEquipments, commodityShipment.trainWagonsEquipment]),
      );
    });

    return map;
  }

  private getTableRows(
    trainStationToCustomersMap: Map<string, string[]>,
    customerToTrainEquipmentMap: Map<string, string[]>,
  ): TShippingPlanListRow[] {
    const tableRows: TShippingPlanListRow[] = [];
    let index = 0;
    trainStationToCustomersMap.forEach((customers: string[], trainStation: string) => {
      customers.forEach((customer: string) => {
        const customerTrainEquipments = customerToTrainEquipmentMap.get(customer) as string[];

        customerTrainEquipments.forEach((trainWagonsEquipment: string) => {
          tableRows.push({
            totalRow: 0,
            index: ++index,
            customer,
            trainWagonsEquipment,
            station: trainStation?.trim(),
            days: {} as { [day: string]: TShippingPlanDay },
          });
        });
      });
    });

    return tableRows;
  }

  private setDaysToTableRows(
    journal: IJournal,
    commodityShipments: ICommodityShipment[],
    tableRows: (TShippingPlanListRow | { trainStation: string })[],
    isOperationalPlan: boolean,
    days: number,
  ): void {
    commodityShipments.forEach((commodityShipment: ICommodityShipment) => {
      const tableRow = tableRows.find((row: TShippingPlanListRow | { trainStation: string }) => {
        return (
          (row as TShippingPlanListRow).customer === commodityShipment.customer?.title &&
          (row as TShippingPlanListRow).trainWagonsEquipment ===
            commodityShipment.trainWagonsEquipment &&
          (row as TShippingPlanListRow).station?.trim() === commodityShipment.station?.title?.trim()
        );
      });

      if (!(tableRow as TShippingPlanListRow)!.customer) {
        return;
      }

      const dayNumber =
        moment(commodityShipment.dateTarget).diff(
          journal.datePlanStarted,
          isOperationalPlan ? 'hours' : 'days',
        ) + 1;
      (tableRow as TShippingPlanListRow)!.days[dayNumber] = {
        shippingPlanId: commodityShipment.id,
        trainVolume:
          (commodityShipment.id && commodityShipment.appointedVolume / 1000) ||
          (commodityShipment.id && 0),
        deviationColor: commodityShipment.deviationColor,
        deviationOfDate: commodityShipment.deviationOfDate,
        deviationOfVolume: commodityShipment.deviationOfVolume,
        deviationOfMixture: commodityShipment.deviationOfMixture,
        customer: commodityShipment.customer,
        trainWagonsEquipment: commodityShipment.trainWagonsEquipment,
        station: commodityShipment.station,
        targetGapRange: commodityShipment.targetGapRange ?? null,
        targetGapStart: commodityShipment.targetGapStart ?? null,
      } as any;

      if (dayNumber > days) return;

      (tableRow as TShippingPlanListRow).totalRow +=
        (commodityShipment.id && commodityShipment.appointedVolume) ||
        (commodityShipment.id && 0) ||
        0;
    });

    tableRows.forEach((tableRow) => {
      if (!(tableRow as TShippingPlanListRow)!.customer) {
        return;
      }

      (tableRow as TShippingPlanListRow).totalRow =
        Math.round(((tableRow as TShippingPlanListRow).totalRow / 1000) * 100) / 100;
    });
  }

  private getTrainStationToCustomersMap(
    commodityShipments: ICommodityShipment[],
  ): Map<string, string[]> {
    const map = new Map<string, string[]>();
    commodityShipments.forEach((commodityShipment: ICommodityShipment) => {
      const existingCustomers = map.get(commodityShipment.station?.title?.trim());
      if (!existingCustomers) {
        map.set(commodityShipment.station?.title?.trim(), [commodityShipment.customer?.title]);
        return;
      }

      map.set(
        commodityShipment.station?.title?.trim(),
        uniq([...existingCustomers, commodityShipment.customer?.title]),
      );
    });

    return map;
  }

  createTotalValue(
    data: TShippingPlanListRow[],
    totalValueMap: Map<string, string>,
    days: number,
  ): void {
    let total = 0;
    for (let day = 1; day <= days; day++) {
      const amount = data.reduce((acc, row) => {
        if (!(row as TShippingPlanListRow)!.customer) {
          return acc;
        }

        const currentRow = row as TShippingPlanListRow;

        return currentRow.days[day] ? (acc += +currentRow.days[day].trainVolume) : acc;
      }, 0);

      total += amount;

      const roundedAmount = Math.round(amount * 100) / 100;

      totalValueMap.set(day.toString(), roundedAmount ? roundedAmount.toString() : '');
    }
    const roundedTotal = Math.round(total * 100) / 100;
    totalValueMap.set('total', roundedTotal ? roundedTotal.toString() : '');
  }
}
