<div
  *ngIf="data"
  class="nsi-table-matrix-type-root"
  [class.-edit-data-mode]="listMode === editListModeEnum.EditData"
  [class.-edit-structure-mode]="listMode === editListModeEnum.EditStructure"
>
  <nguk-section>
    <div class="sticky-container" #tableContainer>
      <nsi-container-buttons
        [listMode]="listMode"
        [isBlockedToEdit]="isBlockedToEdit"
        (saveChanges)="saveChanges()"
        (changeListMode)="changeListMode($event)"
      />

      <table
        mat-table
        cdkDropList
        [dataSource]="data"
        (cdkDropListDropped)="onRowsReorder($event)"
      >
        <ng-container matColumnDef="index">
          <th mat-header-cell *matHeaderCellDef>
            <nguk-text weight="400" styleName="text-xs" class="opacity-text">
              #
            </nguk-text>
          </th>

          <td mat-cell *matCellDef="let row; let index = index">
            <nguk-text weight="400" styleName="text-xs" class="opacity-text">
              {{ index + 1 }}
            </nguk-text>
          </td>
        </ng-container>

        <ng-container
          *ngFor="let column of definedColumns; let columnIndex = index"
          [matColumnDef]="column.id | ngukToString"
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            ngukEditableText
            [column]="column"
            [columnIndex]="columnIndex"
            [textNode]="text"
            [textNodeContent]="column.title"
          >
            <nguk-text class="opacity-text" weight="400" styleName="text-xs" #text>
              {{ column.title }}
            </nguk-text>
          </th>

          <td
            mat-cell
            *matCellDef="let row; let index = index"
            ngukEditableText
            [data]="row.items?.[columnIndex]"
            [column]="column"
            [columnIndex]="columnIndex"
            [textNode]="text"
            [textNodeContent]="columnIndex === 0
              ? row.items && row.items![columnIndex]?.value || 'Строка ' + (index + 1)
              : row.items && row.items![columnIndex]?.value"
          >
            <nguk-text weight="400" styleName="text-xs" #text>
              {{ row.items && row.items![columnIndex]?.value }}
            </nguk-text>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr
          mat-row
          cdkDrag
          cdkDragLockAxis="y"
          *matRowDef="let row; let rowIndex = index; columns: displayedColumns"
          [class.-hovered]="row.id === actionsRowId"
          [cdkDragDisabled]="listMode !== editListModeEnum.EditStructure"
          (mouseenter)="handleMouseEnterRow(row, rowIndex)"
          (mouseleave)="handleMouseLeaveRow($event)"
        ></tr>
      </table>
    </div>
  </nguk-section>
</div>

<ng-template cdk-portal class="dropdown">
  <nsi-row-actions (duplicate)="handleDuplicate()" (delete)="handleDelete()" />
</ng-template>
