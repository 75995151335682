<div class="mining-plan-list-root">
  <nguk-section>
    <div class="sticky-container" tabindex="0">
      <div class="container-buttons" *ngIf="!isShow">
        <nguk-button
          type="primary"
          *ngIf="mapCurrentCells && mapCurrentCells.size > 1"
          class="edit-button"
          (click)="openModal()"
        >
          <mat-icon [svgIcon]="svgIconsEnum.Edit"></mat-icon>
        </nguk-button>
        <nguk-button type="primary" class="create-button" (click)="openModal()">
          <mat-icon [svgIcon]="svgIconsEnum.Plus"></mat-icon>
        </nguk-button>
      </div>
      <table mat-table [dataSource]="data" multiTemplateDataRows #table>
        <ng-container [matColumnDef]="'empty'">
          <th
            mat-header-cell
            *matHeaderCellDef
            [attr.colspan]="columns.length"
            class="horizontal-alignment"
          ></th>
        </ng-container>

        <ng-container [matColumnDef]="'dateStarted'">
          <th
            mat-header-cell
            *matHeaderCellDef
            [attr.colspan]="columnsDays.length"
            class="horizontal-alignment"
          >
            <nguk-text weight="400" styleName="text-xs"
              >График с {{ dataInstance && dataInstance.dateStarted | date: 'dd.MM.yyyy' }}
            </nguk-text>
          </th>
        </ng-container>

        <ng-container [matColumnDef]="'amount-row'">
          <th mat-header-cell *matHeaderCellDef>
            <nguk-text weight="700" styleName="text-xs">Итого </nguk-text>
          </th>

          <td mat-cell *matCellDef="let row; let i = index" class="amount-row">
            <nguk-text weight="700" styleName="text-xs">{{ row.total ? row.total : '' }}</nguk-text>
          </td>
        </ng-container>

        <ng-container [matColumnDef]="'total-row'">
          <th mat-header-cell *matHeaderCellDef class="amount-row">
            <nguk-text weight="700" styleName="text-xs">{{ totalColumns.get(0) }}</nguk-text>
          </th>
        </ng-container>

        <ng-container
          *ngFor="let item of columns; let i = index"
          [matColumnDef]="item.name"
          [sticky]="true"
        >
          <th mat-header-cell *matHeaderCellDef>
            <nguk-th-sort-filter
              [columnName]="item.name"
              [columnDisplay]="item.display"
              [rawData]="rawData"
            ></nguk-th-sort-filter>
          </th>
          <td
            mat-cell
            *matCellDef="let row; let ind = dataIndex"
            [class.border-none]="ind | miningPlanSelectedCell: data"
          >
            <nguk-text weight="400" styleName="text-xs">
              {{ item.name === 'index' ? ind + 1 : row[item.name]?.title || row[item.name] }}
            </nguk-text>
          </td>
        </ng-container>

        <ng-container [matColumnDef]="'index-total'" [sticky]="true">
          <th mat-header-cell *matHeaderCellDef [attr.colspan]="columns.length">
            <nguk-text weight="400" styleName="text-xs">
              {{ id ? 'Итого по дням' : '' }}
            </nguk-text>
          </th>
        </ng-container>

        <ng-container matColumnDef="horizontal-area-first" [sticky]="true">
          <td mat-cell *matCellDef="let row; let i = index" [attr.colspan]="columns.length">
            <div class="horizontal-area">
              <nguk-text weight="700" styleName="text-xs">{{ row.area.title || '-' }}</nguk-text>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="horizontal-area-second">
          <td mat-cell *matCellDef="let row; let i = index" [attr.colspan]="columnsDays.length + 1">
            <div class="horizontal-area"></div>
          </td>
        </ng-container>

        <ng-container *ngFor="let item of columnsDays; let i = index" [matColumnDef]="item.name">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="day-header-cell"
            [class.day-weekend]="+item.display | ngukWeekendDayPipe: dataInstance.dateStarted + ''"
          >
            <nguk-text weight="400" styleName="text-xs" class="opacity-text">{{
              item.display
            }}</nguk-text>
          </th>
          <td
            mat-cell
            *matCellDef="let row; let ind = dataIndex"
            class="day-mat-cell"
            [class.day-weekend]="+item.display | ngukWeekendDayPipe: dataInstance.dateStarted + ''"
            [class.border-none]="ind | miningPlanSelectedCell: data"
            [class.day-mat-cell-active]="mapCurrentCells.get(row.key + '-' + item.display)"
            (click)="chooseDay(row, item)"
            (dblclick)="openModal(row, item)"
          >
            <nguk-text weight="400" styleName="text-xs">
              {{ row | miningPlanValueCell: item }}
            </nguk-text>
          </td>
        </ng-container>

        <ng-container
          *ngFor="let item of columnsDaysTotal; let i = index"
          [matColumnDef]="item.name"
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            class="day-mat-cell"
            [class.day-weekend]="+item.display | ngukWeekendDayPipe: dataInstance.dateStarted + ''"
          >
            <nguk-text weight="700" styleName="text-xs">{{
              totalColumns.get(+item.display)
            }}</nguk-text>
          </th>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="['empty', 'dateStarted']; sticky: true"></tr>
        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
          class="sticky-row"
        ></tr>
        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumnsTotal; sticky: true"
          class="sticky-row"
        ></tr>
        <tr
          mat-row
          *matRowDef="
            let row;
            columns: ['horizontal-area-first', 'horizontal-area-second'];
            let i = dataIndex
          "
          [class.horizontal-area-hidden]="i | miningPlanCheckHorizontalArea: data"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </nguk-section>
</div>

<ng-template #modal></ng-template>
