import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class WarehouseCommunicationService {
  getListWarehouseOP(value: number): number[] {
    switch (value) {
      case 1:
        return [23, 3, 24, 25, 26, 27, 4, 19, 20, 21, 22, 1, 2, 15];
      case 3:
        return [10, 9, 8, 11, 12];
      case 5:
        return [13, 14, 16, 17];
      case 9:
        return [10, 9, 8, 11, 12, 13, 14, 16, 17, 37, 38, 39, 40, 41, 31, 32, 33, 34, 35, 36];
      case 10:
        return [30];
      default:
        return [];
    }
  }
}
