import { Injectable } from '@angular/core';
import { EntityApi } from '../core/abstract/entity.api';
import { ApiProvider } from '../core/providers/api.provider';
import { IDataInstance } from '../data-models/enrichment-standard/data-instance.interface';
import { Observable } from 'rxjs';
import { ApiRequestModel } from '../core/models/api-request.model';

@Injectable({
  providedIn: 'root',
})
export class DataInstanceApiService extends EntityApi<IDataInstance, IDataInstance> {
  protected readonly api = 'sourceData/dataInstance';

  constructor(apiProvider: ApiProvider) {
    super(apiProvider);
  }

  copy(model: { fromId: string }): Observable<any> {
    const apiModel = new ApiRequestModel(this.api, model)
      .addToPath('copy');

    return this.apiProvider.post(apiModel);
  }
}
