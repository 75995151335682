import { Component, HostBinding } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextComponent } from '../text/text.component';

@Component({
  selector: 'nguk-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss'],
  standalone: true,
  imports: [CommonModule, TextComponent],
})
export class SectionComponent {
  @HostBinding('class.nguk-section') private baseClass = true;
}
