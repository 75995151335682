<nguk-modal [visible]="isVisible" [close]="closeModal.bind(this, false)">
  <div class="modal">
    <div class="modal-header">
      <nguk-text weight="600" styleName="text-xl">Производится загрузка файла</nguk-text>
    </div>

    <div class="modal-main">
      <mat-spinner [diameter]="67"></mat-spinner>
    </div>
  </div>
</nguk-modal>
