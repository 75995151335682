import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Page } from '../../../../ui/base/page';

@Component({
  selector: 'calculations-page',
  templateUrl: './calculations-page.component.html',
  styleUrls: ['./calculations-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalculationsPageComponent extends Page {

}
