import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextComponent } from 'src/app/modules/ui/components/text/text.component';
import { DataInstanceApiService } from 'src/app/api/data-instance.api.service';
import { finalize, take } from 'rxjs';
import { IEditJournal } from 'src/app/data-models/enrichment-standard/journal.interface';
import { IDataInstance } from 'src/app/data-models/enrichment-standard/data-instance.interface';
import * as moment from 'moment';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MinMaxDatePipe } from 'src/app/modules/calendar-plan/pipes/modal-journal-list-short/min-max-date.pipe';
import { SpinnerService } from '../../../../../ui/services/spinner.service';

@Component({
  selector: 'calendar-plan-modal-journal-list-short-row-data',
  templateUrl: './modal-journal-list-short-row-data.component.html',
  styleUrls: ['./modal-journal-list-short-row-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TextComponent, MinMaxDatePipe],
})
export class ModalJournalListShortRowDataComponent implements OnInit {
  @Output() chooseDataInstances = new EventEmitter<string>();
  @Input() isOperationalPlan = false;

  @Input() formData!: FormGroup;
  @Input() isHasCalculated = false;

  readonly moment = moment;

  listRowDataCalendar = [
    { name: 'Сбытовая потребность', type: 'shippingPlan' },
    { name: 'Складские остатки', type: 'warehouseBalance' },
    { name: 'План добычи', type: 'miningPlan' },
    { name: 'Нормы выхода', type: 'enrichmentStandard' },
    { name: 'Доступность ресурсов', type: 'resourceAvailability' },
    { name: 'НСИ', type: 'dictionary' },
  ];

  listRowDataOperational = [
    ...this.listRowDataCalendar,
    { name: 'Парк вагонов', type: 'wagonFleet' },
  ];

  currentDataInstance: IDataInstance[] = [];

  constructor(
    private ss: SpinnerService,
    private dataInstanceApiService: DataInstanceApiService,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.getDataList();
  }

  findTypesName(type: string): string {
    return (
      (this.isOperationalPlan ? this.listRowDataOperational : this.listRowDataCalendar).find(
        (item) => item.type === type,
      )?.name ?? ''
    );
  }

  getDataList(): void {
    this.ss.startSpinner();
    this.dataInstanceApiService
      .getList(1, [{ name: 'itemsPerPage', value: 500 * 1000 }])
      .pipe(take(1), finalize(this.ss.stopSpinner))
      .subscribe((data) => {
        const dataSorting = data.sort(
          (lhs, rhs) => new Date(rhs.dateCreated).getTime() - new Date(lhs.dateCreated).getTime(),
        );
        const journal = this.formData.value as IEditJournal;

        (this.isOperationalPlan ? this.listRowDataOperational : this.listRowDataCalendar).forEach(
          (item) => {
            const filterData = dataSorting.find((value) => value.type === item.type);
            if (!journal.dataInstances.length && filterData) {
              this.currentDataInstance.push(filterData);
            } else {
              const value = journal.dataInstances.find((value) => value.type === item.type);
              const current = dataSorting.find((curr) => curr.id === value?.id) as IDataInstance;
              this.currentDataInstance.push(current);
            }
          },
        );

        this.currentDataInstance = this.currentDataInstance.filter((item) => item);

        if (
          this.currentDataInstance.length !==
          (this.isOperationalPlan ? this.listRowDataOperational : this.listRowDataCalendar).length
        ) {
          const skipedDataInstance = (
            this.isOperationalPlan ? this.listRowDataOperational : this.listRowDataCalendar
          ).filter((item) => !this.currentDataInstance.find((el) => el?.type === item.type));

          skipedDataInstance.forEach((item) => {
            const el = data.find((el) => item.type === el.type);
            if (el) {
              this.currentDataInstance.push(el);

              const fg = this.fb.group({
                id: el.id,
                type: el.type,
              });
              this.arrayControlDataInstance.push(fg);
            }
          });
        }

        if (journal && !journal.id && !journal.dataInstances.length) {
          this.currentDataInstance.forEach((item) => {
            const fg = this.fb.group({
              id: item.id,
              type: item.type,
            });

            this.arrayControlDataInstance.push(fg);
          });
        }

        this.cdr.detectChanges();
      });
  }

  get arrayControlDataInstance(): FormArray {
    return this.formData.get('dataInstances') as FormArray;
  }
}
