import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextComponent } from 'src/app/modules/ui/components/text/text.component';
import { ButtonComponent } from 'src/app/modules/ui/components/button/button.component';
import { MatIconModule } from '@angular/material/icon';
import { SvgIconsEnum } from 'src/app/core/enums/svg-icons.enum';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'print-header',
  templateUrl: './print-header.component.html',
  styleUrls: ['./print-header.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, TextComponent, ButtonComponent, MatIconModule],
})
export class PrintHeaderComponent implements OnInit {
  readonly svgIconsEnum = SvgIconsEnum;

  isOP = false;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.isOP = !!this.route.snapshot.queryParams['type'];
  }
}
