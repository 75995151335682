import { ModalBase } from '../../../../ui/base/modal.base';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from '../../../../ui/components/modal/modal.component';
import { TextComponent } from '../../../../ui/components/text/text.component';
import { MatTableModule } from '@angular/material/table';
import { ICommodityShipment } from '../../../../../data-models/commodity-shipment/commodity-shipment.interface';
import { CommodityShipmentService } from '../../../services/commodity-shipment.service';
import { TShippingPlanListRow } from '../../../../data/types/shipping-plan-list-row.type';
import { TShippingPlanDay } from '../../../../data/types/shipping-plan-day.type';
import {
  ICommodityShipmentMaterial
} from '../../../../../data-models/commodity-shipment-material/commodity-shipment-material.interface';
import {
  ICommodityShipmentQualitySpecification
} from '../../../../../data-models/commodity-shipment-quality-specification/commodity-shipment-quality-specification.interface';
import { finalize, forkJoin, mergeMap, take } from 'rxjs';
import { IDataColumnTable } from '../../../../../data-models/data-column-table/data-column-table.interface';
import {
  ICommodityShipmentQualitySpecificationItem
} from '../../../../../data-models/commodity-shipment-quality-specification-item/commodity-shipment-quality-specification-item.interface';
import { EShippingPlanColorAccent } from '../../../../../enums/shipping-plan-color-accent.enum';
import { SpinnerService } from '../../../../ui/services/spinner.service';
import get from 'lodash-es/get';
import isFinite from 'lodash-es/isFinite';
import { isNaN } from 'lodash-es';

@Component({
  selector: 'calendar-plan-commodity-shipment-modal',
  templateUrl: './commodity-shipment-modal.component.html',
  styleUrls: ['./commodity-shipment-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ModalComponent, TextComponent, MatTableModule],
})
export class CommodityShipmentModalComponent extends ModalBase implements OnInit, AfterViewInit {
  row!: TShippingPlanListRow;
  cell!: TShippingPlanDay;
  commodityShipment!: ICommodityShipment;
  shipmentMaterials!: ICommodityShipmentMaterial[];
  shipmentQualitySpecifications!: ICommodityShipmentQualitySpecification[];
  ordinalColumns!: IDataColumnTable[];
  displayedQualitySpecificationColumns!: string[];

  readonly colorAccentEnum = EShippingPlanColorAccent;
  readonly materialColumns: IDataColumnTable[] = [
    { name: 'area.title', display: 'Источник' },
    { name: 'mark.title', display: 'Марка' },
    { name: 'volume', display: 'Объем' },
  ];
  readonly displayedMaterialColumns = this.materialColumns.map((column) => column.name);
  readonly qualitySpecificationColumns: IDataColumnTable[] = [
    { name: 'mixture', display: 'Товарная смесь' },
  ];

  get modalTitle(): string {
    const { station, customer } = this.row;
    const day = Object.keys(this.row.days).find(
      (key: string) => this.row.days[key].shippingPlanId === this.cell.shippingPlanId,
    );

    return `${station} | ${customer} | ${day}`;
  }

  constructor(
    cdr: ChangeDetectorRef,
    private ss: SpinnerService,
    private commodityShipmentService: CommodityShipmentService,
  ) {
    super(cdr);
  }

  ngOnInit(): void {
    this.ss.startSpinner();
    this.commodityShipmentService
      .getCommodityShipmentMaterial(this.cell.shippingPlanId)
      .pipe(take(1), finalize(this.ss.stopSpinner))
      .subscribe((materials: ICommodityShipmentMaterial[]) => {
        this.shipmentMaterials = materials;
        this.cdRef.markForCheck();
      });

    this.commodityShipmentService
      .getCommodityShipmentQualitySpecification(this.cell.shippingPlanId)
      .pipe(
        take(1),
        mergeMap((qualitySpecifications: ICommodityShipmentQualitySpecification[]) => {
          this.shipmentQualitySpecifications = qualitySpecifications;
          const requests = qualitySpecifications.map(
            (qualitySpecification: ICommodityShipmentQualitySpecification) =>
              this.commodityShipmentService.getCommodityShipmentQualitySpecificationItems(
                qualitySpecification.id,
              ),
          );

          return forkJoin(requests);
        }),
        finalize(this.ss.stopSpinner),
      )
      .subscribe(this.handleQualitySpecificationItems);
  }

  ngAfterViewInit(): void {
    this.initModal();
  }

  getShipmentMaterialCellValue(row: ICommodityShipmentMaterial, key: string): void {
    return key === 'volume'
      ? Math.round((row[key] / 1000) * 100) / 100
      : get(row, key);
  }

  private handleQualitySpecificationItems = (
    qualitySpecificationItems: ICommodityShipmentQualitySpecificationItem[][],
  ): void => {
    qualitySpecificationItems.forEach(
      (qualitySpecificationItem: ICommodityShipmentQualitySpecificationItem[], index: number) => {
        qualitySpecificationItem.forEach((element: ICommodityShipmentQualitySpecificationItem) => {
          if (!('items' in this.shipmentQualitySpecifications[index])) {
            this.shipmentQualitySpecifications[index] = {
              ...this.shipmentQualitySpecifications[index],
              items: {},
            };
          }

          const { interval, lowerNorm, upperNorm } = element;
          const intervalValue = interval
            ? interval
            : `${lowerNorm ?? '*'}..${upperNorm ?? '*'}`;
          this.shipmentQualitySpecifications[index].items[element.ordinal] = element;
          (this.shipmentQualitySpecifications[index].items[element.ordinal] as any).formula =
            `${element.designation}[${intervalValue}]=${element.fact ?? ''}`;
          (this.shipmentQualitySpecifications[index].items[element.ordinal] as any).deviationColor =
            this.getItemDeviationColor(
              this.cell.deviationColor as EShippingPlanColorAccent,
              element,
            );
        });
      },
    );

    const maxOrdinal = this.getMaximumOrdinal(qualitySpecificationItems);
    this.initializeOrdinalColumns(maxOrdinal);
    this.cdRef.detectChanges();
  };

  private getItemDeviationColor(
    cellDeviationColor: EShippingPlanColorAccent,
    qualitySpecificationItem: ICommodityShipmentQualitySpecificationItem,
  ): EShippingPlanColorAccent {
    return qualitySpecificationItem.deviation ? cellDeviationColor : EShippingPlanColorAccent.No;
  }

  private getMaximumOrdinal(
    qualitySpecificationItems: ICommodityShipmentQualitySpecificationItem[][],
  ): number {
    let maximum = 0;
    qualitySpecificationItems.forEach(
      (qualitySpecificationItem: ICommodityShipmentQualitySpecificationItem[]) => {
        qualitySpecificationItem.forEach((element: ICommodityShipmentQualitySpecificationItem) => {
          if (element.ordinal <= maximum) {
            return;
          }

          maximum = element.ordinal;
        });
      },
    );

    return maximum;
  }

  private initializeOrdinalColumns(ordinal: number): void {
    this.ordinalColumns = Array.from(
      Array(ordinal),
      (_, index: number): IDataColumnTable => ({
        name: String(index + 1),
        display: String(index + 1),
      }),
    );

    this.displayedQualitySpecificationColumns = [
      ...this.qualitySpecificationColumns,
      ...this.ordinalColumns,
    ].map((column: IDataColumnTable) => column.name);
  }
}
