import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextComponent } from 'src/app/modules/ui/components/text/text.component';
import { ModalComponent } from 'src/app/modules/ui/components/modal/modal.component';
import { ModalBase } from 'src/app/modules/ui/base/modal.base';
import { FormControl, FormGroup } from '@angular/forms';
import { ModalEnrichmentStandardListActionsComponent } from '../modal-enrichment-standard-list-actions/modal-enrichment-standard-list-actions.component';
import { ModalEnrichmentStandardListFormComponent } from '../modal-enrichment-standard-list-form/modal-enrichment-standard-list-form.component';
import { IEnrichmentStandard } from 'src/app/data-models/enrichment-standard/enrichment-standard.interface';
import { ToggleComponent } from 'src/app/modules/forms/components/toggle/toggle.component';

@Component({
  selector: 'modal-enrichment-standard-list',
  templateUrl: './modal-enrichment-standard-list.component.html',
  styleUrls: ['./modal-enrichment-standard-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ModalComponent,
    TextComponent,
    ModalEnrichmentStandardListFormComponent,
    ModalEnrichmentStandardListActionsComponent,
    ToggleComponent,
  ],
})
export class ModalEnrichmentStandardListComponent extends ModalBase implements AfterViewInit {
  updateData!: () => void;
  data!: IEnrichmentStandard[];

  formData!: FormGroup;

  dataInstanceId!: string;
  toggleValue!: string;

  isShow = false;
  saveData = false;

  toggleList = ['Редактирование', 'Копирование'];
  toggleControl = new FormControl<string | null>(null);

  constructor(cdRef: ChangeDetectorRef) {
    super(cdRef);
  }

  getForm(form: FormGroup): void {
    this.formData = form;
  }

  ngAfterViewInit(): void {
    this.initModal();
    this.toggleControl.setValue(this.data.length ? this.toggleList[0] : this.toggleList[1]);
  }
}
