import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SvgIconsEnum } from '../../../../../core/enums/svg-icons.enum';
import { RoutesHelper } from '../../../../../helpers/routes.helper';
import { MatIconModule } from '@angular/material/icon';
import { TextComponent } from '../../../../ui/components/text/text.component';
import { DatePipe, NgForOf, NgIf } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { NsiTableTypeEnum } from '../../../enums/nsi-table-type.enum';
import { IDataInstance } from '../../../../../data-models/enrichment-standard/data-instance.interface';
import { DatePairComponent } from '../../../../ui/components/date-pair/date-pair.component';
import { NsiDictionaryModel } from '../../../../../data-models/dictionary/dictionary.model';

@Component({
  selector: 'nsi-data-instance-item',
  templateUrl: './nsi-data-instance-item.component.html',
  styleUrls: ['./nsi-data-instance-item.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    TextComponent,
    NgForOf,
    RouterLink,
    RouterLinkActive,
    DatePairComponent,
    DatePipe,
    NgIf,
  ],
})
export class NsiDataInstanceItemComponent {
  @Input() index!: number;
  @Input() dataInstance!: IDataInstance;
  @Input() isOpened!: boolean;
  @Input() isCalculationComplete!: boolean;
  @Input() dictionaries: NsiDictionaryModel[] = [];
  @Output() readonly toggleOpenState = new EventEmitter<IDataInstance>();
  @Output() readonly openEditNSI = new EventEmitter<IDataInstance>();

  isSingleClick = true;

  readonly svgIconsEnum = SvgIconsEnum;

  getListIcon(dictionary: NsiDictionaryModel): SvgIconsEnum {
    return dictionary.format === NsiTableTypeEnum.List ? SvgIconsEnum.List : SvgIconsEnum.Grid2x2;
  }

  handleClick(dataInstance: IDataInstance): void {
    this.isSingleClick = true;
    setTimeout(() => {
      if (!this.isSingleClick) {
        return;
      }

      this.toggleOpenState.emit(dataInstance);
    }, 250);
  }

  handleDoubleClick(dataInstance: IDataInstance): void {
    this.isSingleClick = false;
    this.openEditNSI.emit(dataInstance);
  }

  getDictionaryRouterLink(dictionary: NsiDictionaryModel): string[] {
    const tableTypePath = dictionary.format === NsiTableTypeEnum.Matrix
      ? RoutesHelper.NSI_MATRIX_PATH
      : RoutesHelper.NSI_LIST_PATH;

    return [RoutesHelper.EMPTY_PATH, RoutesHelper.NSI_PATH, this.dataInstance.id!, tableTypePath, String(dictionary.id)];
  }
}
