<div class="operational-commodity-shipment-list-root">
  <nguk-section>
    <div class="sticky-container" tabindex="0">
      <table
        mat-table
        [dataSource]="data"
        [class.table-padding]="true"
        multiTemplateDataRows
        #table
      >
        <ng-container *ngFor="let column of columnsFirstHeader">
          <ng-container [matColumnDef]="column.name">
            <th
              mat-header-cell
              *matHeaderCellDef
              [attr.colspan]="column.name === 'specification' ? amountColumns : column.colspan"
            >
              <nguk-text weight="700" styleName="text-xs"> {{ column.display }} </nguk-text>
            </th>
          </ng-container>
        </ng-container>

        <ng-container *ngFor="let item of columns; let i = index" [matColumnDef]="item.name">
          <th mat-header-cell *matHeaderCellDef>
            <nguk-th-sort-filter
              [columnName]="item.name"
              [columnDisplay]="item.display"
              [rawData]="rawData"
            ></nguk-th-sort-filter>
          </th>

          <td
            mat-cell
            *matCellDef="let row; let ind = dataIndex; let renderIndex = renderIndex"
            [class.deviation-volume]="item.name === 'appointedVolume' && !!row.deviationOfVolume"
          >
            <ng-container
              *ngIf="item.name === 'dateAppointed' || item.name === 'dateTarget'; else fields"
            >
              <ng-container *ngIf="item.name === 'dateAppointed'; else dateTarget">
                <nguk-date-pair [date]="row.dateAppointed" *ngIf="!row.deviationOfDate">
                  <ng-container left>
                    {{ row.dateAppointed | date: 'dd.MM' : 'UTC +0' }}
                  </ng-container>
                  <ng-container right>
                    {{ row.dateAppointed | date: 'HH' : 'UTC +0' }}
                  </ng-container>
                </nguk-date-pair>
              </ng-container>
              <ng-template #dateTarget>
                <nguk-date-pair [date]="row.dateTarget">
                  <ng-container left>
                    {{ row.dateTarget | date: 'dd.MM' : 'UTC +0' }}
                  </ng-container>
                  <ng-container right>
                    {{ (row.dateTarget | date: 'HH' : 'UTC +0') + ' + ' + row.targetRange }}
                  </ng-container>
                </nguk-date-pair>
              </ng-template>
            </ng-container>

            <ng-template #fields>
              <nguk-text weight="400" styleName="text-xs">
                {{
                  item.name === 'index'
                    ? ind + 1
                    : item.name === 'appointedVolume'
                      ? row.appointedVolume ?? 0
                      : row[item.name]?.title || row[item.name]
                }}
              </nguk-text>
            </ng-template>
          </td>
        </ng-container>

        <ng-container
          *ngFor="let item of columnsSpecification; let i = index"
          [matColumnDef]="item.name"
        >
          <th mat-header-cell *matHeaderCellDef class="day-header-cell">
            <nguk-text weight="400" styleName="text-xs" class="opacity-text">
              {{ i + 1 }}
            </nguk-text>
          </th>
          <td
            mat-cell
            *matCellDef="let row; let ind = dataIndex"
            [class.deviation-specification]="
              !!row.specification?.indicators[(i + 1).toString()]?.deviation
            "
          >
            <nguk-text weight="400" styleName="text-xs">
              {{ row.specification?.indicators[(i + 1).toString()]?.formula ?? '——' }}
            </nguk-text>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsFirstHeader; sticky: true"></tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>

        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </nguk-section>
</div>

<ng-template #modal></ng-template>
