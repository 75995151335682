<nguk-modal [visible]="isVisible" [close]="closeModal.bind(this, false)">
  <div class="modal">
    <div class="modal-header">
      <div class="modal-header-info">
        <nguk-text weight="600" styleName="text-xl">Код ресурса </nguk-text>
      </div>
    </div>
    <modal-select-resource-table [data]="data" [mapValue]="mapValue" [isShow]="isShow" />
    <modal-select-resource-input [value]="data.resourceCode" />
  </div>
</nguk-modal>
