<div class="graphs-root">
  <nguk-section>
    <div class="sticky-container" tabindex="0">
      <table mat-table [dataSource]="data">
        <ng-container [matColumnDef]="'index'">
          <th mat-header-cell *matHeaderCellDef>#</th>
          <td mat-cell *matCellDef="let row; let index = index">
            <nguk-text styleName="text-xs">
              {{ index + 1 }}
            </nguk-text>
          </td>
        </ng-container>

        <ng-container [matColumnDef]="'customer'">
          <th mat-header-cell *matHeaderCellDef>Заказчик</th>
          <td mat-cell *matCellDef="let row">
            <nguk-text styleName="text-xs">
              {{ row.title ?? '' }}
            </nguk-text>
          </td>
        </ng-container>

        <ng-container [matColumnDef]="'size'">
          <th mat-header-cell *matHeaderCellDef>Размер</th>
          <td mat-cell *matCellDef="let row">
            <nguk-text styleName="text-xs">
              {{ row.size ?? '' }}
            </nguk-text>
          </td>
        </ng-container>

        <ng-container [matColumnDef]="'download'">
          <th mat-header-cell *matHeaderCellDef>
            <a *ngIf="url" [href]="url" class="download">
              <mat-icon [svgIcon]="svgIconsEnum.Download" />
            </a>
          </th>
          <td mat-cell *matCellDef="let row"></td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="['index', 'customer', 'size', 'download']; sticky: true"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: ['index', 'customer', 'size', 'download']"></tr>
      </table>
    </div>
  </nguk-section>
</div>

<ng-template #modal></ng-template>
