import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from 'src/app/modules/ui/components/button/button.component';
import { PrintActionsComponent } from '../../parts/print-actions/print-actions.component';
import { PrintTablesComponent } from '../../parts/print-tables/print-tables.component';
import { PrintHeaderComponent } from '../../parts/print-header/print-header.component';
import { PrintInfoComponent } from '../../parts/print-info/print-info.component';
import { WorkSheet } from 'xlsx';

@Component({
  selector: 'print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    PrintActionsComponent,
    PrintTablesComponent,
    PrintHeaderComponent,
    PrintInfoComponent,
  ],
})
export class PrintComponent {
  @ViewChild('dataToExport', { static: false }) public dataToExport!: ElementRef;

  listTables: { table: HTMLTableElement | WorkSheet; label: string }[] = [];

  constructor() {}

  showButtons = true;

  saveToPdf(): void {
    window.print();
  }

  getListTables(listTables: { table: HTMLTableElement | WorkSheet; label: string }[]) {
    this.listTables = [...listTables];
  }
}
