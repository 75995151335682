import { ChangeDetectorRef, ComponentRef, EventEmitter, Injectable, Type } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, finalize, forkJoin, mergeMap, of, switchMap, take, tap } from 'rxjs';
import { MiningPlanApiService } from 'src/app/api/mining-plan.api.service';
import { IMiningPlan } from 'src/app/data-models/mining-plan/mining-plan.interface';
import { ModalMiningPlanConfirmComponent } from '../../components/parts/mining-plan/modal-mining-plan-confirm/modal-mining-plan-confirm.component';
import { DictionariesEnum } from 'src/app/core/enums/dictionaries.enum';
import { IDictionaryModel } from 'src/app/data-models/dictionary-model/dictionary-model.interface';
import { DictionariesService } from 'src/app/core/services/dictionaries.service';
import { IEditMiningPlan } from 'src/app/data-models/mining-plan/edit-mining-plan.interface';
import * as moment from 'moment';
import { IQueryRequest } from 'src/app/data-models/query-request/query-request.interface';
import { IMiningPlanVolume } from 'src/app/data-models/mining-plan-volume/mining-plan-volume.interface';
import { MiningPlanVolumeApiService } from 'src/app/api/mining-plan-volume.api.service';
import { IEditMiningPlanVolume } from 'src/app/data-models/mining-plan-volume/edit-mining-plan-volume.interface';
import { SpinnerService } from 'src/app/modules/ui/services/spinner.service';

@Injectable({
  providedIn: 'root',
})
export class ModalMiningPlanArrayService {
  close!: EventEmitter<void>;
  update!: EventEmitter<void>;
  isCopy!: boolean;
  isChange!: boolean;
  open!: (
    comp: Type<ModalMiningPlanConfirmComponent>,
  ) => ComponentRef<ModalMiningPlanConfirmComponent>;
  arrayListMiningPlanVolume!: IMiningPlanVolume[][];
  removeListIds!: number[];
  formData!: FormGroup;
  data!: IMiningPlan[];

  fieldsToSkip = ['id', 'dataInstance', 'table'];

  paramsDictionaries = [
    DictionariesEnum.Mark,
    DictionariesEnum.Area,
    DictionariesEnum.Layer,
    DictionariesEnum.Profile,
  ];
  currentParams: DictionariesEnum[] = [];

  constructor(
    private miningPlanApiService: MiningPlanApiService,
    private cdr: ChangeDetectorRef,
    private dictionariesService: DictionariesService,
    private miningPlanVolumeApiService: MiningPlanVolumeApiService,
    private ss: SpinnerService,
  ) {}

  checkValidForm(data: IMiningPlan[], formData: FormGroup): boolean {
    if (data.length > 1) return true;

    formData.markAllAsTouched();

    if (formData.invalid) {
      Object.keys(formData.controls).forEach((controlName) => {
        formData.get(controlName)?.updateValueAndValidity();
      });
      return false;
    }

    return true;
  }

  createArray(value: IMiningPlan[]) {
    this.ss.startSpinner();

    const request = value.map((item) => {
      const body = { ...item };
      delete body['id'];
      delete body['table'];

      if (this.data.length > 1) {
        const newRows = (this.formData.value.table as IEditMiningPlanVolume[]).reduce(
          (acc, row) => acc + +row.volume,
          0,
        );
        body.volume = newRows.toString();
      }

      return this.miningPlanApiService.create(body);
    });

    forkJoin(request)
      .pipe(
        switchMap((res) => {
          const table = this.formData.get('table')?.value as IEditMiningPlanVolume[];

          const arrCreateTable = res
            .map((item, index) =>
              table.map((row) => {
                const body = { ...row };
                delete body['id'];
                body.startTime = body.startTime ? Number(body.startTime) : 0;
                body.finishTime = body.finishTime ? Number(body.finishTime) : 0;
                body.miningPlan = { id: res[index].id!.toString() };
                return this.miningPlanVolumeApiService.create(body);
              }),
            )
            .flat();
          return arrCreateTable.length ? forkJoin(arrCreateTable) : of([]);
        }),
        take(1),
        finalize(this.ss.stopSpinner),
        tap(() => this.close.emit()),
      )
      .subscribe();
  }

  createOptionsDictionaries(formData: FormGroup): Observable<IDictionaryModel[]> {
    const data = { ...formData.value };
    this.currentParams = this.paramsDictionaries.filter(
      (item) => data[item] && typeof data[item] === 'string',
    );

    const requestDictionaries = this.currentParams.map((item) =>
      this.dictionariesService.createOption(item, data[item]),
    );

    return requestDictionaries.length > 0 ? forkJoin(requestDictionaries) : of([]);
  }

  saveArray(data: IMiningPlan[], formData: FormGroup, isCopy: boolean): void {
    if (!this.checkValidForm(data, formData)) return;

    this.ss.startSpinner();

    this.createOptionsDictionaries(formData)
      .pipe(take(1))
      .subscribe((arrayResponse) => {
        arrayResponse.forEach((response, index) => {
          formData.get(this.currentParams[index])?.setValue(response);
        });

        const form = { ...formData.getRawValue() };
        this.paramsDictionaries.forEach((item) => {
          if (!form[item]) form[item] = null;
        });

        data = !data.length
          ? [form]
          : data.map((item) => {
              Object.keys(formData.getRawValue()).forEach((key) => {
                if (this.fieldsToSkip.includes(key)) return;
                if (form[key] && item[key] !== form[key]) item[key] = form[key];
              });
              return item;
            });

        const request = data.map((item) =>
          this.miningPlanApiService.getList(1, this.createQueryArray(item)),
        );

        forkJoin(request)
          .pipe(take(1), finalize(this.ss.stopSpinner))
          .subscribe((res) => {
            const amountResult = res.reduce((acc, item) => acc + item.length, 0);
            const checkIds =
              data.every((item, index) => {
                if (!item.id) {
                  return res[index].length === 0;
                }
                return res[index].length === 1 && item.id === res[index][0].id;
              }) && res.length === data.length;

            if (amountResult > 0) {
              if (checkIds) {
                const createArr = data.filter((item) => !item.id);
                const changeArr = data.filter((item) => item.id);
                this.createArray(createArr);
                this.changeArray(changeArr);
              } else {
                this.openModalArray();
                this.cdr.markForCheck();
              }
            } else {
              this.isCopy ? this.createArray(data) : this.changeArray(data);
            }
          });
      });
  }

  changeArray(value: IMiningPlan[]): void {
    this.ss.startSpinner();

    const requestsUpdateMiningPlan = value
      .filter((item) => item.id)
      .map((item, index) => {
        if (this.data.length > 1) {
          const amountVolume = this.arrayListMiningPlanVolume[index].reduce(
            (acc, row) => acc + +row.volume,
            0,
          );

          const newRows = (this.formData.value.table as IEditMiningPlanVolume[]).reduce(
            (acc, row) => acc + +row.volume,
            0,
          );

          if (amountVolume + newRows > 0) {
            item.volume = (amountVolume + newRows).toString();
          }
        }

        return this.miningPlanApiService.update(item);
      });

    const requestsRemoveVolume = this.removeListIds.map((id) =>
      this.miningPlanVolumeApiService.delete(id),
    );

    const requestsCreateVolume = value
      .map((miningPlan) => {
        const table = this.formData.get('table')?.value as IEditMiningPlanVolume[];
        return table
          .filter((item) => !item.id)
          .map((row) => {
            const body = { ...row };
            delete body['id'];
            body.startTime = body.startTime ? Number(body.startTime) : 0;
            body.finishTime = body.finishTime ? Number(body.finishTime) : 0;
            body.miningPlan = { id: miningPlan.id!.toString() };
            return this.miningPlanVolumeApiService.create(body);
          });
      })
      .flat();

    const requestsUpdateVolume = value
      .map(() => {
        const table = this.formData.get('table')?.value as IEditMiningPlanVolume[];
        return table
          .filter((item) => item.id)
          .map((row) => {
            const body = { ...row };
            body.startTime = body.startTime ? Number(body.startTime) : 0;
            body.finishTime = body.finishTime ? Number(body.finishTime) : 0;
            return this.miningPlanVolumeApiService.update(body);
          });
      })
      .flat();

    forkJoin([
      ...requestsUpdateMiningPlan,
      ...requestsCreateVolume,
      ...requestsRemoveVolume,
      ...requestsUpdateVolume,
    ])
      .pipe(take(1), finalize(this.ss.stopSpinner))
      .subscribe(() => this.close.emit());
  }

  openModalArray(): void {
    this.open(ModalMiningPlanConfirmComponent);
  }

  removeArray(data: IMiningPlan[]) {
    this.ss.startSpinner();
    const request = data
      .filter((item) => item.id)
      .map((item) => {
        return this.miningPlanApiService.delete(item.id as number);
      });

    const requestVolume = this.arrayListMiningPlanVolume
      .map((item) => item.map((row) => this.miningPlanVolumeApiService.delete(row.id)))
      .flat();

    forkJoin([...request, requestVolume.length ? requestVolume : of([])])
      .pipe(take(1), finalize(this.ss.stopSpinner))
      .subscribe(() => {
        this.close.emit();
      });
  }

  createQueryArray(value: IEditMiningPlan) {
    const startDate = moment(value.dateMining).startOf('day').utcOffset(0, true);
    const endDate = moment(value.dateMining).add(1, 'days').startOf('day').utcOffset(0, true);

    const arrQuery: IQueryRequest[] = [
      { name: 'dataInstance.id', value: value.dataInstance.id },
      { name: 'mark.id', value: value.mark.id ?? '' },
      { name: 'layer.id', value: value.layer.id ?? '' },
      { name: 'area.id', value: value.area.id ?? '' },
      { name: 'y', value: value.y ?? '' },
      { name: 'dateMining[strictly_before]', value: endDate.format() },
      { name: 'dateMining[after]', value: startDate.format() },
    ];
    return arrQuery.filter((item) => item.value);
  }

  removeItem(id: string): void {
    const query = [
      {
        name: 'miningPlan.id',
        value: id,
      },
      {
        name: 'itemsPerPage',
        value: 200,
      },
    ];

    const arrVolume = this.miningPlanVolumeApiService.getList(1, query);
    const removesItem = this.miningPlanApiService.delete(id);

    forkJoin([arrVolume, removesItem])
      .pipe(
        mergeMap(([list]) => {
          return list.map((row) => this.miningPlanVolumeApiService.delete(row.id));
        }),
        take(1),
      )
      .subscribe(() => {
        if (this.isCopy) this.update.emit();
        else {
          if (this.close) this.close.emit();
        }
      });
  }
}
