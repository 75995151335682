import { ChangeDetectorRef, Directive, ElementRef, HostBinding, Input, Renderer2 } from '@angular/core';
import { TextComponent } from '../../ui/components/text/text.component';
import { fromEvent, take } from 'rxjs';

@Directive({
  selector: '[ngukEditableText]',
  standalone: true,
})
export class EditableTextDirective<T = { [key: string]: string }> {
  @Input() data!: any;
  @Input() column!: any;
  @Input() columnIndex!: number;
  @Input({ required: true }) textNode!: TextComponent;
  @Input({ required: true }) textNodeContent!: string;
  @HostBinding('class.-invalid') private isInvalidState = false;

  get nativeElement(): HTMLElement {
    return this.elementRef?.nativeElement;
  }

  private inputNode!: null | HTMLInputElement;
  private inputNodeClass = 'nguk-editable-text-input';

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private cdr: ChangeDetectorRef,
  ) {}

  changeViewToEditable(): void {
    if (this.inputNode) {
      return;
    }

    this.renderer.removeChild(this.elementRef, this.textNode.elementRef.nativeElement);
    this.inputNode = this.createInput();
    this.renderer.appendChild(this.elementRef.nativeElement, this.inputNode);
    if (
      ((!this.data || (this.data.ordinal && 'value' in this.data)) || (Object.keys(this.data).length === 1 && this.data.hasOwnProperty('id'))) &&
      (this.textNodeContent?.includes('Столбец') || this.textNodeContent?.includes('Строка'))
    ) {
      this.renderer.addClass(this.inputNode, '-temporary');
    }
  }

  changeViewToReadable(): void {
    if (!this.inputNode) {
      return;
    }

    if (this.data && this.column) {
      if (this.data.value !== this.inputNode!.value) {
        this.data['changed'] = true;
      }
      this.data.value = this.inputNode!.value as any;
    } else if (this.column && this.nativeElement.nodeName === 'TH') {
      this.column.title = this.inputNode!.value;
    }

    this.renderer.removeChild(this.elementRef.nativeElement, this.inputNode);
    this.inputNode = null;
    this.renderer.appendChild(this.elementRef.nativeElement, this.textNode.elementRef.nativeElement);
  }

  checkIsValidState(): boolean {
    if (this.inputNode && !this.inputNode?.value?.trim() && this.textNodeContent) {
      this.setInvalidState();
      return false;
    }

    return true;
  }

  private setInvalidState(): void {
    this.isInvalidState = true;
    this.cdr.detectChanges();

    if (!this.inputNode) {
      return;
    }

    fromEvent(this.inputNode!, 'input')
      .pipe(take(1))
      .subscribe(() => {
        this.isInvalidState = false;
        this.cdr.markForCheck();
      });
  }

  private createInput(): HTMLInputElement {
    const input = this.renderer.createElement('input');
    this.renderer.addClass(input, this.inputNodeClass);
    this.renderer.setProperty(input, 'value', this.textNodeContent ?? '');
    // this.renderer.setAttribute(input, 'size', `${this.textNodeContent.length}`);

    return input;
  }
}
