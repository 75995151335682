import { Pipe, PipeTransform } from '@angular/core';
import { IWarehouseDynamicsItemTransform } from 'src/app/data-models/warehouse-dynamics-item/warehouse-dynamics.interface';

@Pipe({
  name: 'warehouseDynamicsBorderHidden',
  standalone: true,
})
export class WarehouseDynamicsBorderHiddenPipe implements PipeTransform {
  transform(index: number, data: IWarehouseDynamicsItemTransform[]): boolean {
    return data[index + 1] && data[index].warehouse.id !== data[index + 1].warehouse.id;
  }
}
