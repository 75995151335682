<div
  class="journal-list-item-root"
  [class.-opened]="mapIds && mapIds.get(+data.id!)"
  [class.border-none]="lastData"
>
  <div
    class="journal-item-header"
    [attr.role]="'none'"
    (click)="toggleItem()"
    (dblclick)="openModal()"
  >
    <div class="journal-item-name">
      <nguk-text weight="400" styleName="text-xs">{{ indexData + 1 }}</nguk-text>
    </div>

    <div class="vertical-line"></div>

    <div class="container-info">
      <nguk-text weight="400" styleName="text-xs" class="journal-item-note">{{
        note ? note : ''
      }}</nguk-text>
      <div class="container-date-status">
        <nguk-text
          weight="400"
          styleName="text-xs"
          class="schedule-to"
          *ngIf="itemDataInstance?.dateStarted"
          >с {{ itemDataInstance.dateStarted | date: 'dd.MM.yyyy'
          }}{{ getPeriod() ? ', ' + getPeriod() + ' дн.' : '' }}
        </nguk-text>

        <div class="journal-item-date">
          <nguk-text weight="400" styleName="text-xs" class="journal-item-date__date"
            >{{ data.dateCreated | date: 'dd.MM' }}
          </nguk-text>

          <mat-icon [svgIcon]="svgIconsEnum.Clock" />

          <nguk-text weight="400" styleName="text-xs" class="journal-item-date__time">
            {{ data.dateCreated | date: 'HH:mm' }}
          </nguk-text>
        </div>

        <div class="journal-item-info">
          <ng-container *ngIf="itemJournal; else iconDataInstance">
            <mat-icon
              class="journal-item-info-result"
              [svgIcon]="svgIconsEnum.DoubleTick"
              *ngIf="itemJournal.productionPlans.length; else noResult"
            />

            <ng-template #noResult>
              <mat-icon
                class="journal-item-info-noResult"
                [svgIcon]="svgIconsEnum.DoubleTick"
                *ngIf="itemJournal.taskLogs.length; else createdJournal"
              />

              <ng-template #createdJournal>
                <mat-icon class="journal-item-info-created" [svgIcon]="svgIconsEnum.Tick" />
              </ng-template>
            </ng-template>
          </ng-container>

          <ng-template #iconDataInstance>
            <mat-icon
              class="journal-item-info-lockClose"
              *ngIf="isCalculationComplete; else closeCalculate"
              [svgIcon]="svgIconsEnum.LockClose"
            ></mat-icon>

            <ng-template #closeCalculate>
              <mat-icon
                class="journal-item-info-lockOpen"
                [svgIcon]="svgIconsEnum.LockOpen"
              ></mat-icon>
            </ng-template>
          </ng-template>
        </div>
      </div>
    </div>
  </div>

  <div class="journal-list-details" [attr.role]="'none'" *ngIf="mapIds">
    <ng-container *ngFor="let item of submenu">
      <nguk-sidebar-list-submenu-item
        *ngIf="item.type | sidebarListHasItem: itemJournal"
        [title]="item.title"
        [type]="item.type"
        [dictionaryMap]="dictionaryMap"
        [isOpenedId$]="providerActiveIdService.isOpenedId$"
        [menuItems]="item.value"
        [data]="data"
        [mapIds]="mapIds"
        (changeJournal)="changeJournal($event)"
        [isOperationalPlan]="isOperationalPlan"
      />
    </ng-container>
  </div>
</div>

<ng-template #modal></ng-template>
