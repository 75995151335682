<div class="print-header-root">
  <div class="print-header-title">
    <nguk-text weight="600" styleName="text-xl"
      >{{ isOP ? 'ОПЕРАТИВНЫЙ' : 'КАЛЕНДАРНЫЙ' }} ПЛАН</nguk-text
    >
    <nguk-text weight="600" styleName="text-sm">Обогатительная Фабрика Междуреченская </nguk-text>
  </div>

  <div class="print-header-logo">
    <mat-icon [svgIcon]="svgIconsEnum.NewMining" class="new-mining-logo"></mat-icon>
  </div>
</div>
