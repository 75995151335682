<nguk-page>
  <div class="calendar-plan-page-root">
    <calendar-plan-journal-list-short class="journal-list-short"></calendar-plan-journal-list-short>
    <router-outlet></router-outlet>
  </div>
</nguk-page>

<ng-template #headerPortalTemplateRef>{{
  isOperationalPlan ? 'ОПЕРАТИВНЫЙ ПЛАН' : 'КАЛЕНДАРНЫЙ ПЛАН'
}}</ng-template>
