import { EntityApi } from '../core/abstract/entity.api';
import { IDictionaryModel } from '../data-models/dictionary-model/dictionary-model.interface';
import { IEditDictionaryModel } from '../data-models/dictionary-model/edit-dictionary-model.interface';
import { Injectable } from '@angular/core';
import { ApiProvider } from '../core/providers/api.provider';

@Injectable({
  providedIn: 'root',
})
export class StationApiService extends EntityApi<IDictionaryModel, IEditDictionaryModel> {
  protected readonly api = 'dict/station';

  constructor(apiProvider: ApiProvider) {
    super(apiProvider);
  }
}
