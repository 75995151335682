<div class="result-mining-plan-list-root">
  <nguk-section>
    <div class="sticky-container" tabindex="0">
      <table
        mat-table
        [dataSource]="data"
        [class.table-padding]="true"
        multiTemplateDataRows
        #table
      >
        <ng-container [matColumnDef]="'empty'">
          <th
            mat-header-cell
            *matHeaderCellDef
            [attr.colspan]="columns.length"
            class="horizontal-alignment"
          ></th>
        </ng-container>

        <ng-container [matColumnDef]="'index-total'" [sticky]="true">
          <td mat-cell *matCellDef [attr.colspan]="columns.length">
            <nguk-text weight="400" styleName="text-xs" *ngIf="journal">
              {{ id ? 'Итого по ' + (isOperationalPlan ? 'часам' : 'дням') : '' }}
            </nguk-text>
          </td>
        </ng-container>

        <ng-container [matColumnDef]="'total-mark'">
          <td mat-cell *matCellDef="let row" [attr.colspan]="2">
            <nguk-text weight="400" styleName="text-xs" *ngIf="journal">
              {{ 'Итого по марке ' + (row.mark.title ?? '') }}
            </nguk-text>
          </td>
        </ng-container>

        <ng-container *ngIf="!isOperationalPlan; else operationalDate">
          <ng-container [matColumnDef]="'dateStarted'">
            <th
              mat-header-cell
              *matHeaderCellDef
              [attr.colspan]="days"
              class="horizontal-alignment"
            >
              <nguk-text weight="400" styleName="text-xs" *ngIf="journal"
                >График с {{ journal.datePlanStarted | date: 'dd.MM.yyyy' }}
              </nguk-text>
            </th>
          </ng-container>
        </ng-container>

        <ng-template #operationalDate>
          <ng-container *ngFor="let date of listDatesForOP; let i = index">
            <ng-container [matColumnDef]="'dateStarted' + (i + 1)">
              <th
                mat-header-cell
                *matHeaderCellDef
                class="horizontal-alignment position-center"
                [attr.colspan]="getAmountColsForDate(i + 1)"
              >
                <nguk-text weight="400" styleName="text-xs">
                  {{ getDate(i) }}
                </nguk-text>
              </th>
            </ng-container>
          </ng-container>
        </ng-template>

        <ng-container matColumnDef="horizontal-area" [sticky]="true">
          <td mat-cell *matCellDef="let row; let i = index" [attr.colspan]="3">
            <div
              class="horizontal-area"
              *ngIf="row.area"
              [class.margin-horizontal-area]="isOperationalPlan"
            >
              <nguk-text weight="700" styleName="text-xs">{{ row.area.title }}</nguk-text>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="horizontal-area-empty">
          <td
            mat-cell
            *matCellDef="let row; let i = index"
            [attr.colspan]="displayedColumns.length - 3"
          >
            <div class="horizontal-area -empty"></div>
          </td>
        </ng-container>

        <ng-container
          *ngFor="let item of columns; let i = index"
          [matColumnDef]="item.name"
          [sticky]="true"
        >
          <th mat-header-cell *matHeaderCellDef>
            <nguk-th-sort-filter
              [columnName]="item.name"
              [columnDisplay]="item.display"
              [rawData]="rawData"
            ></nguk-th-sort-filter>
          </th>

          <td
            mat-cell
            *matCellDef="let row; let ind = dataIndex; let renderIndex = renderIndex"
            [class.border-none]="ind | resultMiningPlanBorderHidden: data"
          >
            <nguk-text weight="400" styleName="text-xs">
              {{
                item.name === 'index'
                  ? getIndex(renderIndex, row)
                  : row[item.name]?.title || row[item.name]
              }}
            </nguk-text>
          </td>
        </ng-container>

        <ng-container [matColumnDef]="'total-row'">
          <th mat-header-cell *matHeaderCellDef>
            <nguk-text weight="600" styleName="text-xs"> Итого </nguk-text>
          </th>

          <td
            mat-cell
            *matCellDef="let row; let i = dataIndex"
            [class.border-none]="i | resultMiningPlanBorderHidden: data"
          >
            <nguk-text weight="600" styleName="text-xs">
              {{ row.total }}
            </nguk-text>
          </td>
        </ng-container>

        <ng-container [matColumnDef]="'amount-total-row'">
          <td mat-cell *matCellDef="let row">
            <nguk-text weight="600" styleName="text-xs">
              {{ getAmountTotalValue('total') }}
            </nguk-text>
          </td>
        </ng-container>

        <ng-container [matColumnDef]="'total-areaMark-row'">
          <td mat-cell *matCellDef="let row">
            <nguk-text weight="600" styleName="text-xs">
              {{ getAmountTotalValue(row.area.title + '_' + row.mark.title) }}
            </nguk-text>
          </td>
        </ng-container>

        <ng-container *ngFor="let item of columnsDays; let i = index" [matColumnDef]="item.name">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="day-header-cell"
            [class.day-firstShift]="
              isOperationalPlan && !(+item.display | operationalPlanColorShift)
            "
            [class.day-secondShift]="
              isOperationalPlan && (+item.display | operationalPlanColorShift)
            "
            [class.day-weekend]="
              !isOperationalPlan && (+item.display | ngukWeekendDayPipe: journal.datePlanStarted)
            "
          >
            <nguk-text
              weight="400"
              styleName="text-xs"
              class="opacity-text"
              *ngIf="!isOperationalPlan; else operationalDay"
              >{{ item.display }}
            </nguk-text>

            <ng-template #operationalDay>
              <nguk-text weight="400" styleName="text-xs" class="opacity-text"
                >{{ getHours(+item.display) }}
              </nguk-text>
            </ng-template>
          </th>
          <td
            mat-cell
            *matCellDef="let row; let ind = dataIndex"
            class="day-mat-cell"
            [class.day-weekend]="
              !isOperationalPlan && (+item.display | ngukWeekendDayPipe: journal.datePlanStarted)
            "
            [class.day-firstShift]="
              isOperationalPlan && !(+item.display | operationalPlanColorShift)
            "
            [class.day-secondShift]="
              isOperationalPlan && (+item.display | operationalPlanColorShift)
            "
            [class.border-none]="ind | resultMiningPlanBorderHidden: data"
            [class.bg-green]="row.key | resultMiningPlanColorCell: +item.display : mapKeyId"
            (dblclick)="openModal(row, item)"
          >
            <nguk-text [weight]="row.area === 'Итого' ? '600' : '400'" styleName="text-xs">
              {{
                row.key | resultMiningPlanValueCell: +item.display : mapKeyId : isOperationalPlan
              }}
            </nguk-text>
          </td>
        </ng-container>

        <ng-container
          *ngFor="let item of columnsDaysTotal; let i = index"
          [matColumnDef]="item.name"
        >
          <td
            mat-cell
            *matCellDef="let row; let ind = dataIndex"
            class="day-mat-cell"
            [class.day-firstShift]="
              isOperationalPlan && !(+item.display | operationalPlanColorShift)
            "
            [class.day-secondShift]="
              isOperationalPlan && (+item.display | operationalPlanColorShift)
            "
            [class.day-weekend]="
              !isOperationalPlan && (+item.display | ngukWeekendDayPipe: journal.datePlanStarted)
            "
          >
            <nguk-text weight="600" styleName="text-xs">
              {{ getTotalValue('total', i) }}
            </nguk-text>
          </td>
        </ng-container>

        <ng-container
          *ngFor="let item of columnsDaysTotalAreaMark; let i = index"
          [matColumnDef]="item.name"
        >
          <td
            (dblclick)="openModal(row, item, true)"
            mat-cell
            *matCellDef="let row; let ind = dataIndex"
            class="day-mat-cell"
            [class.day-firstShift]="
              isOperationalPlan && !(+item.display | operationalPlanColorShift)
            "
            [class.day-secondShift]="
              isOperationalPlan && (+item.display | operationalPlanColorShift)
            "
            [class.day-weekend]="
              !isOperationalPlan && (+item.display | ngukWeekendDayPipe: journal.datePlanStarted)
            "
          >
            <nguk-text weight="600" styleName="text-xs">
              {{ getTotalValue(row.area.title + '_' + row.mark.title, i) }}
            </nguk-text>
          </td>
        </ng-container>

        <ng-container *ngIf="!isOperationalPlan; else operationalDateRow">
          <tr mat-header-row *matHeaderRowDef="['empty', 'dateStarted']; sticky: true"></tr>
        </ng-container>

        <ng-template #operationalDateRow>
          <tr mat-header-row *matHeaderRowDef="displayedRowDatesForOP; sticky: true"></tr>
        </ng-template>
        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
          class="sticky-row"
        ></tr>

        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumnsTotal; let ind = dataIndex"
          class="total total-result"
          [class.hidden-total]="ind !== 0"
        ></tr>

        <tr
          mat-row
          *matRowDef="
            let row;
            columns: ['horizontal-area', 'horizontal-area-empty'];
            let i = dataIndex
          "
          [class.horizontal-area-hidden]="row | resultMiningPlanHorizontalLine: i : data"
        ></tr>
        ё

        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumnsTotalAreaMark; let i = dataIndex"
          class="total"
          [class.horizontal-area-hidden]="row | resultMiningPlanHorizontalTotalLine: i : data"
        ></tr>

        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </nguk-section>
</div>

<ng-template #modal></ng-template>
