import { Injectable } from '@angular/core';
import { TaskLogApiService } from '../../../api/task-log.api.service';
import { map, mergeMap, Observable } from 'rxjs';
import { ITaskLog } from '../../../data-models/task-log/task-log.interface';
import { FormControl } from '@angular/forms';
import { IEditTaskLog } from 'src/app/data-models/task-log/edit-create-task-log.interface';
import { CalculationLogApiService } from '../../../api/calculation-log.api.service';
import { ICalculationLog } from '../../../data-models/calculation-log/calculation-log.interface';

@Injectable()
export class CalculationsService {
  constructor(
    private taskLogApiService: TaskLogApiService,
    private calculationsApiService: CalculationLogApiService,
  ) {}

  getTaskLogs(): Observable<ITaskLog[]> {
    const query = [{ name: 'itemsPerPage', value: 50 * 100 }];

    return this.taskLogApiService.getList(1, query);
  }

  updateTaskLog(calculation: IEditTaskLog, noteControl: FormControl): Observable<ICalculationLog> {
    return this.getCalculationLog(calculation.calculationLog!.id).pipe(
      map((calculationLog: ICalculationLog) => {
        return {
          ...calculationLog,
          note: noteControl.value,
        };
      }),
      mergeMap((calculationLog) => this.calculationsApiService.update(calculationLog)),
    );
  }

  // createTaskLog(value: IEditTaskLog) {
  //   const body: IEditTaskLog = {};
  //   body.calculationLog = { id: value.calculationLog!.id, note: value.calculationLog!.note };

  //   return this.taskLogApiService.create(body);
  // }

  getCalculationLog(calculationLogId: string): Observable<ICalculationLog> {
    return this.calculationsApiService.getOne(calculationLogId);
  }

  check(): Observable<{ check: boolean }> {
    return this.taskLogApiService.check();
  }
}
