import { FormContext } from '../../../core/abstract/form.context';
import { FormControl, FormGroup } from '@angular/forms';
import { IDataInstance } from '../../../data-models/enrichment-standard/data-instance.interface';

export class NewNsiForm extends FormContext<{ table: string; note: string }> {
  static createForm(dataInstance: IDataInstance): NewNsiForm {
    const form = new FormGroup({
      table: new FormControl(null),
      note: new FormControl(dataInstance?.note),
    });

    return new NewNsiForm(form);
  }

  get table(): FormControl { return this.getControl('table') as FormControl; }
  get note(): FormControl { return this.getControl('note') as FormControl; }

  constructor(
    form: FormGroup,
  ) {
    super(form);
  }

  getFormData(): { table: string; note: string } {
    return this.form.value;
  }
}
