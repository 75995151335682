import { KeycloakService } from 'keycloak-angular';
import { environment } from '../../../environments/env';

export function appInitializeFactory(keycloak: KeycloakService): () => Promise<boolean> {
  return () =>
    keycloak.init({
      config: {
        url: environment.KC_ADDR,
        realm: environment.KC_REALM!,
        clientId: environment.KC_CLIENT_ID!,
      },
      initOptions: {
        redirectUri: `${window.location.origin}/dashboard`,
        checkLoginIframe: false,
      },
    });
}
