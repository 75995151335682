import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Page } from '../../../../ui/base/page';
import { ActivatedRoute } from '@angular/router';
import { PortalsService } from 'src/app/core/services/portals.service';

@Component({
  selector: 'calendar-plan',
  templateUrl: './calendar-plan.component.html',
  styleUrls: ['./calendar-plan.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarPlanComponent extends Page {
  isOperationalPlan = false;

  constructor(
    private route: ActivatedRoute,
    portalsService: PortalsService,
  ) {
    super(portalsService);
    this.isOperationalPlan = !!this.route.snapshot.data['isOperationalPlan'];
  }
}
