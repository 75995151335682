import { Pipe, PipeTransform } from '@angular/core';
import {
  IWarehouseDynamicsItem,
  IWarehouseDynamicsItemTransform,
} from 'src/app/data-models/warehouse-dynamics-item/warehouse-dynamics.interface';

@Pipe({
  name: 'warehouseDynamicsValueCell',
  standalone: true,
})
export class WarehouseDynamicsValueCellPipe implements PipeTransform {
  transform(
    row: IWarehouseDynamicsItemTransform,
    day: number,
    mapKeyId: Map<string, IWarehouseDynamicsItem[]>,
    isWagonDynamics: boolean,
  ): string {
    const isPathWagon = row.warehouse.title.toLocaleLowerCase().includes('путь') && isWagonDynamics;
    let value: number | undefined;

    if (day === 0) {
      value = mapKeyId.get(row.key + day)?.reduce((acc, item) => (+item.volume ?? 0) + acc, 0) ?? 0;
      const transformNumber = Math.round((value / (isWagonDynamics ? 1 : 1000)) * 100) / 100;
      return transformNumber > 0 ? transformNumber.toString() : '';
    } else {
      value =
        mapKeyId
          .get(row.key + day)
          ?.reduce(
            (acc, item) =>
              (item.lineType === (isPathWagon ? 'Пост' : 'Ост') ? +item.volume : 0) + acc,
            0,
          ) ?? 0;
    }

    const amountDynamics =
      mapKeyId.get(row.key + day)?.filter((item) => item.lineType !== 'Ост').length ?? 0;

    const balance = Math.round((value / (isWagonDynamics ? 1 : 1000)) * 100) / 100;

    let writeOffBalance = 0;

    if (balance === 0) {
      const currentWriteOff =
        mapKeyId
          .get(row.key + day)
          ?.reduce((acc, item) => (item.lineType === 'Спис' ? +item.volume : 0) + acc, 0) ?? 0;

      writeOffBalance = Math.round((currentWriteOff / (isWagonDynamics ? 1 : 1000)) * 100) / 100;
    }

    return isPathWagon
      ? balance
        ? balance.toString()
        : ''
      : !amountDynamics
        ? balance > 0
          ? '——'
          : ''
        : balance === 0
          ? writeOffBalance.toString()
          : balance.toString();
  }
}
