import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup } from '@angular/forms';
import { SvgIconsEnum } from 'src/app/core/enums/svg-icons.enum';
import { ModalActionsComponent } from 'src/app/modules/ui/components/modal-actions/modal-actions.component';
import { IMiningPlan } from 'src/app/data-models/mining-plan/mining-plan.interface';
import { ActionModal } from 'src/app/modules/ui/base/action-modal';
import { ModalMiningPlanArrayService } from 'src/app/modules/data/services/mining-plan/modal-mining-plan-array.service';
import { IMiningPlanVolume } from 'src/app/data-models/mining-plan-volume/mining-plan-volume.interface';

@Component({
  selector: 'modal-mining-plan-actions',
  templateUrl: './modal-mining-plan-actions.component.html',
  styleUrls: ['./modal-mining-plan-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ModalActionsComponent],
  providers: [ModalMiningPlanArrayService],
})
export class ModalMiningPlanActionsComponent extends ActionModal implements OnInit, AfterViewInit {
  @ViewChild('modalConfirm', { read: ViewContainerRef }) modalConfirm!: ViewContainerRef;

  @Input() formData!: FormGroup;
  @Input() data!: IMiningPlan[];
  @Input() toggleControl!: FormControl<string | null>;

  @Input() arrayListMiningPlanVolume!: IMiningPlanVolume[][];
  @Input() removeListIds!: number[];

  @Output() readonly close = new EventEmitter<void>();
  @Output() readonly updateData = new EventEmitter<void>();

  fieldsToSkip = ['id', 'dataInstance'];

  isCopy = false;

  readonly svgIconsEnum = SvgIconsEnum;
  readonly _array = Array;

  constructor(
    private cdr: ChangeDetectorRef,
    private modalMiningPlanArrayService: ModalMiningPlanArrayService,
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.modalContainer = this.modalConfirm;
  }

  checkIsChange(): boolean {
    if (this.isCopy) return false;

    if (this.data.length) {
      const amountIds = this.data.reduce((acc, item) => acc + (item.id ? +item.id : 0), 0);
      return amountIds !== 0;
    } else {
      return false;
    }
  }

  initModalMiningPlanServiceData(service: ModalMiningPlanArrayService): void {
    service.close = this.close;
    service.update = this.updateData;
    service.isCopy = this.isCopy;
    const amountIds = this.data.reduce((acc, item) => acc + (item.id ? +item.id : 0), 0);
    service.isChange = amountIds === 0;

    service.arrayListMiningPlanVolume = this.arrayListMiningPlanVolume;
    service.removeListIds = this.removeListIds;
    service.formData = this.formData;
    service.data = this.data;

    service.open = this.open.bind(this);
  }

  initActionsData(): void {
    this.initModalMiningPlanServiceData(this.modalMiningPlanArrayService);
  }

  ngOnInit(): void {
    this.toggleControl.valueChanges.subscribe((value) => {
      if (!value) return;
      this.isCopy = value.toLowerCase() === 'редактирование' ? false : true;
      this.cdr.detectChanges();
    });

    const currentIds = this.data.filter((item) => item.id).length;
    if (currentIds === 0) this.isCopy = true;
  }

  save(): void {
    this.initActionsData();
    this.modalMiningPlanArrayService.saveArray(this.data, this.formData, this.isCopy);
  }

  remove() {
    this.initActionsData();
    this.modalMiningPlanArrayService.removeArray(this.data);
  }
}
