import { IMockDataSelectResource } from './mockData.interface';

export const mockData: IMockDataSelectResource[] = [
  {
    type: 'Карьер «Междуречье»',
    generalWarehouse: 'M1_HUB',
    productWarehouse: '',
    area: 'M1_DSK',
    enrichmentTk1: 'M1_TK1',
    enrichmentTk2: 'M1_TK2',
    wagonsUFTracks: 'M1_ZDOF',
    wagonsLoadingTracks: '',
  },
  {
    type: 'Карьер «УК Южная»',
    generalWarehouse: 'M2_HUB',
    productWarehouse: '',
    area: 'M2_DSK',
    enrichmentTk1: 'M2_TK1',
    enrichmentTk2: 'M2_TK2',
    wagonsUFTracks: 'M2_ZDOF',
    wagonsLoadingTracks: '',
  },
  {
    type: 'Общий склад Рядового',
    generalWarehouse: '',
    productWarehouse: '',
    area: 'HUB_DSK',
    enrichmentTk1: 'HUB_TK1',
    enrichmentTk2: 'HUB_TK2',
    wagonsUFTracks: 'HUB_ZDOF',
    wagonsLoadingTracks: '',
  },
  {
    type: 'Общий склад Продукта',
    generalWarehouse: '',
    productWarehouse: '',
    area: 'SGP_DSK',
    enrichmentTk1: '',
    enrichmentTk2: '',
    wagonsUFTracks: 'SGP_ZDOF',
    wagonsLoadingTracks: '',
  },
  {
    type: 'Участок погрузки',
    generalWarehouse: '',
    productWarehouse: '',
    area: '',
    enrichmentTk1: '',
    enrichmentTk2: '',
    wagonsUFTracks: '',
    wagonsLoadingTracks: 'DSK_ZDSK',
  },
  {
    type: 'Обогащение на ТК1',
    generalWarehouse: '',
    productWarehouse: 'TK1_SGP',
    area: '',
    enrichmentTk1: '',
    enrichmentTk2: '',
    wagonsUFTracks: 'TK1_ZDOF',
    wagonsLoadingTracks: '',
  },
  {
    type: 'Обогащение на ТК2',
    generalWarehouse: '',
    productWarehouse: 'TK2_SGP',
    area: '',
    enrichmentTk1: '',
    enrichmentTk2: '',
    wagonsUFTracks: 'TK2_ZDOF',
    wagonsLoadingTracks: '',
  },
];
