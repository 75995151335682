import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { CalculationsListColumnsEnum } from '../../../enums/calculations-list-columns.enum';
import { CalculationsService } from '../../../services/calculations.service';
import { Observable, catchError, filter, finalize, take, takeWhile, tap, throwError } from 'rxjs';
import { ITaskLog } from '../../../../../data-models/task-log/task-log.interface';
import { ActionModal } from '../../../../ui/base/action-modal';
import { SvgIconsEnum } from '../../../../../core/enums/svg-icons.enum';
import { PopupComponent } from 'src/app/modules/ui/components/popup/popup.component';
import { SpinnerService } from '../../../../ui/services/spinner.service';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { TaskLogApiService } from 'src/app/api/task-log.api.service';
import { Router } from '@angular/router';
import { RoutesHelper } from 'src/app/helpers/routes.helper';
import { TabStateService } from 'src/app/core/services/tab-state.service';

@Component({
  selector: 'calculations-list',
  templateUrl: './calculations-list.component.html',
  styleUrls: ['./calculations-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalculationsListComponent extends ActionModal implements OnInit, AfterViewInit {
  @ViewChild('modal', { read: ViewContainerRef, static: true }) modal!: ViewContainerRef;

  readonly svgIconsEnum = SvgIconsEnum;
  readonly calculationsListColumnsEnum = CalculationsListColumnsEnum;
  readonly calculationsListColumns = [
    CalculationsListColumnsEnum.Index,
    CalculationsListColumnsEnum.Note,
    CalculationsListColumnsEnum.DateCreated,
    CalculationsListColumnsEnum.DateStarted,
    CalculationsListColumnsEnum.MaximumCalculationTime,
    CalculationsListColumnsEnum.DateFinished,
    CalculationsListColumnsEnum.IdLog,
    CalculationsListColumnsEnum.Status,
    CalculationsListColumnsEnum.ExternalWarnings,
    CalculationsListColumnsEnum.Actions,
  ];

  taskLogs: ITaskLog[] = [];

  plannerIds: string[] = [];

  constructor(
    private ss: SpinnerService,
    private cdr: ChangeDetectorRef,
    private calculationsService: CalculationsService,
    private http: HttpClient,
    private taskLogApiService: TaskLogApiService,
    private router: Router,
    private tabStateService: TabStateService,
  ) {
    super();
  }
  ngAfterViewInit(): void {
    this.modalContainer = this.modal;
  }

  ngOnInit(): void {
    this.getListItems();
  }

  checkCalculationStatuses(): void {
    this.ss.startSpinner();
    this.calculationsService
      .check()
      .pipe(
        catchError((err) => {
          this.ss.stopSpinner();
          this.openCalculationErrorModal();

          return throwError(err);
        }),
        filter((response: { check: boolean }) => response.check),
        tap(() => this.getListItems()),
        take(1),
      )
      .subscribe({ complete: () => this.ss.stopSpinner() });
  }

  openModalStatus(row: ITaskLog): void {
    if (!row.externalStatusText) return;

    const modal = this.open(PopupComponent);
    modal.instance.icon = this.svgIconsEnum.ErrorCalculationDone;
    modal.instance.title = 'Ошибка';
    modal.instance.isAutoClose = false;
    modal.instance.text = row.externalStatusText;
  }

  openCalculationErrorModal(): void {
    const modal = this.open(PopupComponent);
    modal.instance.icon = this.svgIconsEnum.ErrorCalculationDone;
    modal.instance.title = 'Сервер Решателя временно недоступен';
    modal.instance.text = 'Попробуйте повторить попытку позднее';
  }

  openModalWarning(row: ITaskLog): void {
    if (!row.externalWarnings?.length) return;

    const modal = this.open(PopupComponent);
    modal.instance.isAutoClose = false;
    modal.instance.listTitle = row.externalWarnings;
  }

  private openCalculationCanceledModal(): void {
    const modal = this.open(PopupComponent);
    modal.instance.icon = this.svgIconsEnum.CancelCalculationDone;
    modal.instance.title = 'Расчёт отменен';
  }

  private getListItems(): void {
    this.ss.startSpinner();
    this.calculationsService
      .getTaskLogs()
      .pipe(
        take(1),
        finalize(() => {
          this.ss.stopSpinner();
        }),
      )
      .subscribe(
        (taskLogs: ITaskLog[]) => {
          this.taskLogs = taskLogs.sort((lhs: ITaskLog, rhs: ITaskLog) =>
            moment(lhs.dateCreated).isBefore(moment(rhs.dateCreated)) ? 1 : -1,
          );
          this.getIdLogs();
          this.cdr.markForCheck();
        },
        (err) => {
          this.openCalculationErrorModal();
        },
      );
  }

  private getIdLogs(): void {
    this.plannerIds = this.taskLogs.map((item) => {
      const date = moment(item.dateCreated).utcOffset(0).format('YYYY-MM-DD-HH-mm-ss');
      return `Planner_${date}_${item.id}`;
    });
  }

  downloadFile(index: number, isResult = false): void {
    this.getJSON(index, isResult)
      .pipe(take(1))
      .subscribe((data) => {
        const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${this.taskLogs[index].longId ?? this.plannerIds[index]}${
          isResult ? '-result' : ''
        }.json`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      });
  }

  getJSON(index: number, isResult = false): Observable<any> {
    const urlFull = process.env['BACKEND_URL'] as string;
    const url = urlFull.slice(0, urlFull.length - 4);
    return this.http.get(
      `${url}/tmp/${this.taskLogs[index].longId ?? this.plannerIds[index]}${
        isResult ? '-result' : ''
      }.json`,
    );
  }

  reloadData(index: number): void {
    this.ss.startSpinner();
    this.taskLogApiService
      .reloadData(this.taskLogs[index].id!)
      .pipe(take(1), finalize(this.ss.stopSpinner))
      .subscribe(() => {
        this.getListItems();
      });
  }

  moveToJournal(row: ITaskLog): void {
    const isOP = !!row.operationalPlan?.id;
    const url = isOP ? RoutesHelper.DATA_OPERATIONAL_PLAN : RoutesHelper.DATA_CALENDAR_PLAN;
    const id = isOP ? row.operationalPlan?.id : row.calculationLog?.id;

    const voidTab = { typeJournal: '', id: '', path: '', dataInstanceId: '' };

    isOP
      ? this.tabStateService.setLastJournalOP(voidTab)
      : this.tabStateService.setLastJournalKP(voidTab);

    this.router.navigate([url, id ?? '']);
  }
}
