<nguk-modal [visible]="isVisible" [close]="closeModal.bind(this, false)">
  <div class="modal">
    <div class="modal-header">
      <div class="modal-header-info">
        <nguk-text weight="600" styleName="text-xl">
          {{
            currentRow && currentDay
              ? 'Ячейка ' + (currentRow + 1) + '-' + currentDay
              : data.length > 1
                ? 'Добавление строк'
                : 'Ячейка'
          }}
        </nguk-text>
      </div>

      <modal-cell-actions
        *ngIf="!isShow"
        class="modal-cell-actions"
        [data]="data"
        [formData]="formData"
        [removeIds]="removeIds"
        [dataInstance]="dataInstance"
        (close)="closeModal(true)"
        (updateData)="updateData()"
      />
    </div>

    <modal-cell-form
      [isShow]="isShow"
      [dataInstance]="dataInstance"
      (form)="getForm($event)"
      (removeIds)="getRemoveIds($event)"
      [data]="data"
      [resourceAvailabilityId]="resourceAvailabilityId"
    />
  </div>
</nguk-modal>
