import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormGroup,
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
  FormControl,
  FormArray,
} from '@angular/forms';
import { SvgIconsEnum } from 'src/app/core/enums/svg-icons.enum';
import { IDataInstance } from 'src/app/data-models/enrichment-standard/data-instance.interface';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TextComponent } from 'src/app/modules/ui/components/text/text.component';
import { InputComponent } from 'src/app/modules/forms/components/input/input.component';
import { DatepickerComponent } from 'src/app/modules/forms/components/datepicker/datepicker.component';
import * as moment from 'moment';
import { TextareaComponent } from 'src/app/modules/forms/components/textarea/textarea.component';

@Component({
  selector: 'modal-data-instances-list-short-form',
  templateUrl: './modal-data-instances-list-short-form.component.html',
  styleUrls: ['./modal-data-instances-list-short-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TextComponent,
    MatFormFieldModule,
    MatInputModule,
    InputComponent,
    DatepickerComponent,
    TextareaComponent,
  ],
})
export class ModalDataInstancesListShortFormComponent implements OnInit {
  formData!: FormGroup;

  arrayHiddenParamsDate = ['enrichmentStandard', 'warehouseBalance'];
  arrayHiddenParamsAmountDays = ['enrichmentStandard', 'warehouseBalance', 'wagonFleet'];
  showParamsDate = true;
  showParamsAmountDays = true;

  @Output() form = new EventEmitter<FormGroup>();
  @Input() data!: IDataInstance;
  @Input() typeDataInstances!: string;
  @Input() isCalculationComplete = false;

  readonly svgIconsEnum = SvgIconsEnum;

  arrNumber = Array.from(Array(31), (_, index) => index + 1);

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.initFormGroup();

    if (this.arrayHiddenParamsDate.includes(this.typeDataInstances)) {
      this.showParamsDate = false;
      this.formData.get('dateStarted')?.removeValidators(Validators.required);
    }

    if (this.arrayHiddenParamsAmountDays.includes(this.typeDataInstances)) {
      this.showParamsAmountDays = false;
      this.formData.get('amountDays')?.removeValidators([Validators.required, Validators.min(1)]);
    }
  }

  getControl(str: string): FormControl {
    return this.formData.get(str) as FormControl;
  }

  initAmountDays(): number {
    return moment(this.data.dateFinished).diff(moment(this.data.dateStarted), 'days');
  }

  getUTCDate(date: string | Date, isVoidTime = false) {
    const localDate = moment(date).utc();
    const localYear = localDate.year();
    const localMonth = localDate.month();
    const localDay = localDate.date();
    const localHours = isVoidTime ? 0 : localDate.hours();
    const localMinutes = isVoidTime ? 0 : localDate.minutes();
    const localSeconds = isVoidTime ? 0 : localDate.seconds();

    return new Date(localYear, localMonth, localDay, localHours, localMinutes, localSeconds);
  }

  initFormGroup(): void {
    this.formData = this.fb.group({
      dateCreated: [this.data?.dateCreated ?? new Date(), [Validators.required]],
      dateStarted: [
        this.data?.dateStarted
          ? this.getUTCDate(this.data.dateStarted)
          : this.getUTCDate(new Date(), true),
      ],
      dateFinished: [this.data?.dateFinished ?? this.getDefaultDateFinished()],
      amountDays: [this.data?.dateStarted && this.data?.dateFinished ? this.initAmountDays() : 1],
      note: [this.data?.note ?? null],
      type: [this.data?.type ?? this.typeDataInstances],
      id: [this.data?.id ?? null],
      calculationLog: this.fb.array([]),
    });

    this.initFormArray();

    if (this.isCalculationComplete) {
      this.formData.disable();
      this.formData.updateValueAndValidity();
    }

    this.form.emit(this.formData);
  }

  getDefaultDateFinished(): string {
    return moment(this.getUTCDate(new Date(), true))
      .utcOffset(0, true)
      .startOf('day')
      .add(1, 'days')
      .format();
  }

  initFormArray(): void {
    this.data?.calculationLog.forEach((item) => {
      const fg = this.fb.group({
        id: item.id,
      });
      (this.formData.get('calculationLog') as FormArray).push(fg);
    });
  }
}
