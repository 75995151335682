<form [formGroup]="formData" class="form">
  <div class="general-info">
    <div class="container-item">
      <div class="form-item">
        <div class="form-item__input">
          <nguk-autocomplete
            [control]="getControl('ordinaryCoalMark')"
            [options]="
              (dictionariesEnum.Mark
                | ngukDictionaryPipe: getControl('ordinaryCoalMark').value
                | async)!
            "
            [dictionary]="dictionariesEnum.Mark"
          >
            <ng-container label>
              <nguk-text color="grey" weight="500" styleName="text-sm">Марка</nguk-text>
            </ng-container>
          </nguk-autocomplete>
        </div>
      </div>

      <div class="form-item">
        <div class="form-item__input">
          <nguk-autocomplete
            [control]="getControl('ordinaryCoalMiningArea')"
            [options]="
              (dictionariesEnum.Area
                | ngukDictionaryPipe: getControl('ordinaryCoalMiningArea').value
                | async)!
            "
            [dictionary]="dictionariesEnum.Area"
          >
            <ng-container label>
              <nguk-text color="grey" weight="500" styleName="text-sm">Участок</nguk-text>
            </ng-container>
          </nguk-autocomplete>
        </div>
      </div>

      <div class="form-item">
        <div class="form-item__input">
          <nguk-autocomplete
            [control]="getControl('ordinaryCoalMiningLayer')"
            [options]="
              (dictionariesEnum.Layer
                | ngukDictionaryPipe: getControl('ordinaryCoalMiningLayer').value
                | async)!
            "
            [dictionary]="dictionariesEnum.Layer"
          >
            <ng-container label>
              <nguk-text color="grey" weight="500" styleName="text-sm">Пласт</nguk-text>
            </ng-container>
          </nguk-autocomplete>
        </div>
      </div>

      <div class="form-item">
        <div class="form-item__input">
          <nguk-autocomplete
            [control]="getControl('ordinaryCoalMiningProfile')"
            [options]="
              (dictionariesEnum.Profile
                | ngukDictionaryPipe: getControl('ordinaryCoalMiningProfile').value
                | async)!
            "
            [dictionary]="dictionariesEnum.Profile"
          >
            <ng-container label>
              <nguk-text color="grey" weight="500" styleName="text-sm">Профиль</nguk-text>
            </ng-container>
          </nguk-autocomplete>
        </div>
      </div>
    </div>

    <div class="vertical-line"></div>

    <div class="container-item">
      <div class="form-item">
        <nguk-text color="grey" weight="500" styleName="text-sm">Ad</nguk-text>
        <div class="form-item__input">
          <nguk-input-ctrl [control]="getControl('ordinaryCoalAd')"></nguk-input-ctrl>
        </div>
      </div>

      <div class="form-item">
        <nguk-text color="grey" weight="500" styleName="text-sm">Vd</nguk-text>
        <div class="form-item__input">
          <nguk-input-ctrl [control]="getControl('ordinaryCoalVd')"></nguk-input-ctrl>
        </div>
      </div>

      <div class="form-item">
        <nguk-text color="grey" weight="500" styleName="text-sm">Y</nguk-text>
        <div class="form-item__input">
          <nguk-input-ctrl [control]="getControl('ordinaryCoalY')"></nguk-input-ctrl>
        </div>
      </div>
    </div>
  </div>

  <div class="additional-info">
    <div class="additional-info__item additional-info__item-header">
      <div class="additional-info__item__param">
        <nguk-text color="grey" weight="400" styleName="text-sm">#</nguk-text>
      </div>
      <div class="additional-info__item__param">
        <nguk-text color="grey" weight="400" styleName="text-sm">Тип</nguk-text>
      </div>
      <div class="additional-info__item__param">
        <nguk-text color="grey" weight="400" styleName="text-sm">Марка</nguk-text>
      </div>

      <div class="additional-info__item__param">
        <nguk-text color="grey" weight="400" styleName="text-sm">Ad</nguk-text>
      </div>

      <div class="additional-info__item__param">
        <nguk-text color="grey" weight="400" styleName="text-sm">Kx</nguk-text>
      </div>
    </div>

    <ng-container *ngIf="isTk1; else TK2">
      <div class="additional-info__item" (click)="index = 1" [attr.role]="'none'">
        <div class="additional-info__item__param">
          <nguk-text weight="400" styleName="text-sm" class="additional-info__item__label"
            >1</nguk-text
          >
        </div>

        <div class="additional-info__item__param">
          <nguk-text weight="400" styleName="text-sm" class="additional-info__item__label"
            >Концентрат</nguk-text
          >
        </div>

        <div class="additional-info__item__param">
          <div class="additional-info__item__param__input">
            <nguk-autocomplete
              *ngIf="index === 1 || saveData; else tk1ConcentrateMark"
              [control]="getControl('tk1ConcentrateMark')"
              [options]="
                (dictionariesEnum.Mark
                  | ngukDictionaryPipe: getControl('tk1ConcentrateMark').value
                  | async)!
              "
              [dictionary]="dictionariesEnum.Mark"
            >
            </nguk-autocomplete>
            <ng-template #tk1ConcentrateMark>
              <nguk-text weight="400" styleName="text-sm" class="additional-info__item__label">{{
                getControl('tk1ConcentrateMark').value?.title || ''
              }}</nguk-text>
            </ng-template>
          </div>
        </div>

        <div class="additional-info__item__param">
          <div class="additional-info__item__param__input">
            <nguk-input-ctrl
              [control]="getControl('tk1ConcentrateAd')"
              *ngIf="index === 1 || saveData; else tk1ConcentrateAd"
            >
            </nguk-input-ctrl>
            <ng-template #tk1ConcentrateAd>
              <nguk-text weight="400" styleName="text-sm" class="additional-info__item__label">{{
                getControl('tk1ConcentrateAd').value
              }}</nguk-text>
            </ng-template>
          </div>
        </div>

        <div class="additional-info__item__param">
          <div class="additional-info__item__param__input">
            <nguk-input-ctrl
              [control]="getControl('tk1ConcentrateKx')"
              *ngIf="index === 1 || saveData; else tk1ConcentrateKx"
            ></nguk-input-ctrl>
            <ng-template #tk1ConcentrateKx>
              <nguk-text weight="400" styleName="text-sm" class="additional-info__item__label">{{
                getControl('tk1ConcentrateKx').value
              }}</nguk-text>
            </ng-template>
          </div>
        </div>
      </div>

      <div class="additional-info__item" (click)="index = 2" [attr.role]="'none'">
        <div class="additional-info__item__param">
          <nguk-text weight="400" styleName="text-sm" class="additional-info__item__label"
            >2</nguk-text
          >
        </div>

        <div class="additional-info__item__param">
          <nguk-text weight="400" styleName="text-sm" class="additional-info__item__label"
            >Отсев</nguk-text
          >
        </div>

        <div class="additional-info__item__param">
          <div class="additional-info__item__param__input">
            <nguk-autocomplete
              *ngIf="index === 2 || saveData; else tk1DropoutMark"
              [control]="getControl('tk1DropoutMark')"
              [options]="
                (dictionariesEnum.Mark
                  | ngukDictionaryPipe: getControl('tk1DropoutMark').value
                  | async)!
              "
              [dictionary]="dictionariesEnum.Mark"
            >
            </nguk-autocomplete>
            <ng-template #tk1DropoutMark>
              <nguk-text weight="400" styleName="text-sm" class="additional-info__item__label">{{
                getControl('tk1DropoutMark').value?.title || ''
              }}</nguk-text>
            </ng-template>
          </div>
        </div>

        <div class="additional-info__item__param">
          <div class="additional-info__item__param__input">
            <nguk-input-ctrl
              [control]="getControl('tk1DropoutAd')"
              *ngIf="index === 2 || saveData; else tk1DropoutAd"
            ></nguk-input-ctrl>
            <ng-template #tk1DropoutAd>
              <nguk-text weight="400" styleName="text-sm" class="additional-info__item__label">{{
                getControl('tk1DropoutAd').value
              }}</nguk-text>
            </ng-template>
          </div>
        </div>

        <div class="additional-info__item__param">
          <div class="additional-info__item__param__input">
            <nguk-input-ctrl
              [control]="getControl('tk1DropoutKx')"
              *ngIf="index === 2 || saveData; else tk1DropoutKx"
            ></nguk-input-ctrl>
            <ng-template #tk1DropoutKx>
              <nguk-text weight="400" styleName="text-sm" class="additional-info__item__label">{{
                getControl('tk1DropoutKx').value
              }}</nguk-text>
            </ng-template>
          </div>
        </div>
      </div>

      <div class="additional-info__item" (click)="index = 3" [attr.role]="'none'">
        <div class="additional-info__item__param">
          <nguk-text weight="400" styleName="text-sm" class="additional-info__item__label"
            >3</nguk-text
          >
        </div>

        <div class="additional-info__item__param">
          <nguk-text weight="400" styleName="text-sm" class="additional-info__item__label"
            >Шлам + ПП</nguk-text
          >
        </div>

        <div class="additional-info__item__param">
          <div class="additional-info__item__param__input">
            <nguk-autocomplete
              *ngIf="index === 3 || saveData; else tk1SludgeMark"
              [control]="getControl('tk1SludgeMark')"
              [options]="
                (dictionariesEnum.Mark
                  | ngukDictionaryPipe: getControl('tk1SludgeMark').value
                  | async)!
              "
              [dictionary]="dictionariesEnum.Mark"
            >
            </nguk-autocomplete>
            <ng-template #tk1SludgeMark>
              <nguk-text weight="400" styleName="text-sm" class="additional-info__item__label">{{
                getControl('tk1SludgeMark').value?.title || ''
              }}</nguk-text>
            </ng-template>
          </div>
        </div>

        <div class="additional-info__item__param">
          <div class="additional-info__item__param__input">
            <nguk-input-ctrl
              [control]="getControl('tk1SludgeAd')"
              *ngIf="index === 3 || saveData; else tk1SludgeAd"
            ></nguk-input-ctrl>
            <ng-template #tk1SludgeAd>
              <nguk-text weight="400" styleName="text-sm" class="additional-info__item__label">{{
                getControl('tk1SludgeAd').value
              }}</nguk-text>
            </ng-template>
          </div>
        </div>

        <div class="additional-info__item__param">
          <div class="additional-info__item__param__input">
            <nguk-input-ctrl
              [control]="getControl('tk1SludgeKx')"
              *ngIf="index === 3 || saveData; else tk1SludgeKx"
            ></nguk-input-ctrl>
            <ng-template #tk1SludgeKx>
              <nguk-text weight="400" styleName="text-sm" class="additional-info__item__label">{{
                getControl('tk1SludgeKx').value
              }}</nguk-text>
            </ng-template>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-template #TK2>
      <div class="additional-info__item" (click)="index = 1" [attr.role]="'none'">
        <div class="additional-info__item__param">
          <nguk-text weight="400" styleName="text-sm" class="additional-info__item__label"
            >1</nguk-text
          >
        </div>

        <div class="additional-info__item__param">
          <nguk-text weight="400" styleName="text-sm" class="additional-info__item__label"
            >Концентрат</nguk-text
          >
        </div>

        <div class="additional-info__item__param">
          <div class="additional-info__item__param__input">
            <nguk-autocomplete
              *ngIf="index === 1 || saveData; else tk2ConcentrateMark"
              [control]="getControl('tk2ConcentrateMark')"
              [options]="
                (dictionariesEnum.Mark
                  | ngukDictionaryPipe: getControl('tk2ConcentrateMark').value
                  | async)!
              "
              [dictionary]="dictionariesEnum.Mark"
            >
            </nguk-autocomplete>
            <ng-template #tk2ConcentrateMark>
              <nguk-text weight="400" styleName="text-sm" class="additional-info__item__label">{{
                getControl('tk2ConcentrateMark').value?.title || ''
              }}</nguk-text>
            </ng-template>
          </div>
        </div>

        <div class="additional-info__item__param">
          <div class="additional-info__item__param__input">
            <nguk-input-ctrl
              [control]="getControl('tk2ConcentrateAd')"
              *ngIf="index === 1 || saveData; else tk2ConcentrateAd"
            ></nguk-input-ctrl>
            <ng-template #tk2ConcentrateAd>
              <nguk-text weight="400" styleName="text-sm" class="additional-info__item__label">{{
                getControl('tk2ConcentrateAd').value
              }}</nguk-text>
            </ng-template>
          </div>
        </div>

        <div class="additional-info__item__param">
          <div class="additional-info__item__param__input">
            <nguk-input-ctrl
              [control]="getControl('tk2ConcentrateKx')"
              *ngIf="index === 1 || saveData; else tk2ConcentrateKx"
            ></nguk-input-ctrl>
            <ng-template #tk2ConcentrateKx>
              <nguk-text weight="400" styleName="text-sm" class="additional-info__item__label">{{
                getControl('tk2ConcentrateKx').value
              }}</nguk-text>
            </ng-template>
          </div>
        </div>
      </div>

      <div class="additional-info__item" (click)="index = 2" [attr.role]="'none'">
        <div class="additional-info__item__param -number">
          <nguk-text weight="400" styleName="text-sm" class="additional-info__item__label"
            >2</nguk-text
          >
        </div>

        <div class="additional-info__item__param">
          <nguk-text weight="400" styleName="text-sm" class="additional-info__item__label"
            >ПП</nguk-text
          >
        </div>

        <div class="additional-info__item__param">
          <div class="additional-info__item__param__input">
            <nguk-autocomplete
              *ngIf="index === 2 || saveData; else tk2IndustrialProductMark"
              [control]="getControl('tk2IndustrialProductMark')"
              [options]="
                (dictionariesEnum.Mark
                  | ngukDictionaryPipe: getControl('tk2IndustrialProductMark').value
                  | async)!
              "
              [dictionary]="dictionariesEnum.Mark"
            >
            </nguk-autocomplete>
            <ng-template #tk2IndustrialProductMark>
              <nguk-text weight="400" styleName="text-sm" class="additional-info__item__label">{{
                getControl('tk2IndustrialProductMark').value?.title || ''
              }}</nguk-text>
            </ng-template>
          </div>
        </div>

        <div class="additional-info__item__param">
          <div class="additional-info__item__param__input">
            <nguk-input-ctrl
              [control]="getControl('tk2IndustrialProductAd')"
              *ngIf="index === 2 || saveData; else tk2IndustrialProductAd"
            ></nguk-input-ctrl>
            <ng-template #tk2IndustrialProductAd>
              <nguk-text weight="400" styleName="text-sm" class="additional-info__item__label">{{
                getControl('tk2IndustrialProductAd').value
              }}</nguk-text>
            </ng-template>
          </div>
        </div>

        <div class="additional-info__item__param">
          <div class="additional-info__item__param__input">
            <nguk-input-ctrl
              [control]="getControl('tk2IndustrialProductKx')"
              *ngIf="index === 2 || saveData; else tk2IndustrialProductKx"
            ></nguk-input-ctrl>
            <ng-template #tk2IndustrialProductKx>
              <nguk-text weight="400" styleName="text-sm" class="additional-info__item__label">{{
                getControl('tk2IndustrialProductKx').value
              }}</nguk-text>
            </ng-template>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</form>
