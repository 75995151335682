import { Route } from '@angular/router';
import { RoutesHelper } from '../../helpers/routes.helper';
import { CalendarPlanComponent } from './components/pages/calendar-plan/calendar-plan.component';
import { MiningPlanListComponent } from '../data/components/parts/mining-plan/mining-plan-list/mining-plan-list.component';
import { WarehouseBalanceListComponent } from '../data/components/parts/warehouse-balance/warehouse-balance-list/warehouse-balance-list.component';
import { ShippingPlanListComponent } from '../data/components/parts/shipping-plan/shipping-plan-list/shipping-plan-list.component';
import { EnrichmentStandardListComponent } from '../data/components/parts/enrichment-standard/enrichment-standard-list/enrichment-standard-list.component';
import { ResultMiningPlanListComponent } from './components/parts/result-mining-plan-list/result-mining-plan-list.component';
import { ResultSupplyComponent } from './components/parts/result-supply/result-supply.component';
import { InternalLogisticsListComponent } from './components/parts/internal-logistics-list/internal-logistics-list.component';
import { WarehouseDynamicsComponent } from './components/parts/warehouse-dynamics/warehouse-dynamics.component';
import { CommodityShipmentListComponent } from './components/parts/commodity-shipment/commodity-shipment-list.component';
import { FundListComponent } from '../data/components/parts/fund/fund-list/fund-list.component';
import { WagonFleetListComponent } from '../data/components/parts/wagon-fleet/wagon-fleet-list/wagon-fleet-list.component';
import { NsiTableListTypeComponent } from '../nsi/components/parts/nsi-table-list-type/nsi-table-list-type.component';
import { NsiTableMatrixTypeComponent } from '../nsi/components/parts/nsi-table-matrix-type/nsi-table-matrix-type.component';
import { GraphsComponent } from './components/parts/graphs/graphs.component';
import { GanttComponent } from './components/parts/gantt/gantt.component';
import { OperationalCommodityShipmentListComponent } from './components/parts/operational-commodity-shipment/operational-commodity-shipment-list/operational-commodity-shipment-list.component';

export const routes: Route[] = [
  {
    path: RoutesHelper.EMPTY_PATH,
    component: CalendarPlanComponent,
    children: [
      {
        path: RoutesHelper.DATA_SHIPPING_PLAN_PATH,
        loadComponent: () => ShippingPlanListComponent,
      },
      {
        path: RoutesHelper.DATA_SHIPPING_PLAN_URL,
        loadComponent: () => ShippingPlanListComponent,
      },
      {
        path: RoutesHelper.DATA_WAREHOUSE_BALANCE,
        loadComponent: () => WarehouseBalanceListComponent,
      },
      {
        path: RoutesHelper.DATA_WAREHOUSE_BALANCE_URL,
        loadComponent: () => WarehouseBalanceListComponent,
      },
      {
        path: RoutesHelper.DATA_MINING_PLAN,
        loadComponent: () => MiningPlanListComponent,
      },
      {
        path: RoutesHelper.DATA_MINING_PLAN_URL,
        loadComponent: () => MiningPlanListComponent,
      },
      {
        path: RoutesHelper.DATA_ENRICHMENT_STANDARD,
        loadComponent: () => EnrichmentStandardListComponent,
      },
      {
        path: RoutesHelper.DATA_ENRICHMENT_STANDARD_URL,
        loadComponent: () => EnrichmentStandardListComponent,
      },
      {
        path: RoutesHelper.DATA_CALENDAR_PLAN_RESULT_MINING_PLAN,
        loadComponent: () => ResultMiningPlanListComponent,
      },
      {
        path: RoutesHelper.DATA_CALENDAR_PLAN_RESULT_MINING_PLAN_URL,
        loadComponent: () => ResultMiningPlanListComponent,
      },
      {
        path: RoutesHelper.DATA_CALENDAR_PLAN_WAREHOUSE_DYNAMICS,
        loadComponent: () => WarehouseDynamicsComponent,
      },
      {
        path: RoutesHelper.DATA_CALENDAR_PLAN_WAREHOUSE_DYNAMICS_URL,
        loadComponent: () => WarehouseDynamicsComponent,
      },
      {
        path: RoutesHelper.DATA_CALENDAR_PLAN_RESULT_SUPPLY,
        loadComponent: () => ResultSupplyComponent,
      },
      {
        path: RoutesHelper.DATA_CALENDAR_PLAN_RESULT_SUPPLY_URL,
        loadComponent: () => ResultSupplyComponent,
      },
      {
        path: RoutesHelper.PLAN_INTERNAL_LOGISTICS_PATH,
        loadComponent: () => InternalLogisticsListComponent,
      },
      {
        path: RoutesHelper.PLAN_INTERNAL_LOGISTICS_URL,
        loadComponent: () => InternalLogisticsListComponent,
      },
      {
        path: RoutesHelper.PLAN_COMMODITY_SHIPMENT_PATH,
        loadComponent: () => CommodityShipmentListComponent,
      },
      {
        path: RoutesHelper.PLAN_COMMODITY_SHIPMENT_URL,
        loadComponent: () => CommodityShipmentListComponent,
      },
      {
        path: RoutesHelper.PLAN_OPERATIONAL_COMMODITY_SHIPMENT_PATH,
        loadComponent: () => OperationalCommodityShipmentListComponent,
      },
      {
        path: RoutesHelper.PLAN_OPERATIONAL_COMMODITY_SHIPMENT_URL,
        loadComponent: () => OperationalCommodityShipmentListComponent,
      },
      {
        path: RoutesHelper.DATA_FUND,
        loadComponent: () => FundListComponent,
      },
      {
        path: RoutesHelper.DATA_FUND_URL,
        loadComponent: () => FundListComponent,
      },
      {
        path: RoutesHelper.NSI_LIST_URL,
        loadComponent: () => NsiTableListTypeComponent,
      },
      {
        path: RoutesHelper.NSI_MATRIX_URL,
        loadComponent: () => NsiTableMatrixTypeComponent,
      },
      {
        path: RoutesHelper.PLAN_WAGON_DYNAMICS_URL,
        loadComponent: () => WarehouseDynamicsComponent,
        data: {
          isWagonDynamics: true,
        },
      },
      {
        path: RoutesHelper.PLAN_WAGON_DYNAMICS_PATH,
        loadComponent: () => WarehouseDynamicsComponent,
        data: {
          isWagonDynamics: true,
        },
      },
      {
        path: RoutesHelper.DATA_WAGON_FLEET,
        loadComponent: () => WagonFleetListComponent,
      },
      {
        path: RoutesHelper.DATA_WAGON_FLEET_URL,
        loadComponent: () => WagonFleetListComponent,
      },
      {
        path: RoutesHelper.DATA_CALENDAR_PLAN_RESULT_GRAPHS,
        loadComponent: () => GraphsComponent,
      },
      {
        path: RoutesHelper.PLAN_TACTS_PATH,
        loadComponent: () => GanttComponent,
      },
      {
        path: RoutesHelper.PLAN_TACTS_URL,
        loadComponent: () => GanttComponent,
      },
    ],
  },
];
