import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'ngukWeekendDayPipe',
  standalone: true,
})
export class WeekendDayPipe implements PipeTransform {
  transform(numberOfDay: number, startDay: string): boolean {
    const day = moment(startDay)
      .add(numberOfDay - 1, 'days')
      .day();
    return day === 0 || day === 6;
  }
}
