<div class="current-user">
  <ng-container *ngIf="authService.user as user">
    <div class="user-data">
      <nguk-text class="user-data__name" weight="400" styleName="text-xs">
        {{ user.lastName }} {{ user.firstName!.charAt(0) | uppercase }}.
      </nguk-text>
      <nguk-text class="user-data__role" weight="500" styleName="text-xs">
        {{ userRole }}
      </nguk-text>
    </div>
    <mat-icon class="exit-icon" [svgIcon]="svgIconsEnum.ExitIcon" (click)="logout.emit()" />
  </ng-container>
</div>
