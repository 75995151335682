import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { IWagonLoaded } from 'src/app/data-models/wagon-loaded/wagon-loaded.interface';

@Injectable({
  providedIn: 'root',
})
export class WagonFleetUnderLoadingFormService {
  constructor(private fb: FormBuilder) {}

  createColumnTact(
    formData: FormArray,
    wagonFleetId: string,
    value: IWagonLoaded | null,
    index?: number,
  ): FormGroup {
    const item = value || (index || index === 0 ? (formData.value[index] as IWagonLoaded) : null);

    return this.fb.group({
      id: [typeof index === 'number' ? null : item?.id ?? null],
      wagonFleet: this.fb.group({
        id: [wagonFleetId],
      }),
      tact: [item?.tact ?? null],
      productionModeTK1: this.fb.array(
        item?.productionModeTK1.map((fg) =>
          this.fb.group({
            id: [fg?.id ?? null],
            title: [fg?.title ?? null],
          }),
        ) ?? [],
      ),
      markSGP9: this.fb.array(
        item?.markSGP9.map((fg) =>
          this.fb.group({
            id: [fg?.id ?? null],
            title: [fg?.title ?? null],
          }),
        ) ?? [],
      ),
      markSGP11: this.fb.array(
        item?.markSGP11.map((fg) =>
          this.fb.group({
            id: [fg?.id ?? null],
            title: [fg?.title ?? null],
          }),
        ) ?? [],
      ),
      markKL10: this.fb.array(
        item?.markKL10.map((fg) =>
          this.fb.group({
            id: [fg?.id ?? null],
            title: [fg?.title ?? null],
          }),
        ) ?? [],
      ),
      customerTK1516Row1: this.fb.array(
        item?.customerTK1516Row1.map((fg) =>
          this.fb.group({
            id: [fg?.id ?? null],
            title: [fg?.title ?? null],
          }),
        ) ?? [],
      ),
      customerTK1516Row2: this.fb.array(
        item?.customerTK1516Row2.map((fg) =>
          this.fb.group({
            id: [fg?.id ?? null],
            title: [fg?.title ?? null],
          }),
        ) ?? [],
      ),
      customerTK1516Row3: this.fb.array(
        item?.customerTK1516Row3.map((fg) =>
          this.fb.group({
            id: [fg?.id ?? null],
            title: [fg?.title ?? null],
          }),
        ) ?? [],
      ),
      wagonTypeTK1516Row1: this.fb.array(
        item?.wagonTypeTK1516Row1.map((fg) =>
          this.fb.group({
            id: [fg?.id ?? null],
            title: [fg?.title ?? null],
          }),
        ) ?? [],
      ),
      wagonTypeTK1516Row2: this.fb.array(
        item?.wagonTypeTK1516Row2.map((fg) =>
          this.fb.group({
            id: [fg?.id ?? null],
            title: [fg?.title ?? null],
          }),
        ) ?? [],
      ),
      wagonTypeTK1516Row3: this.fb.array(
        item?.wagonTypeTK1516Row3.map((fg) =>
          this.fb.group({
            id: [fg?.id ?? null],
            title: [fg?.title ?? null],
          }),
        ) ?? [],
      ),
      wagonAmountTK1516Row1: [item?.wagonAmountTK1516Row1 ?? null],
      wagonAmountTK1516Row2: [item?.wagonAmountTK1516Row2 ?? null],
      wagonAmountTK1516Row3: [item?.wagonAmountTK1516Row3 ?? null],
      customerTK1213Row1: this.fb.array(
        item?.customerTK1213Row1.map((fg) =>
          this.fb.group({
            id: [fg?.id ?? null],
            title: [fg?.title ?? null],
          }),
        ) ?? [],
      ),
      customerTK1213Row2: this.fb.array(
        item?.customerTK1213Row2.map((fg) =>
          this.fb.group({
            id: [fg?.id ?? null],
            title: [fg?.title ?? null],
          }),
        ) ?? [],
      ),
      customerTK1213Row3: this.fb.array(
        item?.customerTK1213Row3.map((fg) =>
          this.fb.group({
            id: [fg?.id ?? null],
            title: [fg?.title ?? null],
          }),
        ) ?? [],
      ),
      wagonTypeTK1213Row1: this.fb.array(
        item?.wagonTypeTK1213Row1.map((fg) =>
          this.fb.group({
            id: [fg?.id ?? null],
            title: [fg?.title ?? null],
          }),
        ) ?? [],
      ),
      wagonTypeTK1213Row2: this.fb.array(
        item?.wagonTypeTK1213Row2.map((fg) =>
          this.fb.group({
            id: [fg?.id ?? null],
            title: [fg?.title ?? null],
          }),
        ) ?? [],
      ),
      wagonTypeTK1213Row3: this.fb.array(
        item?.wagonTypeTK1213Row3.map((fg) =>
          this.fb.group({
            id: [fg?.id ?? null],
            title: [fg?.title ?? null],
          }),
        ) ?? [],
      ),
      wagonAmountTK1213Row1: [item?.wagonAmountTK1213Row1 ?? null],
      wagonAmountTK1213Row2: [item?.wagonAmountTK1213Row2 ?? null],
      wagonAmountTK1213Row3: [item?.wagonAmountTK1213Row3 ?? null],
      customerSGP9Row1: this.fb.array(
        item?.customerSGP9Row1.map((fg) =>
          this.fb.group({
            id: [fg?.id ?? null],
            title: [fg?.title ?? null],
          }),
        ) ?? [],
      ),
      customerSGP9Row2: this.fb.array(
        item?.customerSGP9Row2.map((fg) =>
          this.fb.group({
            id: [fg?.id ?? null],
            title: [fg?.title ?? null],
          }),
        ) ?? [],
      ),
      customerSGP9Row3: this.fb.array(
        item?.customerSGP9Row3.map((fg) =>
          this.fb.group({
            id: [fg?.id ?? null],
            title: [fg?.title ?? null],
          }),
        ) ?? [],
      ),
      wagonTypeSGP9Row1: this.fb.array(
        item?.wagonTypeSGP9Row1.map((fg) =>
          this.fb.group({
            id: [fg?.id ?? null],
            title: [fg?.title ?? null],
          }),
        ) ?? [],
      ),
      wagonTypeSGP9Row2: this.fb.array(
        item?.wagonTypeSGP9Row2.map((fg) =>
          this.fb.group({
            id: [fg?.id ?? null],
            title: [fg?.title ?? null],
          }),
        ) ?? [],
      ),
      wagonTypeSGP9Row3: this.fb.array(
        item?.wagonTypeSGP9Row3.map((fg) =>
          this.fb.group({
            id: [fg?.id ?? null],
            title: [fg?.title ?? null],
          }),
        ) ?? [],
      ),
      wagonAmountSGP9Row1: [item?.wagonAmountSGP9Row1 ?? null],
      wagonAmountSGP9Row2: [item?.wagonAmountSGP9Row2 ?? null],
      wagonAmountSGP9Row3: [item?.wagonAmountSGP9Row3 ?? null],
      customerSGP11Row1: this.fb.array(
        item?.customerSGP11Row1.map((fg) =>
          this.fb.group({
            id: [fg?.id ?? null],
            title: [fg?.title ?? null],
          }),
        ) ?? [],
      ),
      customerSGP11Row2: this.fb.array(
        item?.customerSGP11Row2.map((fg) =>
          this.fb.group({
            id: [fg?.id ?? null],
            title: [fg?.title ?? null],
          }),
        ) ?? [],
      ),
      customerSGP11Row3: this.fb.array(
        item?.customerSGP11Row3.map((fg) =>
          this.fb.group({
            id: [fg?.id ?? null],
            title: [fg?.title ?? null],
          }),
        ) ?? [],
      ),
      wagonTypeSGP11Row1: this.fb.array(
        item?.wagonTypeSGP11Row1.map((fg) =>
          this.fb.group({
            id: [fg?.id ?? null],
            title: [fg?.title ?? null],
          }),
        ) ?? [],
      ),
      wagonTypeSGP11Row2: this.fb.array(
        item?.wagonTypeSGP11Row2.map((fg) =>
          this.fb.group({
            id: [fg?.id ?? null],
            title: [fg?.title ?? null],
          }),
        ) ?? [],
      ),
      wagonTypeSGP11Row3: this.fb.array(
        item?.wagonTypeSGP11Row3.map((fg) =>
          this.fb.group({
            id: [fg?.id ?? null],
            title: [fg?.title ?? null],
          }),
        ) ?? [],
      ),
      wagonAmountSGP11Row1: [item?.wagonAmountSGP11Row1 ?? null],
      wagonAmountSGP11Row2: [item?.wagonAmountSGP11Row2 ?? null],
      wagonAmountSGP11Row3: [item?.wagonAmountSGP11Row3 ?? null],
      customerKL10Row1: this.fb.array(
        item?.customerKL10Row1.map((fg) =>
          this.fb.group({
            id: [fg?.id ?? null],
            title: [fg?.title ?? null],
          }),
        ) ?? [],
      ),
      customerKL10Row2: this.fb.array(
        item?.customerKL10Row2.map((fg) =>
          this.fb.group({
            id: [fg?.id ?? null],
            title: [fg?.title ?? null],
          }),
        ) ?? [],
      ),
      customerKL10Row3: this.fb.array(
        item?.customerKL10Row3.map((fg) =>
          this.fb.group({
            id: [fg?.id ?? null],
            title: [fg?.title ?? null],
          }),
        ) ?? [],
      ),
      wagonTypeKL10Row1: this.fb.array(
        item?.wagonTypeKL10Row1.map((fg) =>
          this.fb.group({
            id: [fg?.id ?? null],
            title: [fg?.title ?? null],
          }),
        ) ?? [],
      ),
      wagonTypeKL10Row2: this.fb.array(
        item?.wagonTypeKL10Row2.map((fg) =>
          this.fb.group({
            id: [fg?.id ?? null],
            title: [fg?.title ?? null],
          }),
        ) ?? [],
      ),
      wagonTypeKL10Row3: this.fb.array(
        item?.wagonTypeKL10Row3.map((fg) =>
          this.fb.group({
            id: [fg?.id ?? null],
            title: [fg?.title ?? null],
          }),
        ) ?? [],
      ),
      wagonAmountKL10Row1: [item?.wagonAmountKL10Row1 ?? null],
      wagonAmountKL10Row2: [item?.wagonAmountKL10Row2 ?? null],
      wagonAmountKL10Row3: [item?.wagonAmountKL10Row3 ?? null],
    });
  }
}
