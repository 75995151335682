import { EntityApi } from '../core/abstract/entity.api';
import { Injectable } from '@angular/core';
import { ApiProvider } from '../core/providers/api.provider';
import { DictionaryRowModel } from '../data-models/dictionary/dictionary-row.model';
import { EditDictionaryRowModel } from '../data-models/dictionary/edit-dictionary-row.model';

@Injectable({
  providedIn: 'root'
})
export class DictionaryRowApiService extends EntityApi<DictionaryRowModel, EditDictionaryRowModel> {
  protected readonly api = 'sourceData/dictionaryRow';

  constructor(apiProvider: ApiProvider) {
    super(apiProvider);
  }
}
