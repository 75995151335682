import { EntityApi } from '../core/abstract/entity.api';
import { Injectable } from '@angular/core';
import { ApiProvider } from '../core/providers/api.provider';
import { ICalculationLog } from '../data-models/calculation-log/calculation-log.interface';

@Injectable({
  providedIn: 'root',
})
export class CalculationLogApiService extends EntityApi<ICalculationLog, ICalculationLog> {
  protected readonly api = 'result/calculationLog';

  constructor(apiProvider: ApiProvider) {
    super(apiProvider);
  }
}
