import { Pipe, PipeTransform } from '@angular/core';
import { TShippingPlanListRow } from '../../types/shipping-plan-list-row.type';

@Pipe({
  name: 'shippingPlanCustomerName',
  standalone: true,
})
export class ShippingPlanCustomerNamePipe implements PipeTransform {
  transform(
    columnName: string,
    value: string,
    index: number,
    data: TShippingPlanListRow[],
  ): string {
    if (columnName !== 'customer' || !data) {
      return value;
    }

    if (index === 0) {
      return value;
    }

    return data[index].customer === data[index - 1].customer ? '' : value;
  }
}
