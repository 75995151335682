<nguk-modal [visible]="isVisible" [close]="closeModal.bind(this, false)">
  <div class="modal">
    <div class="modal-header" *ngIf="!isShow">
      <div class="modal-header-info">
        <nguk-text weight="600" styleName="text-xl" *ngIf="data.length <= 1">
          {{ data.length === 0 ? 'Создание новой записи' : currentData }}
        </nguk-text>

        <nguk-toggle
          [toggleList]="toggleList"
          [control]="toggleControl"
          *ngIf="isVisibleToggle()"
        ></nguk-toggle>
      </div>

      <modal-mining-plan-actions
        class="modal-mining-plan-actions"
        [toggleControl]="toggleControl"
        [data]="data"
        [formData]="formData"
        [arrayListMiningPlanVolume]="arrayListMiningPlanVolume"
        [removeListIds]="removeListIds"
        (close)="closeModal(true)"
        (updateData)="updateData()"
      ></modal-mining-plan-actions>
    </div>

    <modal-mining-plan-form
      [isShow]="isShow"
      [dataInstance]="dataInstance"
      (form)="getForm($event)"
      [data]="data"
      (setArrayListMiningPlanVolume)="getArrayListMiningPlanVolume($event)"
      (setRemoveListIds)="getRemoveListIds($event)"
    ></modal-mining-plan-form>
  </div>
</nguk-modal>
