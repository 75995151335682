import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { IDataInstance } from 'src/app/data-models/enrichment-standard/data-instance.interface';

@Pipe({
  name: 'minMaxDate',
  standalone: true,
})
export class MinMaxDatePipe implements PipeTransform {
  transform(rawDate: Date | undefined, dataInstance: IDataInstance[], isMin: boolean): boolean {
    if (!rawDate) return false;

    const date = moment(rawDate).format();

    if (isMin) {
      return dataInstance.every((item) =>
        item.dateStarted ? moment(date).diff(moment(item.dateStarted), 'days') >= 0 : true,
      );
    } else {
      return dataInstance.every((item) =>
        item.dateFinished ? moment(item.dateFinished).diff(moment(date), 'days') >= 0 : true,
      );
    }
  }
}
