<nguk-modal [visible]="isVisible" [close]="closeModal.bind(this, false)">
  <div class="modal">
    <div class="modal-header">
      <nguk-text weight="600" styleName="text-xl" *ngIf="!data.length && !isShow"
        >Создание</nguk-text
      >

      <nguk-toggle
        [toggleList]="toggleList"
        [control]="toggleControl"
        *ngIf="data.length && !isShow"
      ></nguk-toggle>

      <modal-warehouse-balance-items-actions
        class="modal-warehouse-balance-items-actions"
        [toggleControl]="toggleControl"
        [data]="data"
        [formData]="formData"
        [dataInstance]="dataInstance"
        (close)="closeModal(true)"
        (updateData)="updateData()"
        *ngIf="!isShow"
      ></modal-warehouse-balance-items-actions>
    </div>

    <modal-warehouse-balance-form
      (form)="getForm($event)"
      [data]="data"
      [isShow]="isShow"
      [columns]="columns"
      [dataInstance]="dataInstance"
    ></modal-warehouse-balance-form>
  </div>
</nguk-modal>
