<div class="result-mining-plan-list-root">
  <nguk-section>
    <div class="sticky-container" tabindex="0">
      <table
        mat-table
        #table
        [dataSource]="data"
        [class.table-padding]="true"
        multiTemplateDataRows
      >
        <ng-container [matColumnDef]="'empty'">
          <th
            mat-header-cell
            *matHeaderCellDef
            [attr.colspan]="columns.length"
            class="horizontal-alignment"
          ></th>
        </ng-container>

        <ng-container *ngFor="let date of listDatesForOP; let i = index">
          <ng-container [matColumnDef]="'dateStarted' + (i + 1)">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="horizontal-alignment position-center"
              [attr.colspan]="getAmountColsForDate(i + 1)"
            >
              <nguk-text weight="400" styleName="text-xs">
                {{ getDate(i) }}
              </nguk-text>
            </th>
          </ng-container>
        </ng-container>

        <ng-container [matColumnDef]="'resource'" [sticky]="true">
          <th mat-header-cell *matHeaderCellDef [attr.colspan]="1">
            <nguk-text weight="600" styleName="text-xs"> </nguk-text>
          </th>

          <td
            mat-cell
            *matCellDef="let row; let index = dataIndex"
            [class.hidden-bottom-border]="!isHasBottomBorder(index)"
            [class.main-cell]="getResourceLabel(index)"
            [class.reserve-cell]="row.recordType === 'Резерв'"
            [attr.colspan]="1"
          >
            <nguk-text weight="400" styleName="text-xs">
              {{ getResourceLabel(index) }}
            </nguk-text>
          </td>
        </ng-container>

        <ng-container [matColumnDef]="'differentValue'" [sticky]="true">
          <th mat-header-cell *matHeaderCellDef>
            <nguk-text weight="400" styleName="text-xs"> Ресурс </nguk-text>
          </th>

          <td mat-cell *matCellDef="let row">
            <nguk-text weight="400" styleName="text-xs">
              {{
                row.recordType === 'Заказ' || row.recordType === 'Резерв'
                  ? row.booking
                  : row.recordType === 'На СГП'
                    ? 'Думпкары'
                    : row.recordType ?? ''
              }}
            </nguk-text>
          </td>
        </ng-container>

        <ng-container *ngFor="let item of columnsHours; let i = index" [matColumnDef]="item.name">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="day-header-cell"
            [class.day-firstShift]="!(+item.display | operationalPlanColorShift)"
            [class.day-secondShift]="+item.display | operationalPlanColorShift"
          >
            <nguk-text weight="400" styleName="text-xs" class="opacity-text"
              >{{ getHours(+item.display) }}
            </nguk-text>
          </th>

          <td
            mat-cell
            *matCellDef="let row; let ind = dataIndex"
            class="day-mat-cell"
            [class.hidden]="checkIsHidden()"
            [class.last-cell]="isLastCell(row, item.display)"
            [attr.colspan]="getAmountCols(row, item.display)"
          >
            <div
              class="container-text"
              [class.day-firstShift]="getValueInHour(row, item.display) && !(ind % 2)"
              [class.day-secondShift]="getValueInHour(row, item.display) && ind % 2"
            >
              <nguk-text styleName="text-xs">
                {{ getValueInHour(row, item.display) }}
              </nguk-text>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedRowDatesForOP; sticky: true"></tr>
        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
          class="sticky-row"
        ></tr>

        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </nguk-section>
</div>
