import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextComponent } from 'src/app/modules/ui/components/text/text.component';
import { HeaderUserComponent } from '../header-user/header-user.component';
import { MatIconModule } from '@angular/material/icon';
import { SvgIconsEnum } from '../../../../core/enums/svg-icons.enum';
import { NavigationComponent } from '../navigation/navigation.component';
import { LogoComponent } from 'src/app/modules/ui/components/logo/logo.component';
import { ActionModal } from '../../../ui/base/action-modal';
import { LogoutConfirmationModalComponent } from '../logout-confirmation-modal/logout-confirmation-modal.component';
import { takeWhile } from 'rxjs';
import { AuthService } from '../../../../core/services/auth.service';

@Component({
  selector: 'nguk-authorized-layout-header',
  templateUrl: './authorized-layout-header.component.html',
  styleUrls: ['./authorized-layout-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TextComponent,
    HeaderUserComponent,
    MatIconModule,
    NavigationComponent,
    LogoComponent,
  ],
})
export class AuthorizedLayoutHeaderComponent extends ActionModal {
  @Output() readonly toggleSidebarState = new EventEmitter<void>();
  @ViewChild('modal', { read: ViewContainerRef, static: true }) modal!: ViewContainerRef;
  @HostBinding('class.authorized-layout-header') private baseClass = true;

  readonly svgIconsEnum = SvgIconsEnum;

  constructor(private authService: AuthService) {
    super();
  }

  openLogoutConfirmationModal = (): void => {
    this.modalContainer = this.modal;
    let isAlive = true;
    const modal = this.open(LogoutConfirmationModalComponent);
    modal.instance.openSubject
      .pipe(takeWhile(() => isAlive))
      .subscribe((isLogoutConfirmed: boolean | null) => {
        isAlive = false;
        if (!isLogoutConfirmed) {
          return;
        }

        this.logout();
      });
  }

  private logout(): void {
    this.authService.logout();
  }
}
