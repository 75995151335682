import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TextComponent } from 'src/app/modules/ui/components/text/text.component';
import { IDataColumnTable } from 'src/app/data-models/data-column-table/data-column-table.interface';
import { IWarehouseBalanceItem } from 'src/app/data-models/enrichment-standard/warehouse-balance.interface';
import { InputComponent } from 'src/app/modules/forms/components/input/input.component';
import { AutocompleteComponent } from 'src/app/modules/forms/components/autocomplete/autocomplete.component';
import { DictionaryPipe } from '../../../../../../core/pipes/dictionary.pipe';
import { DictionariesEnum } from 'src/app/core/enums/dictionaries.enum';
import { DatepickerComponent } from '../../../../../forms/components/datepicker/datepicker.component';
import { IDataInstance } from 'src/app/data-models/enrichment-standard/data-instance.interface';
import { WarehouseForOperatingPlanApiService } from 'src/app/api/dictionaries/warehouseForOperatingPlan.api.service';
import { take } from 'rxjs';
import { IDictionaryModel } from 'src/app/data-models/dictionary-model/dictionary-model.interface';
import { WarehouseCommunicationService } from 'src/app/modules/data/services/warehouse-balance/warehouse-communication.service';
import { isObject } from 'lodash-es';

@Component({
  selector: 'modal-warehouse-balance-form',
  templateUrl: './modal-warehouse-balance-form.component.html',
  styleUrls: ['./modal-warehouse-balance-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TextComponent,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    InputComponent,
    AutocompleteComponent,
    DictionaryPipe,
    DatepickerComponent,
  ],
})
export class ModalWarehouseBalanceFormComponent implements OnInit {
  formData!: FormGroup;
  @Output() form = new EventEmitter<FormGroup>();

  @Input() data!: IWarehouseBalanceItem[];
  @Input() columns!: IDataColumnTable[];
  @Input() dataInstance!: IDataInstance;
  @Input() isShow!: boolean;

  defaultListWarehouseOP: IDictionaryModel[] = [];
  listWarehouseOP: IDictionaryModel[] = [];

  readonly dictionariesEnum = DictionariesEnum;

  constructor(
    private fb: FormBuilder,
    private warehouseForOperatingPlanApiService: WarehouseForOperatingPlanApiService,
    private warehouseCommunicationService: WarehouseCommunicationService,
  ) {}

  ngOnInit(): void {
    this.initListWarekouseOP();
  }

  initListWarekouseOP(): void {
    this.warehouseForOperatingPlanApiService
      .getList(1, [
        {
          name: 'itemsPerPage',
          value: 2000,
        },
      ])
      .pipe(take(1))
      .subscribe((res) => {
        this.defaultListWarehouseOP = [...res];
        this.listWarehouseOP = [...res];
        this.initFormGroup();
      });
  }

  initFormGroup(): void {
    const data = this.data;
    const size = this.data.length;

    this.formData = this.fb.group({
      warehouse: [
        size && data.every((el) => el.warehouse.id === data[0].warehouse.id)
          ? data[0].warehouse
          : null,
        [Validators.required],
      ],
      warehouseForOperatingPlan: [
        size &&
        data.every(
          (el) => el.warehouseForOperatingPlan?.id === data[0].warehouseForOperatingPlan?.id,
        )
          ? data[0]?.warehouseForOperatingPlan
          : null,
        [Validators.required],
      ],
      id: [size && data.every((el) => el.id === data[0].id) ? data[0].id : null],
      dataInstance: this.fb.group({
        id: [
          size && data.every((el) => el.dataInstance.id === data[0].dataInstance.id)
            ? data[0].dataInstance.id
            : this.dataInstance.id,
        ],
      }),
      materialType: [
        size && data.every((el) => el.materialType.id === data[0].materialType.id)
          ? data[0].materialType
          : null,
        [Validators.required],
      ],
      mark: [
        size && data.every((el) => el.mark.id === data[0].mark.id) ? data[0].mark : null,
        [Validators.required],
      ],
      volume: [
        size && data.every((el) => el.volume === data[0].volume) ? data[0].volume : null,
        [Validators.required],
      ],
      ad: [
        size && data.every((el) => el.ad === data[0].ad) ? data[0].ad : null,
        [Validators.required],
      ],
      vd: [
        size && data.every((el) => el.vd === data[0].vd) ? data[0].vd : null,
        [Validators.required],
      ],
      y: [size && data.every((el) => el.y === data[0].y) ? data[0].y : null],
      csr: [size && data.every((el) => el.csr === data[0].csr) ? data[0].csr : null],
      hgi: [size && data.every((el) => el.hgi === data[0].hgi) ? data[0].hgi : null],
      area: [size && data.every((el) => el?.area?.id === data[0]?.area?.id) ? data[0].area : null],
      layer: [
        size && data.every((el) => el?.layer?.id === data[0]?.layer?.id) ? data[0].layer : null,
      ],
      profile: [
        size && data.every((el) => el?.profile?.id === data[0]?.profile?.id)
          ? data[0].profile
          : null,
      ],
      dateAvailability: [
        size && data.every((el) => el?.dateAvailability === data[0]?.dateAvailability)
          ? this.convertDateToUTC(new Date(data[0].dateAvailability))
          : null,
        [Validators.required],
      ],
    });

    this.susbcribeForWarehouse();

    if (this.isShow) {
      this.formData.disable();
      this.formData.updateValueAndValidity();
    }

    this.form.emit(this.formData);
  }

  closedAutocomplete(): void {
    const warehouseOP = this.formData.get('warehouseForOperatingPlan')?.value;
    if (!warehouseOP || !isObject(warehouseOP)) {
      this.formData.get('warehouseForOperatingPlan')?.setValue(null);
    }
  }

  susbcribeForWarehouse(): void {
    const warehouse = this.formData.get('warehouse')?.value as IDictionaryModel | null | string;

    if (warehouse) {
      if (!isObject(warehouse)) return;
      const listIds = this.warehouseCommunicationService.getListWarehouseOP(+warehouse?.id);
      this.listWarehouseOP = listIds.length
        ? this.defaultListWarehouseOP.filter((item) => listIds.includes(+item.id))
        : [...this.defaultListWarehouseOP];
    }

    this.formData
      .get('warehouse')
      ?.valueChanges.subscribe((value: IDictionaryModel | null | string) => {
        if (!isObject(value)) {
          this.formData.get('warehouseForOperatingPlan')?.setValue(null);
          this.listWarehouseOP = [...this.defaultListWarehouseOP];
          return;
        }

        const listIds = this.warehouseCommunicationService.getListWarehouseOP(+value?.id);
        this.listWarehouseOP = listIds.length
          ? this.defaultListWarehouseOP.filter((item) => listIds.includes(+item.id))
          : [...this.defaultListWarehouseOP];
        this.formData.get('warehouseForOperatingPlan')?.setValue(null);
      });
  }

  getControl(name: string): FormControl {
    return this.formData.get(name) as FormControl;
  }

  convertDateToUTC(date: Date): Date {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0));
  }
}
