import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IDataColumnTable } from 'src/app/data-models/data-column-table/data-column-table.interface';
import { MatTableModule } from '@angular/material/table';
import { SectionComponent } from 'src/app/modules/ui/components/section/section.component';
import { TextComponent } from 'src/app/modules/ui/components/text/text.component';
import { IEnrichmentStandard } from 'src/app/data-models/enrichment-standard/enrichment-standard.interface';
import { take, takeWhile } from 'rxjs';
import { EnrichmentStandardApiService } from 'src/app/api/enrichment-standard.api.service';
import { ActionModal } from 'src/app/modules/ui/base/action-modal';
import { ModalEnrichmentStandardListComponent } from '../modal-enrichment-standard-list/modal-enrichment-standard-list.component';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from 'src/app/modules/ui/components/button/button.component';
import { SvgIconsEnum } from 'src/app/core/enums/svg-icons.enum';
import { ToggleComponent } from 'src/app/modules/forms/components/toggle/toggle.component';
import { FormControl } from '@angular/forms';
import { columnsData, firstHeaderRow } from '../constants/columns';
import { ActivatedRoute, Router } from '@angular/router';
import { IDataInstance } from 'src/app/data-models/enrichment-standard/data-instance.interface';
import { SpinnerService } from 'src/app/modules/ui/services/spinner.service';
import { RedirectDataService } from 'src/app/modules/data/services/redirect-data.service';
import { EnrichmentStandardBackgroundPipe } from '../../../../pipes/enrichment-standard/enrichment-standard-background.pipe';
import { FilterSortByColumnService } from 'src/app/modules/ui/services/filter-sort-by-column.service';
import { ThSortFilterComponent } from 'src/app/modules/ui/components/th-sort-filter/th-sort-filter.component';
import { CheckFinishCaluclationService } from 'src/app/modules/data/services/check-correct-caluclation.service';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'enrichment-standard-list',
  templateUrl: './enrichment-standard-list.component.html',
  styleUrls: ['./enrichment-standard-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    SectionComponent,
    MatTableModule,
    TextComponent,
    MatIconModule,
    ButtonComponent,
    ToggleComponent,
    EnrichmentStandardBackgroundPipe,
    ThSortFilterComponent,
  ],
})
export class EnrichmentStandardListComponent
  extends ActionModal
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('modal', { read: ViewContainerRef }) modal!: ViewContainerRef;

  @Output() getTypeNorm = new EventEmitter();

  @Input() id!: string | undefined;
  @Input() type!: string | undefined;

  @ViewChild('table', { read: ElementRef })
  table!: ElementRef<HTMLTableElement>;

  @Output() getCurrentTable = new EventEmitter();

  isShow = false;
  isOperationalPlan = false;

  toggleList = ['TK1', 'TK2'];
  toggleControl = new FormControl<string>('');

  data!: IEnrichmentStandard[];
  rawData!: IEnrichmentStandard[];

  currentFirstHeaderRow!: IDataColumnTable[];
  currentColumns!: IDataColumnTable[];

  displayedFirstHeaderRow!: string[];
  displayedColumns!: string[];

  mapRows = new Map<number, IEnrichmentStandard>();
  dataInstance!: IDataInstance;

  isSingleClick = true;
  isAlive = true;
  isDataOwner = true;

  readonly svgIconsEnum = SvgIconsEnum;
  readonly typeDataInstances = 'enrichmentStandard';

  constructor(
    private ss: SpinnerService,
    private cdr: ChangeDetectorRef,
    private enrichmentStandardApiService: EnrichmentStandardApiService,
    private route: ActivatedRoute,
    private router: Router,
    private redirectDataService: RedirectDataService,
    private filterSortByColumnService: FilterSortByColumnService,
    private checkFinishCaluclationService: CheckFinishCaluclationService,
    private authService: AuthService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.isDataOwner = this.authService.isDataOwner;
    if (this.id || !this.isDataOwner) this.isShow = true;

    this.toggleControl.valueChanges.subscribe((value) => this.initRows(value));
    this.toggleControl.setValue(this.type === 'TK2' ? this.toggleList[1] : this.toggleList[0]);

    this.route.params.pipe(takeWhile(() => this.isAlive)).subscribe((params) => {
      const id = params['dataInstanceId'] || this.id;
      id
        ? this.getDataInstance(id)
        : this.redirectDataService.initRedirect(this.router, this.route);
    });
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  ngAfterViewInit() {
    this.getCurrentTable.emit(this.table.nativeElement);
  }

  getDataCalculationLog(): void {
    this.checkFinishCaluclationService.getIsShow(this.dataInstance).subscribe((isShow) => {
      if (isShow) this.isShow = true;
      this.cdr.detectChanges();
    });
  }

  subscribeSortingFiltering(): void {
    this.route.queryParams.pipe(takeWhile(() => this.isAlive)).subscribe((query) => {
      this.data = this.filterSortByColumnService.sort(query, '', this.rawData);
      this.data = this.filterSortByColumnService.filter(query, this.data);

      this.cdr.detectChanges();
    });
  }

  getDataInstance(id: string) {
    this.redirectDataService.initParamsCurrentPage(this.route, id);
    this.ss.startSpinner();
    this.redirectDataService
      .getDataInstance(id)
      .pipe(take(1))
      .subscribe(
        (res) => {
          this.dataInstance = res;
          this.initData(id);
        },
        () => this.ss.stopSpinner(),
      );
  }

  initRows(value: string | null) {
    if (!value) return;
    const hiddenTypeNorm = value === 'TK1' ? 'tk2' : 'tk1';
    this.getTypeNorm.emit(value);

    this.currentFirstHeaderRow = firstHeaderRow.filter(
      (item) => !item.name.includes(hiddenTypeNorm),
    );
    this.displayedFirstHeaderRow = this.currentFirstHeaderRow.map((item) => item.name);

    this.currentColumns = columnsData.filter((item) => !item.name.includes(hiddenTypeNorm));
    this.displayedColumns = this.currentColumns.map((item) => item.name);
  }

  initData(id: string): void {
    this.ss.startSpinner();
    this.getDataCalculationLog();

    this.enrichmentStandardApiService
      .getList(1, [
        { name: 'dataInstance.id', value: id },
        { name: 'itemsPerPage', value: 300 },
      ])
      .pipe(take(1))
      .subscribe(
        (result: IEnrichmentStandard[]) => {
          this.data = result;
          this.rawData = [...this.data];
          this.subscribeSortingFiltering();
          this.mapRows = new Map();
          this.ss.stopSpinner();
          this.cdr.detectChanges();
        },
        () => this.ss.stopSpinner(),
      );
  }

  openModal(row?: IEnrichmentStandard): void {
    this.isSingleClick = false;

    this.modalContainer = this.modal;
    const modalComponent = this.open(ModalEnrichmentStandardListComponent);
    const id = this.dataInstance.id!;

    modalComponent.instance.toggleValue = this.toggleControl.value as string;
    modalComponent.instance.dataInstanceId = id;
    modalComponent.instance.isShow = this.isShow;
    modalComponent.instance.updateData = this.initData.bind(this, id);

    this.closeInteraction = this.initData.bind(this, id);

    if (this.mapRows.size) {
      const arrayData = [...this.mapRows].map((item) => item[1]);
      modalComponent.instance.data = arrayData;
      return;
    } else {
      modalComponent.instance.data = row ? [row] : [];
    }
  }

  toggleRow(row: IEnrichmentStandard) {
    this.isSingleClick = true;
    setTimeout(() => {
      if (this.isSingleClick) {
        this.mapRows.get(row.id) ? this.mapRows.delete(row.id) : this.mapRows.set(row.id, row);
        this.cdr.detectChanges();
      }
    }, 250);
  }
}
