import { EntityApi } from '../core/abstract/entity.api';
import { Injectable } from '@angular/core';
import { ApiProvider } from '../core/providers/api.provider';
import { IEditInternalLogistics } from '../data-models/internal-logistics/edit-internal-logistics.interface';
import { IInternalLogistics } from '../data-models/internal-logistics/internal-logistics.interface';

@Injectable({
  providedIn: 'root',
})
export class InternalLogisticsApiService extends EntityApi<
  IInternalLogistics,
  IEditInternalLogistics
> {
  protected readonly api = 'result/internalLogistics';

  constructor(apiProvider: ApiProvider) {
    super(apiProvider);
  }
}
