import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { RolesEnum } from '../enums/roles.enum';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  roles!: string[];
  #user!: KeycloakProfile;

  get user(): KeycloakProfile | null {
    if (!this.#user) {
      return null;
    }

    return this.#user;
  }

  get isClient(): boolean {
    return this.roles.some((role: string) => role.includes(RolesEnum.Client));
  }

  get isPlanner(): boolean {
    return this.roles.some((role: string) => role.includes(RolesEnum.Planner));
  }

  get isDataOwner(): boolean {
    return this.roles.some((role: string) => role.includes(RolesEnum.DataOwner));
  }

  constructor(private keycloakService: KeycloakService) {}

  logout(): void {
    void this.keycloakService.logout();
  }

  async getUser(): Promise<KeycloakProfile> {
    return this.keycloakService.loadUserProfile().then((user: KeycloakProfile) => {
      this.roles = this.keycloakService.getUserRoles();
      this.#user = user;

      return user;
    });
  }
}
