import { Pipe, PipeTransform } from '@angular/core';
import { IResultMiningPlanItemTransform } from 'src/app/data-models/result-mining-plan-item/result-mining-plan-item.interface';

@Pipe({
  name: 'resultMiningPlanBorderHidden',
  standalone: true,
})
export class ResultMiningPlanBorderHiddenPipe implements PipeTransform {
  transform(index: number, data: IResultMiningPlanItemTransform[]): boolean {
    return data[index + 1] && data[index].area.id !== data[index + 1].area.id;
  }
}
