<div class="commodity-shipment-list-root">
  <nguk-section>
    <div class="sticky-container">
      <table mat-table #table matSort [dataSource]="data" multiTemplateDataRows>
        <ng-container *ngIf="!isOperationalPlan; else operationalDate">
          <ng-container [matColumnDef]="'dateStarted'">
            <th
              mat-header-cell
              *matHeaderCellDef
              [attr.colspan]="displayedColumns.length"
              class="horizontal-alignment"
            >
              <nguk-text weight="400" styleName="text-xs" *ngIf="journal"
                >График с {{ journal.datePlanStarted | date: 'dd.MM.yyyy' }}
              </nguk-text>
            </th>
          </ng-container>
        </ng-container>

        <ng-template #operationalDate>
          <ng-container *ngFor="let date of listDatesForOP; let i = index">
            <ng-container [matColumnDef]="'dateStarted' + (i + 1)">
              <th
                mat-header-cell
                *matHeaderCellDef
                class="horizontal-alignment position-center"
                [attr.colspan]="getAmountColsForDate(i + 1)"
              >
                <nguk-text weight="400" styleName="text-xs">
                  {{ getDate(i) }}
                </nguk-text>
              </th>
            </ng-container>
          </ng-container>
        </ng-template>

        <ng-container *ngFor="let item of columns; let i = index" [matColumnDef]="item.columnName">
          <th mat-header-cell *matHeaderCellDef>
            <nguk-th-sort-filter
              [columnName]="item.columnName"
              [columnDisplay]="item.columnHeading"
              [rawData]="rawData"
            ></nguk-th-sort-filter>
          </th>

          <td
            mat-cell
            [attr.colspan]="
              row.trainStation && item.columnName === 'index' ? displayedColumns.length : 1
            "
            *matCellDef="let row; let ind = dataIndex"
          >
            <nguk-text
              *ngIf="!row.trainStation"
              weight="400"
              styleName="text-xs"
              [class.opacity-text]="item.columnName === 'index'"
            >
              {{ item.columnName | shippingPlanCustomerName: row[item.columnName] : ind : data }}
            </nguk-text>
          </td>
        </ng-container>

        <ng-container [matColumnDef]="'index-total'">
          <th mat-header-cell *matHeaderCellDef [attr.colspan]="3"></th>
        </ng-container>

        <ng-container [matColumnDef]="'total-row'">
          <th mat-header-cell *matHeaderCellDef>
            <nguk-text weight="700" styleName="text-xs"> Итого </nguk-text>
          </th>

          <td mat-cell *matCellDef="let row; let i = index" class="total-row">
            <nguk-text weight="700" styleName="text-xs">{{
              row.totalRow ? row.totalRow : ''
            }}</nguk-text>
          </td>
        </ng-container>

        <ng-container [matColumnDef]="'total-row-amount'">
          <th mat-header-cell *matHeaderCellDef class="total-row">
            <nguk-text weight="700" styleName="text-xs">{{ totalValueMap.get('total') }}</nguk-text>
          </th>
        </ng-container>

        <ng-container
          *ngFor="let day of dayColumnsTotal; let i = index"
          [matColumnDef]="day.columnName"
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            class="day-header-cell"
            [class.day-weekend]="
              !isOperationalPlan &&
              (+day.columnHeading | ngukWeekendDayPipe: journal.datePlanStarted)
            "
          >
            <nguk-text weight="700" styleName="text-xs">{{
              totalValueMap.get(day.columnHeading)
            }}</nguk-text>
          </th>
        </ng-container>

        <ng-container matColumnDef="horizontal-area">
          <td
            mat-cell
            *matCellDef="let row; let i = index"
            [attr.colspan]="displayedColumns.length"
          >
            <div class="horizontal-area" [class.margin-horizontal-area]="isOperationalPlan">
              <nguk-text weight="700" styleName="text-xs">{{ row.station }}</nguk-text>
            </div>
          </td>
        </ng-container>

        <ng-container
          *ngFor="let day of dayColumns; let columnIndex = index"
          [matColumnDef]="day.columnName"
        >
          <th
            class="day-header-cell"
            mat-header-cell
            *matHeaderCellDef
            [class.day-firstShift]="
              isOperationalPlan && !(+day.columnHeading | operationalPlanColorShift)
            "
            [class.day-secondShift]="
              isOperationalPlan && (+day.columnHeading | operationalPlanColorShift)
            "
            [class.day-weekend]="
              !isOperationalPlan &&
              (+day.columnHeading | ngukWeekendDayPipe: journal.datePlanStarted)
            "
          >
            <nguk-text
              class="opacity-text"
              weight="400"
              styleName="text-xs"
              *ngIf="!isOperationalPlan; else operationalDay"
            >
              {{ day.columnHeading }}
            </nguk-text>

            <ng-template #operationalDay>
              <nguk-text weight="400" styleName="text-xs" class="opacity-text"
                >{{ getHours(+day.columnHeading) }}
              </nguk-text>
            </ng-template>
          </th>

          <td
            mat-cell
            class="day-mat-cell"
            [class.day-firstShift]="
              isOperationalPlan && !(+day.columnHeading | operationalPlanColorShift)
            "
            [class.day-secondShift]="
              isOperationalPlan && (+day.columnHeading | operationalPlanColorShift)
            "
            [class.day-weekend]="
              !isOperationalPlan &&
              (+day.columnHeading | ngukWeekendDayPipe: journal.datePlanStarted)
            "
            [class.-yellow]="row.days?.[day.columnName]?.deviationColor === colorAccentEnum.Yellow"
            [class.-red]="row.days?.[day.columnName]?.deviationColor === colorAccentEnum.Red"
            [class.-clickable]="!!row.days?.[day.columnName]"
            *matCellDef="let row"
            (dblclick)="openModal(row, row.days?.[day.columnName])"
          >
            <nguk-text
              *ngIf="!row.trainStation"
              weight="400"
              [color]="
                row.days?.[day.columnName]?.deviationColor === colorAccentEnum.Yellow ||
                row.days?.[day.columnName]?.deviationColor === colorAccentEnum.Red
                  ? 'black'
                  : 'grey'
              "
              styleName="text-xs"
            >
              {{ row.days?.[day.columnName]?.trainVolume }}
              <nguk-text
                class="badge"
                *ngIf="deviationsMap?.get(row.days?.[day.columnName]?.shippingPlanId) && isShow"
                >{{
                  deviationsMap.get(row.days?.[day.columnName]?.shippingPlanId) || ''
                }}</nguk-text
              >
            </nguk-text>
          </td>
        </ng-container>

        <ng-container *ngIf="!isOperationalPlan; else operationalDateRow">
          <tr mat-header-row *matHeaderRowDef="['dateStarted']; sticky: true"></tr>
        </ng-container>

        <ng-template #operationalDateRow>
          <tr mat-header-row *matHeaderRowDef="displayedRowDatesForOP; sticky: true"></tr>
        </ng-template>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumnsTotal; sticky: true"></tr>

        <tr
          mat-row
          *matRowDef="let row; columns: ['horizontal-area']; let i = dataIndex"
          [class.horizontal-area-hidden]="i | shippingPlanCheckHorizontalArea: data"
        ></tr>
        <tr
          mat-row
          [class.-station-row]="!!row.trainStation"
          [class.-no-border]="!!$any(data[index + 1])?.trainStation"
          *matRowDef="let row; let index = index; columns: displayedColumns"
        ></tr>
      </table>
    </div>
  </nguk-section>
</div>

<div class="deviations-table" *ngIf="shipmentQualitySpecifications && isShow">
  <table mat-table [dataSource]="shipmentQualitySpecifications" multiTemplateDataRows>
    <ng-container [matColumnDef]="'number'">
      <th mat-header-cell *matHeaderCellDef>
        <nguk-text weight="400" styleName="text-xs"> Номер</nguk-text>
      </th>

      <td mat-cell *matCellDef="let row; let index = dataIndex">
        <nguk-text weight="400" styleName="text-xs">{{ index + 1 }}</nguk-text>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="'deviations'">
      <th mat-header-cell *matHeaderCellDef>
        <nguk-text weight="400" styleName="text-xs"> Отклонения </nguk-text>
      </th>

      <td mat-cell *matCellDef="let row; let index = dataIndex">
        <nguk-text weight="400" styleName="text-xs">{{ getValueDeviations(row) }}</nguk-text>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="['number', 'deviations']; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: ['number', 'deviations']"></tr>
  </table>
</div>

<ng-template #modal />
