import { FormContext } from '../../../core/abstract/form.context';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IEditShippingPlanLoadingRule } from '../../../data-models/shipping-plan-loading-rule/edit-shipping-plan-loading-rule.interface';

export class EditShippingPlanLoadingRuleForm extends FormContext<IEditShippingPlanLoadingRule> {
  static createForm(
    shippingPlanLoadingRule: IEditShippingPlanLoadingRule,
  ): EditShippingPlanLoadingRuleForm {
    const isYRequired = ['OC', 'CC', 'KC', 'ОС', 'СС', 'КС', 'ОСНЗ'].includes(
      shippingPlanLoadingRule.requiredWagonMark?.toUpperCase(),
    );
    const form = new FormGroup({
      id: new FormControl(shippingPlanLoadingRule?.id),
      shippingPlan: new FormControl(shippingPlanLoadingRule?.shippingPlan),
      requiredWagonMark: new FormControl(shippingPlanLoadingRule?.requiredWagonMark, [
        Validators.required,
      ]),
      factoryProductsMixture: new FormControl(shippingPlanLoadingRule?.factoryProductsMixture, [
        Validators.required,
      ]),
      contractualAd: new FormControl(shippingPlanLoadingRule?.contractualAd, [Validators.required]),
      contractualVd: new FormControl(shippingPlanLoadingRule?.contractualVd, [Validators.required]),
      contractualY: new FormControl(shippingPlanLoadingRule?.contractualY ?? ''),
      contractualCsr: new FormControl(shippingPlanLoadingRule?.contractualCsr ?? ''),
      contractualHgi: new FormControl(shippingPlanLoadingRule?.contractualHgi ?? ''),
      plannedAd: new FormControl(shippingPlanLoadingRule?.plannedAd ?? ''),
      plannedVd: new FormControl(shippingPlanLoadingRule?.plannedVd ?? ''),
      plannedY: new FormControl(shippingPlanLoadingRule?.plannedY ?? ''),
      plannedCsr: new FormControl(shippingPlanLoadingRule?.plannedCsr ?? ''),
      plannedHgi: new FormControl(shippingPlanLoadingRule?.plannedHgi ?? ''),
    });

    return new EditShippingPlanLoadingRuleForm(form, shippingPlanLoadingRule);
  }

  get id(): FormControl {
    return this.getControl('id') as FormControl;
  }
  get shippingPlan(): FormControl {
    return this.getControl('shippingPlan') as FormControl;
  }
  get requiredWagonMark(): FormControl {
    return this.getControl('requiredWagonMark') as FormControl;
  }
  get factoryProductsMixture(): FormControl {
    return this.getControl('factoryProductsMixture') as FormControl;
  }
  get contractualAd(): FormControl {
    return this.getControl('contractualAd') as FormControl;
  }
  get contractualVd(): FormControl {
    return this.getControl('contractualVd') as FormControl;
  }
  get contractualY(): FormControl {
    return this.getControl('contractualY') as FormControl;
  }
  get contractualCsr(): FormControl {
    return this.getControl('contractualCsr') as FormControl;
  }
  get contractualHgi(): FormControl {
    return this.getControl('contractualHgi') as FormControl;
  }
  get plannedAd(): FormControl {
    return this.getControl('plannedAd') as FormControl;
  }
  get plannedVd(): FormControl {
    return this.getControl('plannedVd') as FormControl;
  }
  get plannedY(): FormControl {
    return this.getControl('plannedY') as FormControl;
  }
  get plannedCsr(): FormControl {
    return this.getControl('plannedCsr') as FormControl;
  }
  get plannedHgi(): FormControl {
    return this.getControl('plannedHgi') as FormControl;
  }

  constructor(form: FormGroup, shippingPlanLoadingRule?: IEditShippingPlanLoadingRule) {
    super(form, shippingPlanLoadingRule);
  }

  override getFormData(): IEditShippingPlanLoadingRule {
    return this.form.value;
  }
}
