import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { SpinnerService } from '../../../../ui/services/spinner.service';
import { CommodityShipmentService } from '../../../services/commodity-shipment.service';
import { finalize, forkJoin, mergeMap, take, takeWhile } from 'rxjs';
import { ICommodityShipment } from '../../../../../data-models/commodity-shipment/commodity-shipment.interface';
import { DatePipe, NgForOf, NgIf } from '@angular/common';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { SectionComponent } from '../../../../ui/components/section/section.component';
import { ShippingPlanCustomerNamePipe } from '../../../../data/pipes/shipping-plan/shipping-plan-customer-name.pipe';
import { TextComponent } from '../../../../ui/components/text/text.component';
import { EShippingPlanColorAccent } from '../../../../../enums/shipping-plan-color-accent.enum';
import { TShippingPlanListRow } from '../../../../data/types/shipping-plan-list-row.type';
import { ActionModal } from '../../../../ui/base/action-modal';
import { IProductionPlan } from '../../../../../data-models/production-plan/production-plan.interface';
import { TShippingPlanListColumn } from '../../../../data/types/shipping-plan-list-column.type';
import { TShippingPlanDay } from '../../../../data/types/shipping-plan-day.type';
import { ActivatedRoute, Params } from '@angular/router';
import { CommodityShipmentModalComponent } from '../commodity-shipment-modal/commodity-shipment-modal.component';
import { IJournal } from 'src/app/data-models/enrichment-standard/journal.interface';
import * as moment from 'moment';
import { ICommodityShipmentQualitySpecification } from 'src/app/data-models/commodity-shipment-quality-specification/commodity-shipment-quality-specification.interface';
import { ICommodityShipmentQualitySpecificationItem } from 'src/app/data-models/commodity-shipment-quality-specification-item/commodity-shipment-quality-specification-item.interface';
import { MatBadgeModule } from '@angular/material/badge';
import { ThSortFilterComponent } from 'src/app/modules/ui/components/th-sort-filter/th-sort-filter.component';
import { ShippingPlanCheckHorizontalAreaPipe } from 'src/app/modules/data/pipes/shipping-plan/shipping-plan-check-horizontal-area.pipe';
import { FilterSortByColumnService } from 'src/app/modules/ui/services/filter-sort-by-column.service';
import { WeekendDayPipe } from '../../../../../core/pipes/weekend-day.pipe';
import { OperationalPlanColorShiftPipe } from '../../../pipes/operational-plan-color-shift.pipe';
import { RedirectDataService } from 'src/app/modules/data/services/redirect-data.service';

@Component({
  selector: 'calendar-plan-commodity-shipment-list',
  templateUrl: './commodity-shipment-list.component.html',
  styleUrls: ['./commodity-shipment-list.component.scss'],
  standalone: true,
  providers: [CommodityShipmentService],
  imports: [
    DatePipe,
    MatSortModule,
    MatTableModule,
    NgForOf,
    NgIf,
    SectionComponent,
    ShippingPlanCustomerNamePipe,
    TextComponent,
    MatBadgeModule,
    ThSortFilterComponent,
    ShippingPlanCheckHorizontalAreaPipe,
    WeekendDayPipe,
    OperationalPlanColorShiftPipe,
  ],
})
export class CommodityShipmentListComponent
  extends ActionModal
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('modal', { read: ViewContainerRef, static: true }) modal!: ViewContainerRef;

  @Input() id!: string | undefined;

  @ViewChild('table', { read: ElementRef })
  table!: ElementRef<HTMLTableElement>;

  @Output() getCurrentTable = new EventEmitter();

  isShow = false;
  isAliveFiltering = false;
  isAlive = true;
  isOperationalPlan = false;

  readonly colorAccentEnum = EShippingPlanColorAccent;

  data!: TShippingPlanListRow[];
  rawData!: TShippingPlanListRow[];

  productionPlan!: IProductionPlan;
  journal!: IJournal;
  days!: number;

  columns: TShippingPlanListColumn[] = [
    { columnName: 'index', columnHeading: '#' },
    { columnName: 'customer', columnHeading: 'Заказчик' },
    { columnName: 'trainWagonsEquipment', columnHeading: 'Компл-я ЖД состава' },
  ];
  dayColumns!: TShippingPlanListColumn[];
  dayColumnsTotal!: TShippingPlanListColumn[];
  displayedColumns: string[] = ['customer', 'trainWagonsEquipment'];
  displayedColumnsTotal: string[] = [];

  shipmentQualitySpecifications!: ICommodityShipmentQualitySpecification[][];
  deviationsMap!: Map<string | number, number>;
  deviationsArr!: TShippingPlanDay[];

  maxColumn!: number;

  listDatesForOP: string[] = [];
  displayedRowDatesForOP: string[] = [];

  totalValueMap = new Map<string, string>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private ss: SpinnerService,
    private commodityShipmentService: CommodityShipmentService,
    private filterSortByColumnService: FilterSortByColumnService,
    private redirectDataService: RedirectDataService,
  ) {
    super();
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  ngOnInit(): void {
    this.isOperationalPlan = !!this.activatedRoute.parent?.snapshot.data['isOperationalPlan'];

    if (this.id) this.isShow = true;

    this.ss.startSpinner();

    this.activatedRoute.params
      .pipe(
        takeWhile(() => this.isAlive),
        mergeMap((params: Params) => {
          const idJournal =
            this.activatedRoute.parent?.snapshot.params['calendarPlanId'] ||
            this.activatedRoute.snapshot.params['calendarPlanId'];

          this.redirectDataService.setLastJournal(
            this.activatedRoute,
            params['productionPlanId'] || this.id,
            idJournal,
          );
          return forkJoin([
            this.commodityShipmentService.getProductionPlan(params['productionPlanId'] || this.id),
            this.commodityShipmentService.getJournal(idJournal, this.isOperationalPlan),
          ]);
        }),
      )
      .subscribe(([productionPlan, journal]) => {
        this.productionPlan = productionPlan;
        this.journal = journal;

        this.setAmountsDays();

        this.commodityShipmentService
          .getListItems(productionPlan.id)
          .pipe(take(1), finalize(this.ss.stopSpinner))
          .subscribe((res: ICommodityShipment[]) => {
            this.totalValueMap.clear();
            this.initializeListColumns(this.days);
            this.data = this.commodityShipmentService.mapShippingPlansToListRepresentation(
              journal,
              res,
              this.isOperationalPlan,
              this.days,
            );

            this.commodityShipmentService.createTotalValue(
              this.data,
              this.totalValueMap,
              this.days,
            );

            this.rawData = [...this.data];

            if (!this.isAliveFiltering) {
              this.subscribeSortingFiltering();
              this.isAliveFiltering = true;
            }

            this.initDeviationsMap(this.data);
            this.initDeviationsData();

            this.ss.stopSpinner();
            this.cdr.detectChanges();
          }, this.ss.stopSpinner);
      });
  }

  ngAfterViewInit() {
    this.getCurrentTable.emit(this.table.nativeElement);
  }

  setAmountsDays() {
    if (this.isOperationalPlan) {
      this.days = moment(this.journal.datePlanFinished).diff(
        moment(this.journal.datePlanStarted),
        'hours',
      );

      const amountDates = Math.ceil(this.days / 24);
      this.listDatesForOP = [];
      for (let index = 1; index <= amountDates; index++) {
        this.listDatesForOP.push(`dateStarted${index}`);
      }

      this.displayedRowDatesForOP = [...this.listDatesForOP];
    } else {
      this.days = moment(this.journal.datePlanFinished).diff(
        moment(this.journal.datePlanStarted),
        'days',
      );
    }
  }

  getAmountColsForDate(index: number): number {
    const maxLastIndex = index * 24;
    if (maxLastIndex <= this.days) return 24;

    return maxLastIndex - this.days;
  }

  getDate(index: number): string {
    const firstDate = moment(this.journal.datePlanStarted).utcOffset(0, true);
    return firstDate.add(index, 'days').format('DD.MM.YY');
  }

  getHours(value: number): number {
    return value % 24 || 24;
  }

  private initDeviationsMap(commodityShipments: TShippingPlanListRow[]): void {
    this.deviationsMap = new Map<string, number>();

    commodityShipments.forEach((item) => {
      Object.entries(item.days).forEach(([day, el]) => {
        if (!el.deviationColor || el.deviationColor === 'Нет' || +day > this.days) return;
        this.deviationsMap.set(el.shippingPlanId, this.deviationsMap.size + 1);
      });
    });
  }

  private initDeviationsData() {
    this.ss.startSpinner();
    const deviationsArray = Array.from(this.deviationsMap, ([key]) => key);

    this.shipmentQualitySpecifications = [];

    forkJoin([
      this.commodityShipmentService.getListCommodityShipmentQualitySpecification(),
      this.commodityShipmentService.getListCommodityShipmentQualitySpecificationItems(),
    ])
      .pipe(take(1), finalize(this.ss.stopSpinner))
      .subscribe((response) => {
        const commodityShipmentQualitySpecificationMap = new Map<
          string | number,
          ICommodityShipmentQualitySpecification[]
        >();

        const commodityShipmentQualitySpecificationItemsMap = new Map<
          string | number,
          ICommodityShipmentQualitySpecificationItem[]
        >();

        response[0].forEach((item) => {
          const currentItem = commodityShipmentQualitySpecificationMap.get(
            item.commodityShipment.id,
          );

          if (currentItem) {
            currentItem.push(item);
            commodityShipmentQualitySpecificationMap.set(item.commodityShipment.id, currentItem);
          } else {
            commodityShipmentQualitySpecificationMap.set(item.commodityShipment.id, [item]);
          }
        });

        deviationsArray.forEach((element) => {
          const value = commodityShipmentQualitySpecificationMap.get(element);
          if (!value) return;

          this.shipmentQualitySpecifications.push(value);
        });

        response[1].forEach((item) => {
          const currentItem = commodityShipmentQualitySpecificationItemsMap.get(
            item.commodityShipmentQualitySpecification.id,
          );

          if (currentItem) {
            currentItem.push(item);
            commodityShipmentQualitySpecificationItemsMap.set(
              item.commodityShipmentQualitySpecification.id,
              currentItem,
            );
          } else {
            commodityShipmentQualitySpecificationItemsMap.set(
              item.commodityShipmentQualitySpecification.id,
              [item],
            );
          }
        });

        const deviationsArrayModify = deviationsArray
          .map((element) => commodityShipmentQualitySpecificationMap.get(element)!)
          .map(
            (elements) =>
              elements?.map((el) => commodityShipmentQualitySpecificationItemsMap.get(el.id)!),
          );

        deviationsArrayModify.forEach((el, index) => {
          if (!el) return;
          this.handleQualitySpecificationItems(el, index);
        });

        this.shipmentQualitySpecifications = [...this.shipmentQualitySpecifications];

        this.maxColumn = this.shipmentQualitySpecifications.reduce((acc, item) => {
          if (item.length > acc) acc = item.length;
          return acc;
        }, 0);

        this.cdr.detectChanges();
      });
  }

  getValueDeviations(row: ICommodityShipmentQualitySpecification[]) {
    return row.reduce((acc, item) => {
      Object.values(item.items).forEach((i) => (acc = acc + i.formula + ' | '));
      return acc;
    }, ' | ');
  }

  private handleQualitySpecificationItems = (
    qualitySpecificationItems: ICommodityShipmentQualitySpecificationItem[][],
    ind: number,
  ): void => {
    qualitySpecificationItems.forEach(
      (qualitySpecificationItem: ICommodityShipmentQualitySpecificationItem[], index: number) => {
        qualitySpecificationItem.forEach((element: ICommodityShipmentQualitySpecificationItem) => {
          if (!('items' in this.shipmentQualitySpecifications[ind][index])) {
            this.shipmentQualitySpecifications[ind][index] = {
              ...this.shipmentQualitySpecifications[ind][index],
              items: {},
            };
          }

          if (
            !(
              (element.lowerNorm === '*' || Number(element.fact) >= Number(element.lowerNorm)) &&
              (element.upperNorm === '*' || Number(element.fact) <= Number(element.upperNorm))
            )
          ) {
            this.shipmentQualitySpecifications[ind][index].items[element.ordinal] = element;

            (this.shipmentQualitySpecifications[ind][index].items[element.ordinal] as any).formula =
              `${element.indicatorName}[${element.lowerNorm}..${element.upperNorm}]=${element.fact}`;

            (
              this.shipmentQualitySpecifications[ind][index].items[element.ordinal] as any
            ).deviation = this.createDeviation(
              element.indicatorName,
              element.lowerNorm,
              element.upperNorm,
              element.fact,
            );
          }
        });
      },
    );
  };

  createDeviation(name: string, min: string, max: string, fact: string): string {
    if (max !== '*' && Number(fact) > Number(max)) {
      const diff = Number(fact) - Number(max);
      return `${name}+${(Math.round(diff * 100) / 100).toString()}`;
    } else if (min !== '*' && Number(fact) < Number(min)) {
      const diff = Number(min) - Number(fact);
      return `${name}-${(Math.round(diff * 100) / 100).toString()}`;
    } else {
      return '';
    }
  }

  openModal(row: TShippingPlanListRow, cell: TShippingPlanDay): void {
    if (this.isShow || !cell) return;

    this.modalContainer = this.modal;
    const modalContainer = this.open(CommodityShipmentModalComponent);
    modalContainer.instance.row = row;
    modalContainer.instance.cell = cell;
  }

  private initializeListColumns(days: number): void {
    this.dayColumns = Array.from(
      Array(days),
      (_, index: number): TShippingPlanListColumn => ({
        columnHeading: String(index + 1),
        columnName: String(index + 1),
      }),
    );

    this.displayedColumns = [...this.columns, ...this.dayColumns].map(
      (column: TShippingPlanListColumn) => column.columnName,
    );

    this.displayedColumns.push('total-row');

    this.dayColumnsTotal = Array.from(
      Array(days),
      (_, index: number): TShippingPlanListColumn => ({
        columnHeading: `${index + 1}`,
        columnName: `t${index + 1}`,
      }),
    );

    this.displayedColumnsTotal = [
      'index-total',
      ...this.dayColumnsTotal.map((column: TShippingPlanListColumn) => column.columnName),
      'total-row-amount',
    ];
  }

  subscribeSortingFiltering(): void {
    this.activatedRoute.queryParams.pipe(takeWhile(() => this.isAlive)).subscribe((query) => {
      this.data = this.filterSortByColumnService.sort(query, 'station', this.rawData);
      this.data = this.filterSortByColumnService.filter(query, this.data);
      this.cdr.detectChanges();
    });
  }
}
