import { Injectable } from '@angular/core';
import { EntityApi } from '../core/abstract/entity.api';
import { ApiProvider } from '../core/providers/api.provider';
import { IWagonLoaded } from '../data-models/wagon-loaded/wagon-loaded.interface';
import { IEditWagonLoaded } from '../data-models/wagon-loaded/edit-wagon-loaded.interface';

@Injectable({
  providedIn: 'root',
})
export class WagonLoadedApiService extends EntityApi<IWagonLoaded, IEditWagonLoaded> {
  protected readonly api = 'sourceData/wagonLoaded';

  constructor(apiProvider: ApiProvider) {
    super(apiProvider);
  }
}
