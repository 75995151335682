export enum CalculationsListColumnsEnum {
  Index = 'index',
  DateCreated = 'date-created',
  DateStarted = 'date-started',
  MaximumCalculationTime = 'maximum-calculation-time',
  DateFinished = 'date-finished',
  Status = 'status',
  Note = 'note',
  Actions = 'actions',
  IdLog = 'idLog',
  ExternalWarnings = 'externalWarnings',
}
