import { Component, HostBinding } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlDirective } from '../../directives/control.directive';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { ControlErrorComponent } from '../control-error/control-error.component';

@Component({
  selector: 'nguk-textarea-ctrl',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    ReactiveFormsModule,
    ControlErrorComponent,
    MatButtonModule,
  ],
})
export class TextareaComponent extends ControlDirective {
  @HostBinding('class.nguk-textarea-ctrl') readonly textAreaBaseClass = true;
}
