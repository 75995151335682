import { Pipe, PipeTransform } from '@angular/core';
import { isObject, uniq } from 'lodash-es';

@Pipe({
  name: 'ngukDataFilterPipe',
  standalone: true,
})
export class DataFilterPipe implements PipeTransform {
  transform(data: any[], column: string): string[] {
    return uniq(
      data
        ?.map((item) => {
          const currentColumn = item[column];
          if (typeof currentColumn === 'number') {
            return currentColumn.toString();
          } else if (typeof currentColumn === 'string') {
            return currentColumn.split(';');
          } else if (isObject(currentColumn) && 'title' in currentColumn) {
            return currentColumn.title as string;
          } else return '';
        })
        .flat()
        .sort((a, b) => (a > b ? 1 : a < b ? -1 : 0)),
    );
  }
}
