<div class="nsi-edit-nsi-tables-list-root">
  <nguk-text
    class="list-title"
    weight="700"
    styleName="text-sm"
  >
    Таблицы
  </nguk-text>

  <table mat-table cdkDropList [dataSource]="dictionariesData" (cdkDropListDropped)="onRowsReorder($event)">
    <ng-container [matColumnDef]="tablesListColumnsEnum.Order">
      <th mat-header-cell *matHeaderCellDef>
        <nguk-text color="grey" weight="400" styleName="text-xs">№</nguk-text>
      </th>

      <td mat-cell *matCellDef="let row; let index = index">
        <nguk-text weight="400" styleName="text-xs" color="grey">
          {{ index + 1 }}
        </nguk-text>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="tablesListColumnsEnum.Name">
      <th mat-header-cell *matHeaderCellDef>
        <nguk-text color="grey" weight="400" styleName="text-xs">Имя таблицы</nguk-text>
      </th>

      <td mat-cell *matCellDef="let row; let index = index">
        <input
          class="nguk-editable-text-input"
          [class.-invalid]="!dictionariesData[index].title.length"
          [(ngModel)]="dictionariesData[index].title"
        />
      </td>
    </ng-container>

    <ng-container [matColumnDef]="tablesListColumnsEnum.Format">
      <th mat-header-cell *matHeaderCellDef>
        <nguk-text color="grey" weight="400" styleName="text-xs">Формат</nguk-text>
      </th>

      <td mat-cell *matCellDef="let row; let index = index">
        <div class="list-format">
          <nguk-text weight="400" styleName="text-xs">
            {{ getRowTypeText(row.format) }}
          </nguk-text>
          <mat-icon
            [svgIcon]="svgIconsEnum.ChevronDown"
            [matMenuTriggerFor]="listMenuModes"
            [matMenuTriggerData]="{ row }"
            [class.-active]="listMenuModesTrigger.menuOpen"
            #listMenuModesTrigger="matMenuTrigger"
          />
        </div>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="tablesListColumnsEnum.Columns">
      <th mat-header-cell *matHeaderCellDef>
        <nguk-text color="grey" weight="400" styleName="text-xs">Столбцов</nguk-text>
      </th>

      <td mat-cell *matCellDef="let row; let index = index">
        <nguk-text weight="400" styleName="text-xs">
          {{ row.dictionaryColumns?.length || 0 }}
        </nguk-text>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="tablesListColumnsEnum.Rows">
      <th mat-header-cell *matHeaderCellDef>
        <nguk-text color="grey" weight="400" styleName="text-xs">Строк</nguk-text>
      </th>

      <td mat-cell *matCellDef="let row; let index = index">
        <nguk-text weight="400" styleName="text-xs">
          {{ row.dictionaryRows?.length || 0 }}
        </nguk-text>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="tablesListColumnsEnum.Actions">
      <th mat-header-cell *matHeaderCellDef>
        <nguk-text color="grey" weight="400" styleName="text-xs">Действия</nguk-text>
      </th>

      <td mat-cell *matCellDef="let row; let rowIndex = index">
        <div class="actions-row">
          <nguk-button
            *ngIf="!isCalculationComplete"
            class="copy-button"
            type="secondary"
            matTooltip="Копировать строку"
            (click)="copyRow(rowIndex)"
          >
            <mat-icon [svgIcon]="svgIconsEnum.Duplicate"></mat-icon>
          </nguk-button>

          <nguk-button
            *ngIf="!row.dictionaryRows?.length && !isCalculationComplete"
            class="delete-button"
            type="secondary"
            matTooltip="Удалить строку"
            (click)="deleteRow(rowIndex)"
          >
            <mat-icon [svgIcon]="svgIconsEnum.Delete"></mat-icon>
          </nguk-button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      cdkDrag
      cdkDragLockAxis="y"
      *matRowDef="let row; columns: displayedColumns"
    ></tr>
  </table>

  <div *ngIf="!isCalculationComplete" class="add-row">
    <nguk-button type="secondary" (click)="addRow()">
      <ng-container>
        <mat-icon [svgIcon]="svgIconsEnum.Plus"></mat-icon>
        <nguk-text weight="400" styleName="text-xs">Добавить строку</nguk-text>
      </ng-container>
    </nguk-button>
  </div>
</div>

<mat-menu class="nsi-edit-panel" #listMenuModes="matMenu">
  <ng-template matMenuContent let-row="row">
    <button
      mat-menu-item
      [class.-active]="row.format === nsiTableTypeEnum.List"
      (click)="changeListMode(row, nsiTableTypeEnum.List)"
    >
      <nguk-text styleName="text-xs">Список</nguk-text>
    </button>

    <button
      mat-menu-item
      [class.-active]="row.format === nsiTableTypeEnum.Matrix"
      (click)="changeListMode(row, nsiTableTypeEnum.Matrix)"
    >
      <nguk-text styleName="text-xs">Матрица</nguk-text>
    </button>
  </ng-template>
</mat-menu>
