import { Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgIconsEnum } from 'src/app/core/enums/svg-icons.enum';
import { ButtonComponent } from 'src/app/modules/ui/components/button/button.component';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'upload-file-button',
  standalone: true,
  imports: [CommonModule, ButtonComponent, MatIconModule],
  templateUrl: './upload-file-button.component.html',
  styleUrls: ['./upload-file-button.component.scss'],
})
export class UploadFileButtonComponent {
  @Output() upload = new EventEmitter<void>();

  readonly svgIconsEnum = SvgIconsEnum;
}
