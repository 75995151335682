import { Injectable } from '@angular/core';
import { DataInstanceApiService } from '../../../api/data-instance.api.service';
import { combineLatestWith, finalize, forkJoin, mergeMap, Observable, of } from 'rxjs';
import { IDataInstance } from '../../../data-models/enrichment-standard/data-instance.interface';
import { DictionaryApiService } from '../../../api/dictionary.api.service';
import { NsiDictionaryModel } from '../../../data-models/dictionary/dictionary.model';
import { EditDictionaryModel } from '../../../data-models/dictionary/edit-dictionary.model';
import { differenceBy } from 'lodash-es';
import { DictionaryRowItemApiService } from '../../../api/dictionary-row-item.api.service';
import { DictionaryRowItemModel } from '../../../data-models/dictionary/dictionary-row-item.model';
import { NsiTableTypeEnum } from '../enums/nsi-table-type.enum';
import { DictionaryColumnApiService } from '../../../api/dictionary-column.api.service';
import { DictionaryRowApiService } from '../../../api/dictionary-row.api.service';
import { EditDictionaryColumnModel } from '../../../data-models/dictionary/edit-dictionary-column.model';
import { DictionaryColumnModel } from '../../../data-models/dictionary/dictionary-column.model';
import { DictionaryRowModel } from '../../../data-models/dictionary/dictionary-row.model';
import { EditDictionaryRowModel } from '../../../data-models/dictionary/edit-dictionary-row.model';
import { EditDictionaryRowItemModel } from '../../../data-models/dictionary/edit-dictionary-row-item.model';
import { SpinnerService } from '../../ui/services/spinner.service';
import * as moment from 'moment/moment';
import { NewNsiForm } from '../forms/new-nsi.form';
import { IJournal } from 'src/app/data-models/enrichment-standard/journal.interface';
import { JournalApiService } from 'src/app/api/journal.api.service';

@Injectable({ providedIn: 'root' })
export class NsiService {
  constructor(
    private ss: SpinnerService,
    private journalApiService: JournalApiService,
    private dataInstanceApiService: DataInstanceApiService,
    private dictionaryApiService: DictionaryApiService,
    private dictionaryColumnApiService: DictionaryColumnApiService,
    private dictionaryRowApiService: DictionaryRowApiService,
    private dictionaryRowItemApiService: DictionaryRowItemApiService,
  ) {}

  getDataInstances(): Observable<IDataInstance[]> {
    const query = [
      { name: 'itemsPerPage', value: 500 * 10 },
      { name: 'type', value: 'dictionary' },
    ];

    return this.dataInstanceApiService.getList(1, query);
  }

  getDataInstanceById(id: string): Observable<IDataInstance> {
    return this.dataInstanceApiService.getOne(id);
  }

  updateDataInstance(dataInstance: IDataInstance, form: NewNsiForm): Observable<IDataInstance> {
    return this.dataInstanceApiService.update({ ...dataInstance, note: form.note.value });
  }

  getCalculationLogs(): Observable<IJournal[]> {
    const query = [
      { name: 'page', value: 1 },
      { name: 'itemsPerPage', value: 5000 * 1000 },
    ];

    return this.journalApiService.getList(1, query);
  }

  createDataInstance(note: string): Observable<any> {
    return this.dataInstanceApiService.create({
      calculationLog: [],
      dateCreated: moment(new Date()).startOf('day').utcOffset(0, true).format() as any,
      note,
      type: 'dictionary',
    } as any);
  }

  copyDataInstance(dataInstanceId: string): Observable<any> {
    return this.dataInstanceApiService.copy({ fromId: dataInstanceId });
  }

  getDictionariesByDataInstance(dataInstance: IDataInstance): Observable<NsiDictionaryModel[]> {
    const query = [
      { name: 'itemsPerPage', value: 50 * 100 },
      { name: 'dataInstance.id', value: dataInstance.id! },
    ];

    return this.dictionaryApiService.getList(1, query);
  }

  getDictionary = (dictionaryId: number): Observable<NsiDictionaryModel> => {
    return this.dictionaryApiService.getOne(dictionaryId);
  };

  getDictionaryRowItems = (dictionaryRowId: number): Observable<DictionaryRowItemModel[]> => {
    const query = [
      { name: 'itemsPerPage', value: 60 },
      { name: 'dictionaryRow.id', value: dictionaryRowId },
    ];

    return this.dictionaryRowItemApiService.getList(1, query);
  };

  saveDictionariesChanges(
    dataInstance: IDataInstance,
    oldDictionaries: NsiDictionaryModel[],
    newDictionaries: NsiDictionaryModel[],
  ): Observable<any> {
    const copyDictionaryRequests: Observable<any>[] = [];
    const createDictionaryRequests: Observable<any>[] = [];
    const updateDictionaryRequests: Observable<any>[] = [];
    const deleteDictionaryRequests: Observable<any>[] = [];

    newDictionaries.forEach((dictionary: NsiDictionaryModel & any) => {
      if (dictionary.id) {
        return;
      }

      if (dictionary.copiedFromId) {
        copyDictionaryRequests.push(this.dictionaryApiService.copy(dictionary.copiedFromId));

        return;
      }

      const model = new EditDictionaryModel(
        dataInstance as any,
        dictionary.title,
        dictionary.format,
        dictionary.ordinal,
      );
      createDictionaryRequests.push(this.dictionaryApiService.create(model));
    });

    newDictionaries.forEach((dictionary: NsiDictionaryModel) => {
      if (!dictionary.id) {
        return;
      }

      const model = new EditDictionaryModel(
        dataInstance as any,
        dictionary.title,
        dictionary.format,
        dictionary.ordinal,
        dictionary.id,
      );
      updateDictionaryRequests.push(this.dictionaryApiService.update(model));
    });

    const deletedDictionaries = differenceBy(oldDictionaries, newDictionaries, 'id');
    deletedDictionaries.forEach((dictionary: NsiDictionaryModel) => {
      deleteDictionaryRequests.push(this.dictionaryApiService.delete(dictionary.id));
    });

    return forkJoin(createDictionaryRequests.length ? createDictionaryRequests : [of(null)]).pipe(
      mergeMap(this.createPredefinedDictionaryStructure),
      combineLatestWith(
        forkJoin(copyDictionaryRequests.length ? copyDictionaryRequests : [of(null)]),
        forkJoin(updateDictionaryRequests.length ? updateDictionaryRequests : [of(null)]),
        forkJoin(deleteDictionaryRequests.length ? deleteDictionaryRequests : [of(null)]),
      ),
      mergeMap((response: any[]) =>
        this.handleCopiedDictionaries(response, dataInstance, newDictionaries),
      ),
    );
  }

  saveDictionaryInternalChanges = (
    dictionary: NsiDictionaryModel,
    columns: DictionaryColumnModel[],
    rows: DictionaryRowModel[],
    isMatrixType?: boolean,
  ): Observable<any> => {
    this.ss.startSpinner();
    const createRowsRequests: Observable<any>[] = [];
    const updateRowsRequests: Observable<any>[] = [];
    const deleteRowsRequests: Observable<any>[] = [];
    const dictionaryIRI = `/api/sourceData/dictionary/${dictionary.id}`;

    const [createColumnsItems, updateColumnsItems, deleteColumnsItems] = this.editColumns(
      columns,
      dictionaryIRI,
    );

    rows.forEach((row: DictionaryRowModel) => {
      if (!('title' in row)) {
        let title: string;
        if (isMatrixType) {
          const firstCellValue = (row as any).items[0].value;
          if (firstCellValue.includes('Строка')) {
            return;
          }

          title = firstCellValue;
        } else {
          title = `${(row as any).id}`;
        }
        const model = new EditDictionaryRowModel(dictionaryIRI, title);
        createRowsRequests.push(this.dictionaryRowApiService.create(model));
      }
    });

    const deletedRows = differenceBy(dictionary.dictionaryRows, rows, 'id');
    deletedRows.forEach((row: DictionaryRowModel) => {
      deleteRowsRequests.push(this.dictionaryRowApiService.delete(row.id));
    });

    if (isMatrixType) {
      rows.forEach((row: DictionaryRowModel) => {
        if ('title' in row && !row.items?.[0]?.value?.includes('Строка')) {
          const model = new EditDictionaryRowModel(dictionaryIRI, row.title, row.id);
          updateRowsRequests.push(this.dictionaryRowApiService.update(model));
        }
      });

      dictionary.dictionaryRows.forEach((row) => {
        if (row.items?.[0]?.value?.includes('Строка')) {
          deleteRowsRequests.push(this.dictionaryRowApiService.delete(row.id));
        }
      });
    }

    const createRowsItems = this.createRowsItems(rows, isMatrixType);
    const editRowsItems = this.editRowsItems(rows, isMatrixType);

    return forkJoin(createColumnsItems).pipe(
      combineLatestWith(
        forkJoin(updateColumnsItems),
        forkJoin(deleteColumnsItems),
        forkJoin(deleteRowsRequests.length ? deleteRowsRequests : [of(null)]),
        forkJoin(updateRowsRequests.length ? updateRowsRequests : [of(null)]),
        forkJoin(createRowsItems),
        forkJoin(editRowsItems),
        forkJoin(createRowsRequests.length ? createRowsRequests : [of(null)]).pipe(
          mergeMap((createdRows: any[]) => this.createRowsItems(createdRows, isMatrixType)),
        ),
      ),
      finalize(this.ss.stopSpinner),
    );
  };

  private handleCopiedDictionaries = (
    response: any[],
    dataInstance: IDataInstance,
    newDictionaries: NsiDictionaryModel[],
  ): Observable<any> => {
    const [, copiedDictionariesResponse] = response;
    if (!copiedDictionariesResponse.some(Boolean)) {
      return of(response);
    }

    const dictionariesToCopy = newDictionaries.filter(
      (dictionary: NsiDictionaryModel & any) => dictionary.copiedFromId,
    );
    const requestsToUpdatedCopiedDictionaries: Observable<any>[] = [];
    dictionariesToCopy.forEach((dictionary: NsiDictionaryModel & any, index: number) => {
      const model = new EditDictionaryModel(
        dataInstance as any,
        dictionary.title,
        dictionary.format,
        dictionary.ordinal,
        copiedDictionariesResponse[index].dictionary.id,
      );
      requestsToUpdatedCopiedDictionaries.push(this.dictionaryApiService.update(model));
    });

    return forkJoin(requestsToUpdatedCopiedDictionaries);
  };

  private editColumns = (
    columns: DictionaryColumnModel[],
    dictionaryIRI: string,
  ): [Observable<any>, Observable<any>, Observable<any>] => {
    const createColumnsRequests: Observable<any>[] = [];
    const updateColumnsRequests: Observable<any>[] = [];
    const deleteColumnsRequests: Observable<any>[] = [];
    columns.forEach((column: DictionaryColumnModel) => {
      if (column.title.includes('Столбец')) {
        deleteColumnsRequests.push(this.dictionaryColumnApiService.delete(column.id));
        return;
      }

      if (column.dictionary) {
        const model = new EditDictionaryColumnModel(
          dictionaryIRI,
          column.ordinal,
          column.title,
          column.id,
        );
        updateColumnsRequests.push(this.dictionaryColumnApiService.update(model));
      } else {
        const model = new EditDictionaryColumnModel(dictionaryIRI, column.ordinal, column.title);
        createColumnsRequests.push(this.dictionaryColumnApiService.create(model));
      }
    });

    return [
      forkJoin(createColumnsRequests.length ? createColumnsRequests : [of(null)]),
      forkJoin(updateColumnsRequests.length ? updateColumnsRequests : [of(null)]),
      forkJoin(deleteColumnsRequests.length ? deleteColumnsRequests : [of(null)]),
    ];
  };

  private createRowsItems = (rows: DictionaryRowModel[], isMatrix?: boolean): Observable<any> => {
    const rowsItemsRequests: Observable<any>[] = [];
    let rowsToCreateItems = rows.filter((row) => row && !row.items?.length);
    for (let idx = 0; idx < rowsToCreateItems.length; ++idx) {
      const rowBeforeSave = rowsToCreateItems[idx];
      const rowItemsBeforeSave = new Array(50).fill(0).map((_, index: number) => ({
        ordinal: index + 1,
        value: isMatrix && index === 0 ? rowBeforeSave.title : '',
      }));
      const id = rowBeforeSave.id;
      rowItemsBeforeSave.forEach((rowItem: any) => {
        const model = new EditDictionaryRowItemModel({ id }, rowItem.ordinal, rowItem.value);
        rowsItemsRequests.push(this.dictionaryRowItemApiService.create(model));
      });
    }

    return forkJoin(rowsItemsRequests.length ? rowsItemsRequests : [of(null)]);
  };

  private editRowsItems = (rows: DictionaryRowModel[], isMatrix?: boolean): Observable<any> => {
    const rowsItemsRequests: Observable<any>[] = [];
    for (let idx = 0; idx < rows.length; ++idx) {
      if (!rows[idx].dictionary) {
        continue;
      }

      const id = rows[idx].id;
      rows[idx].items.forEach((rowItem: any) => {
        if (rowItem.id && rowItem.changed) {
          if (isMatrix && rowItem.value.includes('Строка')) {
            return;
          }

          const model = new EditDictionaryRowItemModel(
            { id },
            rowItem.ordinal,
            rowItem.value,
            rowItem.id,
          );
          rowsItemsRequests.push(this.dictionaryRowItemApiService.update(model));
        }
      });
    }

    return forkJoin(rowsItemsRequests.length ? rowsItemsRequests : [of(null)]);
  };

  private createPredefinedDictionaryStructure = (
    createdDictionaries: NsiDictionaryModel[],
  ): Observable<any> => {
    const requests: Observable<any>[] = [];
    createdDictionaries.filter(Boolean).forEach((dictionary: NsiDictionaryModel) => {
      if (dictionary.format !== NsiTableTypeEnum.Matrix) {
        return;
      }

      const columnModel = new EditDictionaryColumnModel(
        `/api/sourceData/dictionary/${dictionary.id}`,
        1,
        'Положение',
      );
      requests.push(this.dictionaryColumnApiService.create(columnModel));
    });

    return forkJoin(requests.length ? requests : of(null));
  };
}
