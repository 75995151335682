<nguk-modal [visible]="isVisible" [close]="closeModal.bind(this, false)">
  <div *ngIf="editNsiForm" class="modal">
    <div class="modal-header">
      <div class="modal-header-info">
        <nguk-text weight="600" styleName="text-xl">
          {{
            dataInstance
              ? toggleControl.value === 'Редактирование' ? 'Редактирование ЭД' : 'Копирование ЭД'
              : 'Создание ЭД'
          }}
        </nguk-text>

        <nguk-toggle *ngIf="dataInstance" [toggleList]="toggleList" [control]="toggleControl" />
      </div>

      <nguk-modal-actions
        [showSave]="true"
        [showCopy]="false"
        [showDelete]="false"
        (save)="handleSave()"
      />
    </div>

    <nsi-edit-nsi-tables-list [dictionaries]="detailedDictionaries" [isCalculationComplete]="isCalculationComplete" />

    <nguk-textarea-ctrl
      direction="vertical"
      placeholder="Введите текст примечания"
      [control]="editNsiForm.note"
    >
      <ng-container label>
        <nguk-text color="grey" weight="400" styleName="text-sm">Примечание</nguk-text>
      </ng-container>
    </nguk-textarea-ctrl>
  </div>
</nguk-modal>
