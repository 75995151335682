import { Injectable } from '@angular/core';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard extends KeycloakAuthGuard {
  constructor(
    protected override readonly router: Router,
    protected readonly keycloak: KeycloakService,
    private authService: AuthService,
  ) {
    super(router, keycloak);
  }

  public isAccessAllowed = async (
    route: ActivatedRouteSnapshot | Route,
    state?: RouterStateSnapshot,
  ) => {
    if (!this.authenticated) {
      await this.keycloak.login({
        redirectUri: window.location.origin + (state?.url ?? ''),
      });
    }

    if (!this.authService.user) {
      await this.authService.getUser();
    }

    const requiredRoles = route.data?.['roles'];
    if (!Array.isArray(requiredRoles) || requiredRoles.length === 0) {
      return true;
    }

    return requiredRoles.some((role) => this.roles.includes(role));
  };
}
