<div class="shipping-plan-list-root">
  <nguk-section>
    <div class="sticky-container">
      <div class="container-buttons" *ngIf="!isShow">
        <nguk-button
          type="primary"
          *ngIf="selectedCells.length > 1"
          class="edit-button"
          (click)="openModal()"
        >
          <mat-icon [svgIcon]="svgIconsEnum.Edit" />
        </nguk-button>
        <nguk-button type="primary" class="create-button" (click)="openModal()">
          <mat-icon [svgIcon]="svgIconsEnum.Plus" />
        </nguk-button>
      </div>
      <table mat-table matSort [dataSource]="data" multiTemplateDataRows #table>
        <ng-container [matColumnDef]="'dateStarted'">
          <th mat-header-cell *matHeaderCellDef [attr.colspan]="displayedColumns.length">
            <nguk-text weight="400" styleName="text-xs">
              График с {{ dataInstance && dataInstance.dateStarted | date: 'dd.MM.yyyy' }}
            </nguk-text>
          </th>
        </ng-container>

        <ng-container
          *ngFor="let item of constantColumns; let i = index"
          [matColumnDef]="item.columnName"
          [sticky]="true"
        >
          <th mat-header-cell *matHeaderCellDef>
            <nguk-th-sort-filter
              [columnName]="item.columnName"
              [columnDisplay]="item.columnHeading"
              [rawData]="rawData"
            ></nguk-th-sort-filter>
          </th>

          <td
            mat-cell
            [attr.colspan]="row | shippingPlanColspan: item : displayedColumns"
            *matCellDef="let row; let ind = dataIndex"
          >
            <nguk-text
              *ngIf="!row.trainStation"
              weight="400"
              styleName="text-xs"
              [class.opacity-text]="item.columnName === 'index'"
              [class.-duplicate-text]="data[ind].isDuplicatesRow"
            >
              {{ item.columnName | shippingPlanCustomerName: row[item.columnName] : ind : data }}
            </nguk-text>
          </td>
        </ng-container>

        <ng-container matColumnDef="horizontal-area-first" [sticky]="true">
          <td mat-cell *matCellDef="let row; let i = index" [attr.colspan]="constantColumns.length">
            <div class="horizontal-area">
              <nguk-text weight="700" styleName="text-xs">{{ row.station || '-' }}</nguk-text>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="horizontal-area-second">
          <td mat-cell *matCellDef="let row; let i = index" [attr.colspan]="dayColumns.length + 1">
            <div class="horizontal-area"></div>
          </td>
        </ng-container>

        <ng-container
          *ngFor="let day of dayColumns; let columnIndex = index"
          [matColumnDef]="day.columnName"
        >
          <th
            class="day-header-cell"
            mat-header-cell
            *matHeaderCellDef
            [class.day-weekend]="
              +day.columnHeading | ngukWeekendDayPipe: dataInstance.dateStarted + ''
            "
          >
            <nguk-text class="opacity-text" weight="400" styleName="text-xs">
              {{ day.columnHeading }}
            </nguk-text>
          </th>

          <td
            mat-cell
            class="day-mat-cell"
            *matCellDef="let row"
            [class.day-weekend]="
              +day.columnHeading | ngukWeekendDayPipe: dataInstance.dateStarted + ''
            "
            [class.-selected]="checkIsSelected(row, columnIndex + 1)"
            [class.-yellow]="row.days?.[day.columnName] | shippingPlanColor: colorAccentEnum.Yellow"
            [class.-red]="row.days?.[day.columnName] | shippingPlanColor: colorAccentEnum.Red"
            [class.-clickable]="!!row.days?.[day.columnName]"
            (click)="selectPlan(row, columnIndex + 1)"
            (dblclick)="openModal(row, day.columnName, columnIndex)"
          >
            <nguk-text
              *ngIf="!row.trainStation"
              weight="400"
              styleName="text-xs"
              [class.-duplicate-text]="row.days?.[day.columnName]?.isDuplicate"
              [color]="
                row.days?.[day.columnName]?.color === colorAccentEnum.Yellow ||
                row.days?.[day.columnName]?.color === colorAccentEnum.Red
                  ? 'black'
                  : 'grey'
              "
            >
              {{ row.days?.[day.columnName]?.isDuplicate ? '!' : ''
              }}{{ row.days?.[day.columnName]?.trainVolume }}
            </nguk-text>
          </td>
        </ng-container>

        <ng-container [matColumnDef]="'index-total'" [sticky]="true">
          <th mat-header-cell *matHeaderCellDef [attr.colspan]="3">
            <nguk-text weight="400" styleName="text-xs">
              {{ id ? 'Итого по дням' : '' }}
            </nguk-text>
          </th>
        </ng-container>

        <ng-container [matColumnDef]="'total-row'">
          <th mat-header-cell *matHeaderCellDef>
            <nguk-text weight="700" styleName="text-xs"> Итого </nguk-text>
          </th>

          <td mat-cell *matCellDef="let row; let i = index" class="total-row">
            <nguk-text weight="700" styleName="text-xs">{{
              row.totalRow ? row.totalRow : ''
            }}</nguk-text>
          </td>
        </ng-container>

        <ng-container [matColumnDef]="'total-row-amount'">
          <th mat-header-cell *matHeaderCellDef class="total-row">
            <nguk-text weight="700" styleName="text-xs">{{ totalValueMap.get('total') }}</nguk-text>
          </th>
        </ng-container>

        <ng-container
          *ngFor="let day of dayColumnsTotal; let i = index"
          [matColumnDef]="day.columnName"
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            class="day-header-cell"
            [class.day-weekend]="
              +day.columnHeading | ngukWeekendDayPipe: dataInstance.dateStarted + ''
            "
          >
            <nguk-text weight="700" styleName="text-xs">{{
              totalValueMap.get(day.columnHeading)
            }}</nguk-text>
          </th>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="['dateStarted']; sticky: true"></tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumnsTotal; sticky: true"></tr>
        <tr
          mat-row
          *matRowDef="
            let row;
            columns: ['horizontal-area-first', 'horizontal-area-second'];
            let i = dataIndex
          "
          [class.horizontal-area-hidden]="i | shippingPlanCheckHorizontalArea: data"
        ></tr>

        <tr
          mat-row
          [class.-station-row]="!!row.trainStation"
          [class.-no-border]="!!$any(data[index + 1])?.trainStation"
          *matRowDef="let row; let index = index; columns: displayedColumns"
        ></tr>
      </table>
    </div>
  </nguk-section>
</div>

<ng-template #modal />
