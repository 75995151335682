import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import * as moment from 'moment';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { IDataInstance } from 'src/app/data-models/enrichment-standard/data-instance.interface';
import { TextComponent } from 'src/app/modules/ui/components/text/text.component';
import { InputComponent } from 'src/app/modules/forms/components/input/input.component';
import { SelectComponent } from 'src/app/modules/forms/components/select/select.component';
import { DatepickerComponent } from 'src/app/modules/forms/components/datepicker/datepicker.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ButtonComponent } from 'src/app/modules/ui/components/button/button.component';
import { SvgIconsEnum } from 'src/app/core/enums/svg-icons.enum';
import {
  IEditResourceAvailabilityCondition,
  IEditResourceAvailabilityConditionTransform,
} from '../../../../../../../data-models/resource-availability-condition/edit-resource-availability-condition.interface';
import { distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'modal-cell-form',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './modal-cell-form.component.html',
  styleUrls: ['./modal-cell-form.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TextComponent,
    InputComponent,
    SelectComponent,
    DatepickerComponent,
    FormsModule,
    ReactiveFormsModule,
    ButtonComponent,
    MatIconModule,
    MatTooltipModule,
    MatTableModule,
  ],
})
export class ModalCellFormComponent implements OnInit {
  formData!: FormGroup;

  @Output() form = new EventEmitter<FormGroup>();
  @Output() removeIds = new EventEmitter<string[]>();

  @Input() data!: IEditResourceAvailabilityConditionTransform[][];
  @Input() dataInstance!: IDataInstance;
  @Input() isShow!: boolean;
  @Input() resourceAvailabilityId!: string;

  dataSource!: IEditResourceAvailabilityConditionTransform[];

  removeIdsArray: string[] = [];

  minDate!: moment.Moment;
  maxDate!: moment.Moment;

  readonly svgIconsEnum = SvgIconsEnum;
  editRowIndex!: number;

  listColumns = ['dateStarted', 'dateFinished', 'duration', 'value', 'unit', 'actions'];

  constructor(
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.initFormGroup();
    this.initMinMaxDate();
  }

  initMinMaxDate(): void {
    this.minDate = moment(this.dataInstance.dateStarted);
    this.maxDate = moment(this.dataInstance.dateFinished);
  }

  initFormGroup(): void {
    this.formData = this.fb.group({
      values: this.fb.array([]),
    });
    this.initFormArray();

    this.form.emit(this.formData);
  }

  initFormArray() {
    !this.data.length
      ? this.addNewRow()
      : this.data.length === 1
        ? this.initOneChooseFormArray()
        : this.initMultiChoosesFormArray();
  }

  initOneChooseFormArray() {
    this.data[0].forEach((item) => {
      this.addFormGroupToFormArray(item, true);
    });
  }

  initMultiChoosesFormArray() {
    this.addFormGroupToFormArray(null);
  }

  getUTCDate(date: string) {
    const localDate = moment(date).utc();
    const localYear = localDate.year();
    const localMonth = localDate.month();
    const localDay = localDate.date();
    const localHours = localDate.hours();
    const localMinutes = localDate.minutes();
    const localSeconds = localDate.seconds();

    return new Date(localYear, localMonth, localDay, localHours, localMinutes, localSeconds);
  }

  addFormGroupToFormArray(
    item: IEditResourceAvailabilityConditionTransform | null,
    isInit = false,
  ) {
    const fg = this.fb.group({
      dateFinished: [
        item && item.dateFinished && item.id
          ? this.getUTCDate(item.dateFinished)
          : this.formatingDate(item?.dateFinished),
      ],
      dateStarted: [
        item && item.dateStarted && item.id
          ? this.getUTCDate(item.dateStarted)
          : this.formatingDate(item?.dateStarted),
      ],
      duration: [item?.duration ?? 24, [Validators.min(1)]],
      day: [item?.day ?? ''],
      id: [item && item.id && isInit ? item.id : ''],
      resourceAvailability: this.fb.group({
        id: [item?.resourceAvailability.id ?? ''],
      }),
      unit: [item?.unit ?? '%'],
      value: [item?.value ?? ''],
    });

    this.initSubcribeForContols(fg);

    this.formDataArray.push(fg);

    this.dataSource = [...this.formDataArray.value];
  }

  formatingDate(date?: string): Date | string {
    if (!date) return '';
    const currentDate = new Date(date);
    currentDate.setHours(0);
    currentDate.setMinutes(0);
    currentDate.setSeconds(0);
    return currentDate;
  }

  initSubcribeForContols(fg: FormGroup): void {
    fg
      .get('dateFinished')
      ?.valueChanges.pipe(distinctUntilChanged())
      .subscribe((value) => {
        const end = moment(value);
        const start = moment(fg.get('dateStarted')?.value);
        const diff = end.diff(start, 'hours');

        if (diff > 0) fg.get('duration')?.setValue(diff);
        else fg.get('duration')?.setValue(0);
      });

    fg
      .get('dateStarted')
      ?.valueChanges.pipe(distinctUntilChanged())
      .subscribe((value) => {
        const start = moment(value);
        const end = moment(fg.get('dateFinished')?.value);
        const diff = end.diff(start, 'hours');

        if (diff > 0) fg.get('duration')?.setValue(diff);
        else fg.get('duration')?.setValue(0);
      });

    fg
      .get('duration')
      ?.valueChanges.pipe(distinctUntilChanged())
      .subscribe((value) => {
        if (!value || +value <= 0) {
          fg.get('duration')?.setValue(1);
          return;
        }

        const start = moment(fg.get('dateStarted')?.value).utcOffset(0);
        const end = moment(fg.get('dateFinished')?.value).utcOffset(0);
        const diff = end.diff(start, 'hours');

        if (+value > diff) {
          fg.get('duration')?.setValue(+diff);
        }
      });
  }

  addNewRow(): void {
    if (this.data.length > 1) {
      this.addFormGroupToFormArray(null);
    } else if (this.data.length === 0) {
      const value = this.createEmptyValue();
      this.addFormGroupToFormArray(value);
    } else {
      const value = { ...this.data[0][0] };
      value.dateStarted = moment(this.dataInstance.dateStarted)
        .add(+value.day! - 1, 'days')
        .startOf('day')
        .utcOffset(0, true)
        .format();

      value.dateFinished = moment(this.dataInstance.dateStarted)
        .add(+value.day!, 'days')
        .startOf('day')
        .utcOffset(0, true)
        .format();

      value.value = '';
      this.addFormGroupToFormArray(value);
    }
  }

  createEmptyValue(): IEditResourceAvailabilityCondition {
    const dateStart = moment(this.dataInstance.dateStarted).utcOffset(0, true).startOf('days');
    const dateFinish = moment(this.dataInstance.dateStarted)
      .utcOffset(0, true)
      .startOf('days')
      .add(1, 'days');
    return {
      resourceAvailability: {
        id: this.resourceAvailabilityId,
      },
      dateStarted: dateStart.format(),
      dateFinished: dateFinish.format(),
      duration: 24,
      value: '',
      unit: '%',
    };
  }

  removeItem(index: number) {
    const el = this.dataSource[index].id;
    if (el) {
      this.removeIdsArray.push(el.toString());
      this.removeIds.emit(this.removeIdsArray);
    }
    this.dataSource.splice(index, 1);
    this.formDataArray.removeAt(index);
    this.dataSource = [...this.formDataArray.value];
  }

  get formDataArray(): FormArray {
    return this.formData.get('values') as FormArray;
  }

  getControlArray(index: number, name: string): FormControl {
    const group = this.formDataArray.controls[index] as FormGroup;
    return group.get(name) as FormControl;
  }

  getControl(name: string): FormControl {
    return this.formData.controls[name] as FormControl;
  }

  chooseActiveRoute(row: IEditResourceAvailabilityConditionTransform, index: number): void {
    if (!row.id) {
      this.editRowIndex = index;
      return;
    }
    const start = moment(row.dateStarted).startOf('day').utcOffset(0, true);
    const finish = moment(row.dateFinished).startOf('day').utcOffset(0, true);

    if (finish.diff(start, 'days') === 1) {
      this.editRowIndex = index;
    } else {
      const current = moment(this.dataInstance.dateStarted)
        .add(+row.day! - 1, 'days')
        .startOf('day')
        .utcOffset(0, true);
      if (current.diff(start, 'days') === 0) {
        this.editRowIndex = index;
      }
    }
  }

  checkParentValue(row: IEditResourceAvailabilityConditionTransform): boolean {
    if (!row.id) return false;

    const start = moment(row.dateStarted).utcOffset(0);
    const finish = moment(row.dateFinished).utcOffset(0);

    if (finish.diff(start, 'hours') === 24) {
      return false;
    } else {
      const current = moment(this.dataInstance.dateStarted)
        .add(+row.day! - 1, 'days')
        .utcOffset(0)
        .startOf('day');

      return current.diff(start, 'hours') > 24;
    }
  }

  checkPrevValue(): boolean {
    return !!this.data.flat().find((item) => item.id) && this.data.length > 1;
  }
}
