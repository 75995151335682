import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalBase } from 'src/app/modules/ui/base/modal.base';
import { ModalComponent } from 'src/app/modules/ui/components/modal/modal.component';
import { TextComponent } from 'src/app/modules/ui/components/text/text.component';
import { ToggleComponent } from 'src/app/modules/forms/components/toggle/toggle.component';
import { FormControl, FormGroup } from '@angular/forms';
import { ModalJournalListShortActionsComponent } from '../modal-journal-list-short-actions/modal-journal-list-short-actions.component';
import { ModalJournalListShortParametersComponent } from '../modal-journal-list-short-parameters/modal-journal-list-short-parameters.component';
import { ModalJournalListShortRowDataComponent } from '../modal-journal-list-short-row-data/modal-journal-list-short-row-data.component';
import { IEditJournal } from 'src/app/data-models/enrichment-standard/journal.interface';
import { ModalJournalListShortActionsFooterComponent } from '../modal-journal-list-short-actions-footer/modal-journal-list-short-actions-footer.component';
import { ModalJournalListShortNoteComponent } from '../modal-journal-list-short-note/modal-journal-list-short-note.component';
import { TStatusClosed } from 'src/app/modules/ui/types/status-closed-modal.type';
import { ModalJournalListShortPrintComponent } from '../modal-journal-list-short-print/modal-journal-list-short-print.component';
import { IDataInstance } from 'src/app/data-models/enrichment-standard/data-instance.interface';
import { ActivatedRoute } from '@angular/router';
import { TaskLogApiService } from 'src/app/api/task-log.api.service';
import { take } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'calendar-plan-modal-journal-list-short',
  templateUrl: './modal-journal-list-short.component.html',
  styleUrls: ['./modal-journal-list-short.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ModalComponent,
    TextComponent,
    ToggleComponent,
    ModalJournalListShortActionsComponent,
    ModalJournalListShortParametersComponent,
    ModalJournalListShortRowDataComponent,
    ModalJournalListShortActionsFooterComponent,
    ModalJournalListShortNoteComponent,
    ModalJournalListShortPrintComponent,
  ],
})
export class ModalJournalListShortComponent extends ModalBase implements OnInit, AfterViewInit {
  journal!: IEditJournal;

  toggleList = ['Редактирование', 'Копирование'];
  toggleControl = new FormControl<string | null>(null);

  isUpdateData = false;
  isCopy = false;
  isHasCalculated = false;
  isOperationalPlan = false;
  isPlanner = true;
  isClient = false;

  currentTypeDataInstances!: string;

  formData!: FormGroup;

  mapDataInstance!: Map<string, IDataInstance>;

  taskLogStatus = ['Ошибка расчета'];

  constructor(
    cdRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private taskLogApiService: TaskLogApiService,
    private authService: AuthService,
  ) {
    super(cdRef);
  }

  ngOnInit(): void {
    this.isOperationalPlan = !!this.route.snapshot.data['isOperationalPlan'];
    this.isPlanner = this.authService.isPlanner;
    this.isClient = this.authService.isClient;

    this.initCalculationsData();

    this.toggleControl.valueChanges.subscribe((value) => {
      this.isCopy = value === 'Копирование';
      this.cdRef.detectChanges();
    });
  }

  ngAfterViewInit(): void {
    this.initModal();
  }

  getFormData(fg: FormGroup) {
    this.formData = fg;
  }

  chooseDataInstances(type: string) {
    this.openNextModal();
    this.currentTypeDataInstances = type;
  }

  changeStatusModal(status: TStatusClosed): void {
    this.changeStatus(status, status === 'success');
  }

  updateData(): void {
    this.isUpdateData = true;
    this.closeModal(true);
  }

  initCalculationsData(): void {
    if (this.journal?.productionPlans.length) {
      this.isHasCalculated = true;
      this.initToogleList();
      return;
    }

    if (!this.journal?.taskLogs.length) {
      this.isHasCalculated = false;
      this.initToogleList();
      return;
    }

    const taskLogs = this.journal.taskLogs;
    const id = taskLogs[taskLogs.length - 1].id;

    this.taskLogApiService
      .getOne(id.toString())
      .pipe(take(1))
      .subscribe((taskLog) => {
        if (this.taskLogStatus.includes(taskLog.status)) {
          this.isHasCalculated = false;
        } else {
          this.isHasCalculated = true;
        }

        this.initToogleList();
        this.cdRef.detectChanges();
      });
  }

  initToogleList(): void {
    if (this.isHasCalculated) {
      this.toggleList = ['Копирование'];
      this.toggleControl.setValue(this.toggleList[0]);
      this.isCopy = true;
    } else {
      this.toggleControl.setValue(this.isCopy ? this.toggleList[1] : this.toggleList[0]);
    }
  }
}
