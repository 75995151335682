import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { RolesEnum } from '../enums/roles.enum';
import { AuthService } from '../services/auth.service';

@Directive({
  selector: '[ngukRole]',
  standalone: true,
})
export class RoleDirective<T = unknown> {
  @Input()
  set ngukRole(allowedRoles: RolesEnum[]) {
    const roles = this.authService.roles;
    const isHaveRole = roles.some((role: string) => allowedRoles.includes(role as RolesEnum));

    if (isHaveRole && !this.hasView) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!isHaveRole && this.hasView) {
      this.viewContainerRef.clear();
      this.hasView = false;
    }
  }

  private hasView!: boolean;

  constructor(
    private templateRef: TemplateRef<T>,
    private viewContainerRef: ViewContainerRef,
    private authService: AuthService,
  ) {}
}
