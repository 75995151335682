import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputComponent } from 'src/app/modules/forms/components/input/input.component';
import { SelectComponent } from 'src/app/modules/forms/components/select/select.component';
import { FormControl } from '@angular/forms';
import { combineLatest, startWith, takeWhile } from 'rxjs';
import { IDataInstance } from 'src/app/data-models/enrichment-standard/data-instance.interface';

@Component({
  selector: 'calendar-plan-filter-data',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './filter-data.component.html',
  styleUrls: ['./filter-data.component.scss'],
  standalone: true,
  imports: [CommonModule, InputComponent, SelectComponent],
})
export class FilterDataComponent implements OnInit, OnDestroy {
  @Output() filteredData = new EventEmitter<IDataInstance[]>();

  @Input() dataInstances!: IDataInstance[];

  data!: IDataInstance[];

  selectValue = [
    { value: 'desc', label: 'Сначала новые' },
    { value: 'asc', label: 'Сначала старые' },
  ];

  select = new FormControl();
  input = new FormControl();

  isAlive = true;
  isFirstChange = true;

  ngOnInit(): void {
    combineLatest([this.select.valueChanges, this.input.valueChanges.pipe(startWith(''))])
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(([select, input]) => {
        const filterData = this.dataInstances
          .filter(
            (item) =>
              `ЭД ${item.id}`.toUpperCase().includes(input.toUpperCase()) ||
              new Date(item.dateCreated)
                .toLocaleDateString('ru-RU', { day: '2-digit', month: '2-digit' })
                .includes(input.toUpperCase()) ||
              item.note.toUpperCase().includes(input.toUpperCase()),
          )
          .sort((a, b) => {
            if (select === 'asc') {
              return new Date(a.dateCreated).getTime() - new Date(b.dateCreated).getTime();
            } else {
              return new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime();
            }
          });
        this.filteredData.emit(filterData);
      });

    this.select.setValue(this.selectValue[0].value);
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
