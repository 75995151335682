import {
  Component,
  DestroyRef,
  HostBinding,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { TextComponent } from '../../../ui/components/text/text.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Moment } from 'moment';
import { ControlDirective } from '../../directives/control.directive';
import { DateFormats } from '../../constants/date-formats.constant';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MaskitoDirective } from '@maskito/angular';
import { ControlErrorComponent } from '../control-error/control-error.component';
import { MatIconModule } from '@angular/material/icon';
import { CalendarModule } from 'primeng/calendar';
import { PrimeNGConfig } from 'primeng/api';
import { ValidationErrorService } from '../../services/validation-error.service';
import { RULocale } from './constants/ru';
import * as moment from 'moment';

@Component({
  selector: 'nguk-datepicker-ctrl',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDatepickerModule,
    MatInputModule,
    TextComponent,
    ReactiveFormsModule,
    MaskitoDirective,
    ControlErrorComponent,
    MatIconModule,
    CalendarModule,
  ],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: DateFormats },
    { provide: MAT_DATE_LOCALE, useValue: 'ru-RU' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
  ],
})
export class DatepickerComponent extends ControlDirective implements OnInit {
  @Input() min!: string | Moment;
  @Input() max!: string | Moment;
  @Input() showTime = false;
  @Input() numberOfMonths = 2;
  @Input() appendTo: string | null = null;
  @ViewChild('input', { read: ViewContainerRef }) input!: ViewContainerRef;
  @HostBinding('class.nguk-datepicker-ctrl') readonly datepickerBaseClass = true;

  minDate!: Date;
  maxDate!: Date;

  constructor(
    dr: DestroyRef,
    validationErrorService: ValidationErrorService,
    private config: PrimeNGConfig,
  ) {
    super(dr, validationErrorService);
  }

  ngOnInit(): void {
    this.config.setTranslation(RULocale);

    if (this.min) this.minDate = this.convertDateToUTC(new Date(this.min.toString()));
    if (this.max) this.maxDate = this.convertDateToUTC(new Date(this.max.toString()));
  }

  convertDateToUTC(date: Date): Date {
    if (this.showTime) return date;

    const localDate = moment(date).utcOffset(0, true);
    const localYear = localDate.year();
    const localMonth = localDate.month();
    const localDay = localDate.date();

    return new Date(localYear, localMonth, localDay, 0, 0, 0);
  }

  convertDate(date: Date) {
    this.control.setValue(this.convertDateToUTC(date));
  }

  inputDate() {
    if (!this.control.value) return;

    const date = this.control.value as Date;
    this.control.setValue(this.convertDateToUTC(date));
  }
}
