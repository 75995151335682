import { Injectable } from '@angular/core';
import { EntityApi } from '../core/abstract/entity.api';
import { ApiProvider } from '../core/providers/api.provider';
import {
  IEditResourceAvailabilityCondition
} from '../data-models/resource-availability-condition/edit-resource-availability-condition.interface';
import {
  IResourceAvailabilityCondition
} from '../data-models/resource-availability-condition/resource-availability-condition.interface';

@Injectable({
  providedIn: 'root',
})
export class ResourceAvailabilityConditionApiService extends EntityApi<
  IResourceAvailabilityCondition,
  IEditResourceAvailabilityCondition
> {
  protected readonly api = 'sourceData/resourceAvailabilityCondition';

  constructor(apiProvider: ApiProvider) {
    super(apiProvider);
  }
}
