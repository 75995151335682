import { IMiningPlanVolume } from 'src/app/data-models/mining-plan-volume/mining-plan-volume.interface';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextComponent } from 'src/app/modules/ui/components/text/text.component';
import { ModalComponent } from 'src/app/modules/ui/components/modal/modal.component';
import { ModalBase } from 'src/app/modules/ui/base/modal.base';
import { FormControl, FormGroup } from '@angular/forms';
import { IMiningPlan } from 'src/app/data-models/mining-plan/mining-plan.interface';
import { ModalMiningPlanFormComponent } from '../modal-mining-plan-form/modal-mining-plan-form.component';
import { IDataInstance } from 'src/app/data-models/enrichment-standard/data-instance.interface';
import { ToggleComponent } from 'src/app/modules/forms/components/toggle/toggle.component';
import * as moment from 'moment';
import { ModalMiningPlanActionsComponent } from '../modal-mining-plan-actions/modal-mining-plan-actions.component';

@Component({
  selector: 'modal-mining-plan',
  templateUrl: './modal-mining-plan.component.html',
  styleUrls: ['./modal-mining-plan.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ModalComponent,
    TextComponent,
    ModalMiningPlanFormComponent,
    ModalMiningPlanActionsComponent,
    ToggleComponent,
  ],
})
export class ModalMiningPlanComponent extends ModalBase implements OnInit, AfterViewInit {
  data!: IMiningPlan[];
  dataIsArray = false;
  updateData!: () => void;
  formData!: FormGroup;
  isShow = false;

  dataInstance!: IDataInstance;

  readonly _array = Array;

  currentData!: string | null;

  toggleList = ['Редактирование', 'Копирование'];
  toggleControl = new FormControl<string | null>(this.toggleList[0]);

  arrayListMiningPlanVolume: IMiningPlanVolume[][] = [];
  removeListIds: number[] = [];

  constructor(cdRef: ChangeDetectorRef) {
    super(cdRef);
  }

  ngOnInit(): void {
    this.dataIsArray = Array.isArray(this.data);
    this.initData();
  }

  ngAfterViewInit(): void {
    this.initModal();
  }

  isVisibleToggle(): boolean {
    if (this.data.length > 0) {
      const amountIds = this.data.reduce((acc, item) => acc + (item.id ? +item.id : 0), 0);
      return amountIds !== 0;
    } else {
      return false;
    }
  }

  initData(): void {
    if (this.data.length === 1)
      this.currentData = this.data ? moment(this.data[0].dateMining).format('DD.MM.YYYY') : null;
  }

  getForm(form: FormGroup): void {
    this.formData = form;
  }

  getArrayListMiningPlanVolume(value: IMiningPlanVolume[][]): void {
    this.arrayListMiningPlanVolume = value;
  }

  getRemoveListIds(value: number[]): void {
    this.removeListIds = value;
  }
}
