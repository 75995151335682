import { Injectable } from '@angular/core';
import { EntityApi } from '../core/abstract/entity.api';
import { ApiProvider } from '../core/providers/api.provider';
import {
  IEditOperationalCommodityShipment,
  IOperationalCommodityShipment,
} from '../data-models/operational-commodity-shipment/operational-commodity-shipment.interface';

@Injectable({
  providedIn: 'root',
})
export class OperationalCommodityShipmentApiService extends EntityApi<
  IOperationalCommodityShipment,
  IEditOperationalCommodityShipment
> {
  protected readonly api = 'result/operationalCommodityShipment';

  constructor(apiProvider: ApiProvider) {
    super(apiProvider);
  }
}
