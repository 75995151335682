export enum DictionariesEnum {
  Customer = 'customer',
  Station = 'station',
  TrainPicking = 'trainPicking',
  Mark = 'mark',
  MaterialType = 'materialType',
  Warehouse = 'warehouse',
  WarehouseForOperatingPlan = 'warehouseForOperatingPlan',
  Layer = 'layer',
  Profile = 'profile',
  Area = 'area',
}
