import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { SpinnerService } from '../../../../ui/services/spinner.service';
import { ActionModal } from '../../../../ui/base/action-modal';
import { ActivatedRoute, Params } from '@angular/router';
import { InternalLogisticsService } from '../../../services/internal-logistics.service';
import { finalize, forkJoin, mergeMap, take, takeWhile } from 'rxjs';
import { IProductionPlan } from '../../../../../data-models/production-plan/production-plan.interface';
import {
  IInternalLogistics,
  IInternalLogisticsTransform,
} from '../../../../../data-models/internal-logistics/internal-logistics.interface';
import { DatePipe, NgForOf, NgIf } from '@angular/common';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { SectionComponent } from '../../../../ui/components/section/section.component';
import { ShippingPlanCustomerNamePipe } from '../../../../data/pipes/shipping-plan/shipping-plan-customer-name.pipe';
import { TextComponent } from '../../../../ui/components/text/text.component';
import { IJournal } from 'src/app/data-models/enrichment-standard/journal.interface';
import * as moment from 'moment';
import { IDataColumnTable } from 'src/app/data-models/data-column-table/data-column-table.interface';
import { ThSortFilterComponent } from '../../../../ui/components/th-sort-filter/th-sort-filter.component';
import { InternalLogisticsBorderHiddenPipe } from '../../../pipes/internal-logistics/internal-logistics-border-hidden.pipe';
import { WeekendDayPipe } from '../../../../../core/pipes/weekend-day.pipe';
import { InternalLogisticsValueCellPipe } from '../../../pipes/internal-logistics/internal-logistics-value-cell.pipe';
import { InternalLogisticsHorizontalLinePipe } from '../../../pipes/internal-logistics/internal-logistics-horizontal-line.pipe';
import { FilterSortByColumnService } from 'src/app/modules/ui/services/filter-sort-by-column.service';
import { OperationalPlanColorShiftPipe } from '../../../pipes/operational-plan-color-shift.pipe';
import { RedirectDataService } from 'src/app/modules/data/services/redirect-data.service';

@Component({
  selector: 'calendar-plan-internal-logistics-list',
  templateUrl: './internal-logistics-list.component.html',
  styleUrls: ['./internal-logistics-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [InternalLogisticsService],
  imports: [
    DatePipe,
    MatSortModule,
    MatTableModule,
    NgForOf,
    NgIf,
    SectionComponent,
    ShippingPlanCustomerNamePipe,
    TextComponent,
    ThSortFilterComponent,
    InternalLogisticsBorderHiddenPipe,
    WeekendDayPipe,
    InternalLogisticsValueCellPipe,
    InternalLogisticsHorizontalLinePipe,
    OperationalPlanColorShiftPipe,
  ],
})
export class InternalLogisticsListComponent
  extends ActionModal
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('modal', { read: ViewContainerRef, static: true }) modal!: ViewContainerRef;

  @ViewChild('table', { read: ElementRef })
  table!: ElementRef<HTMLTableElement>;

  @Output() getCurrentTable = new EventEmitter();

  @Input() id!: string | undefined;

  isShow = false;
  isAlive = true;
  isAliveFiltering = false;
  isOperationalPlan = false;

  productionPlan!: IProductionPlan;

  data!: IInternalLogisticsTransform[];
  rawData!: IInternalLogisticsTransform[];

  journal!: IJournal;
  days!: number;

  mapKeyId = new Map<string, IInternalLogistics[]>();
  directionMarkMap = new Map<string, string[]>();
  totalValueMap = new Map<string, { day: number; amount: string }[]>();
  amountTotalMap = new Map<string, string>();

  columns: IDataColumnTable[] = [
    { name: 'index', display: '#' },
    { name: 'mark', display: 'Марка' },
  ];

  columnsDays!: IDataColumnTable[];
  columnsDaysTotal!: IDataColumnTable[];
  columnsDaysTotalDirection!: IDataColumnTable[];

  displayedColumns!: string[];
  displayedColumnsTotal!: string[];
  displayedColumnsDaysTotalDirection!: string[];

  listDatesForOP: string[] = [];
  displayedRowDatesForOP: string[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private ss: SpinnerService,
    private internalLogisticsService: InternalLogisticsService,
    private filterSortByColumnService: FilterSortByColumnService,
    private redirectDataService: RedirectDataService,
  ) {
    super();
  }
  ngOnDestroy(): void {
    this.isAlive = false;
  }

  ngOnInit(): void {
    this.isOperationalPlan = !!this.activatedRoute.parent?.snapshot.data['isOperationalPlan'];

    this.ss.startSpinner();

    this.activatedRoute.params
      .pipe(
        takeWhile(() => this.isAlive),
        mergeMap((params: Params) => {
          const idJournal =
            this.activatedRoute.parent?.snapshot.params['calendarPlanId'] ||
            this.activatedRoute.snapshot.params['calendarPlanId'];

          this.redirectDataService.setLastJournal(
            this.activatedRoute,
            params['productionPlanId'] || this.id,
            idJournal,
          );
          return forkJoin([
            this.internalLogisticsService.getProductionPlan(params['productionPlanId'] || this.id),
            this.internalLogisticsService.getJournal(idJournal, this.isOperationalPlan),
          ]);
        }),
      )
      .subscribe(([productionPlan, journal]) => {
        this.productionPlan = productionPlan;
        this.journal = journal;

        this.setAmountsDays();

        this.internalLogisticsService
          .getListItems(productionPlan.id)
          .pipe(take(1), finalize(this.ss.stopSpinner))
          .subscribe((res: IInternalLogistics[]) => {
            this.mapKeyId.clear();
            this.directionMarkMap.clear();
            this.totalValueMap.clear();

            this.initializeListColumns();

            this.data = this.internalLogisticsService.initTransformData(
              res,
              this.journal,
              this.mapKeyId,
              this.isOperationalPlan,
            );
            console.log(this.data);

            this.rawData = [...this.data];

            this.internalLogisticsService.createTotalValues(
              this.data,
              this.directionMarkMap,
              this.totalValueMap,
              this.mapKeyId,
              this.days,
              this.amountTotalMap,
            );

            if (!this.isAliveFiltering) {
              this.subscribeSortingFiltering();
              this.isAliveFiltering = true;
            }

            this.ss.stopSpinner();
            this.cdr.detectChanges();
          }, this.ss.stopSpinner);
      });
  }

  ngAfterViewInit() {
    this.getCurrentTable.emit(this.table.nativeElement);
  }

  setAmountsDays() {
    if (this.isOperationalPlan) {
      this.days = moment(this.journal.datePlanFinished).diff(
        moment(this.journal.datePlanStarted),
        'hours',
      );

      const amountDates = Math.ceil(this.days / 24);
      this.listDatesForOP = [];
      for (let index = 1; index <= amountDates; index++) {
        this.listDatesForOP.push(`dateStarted${index}`);
      }

      this.displayedRowDatesForOP = ['empty', ...this.listDatesForOP];
    } else {
      this.days = moment(this.journal.datePlanFinished).diff(
        moment(this.journal.datePlanStarted),
        'days',
      );
    }
  }

  getAmountColsForDate(index: number): number {
    const maxLastIndex = index * 24;
    if (maxLastIndex <= this.days) return 24;

    return maxLastIndex - this.days;
  }

  getDate(index: number): string {
    const firstDate = moment(this.journal.datePlanStarted).utcOffset(0, true);
    return firstDate.add(index, 'days').format('DD.MM.YY');
  }

  getHours(value: number): number {
    return value % 24 || 24;
  }

  private initializeListColumns(): void {
    this.columnsDays = Array.from(Array(this.days), (_, index) => ({
      name: `c${index + 1}`,
      display: `${index + 1}`,
    }));

    this.columnsDaysTotalDirection = Array.from(Array(this.days), (_, index) => ({
      name: `t${index + 1}`,
      display: `${index + 1}`,
    }));

    this.columnsDaysTotal = Array.from(Array(this.days), (_, index) => ({
      name: `tt${index + 1}`,
      display: `${index + 1}`,
    }));

    this.displayedColumns = [...this.columns, ...this.columnsDays].map((col) => col.name);

    this.displayedColumnsTotal = ['index-total', ...this.columnsDaysTotal.map((col) => col.name)];

    this.displayedColumnsDaysTotalDirection = [
      'index-direction',
      ...this.columnsDaysTotalDirection.map((col) => col.name),
    ];

    this.displayedColumns.push('total-row');
    this.displayedColumnsDaysTotalDirection.push('total-row-direction');
    this.displayedColumnsTotal.push('total-row-amount');
  }

  getTotalValue(value: string, i: number): string {
    const current = this.totalValueMap.get(value);
    return current ? (current[i].amount === '0' ? '' : current[i].amount) : '';
  }

  subscribeSortingFiltering(): void {
    this.activatedRoute.queryParams.pipe(takeWhile(() => this.isAlive)).subscribe((query) => {
      this.data = this.filterSortByColumnService.sort(query, 'direction', this.rawData);
      this.data = this.filterSortByColumnService.filter(query, this.data);

      this.internalLogisticsService.createTotalValues(
        this.data,
        this.directionMarkMap,
        this.totalValueMap,
        this.mapKeyId,
        this.days,
        this.amountTotalMap,
      );

      this.cdr.detectChanges();
    });
  }

  getAmountTotalVolume(value: string): string {
    const current = this.amountTotalMap.get(value);
    return current ? current : '';
  }

  getTotalVolumeRow(volume: number): string {
    if (!volume) return '';

    return (Math.round((volume / 1000) * 100) / 100).toString();
  }
}
