<div class="warehouse-dynamics-list-root">
  <nguk-section>
    <div class="sticky-container" tabindex="0">
      <table
        mat-table
        #table
        [dataSource]="data"
        [class.table-padding]="true"
        multiTemplateDataRows
      >
        <ng-container [matColumnDef]="'empty'">
          <th
            mat-header-cell
            *matHeaderCellDef
            [attr.colspan]="columns.length"
            class="horizontal-alignment"
          ></th>
        </ng-container>

        <ng-container *ngIf="!isOperationalPlan; else operationalDate">
          <ng-container [matColumnDef]="'dateStarted'">
            <th
              mat-header-cell
              *matHeaderCellDef
              [attr.colspan]="days"
              class="horizontal-alignment"
            >
              <nguk-text weight="400" styleName="text-xs" *ngIf="journal"
                >График с {{ journal.datePlanStarted | date: 'dd.MM.yyyy' }}
              </nguk-text>
            </th>
          </ng-container>
        </ng-container>

        <ng-template #operationalDate>
          <ng-container *ngFor="let date of listDatesForOP; let i = index">
            <ng-container [matColumnDef]="'dateStarted' + (i + 1)">
              <th
                mat-header-cell
                *matHeaderCellDef
                class="horizontal-alignment position-center"
                [attr.colspan]="getAmountColsForDate(i + 1)"
              >
                <nguk-text weight="400" styleName="text-xs">
                  {{ getDate(i) }}
                </nguk-text>
              </th>
            </ng-container>
          </ng-container>
        </ng-template>

        <ng-container matColumnDef="index-total" [sticky]="true">
          <td mat-cell *matCellDef="let row; let i = index" [attr.colspan]="2">
            <nguk-text weight="400" styleName="text-xs" *ngIf="journal">
              {{ id ? 'Итого по ' + (isOperationalPlan ? 'часам' : 'дням') : '' }}
            </nguk-text>
          </td>
        </ng-container>

        <ng-container matColumnDef="total-warehouse" [sticky]="true">
          <td mat-cell *matCellDef="let row; let i = index" [attr.colspan]="2">
            <nguk-text weight="600" styleName="text-xs">
              {{ row.warehouse?.title === 'Путь_9' ? 'Путь_8_9' : row.warehouse?.title ?? '' }}
            </nguk-text>
          </td>
        </ng-container>

        <ng-container matColumnDef="balance-total" [sticky]="true">
          <td mat-cell *matCellDef="let row; let i = index">
            <nguk-text weight="600" styleName="text-xs">
              {{ getAmountTotalBalanceValue(row.warehouse.title) }}
            </nguk-text>
          </td>
        </ng-container>

        <ng-container matColumnDef="amount-balance-total" [sticky]="true">
          <td mat-cell *matCellDef="let row; let i = index">
            <nguk-text weight="600" styleName="text-xs">
              {{ getAmountTotalBalanceValue('total') }}
            </nguk-text>
          </td>
        </ng-container>

        <ng-container matColumnDef="total-row">
          <th mat-header-cell *matHeaderCellDef>
            <nguk-text weight="600" styleName="text-xs"> Итого </nguk-text>
          </th>
          <td
            mat-cell
            *matCellDef="let row; let ind = dataIndex"
            [class.border-none]="ind | warehouseDynamicsBorderHidden: data"
          >
            <nguk-text weight="600" styleName="text-xs">
              {{ getTotalVolumeRow(row.totalRow) }}
            </nguk-text>
          </td>
        </ng-container>

        <ng-container matColumnDef="total-row-warehouse">
          <td mat-cell *matCellDef="let row">
            <nguk-text weight="600" styleName="text-xs">
              {{ getAmountTotalVolume(row.warehouse.title) }}
            </nguk-text>
          </td>
        </ng-container>

        <ng-container matColumnDef="total-row-amount">
          <td mat-cell *matCellDef="let row">
            <nguk-text weight="600" styleName="text-xs">
              {{ getAmountTotalVolume('total') }}
            </nguk-text>
          </td>
        </ng-container>

        <ng-container
          *ngFor="let item of columns; let i = index"
          [matColumnDef]="item.name"
          [sticky]="true"
        >
          <th mat-header-cell *matHeaderCellDef>
            <nguk-th-sort-filter
              [columnName]="item.name"
              [columnDisplay]="item.display"
              [rawData]="rawData"
            ></nguk-th-sort-filter>
          </th>

          <td
            mat-cell
            *matCellDef="let row; let ind = dataIndex"
            [class.border-none]="ind | warehouseDynamicsBorderHidden: data"
          >
            <nguk-text weight="400" styleName="text-xs" [class.opacity-text]="!row.hasDynamics">
              {{
                item.name === 'index' && row.warehouse !== 'Итого'
                  ? ind + 1
                  : row.warehouse === 'Итого'
                    ? ''
                    : item.name === 'ost'
                      ? (row | warehouseDynamicsValueCell: 0 : mapKeyId : isWagonDynamics)
                      : row[item.name]?.title || row[item.name]
              }}
            </nguk-text>
          </td>
        </ng-container>

        <ng-container *ngFor="let item of columnsDays; let i = index" [matColumnDef]="item.name">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="day-header-cell"
            [class.day-firstShift]="
              isOperationalPlan && !(+item.display | operationalPlanColorShift)
            "
            [class.day-secondShift]="
              isOperationalPlan && (+item.display | operationalPlanColorShift)
            "
            [class.day-weekend]="
              !isOperationalPlan && (+item.display | ngukWeekendDayPipe: journal.datePlanStarted)
            "
          >
            <nguk-text
              weight="400"
              styleName="text-xs"
              class="opacity-text"
              *ngIf="!isOperationalPlan; else operationalDay"
            >
              {{ item.display }}
            </nguk-text>

            <ng-template #operationalDay>
              <nguk-text weight="400" styleName="text-xs" class="opacity-text"
                >{{ getHours(+item.display) }}
              </nguk-text>
            </ng-template>
          </th>
          <td
            mat-cell
            *matCellDef="let row; let ind = dataIndex"
            class="day-mat-cell"
            [class.day-firstShift]="
              isOperationalPlan && !(+item.display | operationalPlanColorShift)
            "
            [class.day-secondShift]="
              isOperationalPlan && (+item.display | operationalPlanColorShift)
            "
            [class.day-weekend]="
              !isOperationalPlan && (+item.display | ngukWeekendDayPipe: journal.datePlanStarted)
            "
            [class.empty-row]="!row.hasDynamics"
            [class.value-void]="
              row | warehouseDynamicsValueCellVoid: +item.display : mapKeyId : isWagonDynamics
            "
            (dblclick)="openModal(row, item)"
            [class.border-none]="ind | warehouseDynamicsBorderHidden: data"
          >
            <nguk-text
              [weight]="row.nestingLevel === 0 || row.nestingLevel === 1 ? '600' : '400'"
              styleName="text-xs"
            >
              {{ row | warehouseDynamicsValueCell: +item.display : mapKeyId : isWagonDynamics }}
            </nguk-text>
          </td>
        </ng-container>

        <ng-container
          *ngFor="let item of columnsDaysTotal; let i = index"
          [matColumnDef]="item.name"
        >
          <td
            mat-cell
            *matCellDef="let row; let ind = dataIndex"
            class="day-mat-cell"
            [class.day-firstShift]="
              isOperationalPlan && !(+item.display | operationalPlanColorShift)
            "
            [class.day-secondShift]="
              isOperationalPlan && (+item.display | operationalPlanColorShift)
            "
            [class.day-weekend]="
              !isOperationalPlan && (+item.display | ngukWeekendDayPipe: journal.datePlanStarted)
            "
          >
            <nguk-text weight="600" styleName="text-xs">
              {{ 'total' | warehouseDynamicsTotalValueCell: i : totalValueMap }}
            </nguk-text>
          </td>
        </ng-container>

        <ng-container
          *ngFor="let item of columnsDaysTotalWarehouse; let i = index"
          [matColumnDef]="item.name"
        >
          <td mat-cell *matCellDef="let row; let ind = dataIndex" class="day-mat-cell">
            <nguk-text weight="600" styleName="text-xs">
              {{ row.warehouse.title | warehouseDynamicsTotalValueCell: i : totalValueMap }}
            </nguk-text>
          </td>
        </ng-container>

        <ng-container *ngIf="!isOperationalPlan; else operationalDateRow">
          <tr mat-header-row *matHeaderRowDef="['empty', 'dateStarted']; sticky: true"></tr>
        </ng-container>

        <ng-template #operationalDateRow>
          <tr mat-header-row *matHeaderRowDef="displayedRowDatesForOP; sticky: true"></tr>
        </ng-template>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
          class="sticky-row"
        ></tr>

        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumnsTotal; let ind = dataIndex"
          class="total total-result"
          [class.hidden-total]="ind !== 0"
        ></tr>

        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumnsTotalWarehouse; let ind = dataIndex"
          class="total total-warehouse"
          [class.row-hidden]="ind | warehouseDynamicsHorizontalLine: data"
        ></tr>

        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </nguk-section>
</div>

<ng-template #modal></ng-template>
