import { NsiTableTypeEnum } from '../../modules/nsi/enums/nsi-table-type.enum';

export class EditDictionaryModel {
  constructor(
    public dataInstance: { id: string },
    public title: string,
    public format: NsiTableTypeEnum,
    public ordinal: number,
    public id?: number,
  ) {}
}
