import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enrichmentStandardBackground',
  standalone: true,
})
export class EnrichmentStandardBackgroundPipe implements PipeTransform {
  transform(value: string): boolean {
    return value.includes('Concentrate') || value.includes('tk1Sludge');
  }
}
