import { IEditTaskLog } from '../../../../../../data-models/task-log/edit-create-task-log.interface';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ButtonComponent } from 'src/app/modules/ui/components/button/button.component';
import { SvgIconsEnum } from 'src/app/core/enums/svg-icons.enum';
import { TextComponent } from 'src/app/modules/ui/components/text/text.component';
import { CalculationsService } from 'src/app/modules/calculations/services/calculations.service';
import { FormControl, FormGroup } from '@angular/forms';
import { TStatusClosed } from 'src/app/modules/ui/types/status-closed-modal.type';
import { IEditJournal } from 'src/app/data-models/enrichment-standard/journal.interface';
import { take, forkJoin, finalize, switchMap } from 'rxjs';
import { TaskLogApiService } from 'src/app/api/task-log.api.service';
import * as moment from 'moment';
import { SpinnerService } from '../../../../../ui/services/spinner.service';
import { ModalJournalListShortService } from 'src/app/modules/calendar-plan/services/modal-journal-list-short.service';
import { CheckboxComponent } from '../../../../../forms/components/checkbox/checkbox.component';

@Component({
  selector: 'calendar-plan-modal-journal-list-short-actions-footer',
  templateUrl: './modal-journal-list-short-actions-footer.component.html',
  styleUrls: ['./modal-journal-list-short-actions-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    MatIconModule,
    MatTooltipModule,
    TextComponent,
    CheckboxComponent,
  ],
  providers: [CalculationsService],
})
export class ModalJournalListShortActionsFooterComponent implements OnInit {
  @Input() formData!: FormGroup;
  @Input() journal!: IEditJournal;
  @Input() isCopy!: boolean;
  @Input() isOperationalPlan = false;
  @Input() isHasCalculated = false;

  @Output() isFulfilled = new EventEmitter<TStatusClosed>();

  readonly svgIconsEnum = SvgIconsEnum;

  plannerId = '';

  graphsControl = new FormControl(false);

  taskLogStatus = ['Ошибка расчета'];

  constructor(
    private ss: SpinnerService,
    private calculationsService: CalculationsService,
    private taskLogApiService: TaskLogApiService,
    private cdr: ChangeDetectorRef,
    private modalJournalListShortService: ModalJournalListShortService,
  ) {}

  ngOnInit(): void {
    this.initCalculationsId();
  }

  calculate(): void {
    this.ss.startSpinner();

    this.modalJournalListShortService
      .changeJournal(this.formData, this.isOperationalPlan)
      .pipe(
        switchMap(() => {
          const body: IEditTaskLog = {
            awaitingGraph: this.graphsControl.value as boolean,
          };

          this.isOperationalPlan
            ? (body.operationalPlan = {
                id: this.formData.value.id,
                note: this.formData.value.note,
              })
            : (body.calculationLog = {
                id: this.formData.value.id,
                note: this.formData.value.note,
              });
          return this.taskLogApiService.create(body);
        }),
        take(1),
        finalize(this.ss.stopSpinner),
      )
      .subscribe(
        () => this.isFulfilled.emit('success'),
        () => this.isFulfilled.emit('error'),
      );
  }

  initCalculationsId(): void {
    if (!this.journal?.taskLogs.length) {
      return;
    }

    this.ss.startSpinner();
    const taskLogs = this.journal.taskLogs;
    const id = taskLogs[taskLogs.length - 1].id;

    this.taskLogApiService
      .getOne(id.toString())
      .pipe(take(1), finalize(this.ss.stopSpinner))
      .subscribe((taskLog) => {
        const date = moment(taskLog.dateCreated).utcOffset(0).format('YYYY-MM-DD-HH-mm-ss');

        const defaultId = `Planner_${date}_${taskLog.id}`;
        this.plannerId = taskLog.longId ?? defaultId;

        this.cdr.detectChanges();
      });
  }
}
